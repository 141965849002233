import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, Typography } from '@mui/material';
import { DataTable, DataTableColumn } from '../../tables';
import { UserRole } from '../../enums';
import { UnderlinedLink } from '..';
import { CompanyOrderPermissions } from './CompanyUserPermissionsList.component';

interface Props {
  title: string;
  companyPermissions?: CompanyOrderPermissions[];
  editCompanyPermissions: (updatedCompany: CompanyOrderPermissions) => void;
}

export const CompanyPermissionsTable = ({
  title,
  companyPermissions,
  editCompanyPermissions,
}: Props) => {
  const { t } = useTranslation();
  const permissionsOrder = [UserRole.Buyer, UserRole.Sales, UserRole.General];

  const scrollToAnchor = (id: string) => {
    const element = document.getElementById(
      'permission_section_' + id.replace(/\s+/g, '_').toLowerCase()
    );
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sortedPermissions =
    companyPermissions &&
    companyPermissions.sort(
      (a, b) =>
        permissionsOrder.indexOf(a.name as UserRole) -
        permissionsOrder.indexOf(b.name as UserRole)
    );

  return (
    <>
      <Grid item paddingTop={'3em'}>
        <Typography
          component="h3"
          variant="h3"
          color="primary"
          sx={{ scrollMarginTop: '80px' }}
        >
          {title}
        </Typography>
      </Grid>
      <DataTable data={sortedPermissions} loading={!companyPermissions}>
        <DataTableColumn
          property="name"
          title={t('companies.orderFilePermissions.columnTitleRoles')}
          output={(item) => (
            <UnderlinedLink
              onClick={() => {
                scrollToAnchor(t(`users.roles.${item}`));
              }}
            >
              {t(`users.roles.${item}`)}
            </UnderlinedLink>
          )}
        />
        <DataTableColumn
          textAlign="center"
          property="shipping"
          title={t('orders.table.shippingDoc')}
          mobileInlineHeaderDirection="rtl"
          output={(item: boolean, permission: CompanyOrderPermissions) => (
            <Checkbox
              color="secondary"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                editCompanyPermissions({
                  ...permission,
                  shipping: e.target.checked,
                } as CompanyOrderPermissions);
              }}
              checked={item}
            />
          )}
        />
        <DataTableColumn
          textAlign="center"
          property="invoiceCredit"
          title={t('orders.table.invoicesDoc')}
          mobileInlineHeaderDirection="rtl"
          output={(item: boolean, permission: CompanyOrderPermissions) => (
            <Checkbox
              color="secondary"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                editCompanyPermissions({
                  ...permission,
                  invoiceCredit: e.target.checked,
                } as CompanyOrderPermissions);
              }}
              checked={item}
            />
          )}
        />
        <DataTableColumn
          textAlign="center"
          property="confirmation"
          title={t('orderDetails.table.confirmationHeader')}
          mobileInlineHeaderDirection="rtl"
          output={(item: boolean, permission: CompanyOrderPermissions) => (
            <Checkbox
              color="secondary"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                editCompanyPermissions({
                  ...permission,
                  confirmation: e.target.checked,
                } as CompanyOrderPermissions);
              }}
              checked={item}
            />
          )}
        />
      </DataTable>
    </>
  );
};
