import { Box } from '@mui/material';
import { MenuItemToolbarProps } from './MenuItemToolbar.props';

export const MenuItemToolbar = ({ children }: MenuItemToolbarProps) => (
  <Box
    sx={{
      mt: 0,
      mb: 0,
      display: { xs: 'none', md: 'flex' },
    }}
  >
    {children}
  </Box>
);
