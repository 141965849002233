import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SignUpConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid>
      <Typography variant="h2">{t('signUp.confirmation.title')}</Typography>

      <Typography>{t('signUp.confirmation.message')}</Typography>

      <Grid mt={5}>
        <Button variant="outlined" onClick={() => navigate('/login')}>
          {t('signUp.confirmation.goBack')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignUpConfirmation;
