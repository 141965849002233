import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { usePortalMetadata } from '../../hooks';
import { InventoryLastSyncDateProps } from './InventoryLastSyncDate.props';

export const InventoryLastSyncDate = ({
  timeZone,
}: InventoryLastSyncDateProps) => {
  const { t, i18n } = useTranslation();
  const { item: portalMetadata } = usePortalMetadata();

  if (!portalMetadata || !portalMetadata.lastDataSync || !timeZone) {
    return null;
  }

  const dateTime = DateTime.fromMillis(portalMetadata.lastDataSync.toMillis());

  return (
    <Box sx={{ fontStyle: 'italic', mt: 2, mb: { xs: 8, md: 2 } }}>
      {t('inventory.lastUpdated')}{' '}
      {dateTime
        .setLocale(i18n.language)
        .setZone(timeZone)
        .toLocaleString(DateTime.DATETIME_MED)}
    </Box>
  );
};
