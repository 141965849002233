import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import * as Sx from '../MultipleReservationsDrawer.styles';
import { useCompany } from '../../../hooks';
import { ImpersonatedCustomer, Nullable } from '../../../types';

export const MultipleReservationsDrawerAgent = ({
  impersonatedCustomer,
}: {
  impersonatedCustomer: Nullable<ImpersonatedCustomer>;
}) => {
  const { t } = useTranslation();
  const { item: company } = useCompany(impersonatedCustomer?.company.companyId);

  return (
    <>
      {/* Icon Section */}
      <Grid
        item
        md={1}
        display="flex"
        textAlign="center"
        alignItems="center"
        sx={Sx.iconSectionAdmin}
      >
        <HowToRegIcon style={{ fontSize: '4rem' }} />
      </Grid>

      {/* Data Section */}
      <Grid
        container
        item
        justifyContent={'space-evenly'}
        alignItems={'center'}
        md={8}
        sx={Sx.dataSection}
      >
        <Grid item md={6}>
          <Grid item>{t('inventory.multipleReservations.reservingFor')}</Grid>
          <Grid item sx={Sx.valueStylesAgent}>
            {impersonatedCustomer?.personName}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.company')}</Grid>
          <Grid item sx={Sx.valueStylesAgent}>
            {impersonatedCustomer?.company.companyName}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.market')}</Grid>
          <Grid item sx={Sx.valueStylesAgent}>
            {company?.canSeeCSA ? 'CSA' : ''}
            {company?.canSeeCSA && company?.canSeeUL ? ', ' : ''}
            {company?.canSeeUL ? 'UL' : ''}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
