import { CollectionQueryOptions } from '../types';
import { ReelSize } from '../models/ReelSize';
import { useLocalCollection } from './useLocalCollection';

export function useReelSizes(options?: CollectionQueryOptions) {
  return useLocalCollection<ReelSize>(
    'PortalSettings/portal_settings/ReelSizes',
    [],
    options?.sorting === undefined
      ? { sortField: 'reelType', sortDirection: 'asc' }
      : options?.sorting || undefined,
    options?.pagination,
    options?.filters
  );
}
