export enum UserRole {
  AgencyAdmin = 'agencyAdmin',
  AgencyCustomerService = 'agencyCustomerService',
  NciAdmin = 'nciAdmin',
  NciCustomerService = 'nciCustomerService',
  NciStaff = 'nciStaff',
  Buyer = 'buyer',
  Sales = 'sales',
  Manager = 'manager',
  General = 'general',
}
