import { useMemo } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import notEmpty from '../../utils/notEmpty';
import { DropDownCheckboxListProps } from './DropDownCheckboxList.props';
import * as Sx from './DropDownCheckboxList.styles';

export const DropDownCheckboxList = <T,>({
  label,
  form,
  fields,
  fieldLabels,
  disabled,
  required,
}: DropDownCheckboxListProps<T>) => {
  const onChange = (event: SelectChangeEvent<number[]>) => {
    const newValues = {} as Partial<T>;

    for (const field of fields) {
      newValues[field] = false as Extract<boolean, T[keyof T]>;
    }

    for (const index of event.target.value) {
      if (typeof index === 'number') {
        newValues[fields[index]] = true as Extract<boolean, T[keyof T]>;
      }
    }

    form.setItem({ ...form.item, ...(newValues as T) });
  };

  const value = useMemo(
    () =>
      fields
        .map((field, index) => (form.item?.[field] ? index : undefined))
        .filter(notEmpty),
    [fields, form]
  );

  const error = fields.map((f) => form.errors[f]).filter((error) => error);

  return (
    <FormControl fullWidth error={error.length > 0} sx={{ mt: 1, mb: 1 }}>
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        fullWidth
        multiple
        required={required}
        disabled={disabled}
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selectedIndexes) =>
          selectedIndexes.map((index) => fieldLabels[index]).join(', ')
        }
        IconComponent={(props) => (
          <KeyboardArrowDownIcon {...props} sx={Sx.arrowIcon} />
        )}
      >
        {fields.map((field, index) => (
          <MenuItem key={index} value={index}>
            <Checkbox checked={!!form.item?.[field]} />
            <ListItemText primary={fieldLabels[index]} />
          </MenuItem>
        ))}
      </Select>
      {error.length > 0 && <FormHelperText>{error[0]}</FormHelperText>}
    </FormControl>
  );
};
