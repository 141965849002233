import { useCallback } from 'react';
import { OrderFilesPermissions } from '../models';
import { UserPermission } from '../enums';
import { usePermission } from './usePermission';

export const useOrderFilePermissions = () => {
  const { hasOneOfPermissions, userHasPermission } = usePermission();

  const getOrderFilePermissions = useCallback(() => {
    const permissions = {} as OrderFilesPermissions;
    if (
      hasOneOfPermissions([
        UserPermission.ViewOrders,
        UserPermission.ManageOrderFilesAccess,
      ])
    ) {
      permissions.confirmation = true;
      permissions.invoiceCredit = true;
      permissions.shipping = true;

      return permissions;
    }

    if (userHasPermission(UserPermission.ViewOrdersShipping)) {
      permissions.shipping = true;
    }
    if (userHasPermission(UserPermission.ViewOrdersInvoice)) {
      permissions.invoiceCredit = true;
    }
    if (userHasPermission(UserPermission.ViewOrdersConfirmation)) {
      permissions.confirmation = true;
    }

    return permissions;
  }, [hasOneOfPermissions, userHasPermission]);

  return {
    getOrderFilePermissions,
  };
};
