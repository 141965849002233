import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

import Formable from '../../types/Formable';
import { useObjectSubform } from '../../hooks';
import { TranslatedString } from '../../types';
import { Language } from '../../enums';

import { Show } from '../Show/Show.component';
import { TranslatedFieldsProps } from './TranslatedFields.props';
import * as Sx from './TranslatedFields.styles';

export const TranslatedFields = <T,>({
  icon,
  tooltipText,
  label,
  field,
  form,
  render,
}: TranslatedFieldsProps<T>) => {
  const subform = useObjectSubform(
    form,
    field,
    {} as TranslatedString
  ) as Formable<TranslatedString>;
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h4" sx={Sx.label}>
        {label}
        <Show if={!!icon}>
          <Tooltip
            arrow
            title={
              <div dangerouslySetInnerHTML={{ __html: tooltipText ?? '' }} />
            }
          >
            <IconButton size="small">{icon}</IconButton>
          </Tooltip>
        </Show>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {render({
            form: subform,
            field: Language.English,
            label: t(`languages.${Language.English}`),
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {render({
            form: subform,
            field: Language.French,
            label: t(`languages.${Language.French}`),
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
