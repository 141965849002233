import { where } from 'firebase/firestore';

import { User } from '../models';
import { CollectionQueryOptions } from '../types/';
import { useCollection } from './useCollection';

export function useUsers(options?: CollectionQueryOptions) {
  return useCollection<User>(
    'Users',
    [where('isDeleted', '==', false)],
    options?.sorting || { sortField: 'name', sortDirection: 'asc' },
    options?.pagination,
    options?.filters
  );
}
