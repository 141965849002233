import { OrderFile } from '../models';
import { CollectionQueryOptions } from '../types/';
import { useLocalCollection } from './useLocalCollection';

export function useUnmappedFiles(options?: CollectionQueryOptions) {
  return useLocalCollection<OrderFile>(
    'UnmappedFiles',
    [],
    options?.sorting || {
      sortField: 'dateTimeUploaded',
      sortDirection: 'desc',
    },
    options?.pagination,
    options?.filters
  );
}
