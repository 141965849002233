import { useTranslation } from 'react-i18next';
import {
  usePagination,
  useCurrentReservations,
  useSorting,
  useReservationFilters,
  useCurrentUser,
} from '../../hooks/';
import { reservationReportCsv, reservationReportPdf } from '../../reports';
import { ReservationTable } from '..';
import { CurrentReservationTableProps } from './CurrentReservationTable.props';

export const CurrentReservationTable = ({
  canViewCompanyReservations = false,
  canViewAgencyReservations = false,
  filters,
  isAdmin = false,
  isAgent = false,
  mode,
}: CurrentReservationTableProps) => {
  const sorting = useSorting('expirationTime');
  const pagination = usePagination();
  const user = useCurrentUser();
  const { t, i18n } = useTranslation();

  const reservationFilters = useReservationFilters({
    canViewCompanyReservations,
    canViewAgencyReservations,
    filters,
    isAdmin,
    isAgent,
    mode,
  });

  const { list, loading, unpaginatedResults } = useCurrentReservations({
    filters: reservationFilters,
    sorting,
    pagination,
  });

  const generatePdfReport = () => {
    reservationReportPdf.generate(
      unpaginatedResults,
      filters,
      user,
      mode,
      t,
      i18n
    );
  };

  const generateCsvReport = () => {
    reservationReportCsv.generate(unpaginatedResults, user.measurementUnits, t);
  };

  return (
    <ReservationTable
      filters={filters}
      generatePdfReport={generatePdfReport}
      generateCsvReport={generateCsvReport}
      isAdmin={isAdmin}
      isAgent={isAgent}
      mode={mode}
      pagination={pagination}
      reservations={!loading ? list : undefined}
      sorting={sorting}
      queryFilters={reservationFilters}
    />
  );
};

export default CurrentReservationTable;
