import { SxProps } from '@mui/material';

const inheritSize: SxProps = {
  height: { xs: 'auto', md: 'inherit' },
  width: 'inherit',
};

export const drawerPaper: SxProps = {
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  height: { xs: '22vh', md: '120px' },
  backgroundColor: '#FAA72D',
  border: 'none',
  padding: { xs: '5px', md: 'unset' },
};

export const adminDrawer: SxProps = {
  ...drawerPaper,
  backgroundColor: 'primary.main',
  color: 'white',
};

export const iconSection: SxProps = {
  ...inheritSize,
  textAlign: 'center',
  justifyContent: 'center',
  backgroundColor: '#E27305',
  color: 'white',
};

export const dataSection: SxProps = {
  ...inheritSize,
  '& .MuiGrid-item': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const adminDataSection: SxProps = {
  ...dataSection,
  alignItems: 'center',
};

export const buttonSection: SxProps = {
  ...inheritSize,
};

export const valueStyles: SxProps = {
  fontSize: { xs: '1em', md: '1.2rem' },
  fontWeight: 'bold',
};

export const buttonStyles: SxProps = {
  width: { xs: '80vw', md: 'inherit' },
  height: 'inherit',
};
