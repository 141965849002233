import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';

import { ConsultationMode } from '../enums';
import { Filter, ReservationFilters } from '../types';
import { useCurrentUser } from './useCurrentUser';

export function useReservationFilters(options: {
  canViewCompanyReservations?: boolean;
  canViewAgencyReservations?: boolean;
  filters: ReservationFilters;
  isAdmin?: boolean;
  isAgent?: boolean;
  mode: ConsultationMode;
}) {
  const {
    canViewCompanyReservations,
    canViewAgencyReservations,
    isAdmin,
    isAgent,
    filters,
    mode,
  } = options;
  const user = useCurrentUser();

  return useMemo(
    () =>
      [
        ...(!isAdmin &&
        !isAgent &&
        (!canViewCompanyReservations || filters.showOnlyMyReservations)
          ? [['madeForId', '==', user.id]]
          : []),
        ...(isAgent &&
        (!canViewAgencyReservations || filters.showOnlyMyReservations)
          ? [['madeForId', '==', user.id]]
          : []),
        ...(isAgent &&
        canViewAgencyReservations &&
        !filters.showOnlyMyReservations
          ? [['agencyId', '==', user.companyRef.id]]
          : []),
        ...(canViewCompanyReservations && !filters.showOnlyMyReservations
          ? [['companyId', '==', user.companyRef.id]]
          : []),
        ...(filters.companyId ? [['companyId', '==', filters.companyId]] : []),
        ...(filters.partNumber
          ? [['reel.normalizedCableId', '==', filters.partNumber]]
          : []),
        ...(filters.reservationNumber
          ? [['reservationNumber', '==', filters.reservationNumber]]
          : []),
        ...(mode === ConsultationMode.History &&
        filters.startDate &&
        filters.startDate.isValid
          ? [
              [
                'reservedTime',
                '>=',
                Timestamp.fromMillis(
                  filters.startDate.setZone(user.timeZone).toMillis()
                ),
              ],
            ]
          : []),
        ...(mode === ConsultationMode.History &&
        filters.endDate &&
        filters.endDate.isValid
          ? [
              [
                'reservedTime',
                '<=',
                Timestamp.fromMillis(
                  filters.endDate
                    .setZone(user.timeZone)
                    .plus({ days: 1 })
                    .toMillis()
                ),
              ],
            ]
          : []),
      ] as Filter[],
    [
      canViewCompanyReservations,
      canViewAgencyReservations,
      filters,
      mode,
      isAdmin,
      isAgent,
      user,
    ]
  );
}
