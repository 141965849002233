import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import * as Sx from '../MultipleReservationsDrawer.styles';
import { Reel, User } from '../../../models';

export const MultipleReservationsDrawerUser = ({
  effectiveUser,
  reservationsCount,
  selectedReels,
}: {
  effectiveUser: User;
  reservationsCount: {
    customer: number;
    northernCables: number;
    total: number;
  };
  selectedReels: Reel[];
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Icon Section */}
      <Grid
        item
        md={2}
        display="flex"
        textAlign="center"
        alignItems="center"
        sx={Sx.iconSectionUser}
      >
        <CheckCircleOutlineIcon style={{ fontSize: '4rem' }} />
      </Grid>
      {/* Data Section */}
      <Grid
        container
        item
        justifyContent={'space-evenly'}
        alignItems={'center'}
        md={7}
        sx={Sx.dataSection}
      >
        <Grid item md={4}>
          <Grid item>{t('inventory.multipleReservations.quantity')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {t('inventory.multipleReservations.quantitySelected', {
              quantity: selectedReels.length,
            })}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.market')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {effectiveUser?.canSeeCSA ? 'CSA' : ''}
            {effectiveUser?.canSeeCSA && effectiveUser?.canSeeUL ? ', ' : ''}
            {effectiveUser?.canSeeUL ? 'UL' : ''}
          </Grid>
        </Grid>

        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.reservations')}</Grid>
          <Grid
            container
            display="flex"
            flexDirection={'row'}
            justifyContent={'center'}
          >
            <Typography sx={Sx.valueStyles}>
              {reservationsCount.customer}
            </Typography>
            <Typography sx={Sx.valueStyles}>
              /{effectiveUser?.maxReservationsPerPeriod}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
