import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { FormattedTimestamp, UnderlinedLink } from '..';
import {
  useAuthentication,
  useCurrentUser,
  useOrderFilePermissions,
} from '../../hooks';
import { OrderFileType } from '../../enums';
import { OrderDetailsCardProps } from './OrderDetailsCard.props';

export const OrderDetailsCard = ({
  orderInstance,
  orderFilesByType,
  orderFilesLoading,
}: OrderDetailsCardProps) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const { isAgent } = useAuthentication();
  const { getOrderFilePermissions } = useOrderFilePermissions();

  const orderFilesPermissions = getOrderFilePermissions();

  const scrollToAnchor = (id: OrderFileType) => {
    const element = document.getElementById(`order_section_${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{ minWidth: 275, borderRadius: 0, borderColor: '#ebeff5' }}
      style={{ backgroundColor: '#f9f9f9' }}
    >
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* LEFT SECTION */}
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
            mb={2}
          >
            <Typography>
              <b>{t('orders.table.company')}:</b> {orderInstance?.companyName}
            </Typography>
            {orderInstance?.shipToName && isAgent && (
              <Typography>
                <b>{t('orders.table.shipTo')}:</b> {orderInstance?.shipToName}
              </Typography>
            )}
            {orderInstance?.agencyName && (
              <Typography>
                <b>{t('orders.table.agency')}:</b> {orderInstance?.agencyName}
              </Typography>
            )}
            <Typography>
              <b>{t('orders.table.poNumber')}:</b> {orderInstance?.poNumber}
            </Typography>
            <Grid display="flex" alignItems="center">
              <Typography sx={{ fontWeight: 'bold' }} mr={0.5}>
                {t('orders.table.lastModified')}:
              </Typography>
              <Typography>
                <FormattedTimestamp
                  timestamp={orderInstance?.dateModified}
                  timeZone={currentUser.timeZone}
                />
              </Typography>
            </Grid>
          </Stack>

          {/* RIGHT SECTION */}
          {!orderFilesLoading && (
            <Stack
              alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
              justifyContent="center"
              spacing={2}
              mb={2}
            >
              {orderFilesPermissions.shipping && (
                <UnderlinedLink
                  onClick={() => {
                    scrollToAnchor(OrderFileType.Shipping);
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }} mr={1}>
                    {t('orders.table.shippingDoc')}:{' '}
                    {orderFilesByType?.[OrderFileType.Shipping].length}
                  </Typography>
                </UnderlinedLink>
              )}
              {orderFilesPermissions.invoiceCredit && (
                <UnderlinedLink
                  onClick={() => {
                    scrollToAnchor(OrderFileType.Invoice);
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }} mr={1}>
                    {t('orders.table.invoicesDoc')}:{' '}
                    {orderFilesByType?.[OrderFileType.Invoice].length}
                  </Typography>
                </UnderlinedLink>
              )}
              {orderFilesPermissions.confirmation && (
                <UnderlinedLink
                  onClick={() => {
                    scrollToAnchor(OrderFileType.Confirmation);
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }} mr={1}>
                    {t('orders.table.orderConfirm')}:{' '}
                    {orderFilesByType?.[OrderFileType.Confirmation].length}
                  </Typography>
                </UnderlinedLink>
              )}
            </Stack>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
