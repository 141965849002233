import { useState, useEffect, useMemo } from 'react';
import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';
import { KeyboardArrowDown, Search as SearchIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { OrderFilters as OrderFiltersType } from '../../types';
import { getSelectableCompanies } from '../../utils';
import { OrderFiltersProps } from './OrderFilters.props';

export const OrderFilters = ({
  companies,
  filters,
  setFilters,
}: OrderFiltersProps) => {
  const { t } = useTranslation();
  const [searchField, setSearchField] = useState('');
  const [debouncedFilters] = useDebounce(searchField, 500);

  const selectableCompanies = useMemo(
    () => getSelectableCompanies(companies),
    [companies]
  );

  useEffect(() => {
    setFilters((value: OrderFiltersType) => {
      return {
        ...value,
        searchField,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters, setFilters]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        my: { xs: 2, md: 4 },
      }}
    >
      <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
        <TextField
          fullWidth
          label={t('orders.filters.searchField')}
          value={searchField || ''}
          onChange={(e) => setSearchField(e.currentTarget.value)}
          inputProps={{
            maxLength: 15,
            minLength: 6,
            placeholder: 'XXXXXXXX',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" sx={{ color: '#979797' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {companies && (
        <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
          <Autocomplete
            fullWidth={true}
            popupIcon={<KeyboardArrowDown />}
            options={selectableCompanies}
            getOptionLabel={(option) => option.name}
            value={
              selectableCompanies.find((c) => c.id === filters.companyId) ??
              null
            }
            onChange={(_, value) => {
              setFilters((prevValue) => {
                return {
                  ...prevValue,
                  companyId: value?.id || null,
                };
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label={t('orders.filters.company')} />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
