import { Country, ProvinceOrState } from '../enums/';

export const CountryProvincesOrStates: Record<Country, ProvinceOrState[]> = {
  CA: [
    ProvinceOrState.Alberta,
    ProvinceOrState.BritishColumbia,
    ProvinceOrState.Manitoba,
    ProvinceOrState.NewBrunswick,
    ProvinceOrState.NewfoundlandAndLabrador,
    ProvinceOrState.NovaScotia,
    ProvinceOrState.Ontario,
    ProvinceOrState.PrinceEdwardIsland,
    ProvinceOrState.Quebec,
    ProvinceOrState.Saskatchewan,
    ProvinceOrState.NorthwestTerritories,
    ProvinceOrState.Nunavut,
    ProvinceOrState.Yukon,
  ],
  US: [
    ProvinceOrState.Alabama,
    ProvinceOrState.Alaska,
    ProvinceOrState.Arizona,
    ProvinceOrState.Arkansas,
    ProvinceOrState.California,
    ProvinceOrState.Colorado,
    ProvinceOrState.Connecticut,
    ProvinceOrState.Delaware,
    ProvinceOrState.Florida,
    ProvinceOrState.Georgia,
    ProvinceOrState.Hawaii,
    ProvinceOrState.Idaho,
    ProvinceOrState.Illinois,
    ProvinceOrState.Indiana,
    ProvinceOrState.Iowa,
    ProvinceOrState.Kansas,
    ProvinceOrState.Kentucky,
    ProvinceOrState.Louisiana,
    ProvinceOrState.Maine,
    ProvinceOrState.Maryland,
    ProvinceOrState.Massachusetts,
    ProvinceOrState.Michigan,
    ProvinceOrState.Minnesota,
    ProvinceOrState.Mississippi,
    ProvinceOrState.Missouri,
    ProvinceOrState.Montana,
    ProvinceOrState.Nebraska,
    ProvinceOrState.Nevada,
    ProvinceOrState.NewHampshire,
    ProvinceOrState.NewJersey,
    ProvinceOrState.NewMexico,
    ProvinceOrState.NewYork,
    ProvinceOrState.NorthCarolina,
    ProvinceOrState.NorthDakota,
    ProvinceOrState.Ohio,
    ProvinceOrState.Oklahoma,
    ProvinceOrState.Oregon,
    ProvinceOrState.Pennsylvania,
    ProvinceOrState.RhodeIsland,
    ProvinceOrState.SouthCarolina,
    ProvinceOrState.SouthDakota,
    ProvinceOrState.Tennessee,
    ProvinceOrState.Texas,
    ProvinceOrState.Utah,
    ProvinceOrState.Vermont,
    ProvinceOrState.Virginia,
    ProvinceOrState.Washington,
    ProvinceOrState.WestVirginia,
    ProvinceOrState.Wisconsin,
    ProvinceOrState.Wyoming,
    ProvinceOrState.DistrictOfColumbia,
    ProvinceOrState.AmericanSamoa,
    ProvinceOrState.Guam,
    ProvinceOrState.NorthernMarianaIslands,
    ProvinceOrState.PuertoRico,
    ProvinceOrState.UnitedStatesMinorOutlyingIslands,
    ProvinceOrState.UnitedStatesVirginIslands,
  ],
};

export const CANADIAN_TAX_MAPPING: { [key: string]: number } = {
  [ProvinceOrState.Alberta]: 0.05,
  [ProvinceOrState.BritishColumbia]: 0.05,
  [ProvinceOrState.Manitoba]: 0.05,
  [ProvinceOrState.NewBrunswick]: 0.15,
  [ProvinceOrState.NewfoundlandAndLabrador]: 0.05,
  [ProvinceOrState.NovaScotia]: 0.05,
  [ProvinceOrState.Ontario]: 0.13,
  [ProvinceOrState.PrinceEdwardIsland]: 0.05,
  [ProvinceOrState.Quebec]: 0.05,
  [ProvinceOrState.Saskatchewan]: 0.05,
  [ProvinceOrState.NorthwestTerritories]: 0.05,
  [ProvinceOrState.Nunavut]: 0.05,
  [ProvinceOrState.Yukon]: 0.05,
  default: 0.13,
};
