import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

import { DecoratedHeader, Show, UnitaryModal } from '../components';
import { getPageTitle } from '../utils/pages';
import {
  usePageTitle,
  usePortalSettings,
  usePublicAuthentication,
} from '../hooks';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import { LanguagesUpper } from '../types/Languages';

const Login: React.FC = () => {
  const functions = useFunctions();
  const { t, i18n } = useTranslation();
  const { login } = usePublicAuthentication();
  const { item: portalSettings } = usePortalSettings();
  usePageTitle(getPageTitle('login'));
  const logUserActivity = httpsCallable(functions, 'logUserActivity');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const lang = i18n.language.toUpperCase() as LanguagesUpper;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
    login(data.get('email') as string, data.get('password') as string).then(
      (result) => {
        if (result !== 'success') {
          setLoading(false);
          setError(result);
        }

        logUserActivity({
          email: data.get('email'),
          success: result === 'success',
        });
      }
    );
  };

  return (
    <UnitaryModal>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, maxWidth: '25rem' }}
      >
        <DecoratedHeader
          icon={<LockRoundedIcon titleAccess={t('alt.lockIcon')} />}
          title={
            <>
              {t('application.title.line1')}
              <br />
              {t('application.title.line2')}
            </>
          }
        />
        <Show if={!!error}>
          <Alert severity="error">
            {error === 'disabled' ? (
              <Trans
                t={t}
                i18nKey="users.errors.userDeactivated"
                values={{
                  phone: formatPhoneNumber(
                    portalSettings?.contactPhoneNumber[lang] || ''
                  ),
                  email: portalSettings?.contactEmail[lang],
                }}
              />
            ) : (
              t('users.errors.invalidCredentials')
            )}
          </Alert>
        </Show>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('users.fields.email')}
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('users.fields.password')}
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Grid container justifyContent="flex-end" sx={{ mb: 3 }}>
          <Grid item>
            <Link
              to="/forgot-password"
              style={{ color: 'black', fontSize: '0.875rem' }}
            >
              {t('users.actions.forgotPassword')}
            </Link>
          </Grid>
        </Grid>
        <Button
          disabled={loading}
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t('users.actions.login')}
        </Button>
        <Button
          color="secondary"
          component={Link}
          fullWidth
          sx={{ mt: 1, mb: 2 }}
          to="/sign-up"
          type="submit"
          variant="outlined"
        >
          {t('users.actions.signUp')}
        </Button>
        <Grid container>
          <Grid item></Grid>
        </Grid>
      </Box>
    </UnitaryModal>
  );
};

export default Login;
