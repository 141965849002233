import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  IconButton,
  useTheme,
  Stack,
  Drawer,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import HowToRegIcon from '@mui/icons-material/HowToRegRounded';
import Search from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import CloseIcon from '@mui/icons-material/Close';
import { DocumentData } from 'firebase/firestore';
import { DateTime } from 'luxon';
import {
  useAppBarHeight,
  useCurrentUser,
  useNotification,
  usePermission,
  useProgress,
} from '../../../../hooks';
import {
  CompanyType,
  NotificationLevel,
  OrderFileType,
  ReportFileType,
  UserPermission,
} from '../../../../enums';
import {
  borderColor,
  confirmButton,
  input as inputStyle,
} from '../../../modals/common.styles';
import AsyncAutocomplete from '../../../../components/AsyncAutocomplete/AsyncAutocomplete.component';
import { dateToYearMonth } from '../../../../components/ReportFilesTable/utils';
import {
  StyledDatePicker,
  StyledDrawerContent,
  StyledDrawerFooter,
  StyledDrawerHeader,
  drawerIconStyles,
  formControlStyles,
  selectStyles,
} from './AssignDocument.styles';
import { AssignDocumentProps } from './AssignDocument.props';
import { AssignDocumentErrorFields } from './enums';
import { ACCEPTED_FILENAME_CHARACTERS, FILENAME_MAX_LENGTH } from './constants';

const AssignDocument = ({
  selectedFile,
  visible,
  visibilitySet,
}: AssignDocumentProps) => {
  const { t } = useTranslation();
  const appBarHeight = useAppBarHeight();
  const { addNotification } = useNotification();
  const functions = useFunctions();
  const { hasPermission } = usePermission();
  const user = useCurrentUser();
  const { showProgress } = useProgress();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<
    OrderFileType | ReportFileType | null
  >(null);
  const [selectedDocument, setSelectedDocument] = useState<DocumentData | null>(
    null
  );
  const [selectedYearMonth, setSelectedYearMonth] = useState<DateTime | null>(
    null
  );
  const [fileName, setFileName] = useState<string>('');
  const [errors, setErrors] = useState<
    Partial<{
      [key in AssignDocumentErrorFields]: boolean;
    }>
  >({});

  const selectableFileTypes = useMemo(
    () => [
      ...Object.values(OrderFileType).filter(
        (value) => value !== OrderFileType.Unidentified
      ),
      ...Object.values(ReportFileType).filter(
        (value) => value !== ReportFileType.Unidentified
      ),
    ],
    []
  );

  const isOrderFileType = useCallback(
    (value: OrderFileType | ReportFileType | null) =>
      Object.values(OrderFileType).includes(value as OrderFileType),
    []
  );

  const isCurrentOrderFileType = useMemo(
    () => isOrderFileType(selectedFileType),
    [selectedFileType, isOrderFileType]
  );

  // Handlers
  const fileTypeHandler = useCallback(
    (value: string) => {
      setSelectedFileType(
        selectableFileTypes.find((fileType) => fileType === value) || null
      );
    },
    [selectableFileTypes]
  );

  useEffect(() => {
    setSelectedDocument(null);
    setSelectedYearMonth(null);
  }, [isCurrentOrderFileType]);

  // Pre-populate file name and type upon opening the modal
  useEffect(() => {
    if (visible) {
      if (selectedFile.fileName) {
        setFileName(selectedFile.fileName);
      }
      fileTypeHandler(selectedFile.fileType);
    }
  }, [selectedFile, visible, fileTypeHandler]);

  const closeModalHandler = () => {
    resetFields();
    visibilitySet(false);
  };

  const confirmHandler = async () => {
    setLoading(true);
    showProgress(true);
    const currentErrors: Partial<{
      [key in AssignDocumentErrorFields]: boolean;
    }> = {};

    try {
      currentErrors[AssignDocumentErrorFields.FileName] =
        !fileName ||
        !ACCEPTED_FILENAME_CHARACTERS.test(fileName) ||
        fileName.length > FILENAME_MAX_LENGTH;

      currentErrors[AssignDocumentErrorFields.SelectedFileType] =
        !selectedFileType;

      currentErrors[AssignDocumentErrorFields.SelectedDocument] =
        !selectedDocument;

      currentErrors[AssignDocumentErrorFields.SelectedYearMonth] =
        !isCurrentOrderFileType &&
        (!selectedYearMonth || selectedYearMonth > DateTime.now());

      setErrors((prevValue) => {
        return { ...prevValue, ...currentErrors };
      });

      if (Object.values(currentErrors).find((error) => error)) {
        throw new Error('forms.savedUnsuccessfullyDueToValidation');
      }

      if (!user.isNCEmployee || !hasPermission(UserPermission.ViewOrders)) {
        throw new Error('forms.permissionDenied');
      }

      const assignUnmappedFileCall = httpsCallable(
        functions,
        `assignUnmapped${isCurrentOrderFileType ? 'Order' : 'Company'}File`
      );

      await assignUnmappedFileCall({
        fileId: selectedFile.id,
        fileName,
        fileType: selectedFileType,
        ...(isCurrentOrderFileType
          ? { orderId: selectedDocument?.id }
          : {
              companyId: selectedDocument?.id,
              yearMonth: selectedYearMonth
                ? dateToYearMonth(selectedYearMonth)
                : null,
            }),
      });

      closeModalHandler();
      addNotification(
        t('unassignedDocuments.assignFile.confirmAction.success', {
          fileName: `${fileName}${selectedFile.fileExtension}`,
          document: isCurrentOrderFileType
            ? selectedDocument?.orderNumber
            : selectedDocument?.name,
        }),
        NotificationLevel.Success
      );
    } catch (e) {
      if ((e as Error).message.includes('MESSAGE_SIZE_EXCEEDED')) {
        closeModalHandler();
        addNotification(
          t('unassignedDocuments.assignFile.confirmAction.warningSizeLimit'),
          NotificationLevel.Warning
        );
      } else {
        addNotification(
          t('unassignedDocuments.assignFile.confirmAction.error'),
          NotificationLevel.Error
        );
      }
    } finally {
      setLoading(false);
      showProgress(false);
    }
  };

  // helpers
  const resetFields = () => {
    setSelectedFileType(null);
    setSelectedDocument(null);
    setSelectedYearMonth(null);
    setLoading(false);
    setErrors({});
  };

  const renderDrawerHeader = () => (
    <StyledDrawerHeader display="flex" justifyContent="space-between">
      <Grid
        container
        alignItems="center"
        display="flex"
        gap={3}
        wrap="nowrap"
        mr={3}
      >
        <HowToRegIcon sx={drawerIconStyles} />
        <Typography variant="h3" color={theme.palette.common.black}>
          {t('unassignedDocuments.assignFile.title')}
        </Typography>
      </Grid>
      <IconButton onClick={() => visibilitySet(false)}>
        <CloseIcon htmlColor={theme.palette.common.black} />
      </IconButton>
    </StyledDrawerHeader>
  );

  const renderAssignDocument = () => (
    <Stack spacing={1}>
      <Typography>
        {t(
          `unassignedDocuments.assignFile.to${
            isCurrentOrderFileType ? 'Order' : 'Agency'
          }`
        )}
      </Typography>
      <AsyncAutocomplete
        key={isCurrentOrderFileType.toString()}
        collectionName={isCurrentOrderFileType ? 'Orders' : 'Companies'}
        displayFieldName={isCurrentOrderFileType ? 'orderNumber' : 'name'}
        queryFilters={
          isCurrentOrderFileType
            ? undefined
            : [
                ['isDeleted', '==', false],
                ['type', '==', CompanyType.Agency],
              ]
        }
        hasError={errors[AssignDocumentErrorFields.SelectedDocument]}
        normalizedFieldName={
          isCurrentOrderFileType ? 'orderNumberNormalized' : 'nameNormalized'
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={inputStyle}
            placeholder={t(
              `unassignedDocuments.assignFile.search${
                isCurrentOrderFileType ? 'Order' : 'Agency'
              }Placeholder`
            )}
            error={errors[AssignDocumentErrorFields.SelectedDocument]}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" sx={borderColor} />
                </InputAdornment>
              ),
            }}
          />
        )}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        visible={visible}
      />
    </Stack>
  );

  const renderYearMonth = () => (
    <Stack spacing={1}>
      <Typography>{t('unassignedDocuments.assignFile.forDate')}</Typography>
      <StyledDatePicker
        views={['year', 'month']}
        value={selectedYearMonth}
        slotProps={{
          actionBar: { actions: ['clear'] },
          textField: {
            required: true,
            error: errors[AssignDocumentErrorFields.SelectedYearMonth],
          },
        }}
        onChange={(value) => setSelectedYearMonth(value as DateTime)}
        maxDate={DateTime.now()}
      />
    </Stack>
  );

  const renderDrawerContent = () => (
    <StyledDrawerContent spacing={2}>
      <Stack spacing={1}>
        <Typography>{t('unassignedDocuments.assignFile.assignTo')}</Typography>
        <TextField
          fullWidth
          value={fileName}
          error={errors[AssignDocumentErrorFields.FileName]}
          placeholder={t('unassignedDocuments.assignFile.fileNamePlaceholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFileName(event.target.value);
          }}
          inputProps={{
            maxLength: FILENAME_MAX_LENGTH,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={borderColor}>
                  {selectedFile.fileExtension}
                </Typography>
              </InputAdornment>
            ),
          }}
          sx={inputStyle}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography>{t('unassignedDocuments.assignFile.ofType')}</Typography>
        <FormControl sx={formControlStyles} fullWidth>
          <Select
            labelId="file-type-label"
            id="file-type-select"
            displayEmpty
            value={selectedFileType ?? ''}
            error={errors[AssignDocumentErrorFields.SelectedFileType]}
            onChange={(ev) => fileTypeHandler(ev.target.value)}
            input={<OutlinedInput />}
            variant="outlined"
            sx={selectStyles}
            renderValue={(selected) =>
              selected ? (
                t(
                  `${
                    isOrderFileType(selected) ? 'orders' : 'reports'
                  }.fileTypes.${selected}`
                )
              ) : (
                <Typography sx={borderColor}>
                  {t('unassignedDocuments.assignFile.fileTypePlaceholder')}
                </Typography>
              )
            }
            IconComponent={KeyboardArrowDown}
          >
            <MenuItem disabled value="">
              <em>{t('unassignedDocuments.assignFile.fileTypePlaceholder')}</em>
            </MenuItem>
            {selectableFileTypes.map((fileType) => (
              <MenuItem key={fileType} value={fileType}>
                {t(
                  `${
                    isOrderFileType(fileType) ? 'orders' : 'reports'
                  }.fileTypes.${fileType}`
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {!!selectedFileType && renderAssignDocument()}
      {!!selectedFileType && !isCurrentOrderFileType && renderYearMonth()}
    </StyledDrawerContent>
  );

  const renderDrawerFooter = () => (
    <StyledDrawerFooter spacing={2} direction="row">
      <Button
        variant="outlined"
        onClick={() => closeModalHandler()}
        disabled={loading}
      >
        {t('unassignedDocuments.assignFile.cancelButton')}
      </Button>
      {!!selectedFileType && (
        <Button
          sx={confirmButton}
          onClick={() => confirmHandler()}
          disabled={loading}
        >
          {t(
            `unassignedDocuments.assignFile.assign${
              isCurrentOrderFileType ? 'Order' : 'Agency'
            }Button`
          )}
        </Button>
      )}
    </StyledDrawerFooter>
  );

  return (
    <Drawer
      PaperProps={{ sx: { top: appBarHeight, bottom: 0, height: 'auto' } }}
      transitionDuration={100}
      open={visible}
      anchor="right"
    >
      {renderDrawerHeader()}
      {renderDrawerContent()}
      {renderDrawerFooter()}
    </Drawer>
  );
};

export default AssignDocument;
