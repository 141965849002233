import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/403_logo.png';

import { ErrorMessageProps } from './ErrorMessage.props';
import * as Sx from './ErrorMessage.styles';

export const ErrorMessage = ({
  error,
  variant = 'dark',
}: ErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Sx.box(variant)}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img alt={t('errors.imageAltText')} src={logo} />
        <Typography variant="h1" sx={Sx.title(variant)}>
          {t(`errors.${error}.title`)}
        </Typography>
        <Typography sx={Sx.message(variant)}>
          {t(`errors.${error}.message`)}
        </Typography>
      </Box>
    </Box>
  );
};
