import { SxProps } from '@mui/material';

export const downloadButton = (): SxProps => {
  return {
    cursor: 'pointer',
    marginTop: {
      xs: '1em',
      md: 'inherit',
    },
    width: {
      xs: '100%',
      md: 'inherit',
    },
  };
};
