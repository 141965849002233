import { Avatar, Button, Grid, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import StarIcon from '@mui/icons-material/Star';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePageTitle, usePermission, usePortalMetadata } from '../../../hooks';
import { Container, DashboardCard } from '../../../components';
import { NeedsPermission } from '../../../security';
import { DefaultValue, UserPermission } from '../../../enums';

import * as Sx from './Dashboard.styles';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { hasAllPermissions } = usePermission();
  const navigate = useNavigate();
  usePageTitle();

  const { item } = usePortalMetadata();

  const conversionRate =
    item && item.rollingCompletedReservationsCount > 0
      ? `${Math.round(
          (item?.rollingPurchasedReservationsCount /
            item?.rollingCompletedReservationsCount) *
            100
        ).toString()}%`
      : DefaultValue.NotAvailable;

  const spaceBetween = !!hasAllPermissions([
    UserPermission.ViewReservationReports,
    UserPermission.ViewAllReservations,
    UserPermission.ManageCustomerUsers,
  ]);

  return (
    <Grid sx={Sx.background}>
      <Container sx={Sx.container} subSx={Sx.innerContainer}>
        <Grid>
          <Grid display="flex" alignItems="center">
            <Avatar sx={Sx.avatar}>
              <DashboardIcon fontSize="large" />
            </Avatar>
            <Typography variant="h1" color="white" ml={2}>
              {t('dashboard.title')}
            </Typography>
          </Grid>

          <Grid display="flex" justifyContent="right" mt={5}>
            <NeedsPermission
              oneOf={[
                UserPermission.ManageNCUsers,
                UserPermission.ManageCustomerUsers,
                UserPermission.ManageAgencyUsers,
              ]}
            >
              <Button
                onClick={() => navigate('/admin/users/new')}
                startIcon={<HowToRegIcon />}
                variant="contained"
                sx={Sx.buttonMR}
              >
                {t('dashboard.addUser')}
              </Button>
            </NeedsPermission>
          </Grid>
        </Grid>

        <Grid sx={Sx.grid(spaceBetween)}>
          <NeedsPermission oneOf={[UserPermission.ViewReservationReports]}>
            <DashboardCard
              icon={<StarIcon sx={Sx.icon} />}
              innerMessage={`${item?.rollingPurchasedReservationsCount}/${item?.rollingCompletedReservationsCount}`}
              link="/admin/reports/conversion-rate"
              linkText={t('dashboard.reports')}
              message={conversionRate}
              title={t('dashboard.reservationConversionRate')}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ViewAllReservations]}>
            <DashboardCard
              icon={<HourglassTopIcon sx={Sx.icon} />}
              link="/admin/reservations"
              linkText={t('dashboard.reservations')}
              message={item?.activeReservationsCount?.toString()}
              title={t('dashboard.totalCurrentReservations')}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ManageCustomerUsers]}>
            <DashboardCard
              icon={<SupervisorAccountIcon sx={Sx.icon} />}
              link="/admin/users/awaitingApproval"
              linkText={t('dashboard.awaitingApproval')}
              message={item?.usersAwaitingApprovalCount?.toString()}
              title={t('dashboard.awaitingApproval')}
            />
          </NeedsPermission>
        </Grid>
      </Container>
    </Grid>
  );
};
