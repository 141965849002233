import { Avatar, Grid, Typography } from '@mui/material';

import { Container, Show } from '../../components';

import { PublicHeaderProps } from './PublicHeader.props';
import * as Sx from './PublicHeader.styles';

export const PublicHeader = (props: PublicHeaderProps) => (
  <Grid sx={Sx.mainGrid} role="banner">
    <Container>
      <Grid sx={Sx.grid}>
        <Show if={!!props.icon}>
          <Avatar sx={Sx.icon}>{props.icon}</Avatar>
        </Show>
        <Typography variant="h1" sx={Sx.title}>
          {props.title}
        </Typography>
      </Grid>
    </Container>
  </Grid>
);
