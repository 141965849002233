export default function formatPhoneNumber(value: string | null): string | null {
  if (!value) {
    return value;
  }
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return value;
}
