export enum TimeZone {
  ChamorroTime = 'Pacific/Guam',
  SamoaTime = 'Pacific/Midway',
  HawaiiAleutianStandardTime = 'Pacific/Honolulu',
  HawaiiAleutianTime = 'America/Adak',
  AlaskaTime = 'America/Anchorage',
  PacificTime = 'America/Los_Angeles',
  MountainStandardTime = 'America/Phoenix',
  MountainTime = 'America/Denver',
  CentralStandardTime = 'America/Regina',
  CentralTime = 'America/Chicago',
  EasternStandardTime = 'America/Atikokan',
  EasternTime = 'America/New_York',
  AtlanticStandardTime = 'America/Puerto_Rico',
  AtlanticTime = 'America/Halifax',
  NewfoundlandTime = 'America/St_Johns',
}
