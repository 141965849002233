import { SxProps } from '@mui/material';

export const box: SxProps = {
  mt: 'auto',
  backgroundColor: 'primary.main',
  display: { xs: 'none', md: 'block' },
};

export const copyrights: SxProps = { mr: 1, ml: 0 };

export const menuItem: SxProps = {
  textDecoration: 'underline',
  '&:hover, &:focus': {
    color: 'secondary.main',
    textDecoration: 'underline',
  },
};

export const contactLink: SxProps = { ...menuItem, mx: 16 };

export const separator: SxProps = { mx: 0 };

export const terms: SxProps = { ...menuItem, pl: 1 };

export const toolbar: SxProps = {
  my: 0,
  color: '#c2c2c2',
  '& .MuiMenuItem-root, & .MuiTypography-root': {
    fontSize: '0.8rem',
  },
};
