import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { AnyObjectSchema, ValidationError } from 'yup';

import Formable from '../types/Formable';

export function useMapSubform<T, K extends string | number | symbol, V>(
  form: Formable<T>,
  field: keyof T,
  index: K,
  defaultItem: V,
  translation?: TFunction,
  validations?: AnyObjectSchema
) {
  const { t } = useTranslation();
  const item = useMemo(() => {
    const map = form.item?.[field] as unknown as Record<K, V>;
    return map?.[index] || null;
  }, [form, field, index]);

  const setItem = useCallback(
    (item: V) => {
      const map = ((form.item?.[field] as unknown) || {}) as Record<K, V>;
      const clonedMap = JSON.parse(JSON.stringify(map));
      clonedMap[index] = item;
      const newValues = {} as Partial<T>;
      newValues[field] = clonedMap as unknown as Extract<K, T[keyof T]>;
      form.setItem({ ...form.item, ...(newValues as T) });
    },
    [field, form, index]
  );

  const [errors, setErrors] = useState({} as Record<keyof V, string>);

  useEffect(() => {
    if (form.item && !item) {
      setItem(defaultItem);
    }
  }, [form.item, item, defaultItem, setItem]);

  useEffect(() => {
    if (validations && translation) {
      const newErrors = {} as Record<keyof V, string>;
      validations
        .validate(item, {
          abortEarly: false,
        })
        .then(() => {
          setErrors(newErrors);
        })
        .catch((e) => {
          const error = e as ValidationError;

          error.inner.forEach((field) => {
            if (typeof field.errors[0] === 'string') {
              newErrors[field.path as keyof V] = t(field.errors[0]);
            } else {
              const { key, values } = field.errors[0] as unknown as {
                key: string;
                values: Record<string, string>;
              };
              newErrors[field.path as keyof V] = t(key, values);
            }
          });

          setErrors(newErrors);
        });
      setErrors(newErrors);
    }
  }, [item, validations, t, translation]);

  return { ...form, item, setItem, originalItem: item, errors };
}
