import { SxProps } from '@mui/material';
import nciLogo from '../../assets/NCILoadingLogo.png';

export const box = (visible: boolean): SxProps => {
  return {
    position: 'fixed',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    zIndex: visible ? 1200 : -1200,
    backgroundColor: 'rgba(0,0,0, 0.8)',
    visibility: visible ? 'visible' : 'hidden',
  };
};

export const loadingBox = (): SxProps => {
  return {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
};

export const imageBox = (): SxProps => {
  return {
    height: '18rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundImage: `url(${nciLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
  };
};

export const image = (): SxProps => {
  return {
    display: 'flex',
    zIndex: -1,
    alignSelf: 'center',
    animation: 'rotation 5s infinite linear',
    '@keyframes rotation': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  };
};

export const loadingMessage = (): SxProps => {
  return {
    pt: 1,
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  };
};
