import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import { FormControlHeader, UserForm } from '../../components';
import { ModificationMode, UserStatus } from '../../enums';
import {
  useNewUser,
  useForm,
  usePageTitle,
  useCurrentUser,
  useCompany,
} from '../../hooks';
import { getUserSchema } from '../../validation';
import { getPageTitle } from '../../utils/pages';

const AddUser: React.FC = () => {
  const functions = useFunctions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useNewUser(true);
  const currentUser = useCurrentUser();
  const { item: company } = useCompany(currentUser.companyRef.id);
  const mode = ModificationMode.Add;
  usePageTitle(getPageTitle('user.new'));

  const userValidations = useMemo(
    () => getUserSchema(mode, functions),
    [mode, functions]
  );

  const form = useForm(user, {
    mode: mode,
    validations: userValidations,
    onRevert: () => {
      navigate('/agent/users');
      return false;
    },
    beforeSave: (item) => {
      // Default status is Waiting for Approval for user create by an agent
      item.status = UserStatus.WaitingForApproval;
      item.suggestedCompanyName = company?.name + ' (agency)';
    },
    onSaveSuccess: () => {
      navigate('/agent/users');
    },
  });

  return (
    <>
      <FormControlHeader
        backTo={'/agent/users'}
        form={form}
        title={t('users.form.title', { name: user.item?.name })}
        saveButtonLabel={t('users.actions.sendInvite')}
      />
      {company && (
        <UserForm
          form={form}
          isAdmin
          isAgent
          mode={mode}
          companies={[company]}
        />
      )}
      <Outlet context={form} />
    </>
  );
};

export default AddUser;
