import { useMemo } from 'react';

import { useFirestore } from 'reactfire';
import { doc } from 'firebase/firestore';
import { CollectionQueryOptions, Filter } from '../types';
import { SanitizedConversionRate } from '../models';
import { updateStatistics } from '../components/ConversionRate/common';
import { useReservationLogs } from './useReservationLogs';
import { useDataCollection } from './useDataCollection';
import { useSorting } from './useSorting';
import { useUsers } from './useUsers';

export function useUserConversionRates(
  companyId?: string,
  options?: CollectionQueryOptions,
  agencyId?: string
) {
  const sorting = useSorting('date', { sortDirection: 'asc' });
  const userFilters = useMemo(
    () =>
      [
        companyId ? ['companyId', '==', companyId] : undefined,
        agencyId ? ['agencyId', '==', agencyId] : undefined,
        ...(options?.filters || []),
      ] as Filter[],
    [agencyId, companyId, options?.filters]
  );

  const { list: reservationLogs, loading: logsLoading } = useReservationLogs({
    filters: userFilters,
    sorting,
  });

  const firestore = useFirestore();

  const { list: usersList, loading: usersLoading } = useUsers({
    filters: [
      [
        'companyRef',
        '==',
        doc(firestore, 'Companies', agencyId || companyId || ''),
      ],
    ],
  });

  const userConversionRates = useMemo(() => {
    if (logsLoading || usersLoading) {
      return [];
    }

    const agencyView = !!agencyId;

    const records = reservationLogs.reduce((acc, cur) => {
      const id = cur.madeForId;

      const record = acc[id] || new SanitizedConversionRate();
      if (!record.id) {
        record.id = id;
      }

      // AGENCY VIEW:
      // We need to get the user name from the user list
      if (agencyView) {
        if (!record.userName) {
          const user = usersList.find((u) => u.id === id);
          record.userName = user?.name || cur.madeByName;
        }
      } else {
        // ADMIN VIEW:
        // For companies we take madeForName
        // For agencies, we take madeByName, except for agency logs with non-agent as madeById
        let username = cur.madeForName;
        if (cur.agencyId) {
          if (cur.madeById === cur.madeForId) {
            username = cur.madeByName;
          } else {
            username = record.userName || cur.madeByName;
          }
        }

        if (record.userName !== username) {
          record.userName = username;
        }
      }

      updateStatistics(cur.endStatus, record);

      acc[id] = record;
      return acc;
    }, {} as Record<string, SanitizedConversionRate>);

    return Object.values(records);
  }, [agencyId, logsLoading, reservationLogs, usersList, usersLoading]);

  return useDataCollection(
    userConversionRates,
    options?.sorting,
    options?.pagination
  );
}
