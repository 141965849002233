import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { queryStringToObject } from '../utils/strings';
import { Languages, Nullable } from '../types';
import { defaultLanguage, supportedLanguages } from '../types/Languages';

export const useAppLanguage = (userLang = '') => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const { lang: paramLang = '' } = queryStringToObject<{ lang: Languages }>(
    search
  );

  const checkLang = (lang: string): Nullable<Languages> =>
    supportedLanguages.includes(lang.toLowerCase() as Languages)
      ? (lang.toLowerCase() as Languages)
      : null;

  useEffect(() => {
    const language =
      checkLang(paramLang) ??
      checkLang(userLang) ??
      checkLang(window.navigator.language?.substring(0, 2) ?? '') ??
      defaultLanguage;

    i18n.changeLanguage(language);
  }, [i18n, paramLang, userLang]);
};
