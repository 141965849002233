import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  ImageList,
  ImageListItem,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import returnableImg from '../../assets/returnable_reel_small.png';
import nonReturnableImg from '../../assets/non-returnable_reel_small.png';
import { ReelReturnInfoPopupProps } from './ReelReturnInfoPopup.props';

export const ReelReturnInfoPopup = ({
  loading = false,
}: ReelReturnInfoPopupProps) => {
  const { t } = useTranslation();
  const [reelDialogShow, setReelDialogShow] = useState<boolean>(true);

  const handleDialogClose = () => {
    setReelDialogShow(false);
  };

  return (
    <Dialog
      open={reelDialogShow && !loading}
      PaperProps={{ sx: { borderRadius: '1rem' } }}
    >
      <DialogTitle>{t('reelReturns.notificationPopup.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ImageList cols={2}>
            <ImageListItem key="NonReturnableReelImage">
              <img
                src={`${nonReturnableImg}`}
                alt="Non-returnable reel"
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem key="ReturnableReelImage">
              <img
                src={`${returnableImg}`}
                alt="Returnable reel"
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
          <Typography paragraph>
            {t('reelReturns.notificationPopup.body1')}
          </Typography>
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: t('reelReturns.notificationPopup.body2'),
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleDialogClose}>
          {t('forms.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
