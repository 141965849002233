import React, { useState, useCallback } from 'react';

import { ProgressProviderProps } from './ProgressProvider.props';
import {
  ProgressContext,
  ProgressContextValue,
} from './ProgressProvider.interface';

export const ProgressProvider = ({ children }: ProgressProviderProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const contextValue: ProgressContextValue = {
    showProgress: useCallback(
      (show: boolean) => setVisible(show),
      [setVisible]
    ),
    visible,
  };

  return (
    <ProgressContext.Provider value={contextValue}>
      {children}
    </ProgressContext.Provider>
  );
};
