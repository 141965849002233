import { Typography } from '@mui/material';
import * as Sx from './ReelReturnStatusBadge.style';
import ReelReturnStatusBadgeProps from './ReelReturnStatusBadge.props';

export const ReelReturnStatusBadge = ({
  status,
  text,
}: ReelReturnStatusBadgeProps) => (
  <Typography
    id="reel-returns-badge"
    px={0.5}
    py={0.5}
    variant="body1"
    sx={Sx.statusBadge(status)}
  >
    {text}
  </Typography>
);
