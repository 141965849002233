import { Box, ListItem, ListItemText, SxProps, styled } from '@mui/material';

export const StyledHeaderContainer = styled(Box)(() => {
  return {
    backgroundColor: '#2C2C2C',
    padding: '25px 10px 25px 20px',
  };
});

export const StyledBodyContainer = styled(Box)(() => {
  return {
    height: '100%',
    overflowY: 'auto',
    padding: '40px 0 20px 0',
  };
});

export const StyledFooterContainer = styled(Box)(() => {
  return {
    backgroundColor: '#E0E0E0',
    minHeight: '90px',
    height: '90px',
    padding: '25px 10px 25px 20px',
  };
});

export const StyledListTitle = styled(Box)(() => {
  return {
    padding: '0 0 20px 20px',
  };
});

export const StyledListItem = styled(ListItem)(() => {
  return {
    padding: '10px 10px 10px 20px',
    borderBottom: '1px solid #E0E0E0',
  };
});

export const StyledListItemText = styled(ListItemText)(() => {
  return {
    maxWidth: '280px',
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
});

export const drawerPaper = (isMobile: boolean): SxProps => {
  return {
    bottom: 0,
    top: 'unset',
    borderRadius: '10px 10px 0 0',
    width: isMobile ? '100%' : '360px',
    height: isMobile ? '100%' : '70%',
    overflow: 'hidden',
  };
};
