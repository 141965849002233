import * as yup from 'yup';

const UpdatePasswordValidations = yup.object({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required().min(8),
  confirmPassword: yup
    .string()
    .required()
    .oneOf(
      [yup.ref('newPassword'), null],
      'common.validations.fieldPasswordShouldMatch'
    ),
});

export default UpdatePasswordValidations;
