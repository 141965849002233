import { useEffect, useState } from 'react';
import {
  collection,
  endAt,
  limit,
  limitToLast,
  orderBy,
  query,
  QueryConstraint,
  startAt,
  where,
} from 'firebase/firestore';

import firebaseHooks from '../firebase/hooks';
import { Filter, Pageable, Sortable } from '../types/';
import notEmpty from '../utils/notEmpty';

/**
 * Access a Firestore collection
 *
 * @param path - Firestore path
 * @param defaultQueryConstaints - Default query constraints to apply to query sent to Firestore
 * @param sorting - Sorting to apply to query
 * @param pagination - Pagination to apply to query
 * @param filters - Filters to apply to query
 */
export function useCollection<T>(
  path: string,
  defaultQueryConstaints: (QueryConstraint | undefined)[],
  sorting?: Sortable,
  pagination?: Pageable,
  filters: (Filter | undefined)[] = []
) {
  const [list, setList] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const firestore = firebaseHooks.useFirestore();
  const firestoreCollection = collection(firestore, path);

  const limitCount = pagination
    ? pagination.itemsPerPage + (pagination.endAt ? 2 : 1)
    : Number.MAX_SAFE_INTEGER;

  const inequalitySearch = filters.filter(
    (filterBy, index, self) =>
      filterBy &&
      typeof filterBy[2] !== 'object' &&
      index ===
        self.findIndex((filter) => filter && filter[0] === filterBy[0]) &&
      (filterBy[1] === '<=' || filterBy[1] === '>=' || filterBy[1] === '!=')
  );

  const queryConstraints = [
    // Set searching order for inequalities
    ...inequalitySearch.map((item) => item && orderBy(item[0], 'asc')),
    // Additional filters provided
    ...filters.map((filter) => (filter ? where(...filter) : undefined)),
    // Sort by requested field, except if there is an equality filter with the same name
    sorting
      ? !filters.some(
          (filter) =>
            filter && filter[0] === sorting.sortField && filter[1] === '=='
        )
        ? orderBy(sorting.sortField, sorting.sortDirection)
        : orderBy(sorting.defaultSortField || 'id', 'asc')
      : undefined,
    // Default query to apply to collection
    ...defaultQueryConstaints,
    // Paginate results
    pagination && pagination.startAt ? startAt(pagination.startAt) : undefined,
    pagination && pagination.endAt ? endAt(pagination.endAt) : undefined,
    pagination
      ? pagination.endAt || pagination.atEnd
        ? limitToLast(limitCount)
        : limit(limitCount)
      : undefined,
  ].filter(notEmpty);

  const firestoreQuery = query(firestoreCollection, ...queryConstraints);

  const { data } = firebaseHooks.useFirestoreCollection(firestoreQuery);

  // Reset the pagination if the sorting or filters change
  useEffect(() => {
    if (pagination) {
      pagination.returnToBeginning();
    }
    // No not apply exhaustive deps rule to this hook, as it can cause
    // an infinite loop, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting?.sortField, sorting?.sortDirection, filters]);

  useEffect(() => {
    if (data) {
      // If paging backward and we do not receive all the results expected,
      // return completely to the beginning
      if (pagination && pagination.endAt && data.docs.length < limitCount) {
        pagination.returnToBeginning();
      }

      // Get the list of documents for the number requested, skipping
      // the first item if going backwards or to the last records
      const documents = !pagination
        ? data.docs
        : data.docs.slice(
            (pagination.endAt && data.docs.length === limitCount) ||
              pagination.atEnd
              ? 1
              : 0,
            (pagination.endAt && data.docs.length === limitCount) ||
              pagination.atEnd
              ? pagination.itemsPerPage + 1
              : pagination.itemsPerPage
          );

      // Set the list of items based on documents received
      setList(documents.map((d) => d.data() as unknown as T));

      // Set as loaded
      setLoading(false);

      if (pagination) {
        // Set the document to start the next page at, except if we are
        // going directly to the end
        pagination.setNextStartAt(
          pagination.atEnd
            ? undefined
            : data.docs.slice(pagination.itemsPerPage)[0]
        );

        // Set the document to end the previous page at, except if we are
        // going not starting or ending at a particular record, or at the
        // end of the records
        pagination.setNextEndAt(
          !pagination.startAt && !pagination.endAt && !pagination.atEnd
            ? undefined
            : pagination.endAt || pagination.atEnd
            ? data.docs.length === limitCount
              ? data.docs[1]
              : undefined
            : data.docs[0]
        );
      }
    }
    // No not apply exhaustive deps rule to this hook, as it can cause
    // an infinite loop, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    list,
    loading,
  };
}
