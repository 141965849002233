import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import {
  TableBody,
  TableContainer,
  TableSortLabel,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  Collapse,
  Checkbox,
  useMediaQuery,
  Theme,
  SxProps,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { difference, get, union } from 'lodash';

import { Model } from '../types';
import MutableSortable from '../types/MutableSortable';
import Pageable from '../types/Pageable';
import { MoreMenu, Pagination } from '../components';
import { DataTableColumnProps } from './DataTableColumnProps';
import { StyledTable, StyledTableRow, StyledTableCell } from '.';

interface Props<T extends Model> {
  children: Array<ReactElement<DataTableColumnProps<T>> | boolean | undefined>;
  data?: Array<T>;
  isDisabledFunction?: (item: T) => boolean;
  isSelectedFunction?: (items: Array<T>, item: T) => boolean;
  loading?: boolean;
  menuItems?: (item: T) => ReactNode;
  pagination?: Pageable;
  sorting?: MutableSortable;
  rowCollapseContent?: (item: T) => ReactNode;
  rowCollapseOpened?: (item: T) => boolean;
  rowOnClick?: (item: T) => void;
  selected?: T[];
  setSelectAll?: Dispatch<SetStateAction<T[]>>;
  mobileBorder?: string;
  mobileHideMenuItems?: boolean;
  tableBodyStyles?: SxProps;
  tableHeaderStyles?: SxProps;
  tableStyles?: SxProps;
}

export const DataTable = <T extends Model>({
  children,
  data,
  isDisabledFunction,
  isSelectedFunction,
  loading,
  menuItems,
  pagination,
  sorting,
  rowCollapseContent,
  rowCollapseOpened,
  rowOnClick,
  selected,
  setSelectAll,
  mobileBorder,
  mobileHideMenuItems = false,
  tableBodyStyles,
  tableHeaderStyles,
  tableStyles,
}: Props<T>) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<T | null>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const columns = children.filter((x) => x) as Array<
    ReactElement<DataTableColumnProps<T>>
  >;

  const onRowClick = (dataItem: T) => {
    if (mobileBorder !== 'none') {
      if (activeItem === dataItem) {
        setActiveItem(null);
      } else {
        setActiveItem(dataItem);
      }
    }
    if (rowOnClick) {
      rowOnClick(dataItem);
    }
  };

  const selectAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectAll && data && setSelectAll((prev) => union(prev, data));
    } else {
      setSelectAll && data && setSelectAll((prev) => difference(prev, data));
    }
  };

  return (
    <TableContainer sx={{ ...tableStyles }}>
      <StyledTable>
        <TableHead sx={{ ...tableHeaderStyles }}>
          <StyledTableRow>
            {columns &&
              columns.map((child) => (
                <StyledTableCell
                  key={child.props.property.toString()}
                  onClick={() =>
                    sorting &&
                    !child.props.disableSort &&
                    sorting.sortByField(child.props.property.toString())
                  }
                  width={
                    child.props.property.toString() !== 'selectable'
                      ? child.props.width
                      : '42px'
                  }
                  sx={{
                    cursor:
                      sorting && !child.props.disableSort
                        ? 'pointer'
                        : undefined,
                    display: {
                      md: child.props.hidden ? 'none' : 'table-cell',
                    },
                    textAlign: {
                      md: child.props.textAlign ?? 'left',
                      sm: 'left',
                    },
                  }}
                  sortDirection={
                    child.props.disableSort ? sorting?.sortDirection : undefined
                  }
                >
                  {child.props.property.toString() === 'selectable' ? (
                    <Checkbox
                      checked={
                        selected &&
                        data &&
                        selected.length > 0 &&
                        data.every((i) => selected.includes(i))
                      }
                      onChange={selectAllChecked}
                    />
                  ) : (
                    child.props.title
                  )}
                  {sorting &&
                    !child.props.disableSort &&
                    data &&
                    data.length > 0 && (
                      <TableSortLabel
                        active={sorting?.sortField === child.props.property}
                        direction={
                          sorting?.sortField === child.props.property
                            ? sorting?.sortDirection
                            : undefined
                        }
                        IconComponent={ArrowDropDownIcon}
                        onClick={() =>
                          sorting?.sortByField(child.props.property.toString())
                        }
                      />
                    )}
                </StyledTableCell>
              ))}
            {menuItems && <StyledTableCell />}
          </StyledTableRow>
        </TableHead>
        <TableBody sx={{ ...tableBodyStyles }}>
          {data &&
            data.map((dataItem, index) => (
              <React.Fragment key={dataItem.id}>
                <StyledTableRow
                  onClick={() => {
                    if (!(isDisabledFunction && isDisabledFunction(dataItem))) {
                      onRowClick(dataItem);
                    }
                  }}
                  className={`${index % 2 === 1 ? 'alternate-color' : ''} ${
                    activeItem === dataItem && mobileBorder !== 'none'
                      ? 'active-item'
                      : ''
                  } ${
                    selected &&
                    isSelectedFunction &&
                    selected?.length > 0 &&
                    isSelectedFunction(selected, dataItem)
                      ? 'selected-item'
                      : ''
                  } ${
                    isDisabledFunction && isDisabledFunction(dataItem)
                      ? 'disabled-item'
                      : ''
                  }`}
                  sx={{
                    ...(rowOnClick && {
                      cursor: 'pointer',
                    }),
                    paddingTop: {
                      xs: '1em',
                    },
                    paddingBottom: {
                      xs: '0.5em',
                    },
                    border: {
                      md: 'inherit',
                      xs: mobileBorder,
                    },
                    wordBreak: 'break-all',
                    '&active': {
                      border: {
                        md: 'inherit',
                        xs: mobileBorder,
                      },
                    },
                  }}
                >
                  {columns &&
                    columns.map((child, index) => (
                      <StyledTableCell
                        sx={{
                          display: {
                            xs:
                              child.props.mobileHidden &&
                              (activeItem !== dataItem ||
                                !child.props.mobileVisibleWhenActive)
                                ? 'none'
                                : child.props.mobileInlineHeaderDirection
                                ? 'flex'
                                : 'table-cell',
                            md: child.props.hidden ? 'none' : 'table-cell',
                          },
                          color: {
                            xs:
                              index === 0 && activeItem === dataItem
                                ? 'secondary.main'
                                : 'inherit',
                            md: 'inherit',
                          },
                          flexBasis: {
                            xs:
                              child.props.mobileWidth ||
                              (index === 0 && menuItems ? '75%' : '100%'),
                            md: 'unset',
                          },
                          fontWeight: {
                            xs: index === 0 ? 'bold' : 'unset',
                            md: 'unset',
                          },
                          height: {
                            xs: child.props.mobileHeight,
                            md: 'unset',
                          },
                          order: {
                            xs: child.props.mobileOrder ?? index - 1,
                            md: 'unset',
                          },
                          textAlign: {
                            md: child.props.textAlign ?? 'left',
                            xs: child.props.mobileTextAlign ?? 'left',
                          },
                          ...child.props.otherStyling,
                        }}
                        key={child.props.property.toString()}
                      >
                        <>
                          {index !== 0 &&
                            child.props.hasMobileLabel !== false && (
                              <Typography
                                variant="caption"
                                sx={{
                                  display: { xs: 'block', md: 'none' },
                                  alignSelf: {
                                    xs: child.props.mobileInlineHeaderDirection
                                      ? 'center'
                                      : 'inherit',
                                    md: 'inherit',
                                  },
                                  order:
                                    child.props.mobileInlineHeaderDirection ===
                                    'rtl'
                                      ? 1
                                      : 0,
                                  flexGrow:
                                    child.props.mobileInlineHeaderDirection ===
                                    'ltr'
                                      ? 10
                                      : 0,
                                }}
                              >
                                {child.props.title}
                              </Typography>
                            )}
                          {child.props.activeOutput && activeItem === dataItem
                            ? child.props.activeOutput(
                                get(dataItem, child.props.property),
                                dataItem
                              )
                            : child.props.output
                            ? child.props.output(
                                get(dataItem, child.props.property),
                                dataItem
                              )
                            : get(dataItem, child.props.property)}
                        </>
                      </StyledTableCell>
                    ))}
                  {menuItems && !(mobileHideMenuItems && isMobile) && (
                    <StyledTableCell
                      sx={{
                        flexBasis: {
                          xs: '25%',
                          md: 'unset',
                        },
                        height: 0,
                        order: -1,
                        textAlign: 'right',
                        '& button': {
                          visibility: menuItems(dataItem)
                            ? 'visible'
                            : 'hidden',
                        },
                      }}
                    >
                      <MoreMenu>{menuItems(dataItem)}</MoreMenu>
                    </StyledTableCell>
                  )}
                </StyledTableRow>

                {rowCollapseContent && rowCollapseOpened && (
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: 'none' }}
                      colSpan={children.length}
                    >
                      <Collapse
                        sx={{
                          position: { xs: 'fixed', md: 'static' },
                          left: 0,
                          bottom: -1,
                          width: '100%',
                          zIndex: 5000,
                        }}
                        in={rowCollapseOpened(dataItem)}
                      >
                        {rowCollapseOpened(dataItem) &&
                          rowCollapseContent(dataItem)}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
      </StyledTable>
      {data && data.length === 0 && (loading === undefined || !loading) && (
        <Typography variant="h3" sx={{ mt: 4, mb: 4 }}>
          {t('common.noItemsFound')}
        </Typography>
      )}

      {pagination && (
        <Pagination
          pagination={pagination}
          disabled={!data || data.length === 0}
        />
      )}
    </TableContainer>
  );
};
