// For more information: https://github.com/FirebaseExtended/reactfire/discussions/228
const reactFirePreloadedObservables = (globalThis as Record<string, unknown>)[
  '_reactFirePreloadedObservables'
] as Map<string, unknown> | undefined;

const clearFirestoreCache = () => {
  if (reactFirePreloadedObservables) {
    Array.from(reactFirePreloadedObservables.keys())
      .filter((key) => key.includes('firestore'))
      .forEach((key) => reactFirePreloadedObservables.delete(key));
  }
};

export default clearFirestoreCache;
