import { Order } from '../models';
import { CollectionQueryOptions } from '../types/';
import { useCollection } from './useCollection';

export function useOrders(options?: CollectionQueryOptions) {
  return useCollection<Order>(
    'Orders',
    [],
    options?.sorting || { sortField: 'dateModified', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
