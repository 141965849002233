import { createContext, useState, useCallback } from 'react';

import { NotificationLevel } from '../enums';
import { Nullable } from '../types';

export interface Notification {
  message: string;
  level: NotificationLevel;
  elevated: boolean;
}

export interface NotificationContextValue {
  notification: Notification | null;
  addNotification: (
    message: string,
    level: NotificationLevel,
    elevated?: boolean
  ) => void;
  clearNotification: () => void;
}

export const NotificationContext = createContext(
  {} as NotificationContextValue
);

interface Props {
  children: React.ReactNode;
}

export const NotificationProvider = ({ children }: Props) => {
  const [notification, setNotification] =
    useState<Nullable<Notification>>(null);

  const contextValue = {
    notification,
    addNotification: useCallback(
      (
        message: string,
        level: NotificationLevel,
        elevated: boolean | undefined
      ) => {
        setNotification({ message, level, elevated: elevated || false });
      },
      [setNotification]
    ),
    clearNotification: useCallback(
      () => setNotification(null),
      [setNotification]
    ),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
