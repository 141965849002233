import { UserActivity } from '../models';
import { CollectionQueryOptions } from '../types/';
import { useCollection } from './useCollection';

export function useUserActivities(options?: CollectionQueryOptions) {
  return useCollection<UserActivity>(
    'UserActivities',
    [],
    options?.sorting || { sortField: 'date', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
