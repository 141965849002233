import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useTranslation } from 'react-i18next';

import React from 'react';
import {
  CompanyDetails,
  Container,
  DecoratedHeader,
  ProgressIndicator,
} from '../../../components';
import { useCompany } from '../../../hooks';
import { AgencyPageView, CompanyType, UserPermission } from '../../../enums';
import { Tab } from '../../../navigation';
import { NeedsPermission } from '../../../security';

interface Props {
  children: React.ReactNode;
  agencyId?: string;
  view: AgencyPageView;
  setView: React.Dispatch<React.SetStateAction<AgencyPageView>>;
}

const AgencyPartial = ({ children, agencyId, view, setView }: Props) => {
  const { t } = useTranslation();
  const agency = useCompany(agencyId || '');

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<BusinessCenterIcon />}
          title={t('navigation.companies')}
          to="/admin/agencies"
          variant="colored"
        ></DecoratedHeader>
      </Container>
      <React.Fragment>
        {agency.loading ? (
          <ProgressIndicator />
        ) : (
          <>
            <CompanyDetails company={agency} companyType={CompanyType.Agency} />
            <Container sx={{ mt: 5 }}>
              <Tab
                title={t('navigation.associatedCustomers')}
                onClick={() => setView(AgencyPageView.AssociatedCompanies)}
                active={view === AgencyPageView.AssociatedCompanies}
              />
              <NeedsPermission oneOf={[UserPermission.ManageAgencyUsers]}>
                <Tab
                  title={t('navigation.assignedUsers')}
                  onClick={() => setView(AgencyPageView.AssignedUsers)}
                  active={view === AgencyPageView.AssignedUsers}
                />
              </NeedsPermission>
            </Container>
          </>
        )}
      </React.Fragment>
      {children}
    </>
  );
};

export default AgencyPartial;
