import { useCallback, useMemo } from 'react';
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  User,
} from 'firebase/auth';

import { UpdatePassword } from '../models';
import { Persistable } from '../types';

class PasswordError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CustomError';
  }
}

export function useUpdatePassword(): Persistable<UpdatePassword> {
  const item = useMemo(() => {
    return { oldPassword: '', newPassword: '', confirmPassword: '' };
  }, []);

  const save = useCallback(async (item: UpdatePassword) => {
    const auth = getAuth();
    const user = auth.currentUser as User;

    const credentials = EmailAuthProvider.credential(
      user?.email || '',
      item.oldPassword
    );

    try {
      await reauthenticateWithCredential(user, credentials);

      await updatePassword(user, item.newPassword);

      return item;
    } catch (e) {
      throw new PasswordError('users.errors.invalidPassword');
    }
  }, []);

  return {
    item,
    save,
  };
}
