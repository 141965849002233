import { HowToReg } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useAuthentication,
  usePermission,
  useReservationsAreAllowed,
} from '../../hooks';
import { UserPermission } from '../../enums';
import { ContrastButtonMain } from '..';

import * as Sx from './ImpersonateButton.styles';

export const ImpersonateButton = () => {
  const { isAdmin, isAgent } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const reservationsAreAllowed = useReservationsAreAllowed();

  const hideMakeReservation =
    !reservationsAreAllowed ||
    !(isAgent || isAdmin) ||
    (isAgent && !hasPermission(UserPermission.ManageOwnReservations)) ||
    (isAdmin && !hasPermission(UserPermission.ImpersonateCustomer));

  if (hideMakeReservation) {
    return null;
  }

  return (
    <ContrastButtonMain
      startIcon={<HowToReg />}
      sx={Sx.button}
      onClick={() =>
        isAgent
          ? navigate('/agent/inventory/ul/reserve')
          : navigate('/admin/inventory/csa/reserve')
      }
    >
      {t('inventory.impersonate.makeReservation')}
    </ContrastButtonMain>
  );
};
