import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateTime, Interval } from 'luxon';

import { TimestampCountdownProps } from './TimestampCountdown.props';

export const TimestampCountdown = ({
  maxDelay,
  onExpire,
  timestamp,
  timeZone,
  children,
  dayOffs,
  weekDaysOff,
  status,
}: TimestampCountdownProps) => {
  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());
  const [onExpireCalled, setOnExpireCalled] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timestamp) {
      const dateTime = DateTime.fromMillis(timestamp.toMillis());

      if (!onExpireCalled && onExpire && dateTime < currentDate) {
        onExpire();
        setOnExpireCalled(true);
      }
    }
  }, [currentDate, onExpire, onExpireCalled, timestamp]);

  if (!timestamp || !timeZone) {
    return null;
  }

  const dateTime = DateTime.fromMillis(timestamp.toMillis());
  const daysOffDate = dayOffs
    ?.filter((d) => {
      const dayDate = DateTime.fromMillis(d.toMillis());
      return (
        dayDate.startOf('day') <= dateTime.startOf('day') &&
        dayDate.startOf('day') >= currentDate.startOf('day')
      );
    })
    .map((d) => DateTime.fromMillis(d.toMillis()));

  let duration = null;
  const interval = Interval.fromDateTimes(currentDate, dateTime);
  const subIntervals = interval.splitBy({ days: 1 });
  subIntervals.forEach((s) => {
    if (s.start && weekDaysOff?.includes(s.start.weekday)) {
      daysOffDate?.push(s.start);
    }
  });

  if (
    daysOffDate &&
    daysOffDate?.length > 0 &&
    status === 'reserved' &&
    !dateTime.hasSame(currentDate, 'day')
  ) {
    if (daysOffDate.some((dod) => currentDate.hasSame(dod, 'day'))) {
      let nextOpenedDay = currentDate.plus({ days: 1 });

      while (daysOffDate.some((dod) => nextOpenedDay.hasSame(dod, 'day'))) {
        nextOpenedDay = currentDate.plus({ days: 1 });
      }

      duration = dateTime.diff(nextOpenedDay.startOf('day'), [
        'hours',
        'minutes',
      ]);
    } else {
      const dayBefore = dateTime.minus({ days: 1 }).set({
        hour: currentDate.hour,
        minute: currentDate.minute,
        second: currentDate.second,
      });
      duration = dateTime.diff(dayBefore, ['hours', 'minutes']);
    }
  } else {
    duration = dateTime.diff(currentDate, ['hours', 'minutes']);
  }

  if (dateTime < currentDate) {
    return null;
  }

  return (
    <>
      <Box>{duration?.toFormat('hh:mm')}</Box>
      {duration && duration?.hours < maxDelay ? children : null}
    </>
  );
};
