import { UserStatus } from '../enums/';

export const UserStatusColors: Record<UserStatus, string> = {
  waitingApproval: '#FBB040',
  pendingEmailValidation: '#FBB040',
  pending: '#979797',
  active: '#E27305',
  inactive: '#2C2C2C',
  deleted: '#E27305',
};
