import { UserRole } from '../enums/';

export const Roles = [
  UserRole.NciAdmin,
  UserRole.NciCustomerService,
  UserRole.NciStaff,
  UserRole.AgencyAdmin,
  UserRole.AgencyCustomerService,
  UserRole.Buyer,
  UserRole.General,
  UserRole.Manager,
  UserRole.Sales,
];

export const AdminRoles = [
  UserRole.NciAdmin,
  UserRole.NciCustomerService,
  UserRole.NciStaff,
];

export const CustomerRoles = [
  UserRole.Buyer,
  UserRole.General,
  UserRole.Manager,
  UserRole.Sales,
];

export const AgentRoles = [
  UserRole.AgencyAdmin,
  UserRole.AgencyCustomerService,
];

export const AdminRole = [UserRole.NciAdmin];
