import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { defaultLanguage, supportedLanguages } from '../types/Languages';

import en from './en.json';
import fr from './fr.json';

const resources = {
  en: { translation: en },
  fr: { translation: fr },
};

use(initReactI18next).init({
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  lng: defaultLanguage,
  resources,
  supportedLngs: supportedLanguages,
});
