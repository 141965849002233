import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { ReelQuantity } from '../../ReelQuantity/ReelQuantity.component';
import { ReelWeight } from '../../ReelWeight/ReelWeight.component';
import { Show } from '../../Show/Show.component';
import { ShoppingTruckCardProps } from './ShoppingTruckCard.props';
import * as Sx from './ShoppingTruckCard.styles';

export const ShoppingTruckCard = ({
  measureUnits,
  reel,
  toggleItemSelection,
}: ShoppingTruckCardProps) => (
  <Card sx={Sx.card}>
    <CardContent sx={Sx.cardContent}>
      <Typography
        sx={{ fontWeight: 'bold' }}
      >{`${reel.description} - ${reel.cableRef.id}`}</Typography>

      <Show if={!!reel.colors}>
        <Typography>{reel.colors}</Typography>
      </Show>

      <ReelQuantity item={reel} unitsType={measureUnits} />

      <Box
        display="flex"
        width="100%"
        columnGap={1}
        justifyContent="space-between"
      >
        <Typography>{reel.reel || '--'}</Typography>
        <Typography>
          {reel.weight > 0 ? (
            <ReelWeight item={reel} unitsType={measureUnits} />
          ) : (
            <Typography>--</Typography>
          )}
        </Typography>
        <Typography>{reel.id}</Typography>
      </Box>
    </CardContent>
    <CardActions>
      <IconButton
        size="large"
        color="secondary"
        onClick={() => toggleItemSelection(reel)}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </CardActions>
  </Card>
);
