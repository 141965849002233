import { Box, MenuItem } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ExpanderMenuItemProps } from './ExpanderMenuItem.props';

export const ExpanderMenuItem = ({ onClick, open }: ExpanderMenuItemProps) => (
  <MenuItem
    onClick={onClick}
    sx={{
      mt: -1,
      pt: 2.5,
      pb: 2.5,
      mb: 4,
      backgroundColor: open ? 'primary.main' : 'secondary.main',
      borderBottomRightRadius: '1.5rem',
      color: open ? 'secondary.main' : 'white',
      transition: '250ms all',
      '&:hover': {
        backgroundColor: open ? 'primary.main' : 'secondary.main',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: open ? 'flex-end' : 'center',
        alignItems: 'end',
        flex: 1,
        gap: '0.5em',
        width: '100%',
      }}
    >
      {open ? (
        <ArrowBackIosIcon fontSize="small" />
      ) : (
        <ArrowForwardIosIcon fontSize="small" />
      )}
    </Box>
  </MenuItem>
);
