import { SxProps } from '@mui/material';

export const iconButton = (): SxProps => {
  return {
    position: 'fixed',
    bottom: {
      md: '3em',
      xs: '0.5em',
    },
    right: {
      md: '2em',
      xs: '1em',
    },
    color: '#fff',
    backgroundColor: '#e68008',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#e68008',
      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.4)',
      transform: 'translateY(-3px)',
    },
  };
};
