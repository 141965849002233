import { SxProps } from '@mui/material';

export const avatar: SxProps = {
  border: 2,
  borderColor: 'secondary.light',
  backgroundColor: 'secondary.main',
  width: '60px',
  height: '60px',
};

export const background: SxProps = {
  alignItems: 'stretch',
  background: 'linear-gradient(to bottom, #e68008 250px, #F9F9F9 125px 250px)',
  display: 'flex',
  flexGrow: 1,
  paddingTop: '45px',
};

export const container: SxProps = {
  maxWidth: '1500px',
};

export const icon: SxProps = {
  fontSize: '50px',
};

export const innerContainer: SxProps = {
  height: '100%',
  width: '100%',
  minHeight: '575px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBottom: '50px',
  position: 'relative',
};

export const grid = {
  display: 'flex',
  gap: '6em',
  marginTop: '45px',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: { sm: '100%', md: 'calc(100% - 4rem)' },
  '& .overflow-nav': {
    background: '#F9F9F9',
  },
};
