import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  Grid,
  ListItem,
  TextField as MuiTextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { uniq } from 'lodash';
import {
  DropDownCheckboxList,
  TextField,
  FormControlHeader,
  Container,
} from '..';
import { useForm } from '../../hooks';
import firebaseHooks from '../../firebase/hooks';
import { CompanyType, ModificationMode } from '../../enums';
import { Company as CompanyModel } from '../../models';
import getCompanySchema from '../../validation/CompanyValidations';
import { MyAgencyDetailsProps } from './MyAgencyDetails.props';
import { StyledPaper, StyledTypography } from './MyAgencyDetails.styles';

export const MyAgencyDetails = ({ agency }: MyAgencyDetailsProps) => {
  const { t } = useTranslation();
  const firestore = firebaseHooks.useFirestore();
  const [shippingEmailField, setShippingEmailField] = useState('');

  const companyValidations = useMemo(
    () => getCompanySchema(firestore, CompanyType.Agency),
    [firestore]
  );

  const form = useForm(agency, {
    mode: ModificationMode.Edit,
    validations: companyValidations,
  });

  // Reset form => prevents updates to Associated customers from creating two concurrent versions of the form
  useEffect(() => {
    if (agency.item) {
      form.setItem(agency.item, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency]);

  const updateShippingEmails = (emails: string[]) => {
    const duplicateFree = uniq(emails);

    form.setItem({
      ...form.item,
      shippingDocEmailRecipient: duplicateFree,
    } as CompanyModel);
  };

  const addEmailsToList = () => {
    const updatedEmailsArr = shippingEmailField
      ?.toLowerCase()
      .replace(/ /g, '')
      .split(',')
      .concat(form.item?.shippingDocEmailRecipient || []);
    setShippingEmailField('');
    updateShippingEmails(updatedEmailsArr || []);
  };

  return (
    <>
      <FormControlHeader
        form={form}
        title={t('companies.form.title', { name: form.item?.name })}
      />
      <Container backgroundColor="secondary.light" sx={{ pb: 5 }}>
        <Grid container>
          <Grid item md={6} sx={{ pr: { md: '20%' } }}>
            <StyledTypography component="h3" variant="h3">
              {t('users.form.sections.agencyProfileInformation')}
            </StyledTypography>

            <TextField
              form={form}
              field="name"
              label={t('agencies.fields.agencyName')}
              disabled
              required
            />

            <StyledPaper component="ul">
              <MuiTextField
                fullWidth
                type="text"
                label={t('companies.fields.shippingEmail')}
                value={shippingEmailField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        disabled={shippingEmailField.length <= 0}
                        onClick={() => addEmailsToList()}
                      >
                        {t('companies.actions.add')}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addEmailsToList();
                  }
                }}
                onChange={(e) => setShippingEmailField(e.target.value)}
              />
              {form.item?.shippingDocEmailRecipient?.map((email, key) => (
                <ListItem key={key} sx={{ width: 'inherit' }}>
                  <Chip
                    label={email}
                    color={
                      form.errors &&
                      Object.keys(form.errors).includes(
                        `shippingDocEmailRecipient[${key}]`
                      )
                        ? 'error'
                        : 'default'
                    }
                    onDelete={() => {
                      const updatedEmailsArr =
                        form.item?.shippingDocEmailRecipient?.filter(
                          (element) => element !== email
                        );
                      updateShippingEmails(updatedEmailsArr || []);
                    }}
                  />
                </ListItem>
              ))}
            </StyledPaper>
          </Grid>

          <Grid item md={6} sx={{ pr: { md: '20%' } }}>
            <StyledTypography component="h3" variant="h3">
              {t('users.form.sections.inventory')}
            </StyledTypography>

            <DropDownCheckboxList
              form={form}
              disabled
              label={t('companies.fields.canSeeCSAOrUL')}
              fields={['canSeeCSA', 'canSeeUL']}
              fieldLabels={[
                t('companies.fields.canSeeCSA'),
                t('companies.fields.canSeeUL'),
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
