import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { Grid, Stack, Typography } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import { useErrorLogs, usePagination, useSorting } from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { TimeZone } from '../../enums';
import { Filter, Nullable } from '../../types';
import { FormattedTimestamp, TableRowDialog } from '..';
import { ErrorLogType } from '../../enums/ErrorLogType';
import { ErrorLog } from '../../models';
import { ErrorLogsTableProps } from './ErrorLogsTable.props';

export const ErrorLogsTable = ({ filters }: ErrorLogsTableProps) => {
  const { t } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('date', { sortDirection: 'desc' });
  const [openedRow, setOpenedRow] = useState<Nullable<string>>(null);

  const queryFilters = useMemo(() => {
    if (!filters) {
      return [];
    }

    const result = [];

    const { startDate, endDate, errorLogType } = filters;

    if (startDate) {
      result.push([
        'date',
        '>=',
        Timestamp.fromMillis(startDate.startOf('day').toMillis()),
      ]);
    }

    if (endDate) {
      result.push([
        'date',
        '<=',
        Timestamp.fromMillis(endDate.endOf('day').toMillis()),
      ]);
    }

    if (errorLogType) {
      result.push(['type', '==', errorLogType]);
    }

    return result;
  }, [filters]) as Filter[];

  const { list } = useErrorLogs({ pagination, sorting, filters: queryFilters });

  const renderErrorDate = (date: Timestamp) => (
    <FormattedTimestamp timestamp={date} timeZone={TimeZone.EasternTime} />
  );

  const renderErrorLogType = (type: ErrorLogType) => (
    <Typography>{t(`reports.errorLogs.types.${type}`)}</Typography>
  );

  const renderErrorMessage = (message: string, log: ErrorLog) => {
    const active = log.id === openedRow;
    return (
      <Grid container alignItems="center" flexWrap="nowrap">
        <Typography
          variant="body1"
          sx={{
            color: active ? 'secondary.main' : undefined,
            whiteSpace: 'nowrap',
          }}
        >
          {message}
        </Typography>
        {active ? (
          <KeyboardArrowDown sx={{ color: 'secondary.main' }} />
        ) : (
          <KeyboardArrowRight />
        )}
      </Grid>
    );
  };

  const renderRowContent = (log: ErrorLog) => (
    <TableRowDialog title={t('reports.errorLogs.details')}>
      <Stack>
        {Object.entries(log.details).map(([key, value]) => (
          <pre key={key}>
            <Typography>
              <b>{key}</b> :{' '}
              <code>{JSON.stringify(JSON.parse(value), null, 2)}</code>
            </Typography>
          </pre>
        ))}
      </Stack>
    </TableRowDialog>
  );

  return (
    <DataTable
      data={list}
      pagination={pagination}
      sorting={sorting}
      rowCollapseContent={(log) => renderRowContent(log)}
      rowCollapseOpened={(log) => log.id === openedRow}
      rowOnClick={(log) => setOpenedRow(openedRow !== log.id ? log.id : null)}
    >
      <DataTableColumn
        width="25%"
        property="date"
        title={t('reports.errorLogs.timeOfEvent')}
        output={renderErrorDate}
      />
      <DataTableColumn
        width="25%"
        property="type"
        title={t('reports.errorLogs.type')}
        output={renderErrorLogType}
      />
      <DataTableColumn
        disableSort
        width="50%"
        property="message"
        title={t('reports.errorLogs.error')}
        output={renderErrorMessage}
      />
    </DataTable>
  );
};
