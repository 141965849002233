import { where } from 'firebase/firestore';
import { ReelDepot } from '../models';
import { CollectionQueryOptions } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useReelDepots(options?: CollectionQueryOptions) {
  return useLocalCollection<ReelDepot>(
    'PortalSettings/portal_settings/ReelDepots',
    [
      options?.includeDeleted === true
        ? undefined
        : where('isActive', '==', true),
    ],
    options?.sorting === undefined
      ? { sortField: 'name', sortDirection: 'asc' }
      : options?.sorting || undefined,
    options?.pagination,
    options?.filters
  );
}
