import { SxProps } from '@mui/material';

export const buttons: SxProps = {
  flexBasis: { md: 'auto' },
  justifyContent: { xs: 'space-between', md: 'flex-end' },
  width: { md: 'auto' },
};

export const container: SxProps = {
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  mb: 1,
  mt: { xs: 2, md: 5 },
};

export const item: SxProps = {
  alignItems: 'center',
  flexBasis: { md: 'auto' },
  gap: 2,
  justifyContent: 'flex-end',
  mr: { xs: 0, md: 3 },
  mt: { xs: 3, md: 0 },
  order: { xs: 3, md: 0 },
  width: { md: 'auto' },
};

export const select: SxProps = {
  textAlign: 'center',
};

export const selectMenu: SxProps = {
  '& .MuiPaper-root': {
    borderTopLeftRadius: '1em',
    borderTopRightRadius: '1em',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
};
