import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { ListItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Container, CustomerRecipientsDrawer, Show } from '../../components';
import { useCompany, usePagination, useSorting } from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { useDataCollection } from '../../hooks/useDataCollection';
import { AgencyCustomer } from '../../types';
import { StyledTextButton } from '../Button/Button.component';
import { CustomerShipToDrawer } from '../CustomerShipTo/CustomerShipToDrawer.component';
import { AgencyCustomersListProps } from './AgencyCustomersList.props';

interface SideDrawerDisplay {
  customer: AgencyCustomer | null;
  type: 'shipTo' | 'recipients' | null;
}

const MAX_RECIPENTS_DISPLAY = 2;

export const AgencyCustomersList = ({ agencyId }: AgencyCustomersListProps) => {
  const { t } = useTranslation();
  const sorting = useSorting('companyName');
  const pagination = usePagination();
  const [sideDrawerDisplay, setSideDrawerDisplay] = useState<SideDrawerDisplay>(
    { type: null, customer: null }
  );

  const agency = useCompany(agencyId || '');

  const companies: AgencyCustomer[] = useMemo(() => {
    if (agency.loading || !agency.item || !agency.item.associatedCompanies) {
      return [];
    }

    return Object.values(agency.item.associatedCompanies)
      .filter((a) => !a.isDeleted)
      .map((company) => {
        return {
          ...company,
          id: company.companyId,
          shipToAmount: company.customerAddresses?.length || 0,
          companyAccountNumber:
            company.companyAccountNumber || t('common.notApplicable'),
        };
      });
  }, [agency, t]);

  const { list: data } = useDataCollection(companies, sorting, pagination);

  const nameOutput = (id: string) => {
    const customer = companies.find((company) => company.id === id) ?? null;
    return (
      <Typography>
        {customer?.companyName || t('common.notApplicable')}
      </Typography>
    );
  };

  const shipToOutput = (shipTos: number, customer: AgencyCustomer) =>
    shipTos === 0 ? (
      <Typography>{shipTos}</Typography>
    ) : (
      <StyledTextButton
        onClick={() => setSideDrawerDisplay({ customer, type: 'shipTo' })}
      >
        {t('agencies.shipTo.label', { count: shipTos })}
      </StyledTextButton>
    );

  const renderButtonText = (
    text: string,
    customer: AgencyCustomer | null,
    key?: string
  ) => (
    <ListItem key={key} sx={{ width: 'inherit', px: 0 }}>
      <StyledTextButton
        onClick={() => setSideDrawerDisplay({ customer, type: 'recipients' })}
      >
        {text}
      </StyledTextButton>
    </ListItem>
  );

  const recipientsOutput = (customer: AgencyCustomer) => (
    <Stack direction="column" sx={{ width: 'inherit' }}>
      {!customer.shippingDocEmailRecipient?.length
        ? renderButtonText(t('agencies.fields.emptyRecipients'), customer)
        : (customer.shippingDocEmailRecipient || [])
            .slice(0, MAX_RECIPENTS_DISPLAY)
            .map((email, key) =>
              renderButtonText(email, customer, email + key)
            )}
      {(customer.shippingDocEmailRecipient || []).length >
        MAX_RECIPENTS_DISPLAY &&
        renderButtonText(
          t('agencies.emailRecipients.seeAll', {
            count: (customer.shippingDocEmailRecipient || []).length,
          }),
          customer
        )}
    </Stack>
  );

  const resetCustomer = () => {
    setSideDrawerDisplay({ customer: null, type: null });
  };

  return (
    <Container sx={{ marginTop: 6 }}>
      <DataTable data={data} pagination={pagination} sorting={sorting}>
        <DataTableColumn
          title={t('agencies.fields.customer')}
          property="companyId"
          width="25%"
          output={nameOutput}
        />
        <DataTableColumn
          property="companyAccountNumber"
          title={t('companies.fields.accountNumber')}
          width="20%"
        />
        <DataTableColumn
          property="shipToAmount"
          title={t('agencies.fields.shipTo')}
          width="20%"
          output={shipToOutput}
        />
        <DataTableColumn
          disableSort
          property="emailRecipients"
          title={t('agencies.fields.emailRecipients')}
          width="35%"
          output={(_, customer: AgencyCustomer) => recipientsOutput(customer)}
        />
      </DataTable>
      <Show
        if={!!sideDrawerDisplay.customer && sideDrawerDisplay.type === 'shipTo'}
      >
        <CustomerShipToDrawer
          customer={sideDrawerDisplay.customer}
          onClose={resetCustomer}
        />
      </Show>
      <Show
        if={
          !!sideDrawerDisplay.customer &&
          sideDrawerDisplay.type === 'recipients'
        }
      >
        <CustomerRecipientsDrawer
          agency={agency}
          customer={sideDrawerDisplay.customer}
          onClose={resetCustomer}
        />
      </Show>
    </Container>
  );
};
