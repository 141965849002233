import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Company } from '../../models';

interface Props {
  company: Company;
  onDeleteClick: (company: Company) => void;
}

export const CompaniesTableMenuItems = ({ company, onDeleteClick }: Props) => {
  const { t } = useTranslation();
  return (
    <MenuItem
      disabled={company.employeeCount !== 0}
      onClick={() => onDeleteClick(company)}
    >
      <ListItemIcon>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('users.actions.delete')}</ListItemText>
    </MenuItem>
  );
};
