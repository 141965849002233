import * as yup from 'yup';
import { Functions } from 'firebase/functions';

import { Country, Language, ProvinceOrState, TimeZone } from '../enums';
import { CountryProvincesOrStates } from '../mappings';

const getSignUpValidations = (functions: Functions) =>
  yup.object({
    name: yup.string().trim().required(),
    email: yup
      .string()
      .required()
      .email()
      .callableFunction(
        functions,
        'emailUsableForUserCreation',
        false,
        'common.validations.fieldShouldBeUnique'
      ),
    title: yup.string().trim().required(),
    phoneNumber: yup
      .string()
      .matches(
        /^(1|)?(\d{3})(\d{3})(\d{4})$/,
        'common.validations.fieldMustBePhoneNumber'
      )
      .required(),
    language: yup.mixed<Language>().required(),
    password: yup.string().min(8).required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf(
        [yup.ref('password'), null],
        'common.validations.fieldPasswordShouldMatch'
      ),
    country: yup.mixed<Country>().required(),
    provinceOrState: yup
      .mixed<ProvinceOrState>()
      .required()
      .test({
        name: 'provinceOrState',
        exclusive: true,
        message: 'common.validations.fieldNotAValidChoice',
        test: (state, context) => {
          const country = context.parent.country;

          if (!country || !state) {
            return true;
          }

          return CountryProvincesOrStates[country as Country].includes(state);
        },
      }),
    city: yup.string().trim().required(),
    timeZone: yup.mixed<TimeZone>().required(),
    suggestedCompanyName: yup.string().trim().required(),
  });

export default getSignUpValidations;
