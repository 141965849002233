import { SxProps } from '@mui/system';
import { MULTIPLE_RESERVATIONS_DRAWER_HEIGHT } from '../MultipleReservationsDrawer/MultipleReservationsDrawer.styles';

const drawerWidth = '20vw';
const backgroundColor = '#eeeeee';
const drawerPaddingX = '24px';

export const drawerPaper: SxProps = {
  backgroundColor: backgroundColor,
  paddingX: drawerPaddingX,
  width: drawerWidth,
};

export const titleBox: SxProps = {
  position: 'fixed',
  top: 0,
  right: 0,
  width: drawerWidth,
  height: '4.5rem',
  backgroundColor: backgroundColor,
  paddingX: drawerPaddingX,
  cursor: 'pointer',
  zIndex: 1,

  '& p': {
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
};

export const cardSection = (showNote: boolean): SxProps => {
  return {
    paddingTop: '4.5rem',
    paddingBottom: `calc((${MULTIPLE_RESERVATIONS_DRAWER_HEIGHT} * ${
      showNote ? 2 : 1
    }) + 8px)`,
  };
};

export const buttonsSection = (showNote: boolean): SxProps => {
  return {
    position: 'fixed',
    bottom: 0,
    right: 0,
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    paddingBottom: 2,
    width: drawerWidth,
    height: `calc(${MULTIPLE_RESERVATIONS_DRAWER_HEIGHT} * ${
      showNote ? 2 : 1
    })`,
    backgroundColor: backgroundColor,
    paddingX: drawerPaddingX,

    '& .MuiInputBase-root.MuiFilledInput-root': {
      backgroundColor: 'white',
    },

    ...(!showNote && {
      '& button:first-of-type': {
        fontWeight: 'bold',
        backgroundColor: 'white',

        '&:hover, &:disabled': {
          backgroundColor: 'white',
        },
      },
    }),
  };
};
