export default function formatReservationNumber(value: number): string {
  const paddedString = value.toString().padStart(9, '0');
  return (
    paddedString.slice(0, 3) +
    ' ' +
    paddedString.slice(3, 6) +
    ' ' +
    paddedString.slice(6, 9)
  );
}
