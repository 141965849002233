import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { FolderOpen } from '@mui/icons-material';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { BottomDrawer } from '../../../../components';
import { useNotification, useProgress } from '../../../../hooks';
import { NotificationLevel } from '../../../../enums';
import * as Sx from '../../../modals/common.styles';
import { OrderFile } from '../../../../models';

const DeleteOrderFiles = ({
  documents,
  documentToDelete,
  orderId,
  visible,
  visibilitySet,
}: {
  documents: OrderFile[];
  documentToDelete: OrderFile | null;
  orderId: string | undefined;
  visible: boolean;
  visibilitySet: (clearSelected: boolean) => void;
}) => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();
  const deleteFunction = httpsCallable(functions, 'deleteOrderFiles');
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const deleteOrderFiles = async () => {
    setDisabled(true);

    addNotification(
      t('orderDetails.delete.notification.info'),
      NotificationLevel.Information
    );

    try {
      await deleteFunction({
        orderId,
        files: documentToDelete ? [documentToDelete] : documents,
      });
      addNotification(
        t('orderDetails.delete.notification.success'),
        NotificationLevel.Success
      );
    } catch {
      addNotification(
        t('orderDetails.delete.notification.error'),
        NotificationLevel.Error
      );
    }

    setDisabled(false);
    visibilitySet(true);
  };

  return (
    <BottomDrawer
      title={t(
        `orderDetails.delete.title.${
          documentToDelete !== null ? 'singular' : 'multiple'
        }`,
        {
          fileName: `${documentToDelete?.fileName}${documentToDelete?.fileExtension}`,
        }
      )}
      icon={<FolderOpen sx={{ color: 'secondary.main' }} />}
      open={visible}
    >
      <Grid container justifyContent={!isMobile ? 'space-between' : ''}>
        <Typography component={Grid} item my={2}>
          {t('orderDetails.delete.message', {
            count: documentToDelete ? 1 : documents.length,
          })}
        </Typography>
        <Grid item sx={Sx.buttonContainer}>
          <Button
            disabled={disabled}
            onClick={() => deleteOrderFiles()}
            variant="contained"
            sx={Sx.confirmButton}
          >
            {t('forms.confirm')}
          </Button>
          <Button
            disabled={disabled}
            onClick={() => visibilitySet(false)}
            variant="outlined"
            sx={Sx.cancelButton}
          >
            {t('forms.cancel')}
          </Button>
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default DeleteOrderFiles;
