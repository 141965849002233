import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
  StorageProvider,
} from 'reactfire';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { connectAuthEmulator, getAuth } from '@firebase/auth';
import FirebaseAppCheck from './FirebaseAppCheck';

interface Props {
  children: React.ReactNode;
}

const FirebaseContext = ({ children }: Props) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const functions = getFunctions(app);
  const fileStorage = getStorage(app);
  if (process.env.REACT_APP_FIREBASE_USE_EMULATORS) {
    connectAuthEmulator(
      auth,
      process.env.REACT_APP_AUTH_EMULATOR_URL as string,
      { disableWarnings: true }
    );
    connectFirestoreEmulator(
      firestore,
      process.env.REACT_APP_FIRESTORE_EMULATOR_HOST as string,
      parseInt(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT as string)
    );
    connectFunctionsEmulator(
      functions,
      process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST as string,
      parseInt(process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT as string)
    );
    connectStorageEmulator(
      fileStorage,
      process.env.REACT_APP_FILESTORE_EMULATOR_HOST as string,
      parseInt(process.env.REACT_APP_FILESTORE_EMULATOR_PORT as string)
    );
  }

  return (
    <FirebaseAppCheck app={app}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestore}>
          <FunctionsProvider sdk={functions}>
            <StorageProvider sdk={fileStorage}>{children}</StorageProvider>
          </FunctionsProvider>
        </FirestoreProvider>
      </AuthProvider>
    </FirebaseAppCheck>
  );
};

export default FirebaseContext;
