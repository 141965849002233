import { useTranslation } from 'react-i18next';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { translateString } from '../../i18n';
import { InventoryWarningMessageColor as MessageColor } from '../../enums';
import { InventoryWarningMessageCardProps } from './InventoryWarningMessageCard.props';

export const InventoryWarningMessageCard = ({
  header,
  body,
  color,
}: InventoryWarningMessageCardProps) => {
  const { i18n } = useTranslation();

  return (
    <Card
      variant="outlined"
      sx={{ minWidth: 275, borderRadius: 0, mb: 4 }}
      style={{
        backgroundColor:
          color === MessageColor.Green
            ? '#edf7ed'
            : color === MessageColor.Yellow
            ? '#fff4e5'
            : color === MessageColor.Red
            ? '#fdeded'
            : '#e5f6fd',
        color:
          color === MessageColor.Green
            ? '#2e7d32'
            : color === MessageColor.Yellow
            ? '#663c00'
            : color === MessageColor.Red
            ? '#5f2120'
            : '#014361',
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
          <WarningAmberIcon fontSize="small" />
          <Typography fontWeight="500" fontSize="1.25rem">
            <Markdown
              skipHtml
              unwrapDisallowed
              allowedElements={['strong', 'em']}
              children={translateString(header, i18n)}
            />
          </Typography>
        </Stack>
        <Typography color="text.secondary" fontSize="1rem" component={'div'}>
          <Markdown
            skipHtml
            unwrapDisallowed
            allowedElements={['strong', 'em', 'br', 'ul', 'li', 'ol', 'a', 'p']}
            children={translateString(body, i18n)}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};
