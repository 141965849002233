import StarIcon from '@mui/icons-material/Star';
import { Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { DashboardCard } from '../../../components';
import { DefaultValue, ReservationStatus } from '../../../enums';
import { useCurrentUser, useReservationLogs } from '../../../hooks';
import { Filter } from '../../../types';
import * as Sx from './Dashboard.styles';

export const ConversionRateCard: React.FC = () => {
  const currentUser = useCurrentUser();

  const filters = useMemo(() => {
    const endDate = DateTime.now().plus({ days: -1 }); // Exclude today
    const startDate = endDate.plus({ days: -30 }); // Go back 30 days

    return [
      ['date', '>=', Timestamp.fromMillis(startDate.startOf('day').toMillis())],
      ['date', '<=', Timestamp.fromMillis(endDate.endOf('day').toMillis())],
      ['agencyId', '==', currentUser.companyRef.id],
    ] as Filter[];
  }, [currentUser.companyRef.id]);

  const { list: reservationLogs, loading } = useReservationLogs({
    filters,
  });

  const { conversionRate, conversionRateInnerMessage } = useMemo(() => {
    let purchased = 0;
    let completed = 0;
    let conversionRate: string = DefaultValue.NotAvailable;
    const innerMessage = () => `${purchased} / ${completed}`;

    if (loading) {
      return {
        conversionRate,
        conversionRateInnerMessage: innerMessage(),
      };
    }

    reservationLogs.forEach((log) => {
      if (log.endStatus === ReservationStatus.Purchased) {
        purchased += 1;
      }

      if (
        [
          ReservationStatus.Cancelled,
          ReservationStatus.Purchased,
          ReservationStatus.Expired,
          ReservationStatus.UnknownExpired,
        ].includes(log.endStatus)
      ) {
        completed += 1;
      }
    });

    if (completed > 0) {
      conversionRate = `${Math.round((purchased / completed) * 100)}%`;
    }

    return {
      conversionRate,
      conversionRateInnerMessage: innerMessage(),
    };
  }, [loading, reservationLogs]);

  return (
    <DashboardCard
      icon={<StarIcon sx={Sx.icon} />}
      innerMessage={conversionRateInnerMessage}
      link="/agent/reports/conversion-rate"
      linkText={t('dashboard.reports')}
      message={conversionRate}
      title={t('dashboard.reservationConversionRate')}
    />
  );
};
