import * as yup from 'yup';
import { ProductType } from '../enums';

const SpecificationSchema = yup.object({
  productType: yup.mixed<ProductType>().required(),
  name: yup.object({
    EN: yup.string().required(),
    FR: yup.string().test({
      name: 'nameFRRequired',
      exclusive: false,
      message: 'common.validations.fieldRequired',
      test: (value, context) => {
        // Undocumented feature from Yup, but you can access the parent tree using from, therefore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parent = (context as any).from[1].value;

        return !parent.url.FR || (!!parent.url.FR && !!value);
      },
    }),
  }),
  url: yup.object({
    EN: yup.string().required().url(),
    FR: yup
      .string()
      .url()
      .test({
        name: 'urlFRRequired',
        exclusive: false,
        message: 'common.validations.fieldRequired',
        test: (value, context) => {
          // Undocumented feature from Yup, but you can access the parent tree using from, therefore
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const parent = (context as any).from[1].value;

          return !parent.name.FR || (!!parent.name.FR && !!value);
        },
      }),
  }),
});

export default SpecificationSchema;
