import ShieldIcon from '@mui/icons-material/Shield';
import { useTranslation } from 'react-i18next';

import { Container, DecoratedHeader } from '../../../components';
import { Tab } from '../../../navigation';
import NeedsPermission from '../../../security/NeedsPermission';
import { UserPermission } from '../../../enums/';

interface Props {
  children: React.ReactNode;
}

const PortalSettings = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<ShieldIcon />}
          title={t('portalSettings.title')}
          variant="colored"
        >
          <NeedsPermission
            oneOf={[
              UserPermission.ManagePortalSettings,
              UserPermission.ManageInventoryClasses,
            ]}
          >
            <Tab
              title={t('portalSettings.reservations.title')}
              to={'/admin/portal/reservations'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ManageRolesAndPermissions]}>
            <Tab
              title={t('portalSettings.permissions.title')}
              to={'/admin/portal/permissions'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ManagePortalSettings]}>
            <Tab
              title={t('portalSettings.specifications.title')}
              to={'/admin/portal/specifications'}
              match="/admin/portal/specifications/*"
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ManagePortalSettings]}>
            <Tab
              title={t('portalSettings.contacts.title')}
              to={'/admin/portal/contacts'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ManageAgentBulletinBoard]}>
            <Tab
              title={t('portalSettings.agentBulletinBoard.title')}
              to={'/admin/portal/agent-bulletin-board'}
            />
          </NeedsPermission>
        </DecoratedHeader>
      </Container>
      {children}
    </>
  );
};

export default PortalSettings;
