import { DateTime } from 'luxon';
import { i18n } from 'i18next';
import { TFunction } from 'react-i18next';

import ConversionRateFilters from '../../types/ConversionRateFilters';
import report_logo from '../../assets/report_logo.png';
import reservation_icon from '../../assets/ReservationIcon.png';
import { SanitizedConversionRate } from '../../models';

import { Nullable } from '../../types';
import report, { MARGIN, PDF_CONFIG } from './report';

const NAMESPACE = 'reports.conversionRate';

class reservationConversionRateReport extends report {
  private conversions: SanitizedConversionRate[];
  private filters: ConversionRateFilters;
  private readonly DEFAULT_ROW_HEIGHT = 13;
  private startDate: Nullable<DateTime>;
  private endDate: Nullable<DateTime>;
  private rowIndex = 0;

  constructor(
    conversions: SanitizedConversionRate[],
    filters: ConversionRateFilters,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super(NAMESPACE, t, i18n);

    this.conversions = conversions;
    this.filters = filters;
    this.startDate = this.filters.startDate;
    this.endDate = this.filters.endDate;

    if (typeof this.startDate === 'string') {
      this.startDate = DateTime.fromISO(this.startDate);
    }

    if (typeof this.endDate === 'string') {
      this.endDate = DateTime.fromISO(this.endDate);
    }
    this.startDate = this.startDate?.setLocale('en') ?? null;
    this.endDate = this.endDate?.setLocale('en') ?? null;
  }

  protected buildHeader(): void {
    this.outputImage(report_logo, MARGIN.X, MARGIN.Y);
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(reservation_icon, MARGIN.X, this.curHeight, 10);

    this.outputH1(
      this.t(`${NAMESPACE}.title`),
      this.curWidth + 15,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 2;

    let filters = this.t(`${NAMESPACE}.dateFilter`, {
      from: this.startDate?.toLocaleString(),
      to: this.endDate?.toLocaleString(),
    });

    if (this.filters.companyIds) {
      this.conversions.forEach((c, index, arr) => {
        filters += index === 0 ? '\n(' : '';
        filters += c.companyName;
        filters += index === arr.length - 1 ? ')' : ', ';
      });
    }

    const addedLines = this.outputH3(
      filters,
      this.curWidth,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + addedLines * 5;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;
    const totalRows = this.conversions.reduce((acc, item) => {
      acc += item.userConversionStatistics.length + 1;
      return acc;
    }, 0);

    this.conversions.forEach((conversion) => {
      this.buildRow(conversion, totalRows);
    });
  }

  private buildRow(
    conversion: SanitizedConversionRate,
    totalRows: number
  ): void {
    const { companyName, reserved, cancelled, expired, purchased, rate } =
      conversion;

    this.addDataColumn('company', companyName, MARGIN.X, 35);
    this.addDataColumn('reservations', String(reserved), MARGIN.X + 45);
    this.addDataColumn('cancelled', String(cancelled), MARGIN.X + 75);
    this.addDataColumn('expired', String(expired), MARGIN.X + 98);
    this.addDataColumn('purchased', String(purchased), MARGIN.X + 120);
    this.addDataColumn('rateDecimal', String(rate / 100), MARGIN.X + 145);

    this.curHeight += 9;

    this.drawLine(0.3);

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, this.rowIndex, totalRows);
    this.rowIndex++;

    conversion.userConversionStatistics.forEach((userStats, userIndex) => {
      this.buildUserStatRow(userStats, conversion, userIndex, totalRows);
    });
  }

  private buildUserStatRow(
    userStats: SanitizedConversionRate,
    companyStats: SanitizedConversionRate,
    userRowIndex: number,
    totalRows: number
  ) {
    const { userName, reserved, cancelled, expired, purchased, rate } =
      userStats;
    this.addDataColumn('userName', userName, MARGIN.X + 5, 30);
    this.addDataColumn('reservations', String(reserved), MARGIN.X + 45);
    this.addDataColumn('cancelled', String(cancelled), MARGIN.X + 75);
    this.addDataColumn('expired', String(expired), MARGIN.X + 98);
    this.addDataColumn('purchased', String(purchased), MARGIN.X + 120);
    this.addDataColumn('rateDecimal', String(rate / 100), MARGIN.X + 145);

    this.curHeight += 9;

    this.drawLine(
      userRowIndex === companyStats.userConversionStatistics.length - 1
        ? 0.3
        : 0.1
    );

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, this.rowIndex, totalRows);
    this.rowIndex++;
  }

  protected getFilename(): string {
    const fromDate = this.startDate?.toFormat('LLddyyyy');
    const toDate = this.endDate?.toFormat('LLddyyyy');

    return `NC_ReservationConversionRates_${fromDate}-${toDate}.pdf`;
  }

  static generate = (
    conversions: SanitizedConversionRate[],
    filters: ConversionRateFilters,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) => {
    const report = new reservationConversionRateReport(
      conversions,
      filters,
      t,
      i18n
    );

    report.generate();
  };
}

export default reservationConversionRateReport;
