import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  FormControlHeader,
  ProgressIndicator,
  Select,
  TextField,
  TranslatedFields,
} from '../../components';
import { Specification as SpecificationModel } from '../../models';
import {
  useForm,
  usePageTitle,
  useProductTypes,
  useSpecification,
} from '../../hooks';
import { ModificationMode } from '../../enums';
import { SpecificationValidations } from '../../validation';
import { getPageTitle } from '../../utils/pages';

const Specification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { specId } = useParams();
  const specification = useSpecification(specId || '');
  usePageTitle(
    specId === 'new'
      ? getPageTitle('portalSettings.specifications.new')
      : getPageTitle('portalSettings.specifications.edit')
  );
  const { item, loading: productTypeLoading } = useProductTypes();

  const productTypes = useMemo(() => {
    if (!item || productTypeLoading) return [];

    return Object.keys(item.types).map((type) => parseInt(type));
  }, [item, productTypeLoading]);

  const form = useForm(specification, {
    mode: specId === 'new' ? ModificationMode.Add : ModificationMode.Edit,
    validations: SpecificationValidations,
    defaultValue: new SpecificationModel(),
    beforeSave: (item) => {
      item.normalizedName = {
        EN: item.name.EN.toLowerCase(),
        FR: item.name.FR?.toLowerCase() || '',
      };
    },
    onRevert: () => {
      if (specId === 'new') {
        navigate('/admin/portal/specifications');
        return false;
      }
      return true;
    },
    onSaveSuccess: (savedItem) => {
      if (specId === 'new') {
        navigate(`/admin/portal/specifications/${savedItem.id}`);
      }
    },
  });

  return (
    <>
      <FormControlHeader
        backTo="/admin/portal/specifications"
        form={form}
        title={
          specId === 'new'
            ? t('portalSettings.specifications.new')
            : specification.item?.name?.EN
        }
      />

      <Container>
        {specification.loading || productTypeLoading ? (
          <ProgressIndicator />
        ) : (
          <Box sx={{ mt: 6, mb: 6 }}>
            <Typography variant="h3">Product Type</Typography>

            <Grid sx={{ mt: 4, mb: 4, maxWidth: { xs: '100%', sm: '50%' } }}>
              <Select
                form={form}
                field="productType"
                label={t('portalSettings.specifications.table.productType')}
                options={productTypes}
                getOptionLabel={(value) => t(`productTypes.${value}`)}
                fullWidth={false}
              />
            </Grid>

            <Typography variant="h3">Specification</Typography>
            <Grid sx={{ mt: 4 }}>
              <TranslatedFields
                form={form}
                field="name"
                label={t('portalSettings.specifications.table.name')}
                render={(options) => <TextField {...options} />}
              />

              <TranslatedFields
                form={form}
                field="url"
                label={t('portalSettings.specifications.table.url')}
                render={(options) => <TextField {...options} />}
              />
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
};

export default Specification;
