import { SxProps } from '@mui/material';

export const grid: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

export const mainGrid: SxProps = {
  ...grid,
  backgroundColor: 'primary.main',
  height: '150px',
};

export const icon: SxProps = {
  m: 1,
  mr: 2,
  backgroundColor: 'primary.main',
  borderColor: 'secondary.light',
  borderWidth: '3px',
  borderStyle: 'solid',
  width: { xs: '2em', md: '3em' },
  height: { xs: '2em', md: '3em' },
  '& .MuiSvgIcon-root': {
    color: 'background.paper',
    width: { xs: '1em', md: '1.5em' },
    height: { xs: '1em', md: '1.5em' },
  },
};

export const title: SxProps = {
  fontSize: '2.5rem',
  color: 'secondary.light',
};
