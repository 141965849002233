import {
  useAuth,
  useSigninCheck,
  useFirestore,
  useFirestoreCollection,
  useFirestoreDocData,
} from 'reactfire';

const hooks = {
  useAuth,
  useFirestore,
  useFirestoreCollection,
  useFirestoreDocData,
  useSigninCheck,
};

export default hooks;
