import { EmptyLayout } from '../layouts';

import { ProgressIndicator } from '../components';

const Loading: React.FC = () => (
  <EmptyLayout>
    <ProgressIndicator />
  </EmptyLayout>
);

export default Loading;
