import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useTranslation } from 'react-i18next';

import { Container, DecoratedHeader, Show } from '../../../components';
import { Tab } from '../../../navigation';
import { NeedsPermission } from '../../../security';
import { UserPermission } from '../../../enums';

interface Props {
  children: React.ReactNode;
  showTabs: boolean;
}

const CompaniesPartial = ({ children, showTabs }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<BusinessCenterIcon />}
          title={t('navigation.companies')}
          to="/admin/companies"
          variant="colored"
        >
          <Show if={!!showTabs}>
            <NeedsPermission
              allOf={[
                UserPermission.ManageCompanies,
                UserPermission.ManageAgencies,
              ]}
            >
              <Tab title={t('navigation.companies')} to={'/admin/companies'} />
              <Tab title={t('navigation.agencies')} to={'/admin/agencies'} />
            </NeedsPermission>
          </Show>
        </DecoratedHeader>
      </Container>
      {children}
    </>
  );
};

export default CompaniesPartial;
