import { useState } from 'react';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { DocumentReference } from 'firebase/firestore';
import { Model } from '../types';

/**
 * Create a new Firestore document via a Firebase function
 *
 * @param creationFunctionName - Name of Firebase function
 * @param defaultValue - Initial value of document
 */
export function useNewDocumentByFunction<T extends Model>(
  creationFunctionName: string,
  defaultValue: T | null
) {
  const functions = useFunctions();
  const [item] = useState(defaultValue);
  const createFunction = httpsCallable<T, T>(functions, creationFunctionName);

  const save = async (newItem: T) => {
    // Serialize DocumentReferences as { id, path }, as Firebase's JS Function API
    // cannot handle this data type as a payload
    for (const [key, value] of Object.entries(newItem)) {
      if (value instanceof DocumentReference) {
        // Need to override type-safety here, therefore:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newAnyItem = newItem as any;
        newAnyItem[key] = {
          id: newAnyItem[key].id,
          path: newAnyItem[key].path,
        };
      }
    }

    let result = { data: {} as T };

    result = await createFunction(newItem);

    return result.data;
  };

  return {
    item,
    save,
    loading: false,
  };
}
