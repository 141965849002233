import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  FilterOptionsState,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { FilterContainer } from '..';
import { Company } from '../../models';
import { useLocalStorage } from '../../hooks';
import { ConversionRateFilters as Filters } from '../../types';
import { ConversionRateFiltersProps } from './ConversionRateFilters.props';

const emptyFilters = {
  companyIds: null,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
};

const PAGE_SIZE = 30;

export const ConversionRateFilters = ({
  onSearch,
  loadedFilters,
  companies,
  loadingCompanies,
}: ConversionRateFiltersProps) => {
  const { setItem: setExpandedCompanies } = useLocalStorage<string[]>(
    'conversionRates',
    'expandedCompanies'
  );
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState<Company[]>([]);
  const [companyInputValue, setCompanyInputValue] = useState<string>('');
  const [filters, setFilters] = useState<Filters>(
    loadedFilters || emptyFilters
  );

  const clearFilters = () => {
    setSelectedOptions([]);
    setFilters(emptyFilters);
    setExpandedCompanies([]);
    onSearch(null);
  };

  const _filterOptions = createFilterOptions<Company>();
  const filterOptions = (
    options: Company[],
    state: FilterOptionsState<Company>
  ) => {
    const results = _filterOptions(options, state);
    return [...selectedOptions, ...results.slice(0, page * PAGE_SIZE)];
  };

  useEffect(() => {
    if (loadedFilters) {
      setSelectedOptions(() =>
        companies.filter((company) =>
          loadedFilters?.companyIds?.includes(company.id)
        )
      );
    }
  }, [companies, loadedFilters]);

  return (
    <FilterContainer
      title={t('reports.conversionRate.title')}
      buttonsDisabled={!filters.startDate || !filters.endDate}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.conversionRate.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || DateTime.now()}
          />

          <DatePicker
            label={t('reports.conversionRate.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate}
            maxDate={DateTime.now()}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            options={companies ?? []}
            loading={!!loadingCompanies}
            value={selectedOptions}
            onClose={() => setPage(1)}
            ListboxProps={{
              onScrollCapture: (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  setPage((page) => page + 1);
                }
              },
              /** Fix for scrolling to top when loading new page of options */
              role: 'list-box',
            }}
            getOptionDisabled={(option) =>
              selectedOptions.length === 30 && !selectedOptions.includes(option)
                ? true
                : false
            }
            getOptionLabel={(company) => company.name}
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Typography sx={{ width: 'calc(100% - 10px)' }}>
                  {option.name}
                </Typography>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
              </li>
            )}
            onChange={(_, item) => {
              setSelectedOptions(item);
              setFilters((prev) => {
                return {
                  ...prev,
                  companyIds:
                    item.length > 0 ? item.map((val) => val.id) : null,
                };
              });
            }}
            renderTags={(value) => (
              <Typography pl={'5px'}>
                {value.length === 1
                  ? value[0].name
                  : t('reports.conversionRate.companySelected', {
                      count: value.length,
                    })}
              </Typography>
            )}
            sx={{ flex: '1 1 0' }}
            inputValue={companyInputValue}
            onInputChange={(
              event: React.SyntheticEvent,
              value: string,
              reason: string
            ) => {
              if (event && event.type === 'blur') {
                setCompanyInputValue('');
              } else if (reason !== 'reset') {
                setCompanyInputValue(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.conversionRate.company')}
              />
            )}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
