import { useTranslation } from 'react-i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CableIcon from '@mui/icons-material/Cable';
import PieChartIcon from '@mui/icons-material/PieChart';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { MenuItem } from '..';
import { NeedsPermission } from '../../security';
import { UserPermission } from '../../enums';
import { useCurrentUser, usePermission } from '../../hooks';

export const AgentMenu = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();

  const currentUser = useCurrentUser();

  return (
    <>
      <MenuItem
        title={t('navigation.dashboard')}
        icon={<DashboardIcon />}
        to="/agent/dashboard"
      />

      <NeedsPermission
        oneOf={[
          UserPermission.ManageAgencyCustomerReservations,
          UserPermission.ManageOwnReservations,
        ]}
      >
        <MenuItem
          title={t('navigation.reservations')}
          icon={<HourglassTopIcon />}
          to="/agent/reservations"
          match="agent/reservations/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ViewOrders]}>
        <MenuItem
          title={t('navigation.orders')}
          icon={<FolderOpenIcon />}
          to="/agent/orders"
          match="agent/orders/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ViewInventory]}>
        <MenuItem
          title={t('navigation.inventory')}
          icon={<CableIcon />}
          to="/agent/inventory/ul"
          match="/agent/inventory/*"
        />
      </NeedsPermission>

      <NeedsPermission
        oneOf={[
          UserPermission.ViewSalesReports,
          UserPermission.ViewCommissionsReports,
          UserPermission.ViewConversionRatesReports,
        ]}
      >
        <MenuItem
          title={t('navigation.reports')}
          icon={<PieChartIcon />}
          to={
            hasPermission(UserPermission.ViewSalesReports)
              ? '/agent/reports/sales'
              : hasPermission(UserPermission.ViewCommissionsReports)
              ? '/agent/reports/commissions'
              : '/agent/reports/conversion-rate'
          }
          match="/agent/reports/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ManageAgencyUsers]}>
        <MenuItem
          title={t('navigation.users')}
          icon={<SupervisedUserCircleIcon />}
          to="/agent/users"
          match="agent/users/*"
        />
      </NeedsPermission>
      <NeedsPermission oneOf={[UserPermission.ManageAgency]}>
        <MenuItem
          title={t('navigation.myAgency')}
          icon={<BusinessCenterIcon />}
          to={`/agent/agencies/${currentUser?.companyRef.id}`}
          match="agent/agencies/*"
        />
      </NeedsPermission>
    </>
  );
};
