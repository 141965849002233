import { useState } from 'react';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useTranslation } from 'react-i18next';
import { useOutlet } from 'react-router-dom';

import { ErrorCategory, UserPageView, UserPermission } from '../enums/';
import {
  NeedsPermission,
  RequirePermission,
  roles,
  statuses,
} from '../security';
import { UserFilters as UserFiltersType } from '../types';
import {
  CompanyUserPermissionsList,
  Container,
  DecoratedHeader,
  Show,
  UserFilters,
  UserTable,
} from '../components';
import { getPageTitle } from '../utils/pages';
import {
  useCompany,
  useCurrentUser,
  usePageTitle,
  usePermission,
} from '../hooks';
import { Tab } from '../navigation';
import { HttpError } from '.';

interface Props {
  view: UserPageView;
}

const Users: React.FC<Props> = ({ view }) => {
  usePageTitle(
    view === UserPageView.OrderFilePermissions
      ? getPageTitle('users.permissions')
      : getPageTitle('users')
  );
  const outlet = useOutlet();
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const user = useCurrentUser();
  const company = useCompany(user.companyRef?.id || '');

  const [filters, setFilters] = useState<UserFiltersType>({
    companyId: null,
    role: null,
    status: null,
  });

  return (
    <RequirePermission oneOf={[UserPermission.ViewCompanyUsers]}>
      {outlet || (
        <>
          <Container>
            <DecoratedHeader
              icon={<SupervisedUserCircleIcon />}
              title={t('navigation.users')}
              variant="colored"
            >
              <NeedsPermission oneOf={[UserPermission.ViewCompanyUsers]}>
                <Tab title={t('navigation.assignedUsers')} to={'/users'} />
              </NeedsPermission>
              <NeedsPermission oneOf={[UserPermission.ManageOrderFilesAccess]}>
                <Tab
                  title={t('navigation.orderFilePermissions')}
                  to={'/users/order-file-permissions'}
                />
              </NeedsPermission>
            </DecoratedHeader>
          </Container>
          <Show if={view === UserPageView.ListUsers}>
            <UserFilters
              roles={roles.CustomerRoles}
              statuses={statuses.Statuses}
              filters={filters}
              setFilters={setFilters}
            />
            <UserTable filters={filters} />
          </Show>
          <Show
            if={
              view === UserPageView.OrderFilePermissions &&
              !company.loading &&
              !!hasPermission(UserPermission.ManageOrderFilesAccess)
            }
          >
            <CompanyUserPermissionsList
              backTo={'/users'}
              company={company}
              formHeaderTitle={t('companies.orderFilePermissions.pageTitle')}
            />
          </Show>
          <Show
            if={
              view === UserPageView.OrderFilePermissions &&
              !hasPermission(UserPermission.ManageOrderFilesAccess)
            }
          >
            <HttpError errorCategory={ErrorCategory.AccessDenied} />
          </Show>
        </>
      )}
    </RequirePermission>
  );
};

export default Users;
