import { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';
import { useFormFieldValue } from '../../hooks';
import { RadioGroupProps } from './RadioGroup.props';
import * as Sx from './RadioGroup.style';

export const RadioGroup = <T, K>({
  defaultValue,
  options,
  disabled,
  required,
  label,
  form,
  field,
  fullWidth,
  row,
}: RadioGroupProps<T, K>) => {
  const { value, setValue } = useFormFieldValue<T, K | null>(form, field);

  useEffect(() => {
    if (value === undefined && defaultValue && defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [value, setValue, defaultValue]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onChangeUnits = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setValue(value as unknown as K);
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
      sx={Sx.formControlRoot()}
      error={!!form.errors[field]}
    >
      <FormLabel component="legend" sx={Sx.formLabel()}>
        {label}
      </FormLabel>
      <MuiRadioGroup
        row={row}
        defaultValue={defaultValue}
        value={value || null}
        onChange={onChangeUnits}
        sx={Sx.formRadioGroup()}
      >
        {options.map((item) => (
          <FormControlLabel
            key={item.key}
            value={item.key}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
