import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReservationCounterProps } from './ReservationCounter.props';

export const ReservationCounter = ({
  currentReservations = 0,
  maxReservations = 0,
  madeForReservations = 0,
  totalReservations = 0,
  variant = 'simple',
}: ReservationCounterProps) => {
  const { t } = useTranslation();

  const detailed = variant === 'detailed';
  const direction = detailed ? 'column-reverse' : 'column';
  const alignItems = detailed ? 'center' : 'flex-start';

  return (
    <Grid
      container
      flexDirection="row"
      gap={5}
      justifyContent={{
        xs: detailed ? 'space-between' : 'flex-start',
        md: 'flex-start',
      }}
    >
      <Grid
        item
        flexDirection={direction}
        justifyContent="flex-end"
        alignItems={alignItems}
        sx={{ display: 'flex', mt: 1 }}
      >
        <Typography sx={{ fontSize: '0.875rem' }}>
          {detailed
            ? t('reservations.madeByReservations')
            : t('reservations.currentReservations')}
        </Typography>
        <Typography
          sx={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}
        >
          <Typography
            color="secondary"
            sx={{ fontSize: 'inherit', fontWeight: 'inherit', lineHeight: 1 }}
            component="span"
          >
            {currentReservations}
          </Typography>
          /{maxReservations}
        </Typography>
      </Grid>
      {detailed && (
        <>
          <Grid
            item
            flexDirection={direction}
            justifyContent="flex-end"
            alignItems={alignItems}
            sx={{ display: 'flex', mt: 1 }}
          >
            <Typography sx={{ fontSize: '0.875rem' }}>
              {t('reservations.madeForReservations')}
            </Typography>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}
            >
              {madeForReservations}
            </Typography>
          </Grid>
          <Grid
            item
            flexDirection={direction}
            justifyContent="flex-end"
            alignItems={alignItems}
            sx={{ display: 'flex', mt: 1 }}
          >
            <Typography sx={{ fontSize: '0.875rem' }}>
              {t('reservations.totalReservations')}
            </Typography>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}
            >
              {totalReservations}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
