import { TFunction } from 'react-i18next';

import { SanitizedConversionRate } from '../../models';
import ConversionRateFilters from '../../types/ConversionRateFilters';
import report from './report';

const PREFIX = 'NC_ReservationConversionRates_';
const NAMESPACE = 'reports.conversionRate.csv';
const EXPORTED_KEYS = [
  'companyName',
  'userName',
  'reserved',
  'cancelled',
  'expired',
  'purchased',
  'rate',
];

class reservationConversionRateReport extends report<SanitizedConversionRate> {
  private conversions: SanitizedConversionRate[];
  private filters: ConversionRateFilters;

  constructor(
    conversions: SanitizedConversionRate[],
    filters: ConversionRateFilters,
    t: TFunction<'translation', undefined>
  ) {
    let keys = EXPORTED_KEYS;
    if (!conversions.some((item) => item.userConversionStatistics.length > 0)) {
      keys = EXPORTED_KEYS.filter((key) => key !== 'userName');
    }

    super(PREFIX, NAMESPACE, keys, t);

    this.conversions = conversions;
    this.filters = filters;
  }

  protected override getFilename(): string {
    const fromDate = this.filters.startDate?.toFormat('LLddyyyy');
    const toDate = this.filters.endDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${fromDate}_${toDate}.csv`;
  }

  protected getSanitizedData(): SanitizedConversionRate[] {
    return this.conversions
      .map(
        (conversion) =>
          ({
            ...conversion,
            userName: this.t('reports.conversionRate.csv.companyTotal'),
            rate: conversion.rate / 100,
          } as SanitizedConversionRate)
      )
      .flatMap((conversion) => [
        conversion,
        ...conversion.userConversionStatistics.map(
          (stat) =>
            ({
              ...stat,
              rate: stat.rate / 100,
            } as SanitizedConversionRate)
        ),
      ]);
  }

  static generate(
    conversions: SanitizedConversionRate[],
    filters: ConversionRateFilters,
    t: TFunction<'translation', undefined>
  ) {
    const report = new reservationConversionRateReport(conversions, filters, t);

    report.generateCSV();
  }
}

export default reservationConversionRateReport;
