import { MeasurementUnits } from '../enums';

export const METER_TO_FEET_RATIO = 3.28084;
const KILOGRAMS_TO_POUNDS_RATIO = 2.20462;

export const convertQuantity = (type: MeasurementUnits, value = 0) => {
  if (type === MeasurementUnits.Imperial) {
    return Math.round((value * METER_TO_FEET_RATIO) / 10) * 10;
  }

  return String(value);
};

export const convertWeight = (type: MeasurementUnits, value = 0) => {
  if (value <= 0) {
    return '';
  }

  if (type === MeasurementUnits.Imperial) {
    return (value * KILOGRAMS_TO_POUNDS_RATIO).toFixed(2);
  }

  return String(value);
};
