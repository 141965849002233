import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThumbnailImageProps } from 'react-grid-gallery';
import {
  StyledImageComponent,
  StyledImageOrderCheckComponent,
} from './BulletinBoardGallery.styles';

interface Props extends ThumbnailImageProps {
  onDelete: (index: number) => void;
  onOrderClick: (index: number) => void;
  order: number | undefined;
}

export const BulletinBoardGalleryImage: React.FC<Props> = ({
  onDelete,
  onOrderClick,
  order,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledImageComponent>
      <Button
        className="delete-button"
        startIcon={<DeleteIcon />}
        onClick={() => onDelete(props.index)}
      >
        {t('portalSettings.agentBulletinBoard.delete')}
      </Button>
      <StyledImageOrderCheckComponent
        onClick={() => onOrderClick(props.index)}
        size="small"
      >
        {!!order && <Typography variant="h3">{order}</Typography>}
      </StyledImageOrderCheckComponent>
      <img
        {...props.imageProps}
        style={{
          ...props.imageProps.style,
          filter: order ? 'brightness(50%)' : 'none',
        }}
        title={props.imageProps.title ?? undefined}
        alt={props.imageProps.alt}
        onClick={() => onOrderClick(props.index)}
      />
    </StyledImageComponent>
  );
};
