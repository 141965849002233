import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import {
  Container,
  DecoratedHeader,
  OrderControlHeader,
  OrderDetailsCard,
  OrderFileTable,
  ScrollToTop,
} from '../../components';
import {
  useCurrentUser,
  useDownloadFiles,
  useOrder,
  usePageTitle,
} from '../../hooks';
import { OrderFile } from '../../models';
import { useOrderFiles } from '../../hooks/useOrderFiles';
import { EmailFiles } from '../modals';
import { OrderFileType } from '../../enums';
import { OrderFilesByType } from '../../types';
import {
  UserRoleGroup,
  UserRoleGroupPath,
  getUserRoleGroup,
} from '../../utils/userRoleGroup';
import { useAuthentication } from '../../hooks/useAuthentication';
import DeleteOrderFiles from './modals/DeleteOrderFiles/DeleteOrderFiles.component';

const Order: React.FC = () => {
  const [selectedDocuments, setSelectedDocuments] = useState<OrderFile[]>([]);
  const [documentToDelete, setDocumentToDelete] = useState<OrderFile | null>(
    null
  );
  const [displayEmailDrawer, setDisplayEmailDrawer] = useState(false);
  const [displayDeleteFilesDrawer, setDisplayDeleteFilesDrawer] =
    useState(false);
  const { t } = useTranslation();
  const { downloadOrderFilesBundle } = useDownloadFiles();
  const { orderId } = useParams();
  const currentUser = useCurrentUser();
  const { isAdmin } = useAuthentication();
  const navigate = useNavigate();

  const order = useOrder(orderId || '');
  const { list: orderFilesList, loading: orderFilesLoading } = useOrderFiles(
    orderId ?? ''
  );

  usePageTitle(
    t('pages.order.pageTitle', { orderNumber: order.item?.orderNumber })
  );

  const pathPrefix =
    UserRoleGroupPath[
      getUserRoleGroup(currentUser.role) ?? UserRoleGroup.Customer
    ];

  const orderFilesByType = useMemo(() => {
    if (!orderFilesLoading && orderFilesList.length > 0) {
      return orderFilesList.reduce(
        (acc, curr) => {
          if (curr.fileType !== OrderFileType.Unidentified) {
            acc[curr.fileType].push(curr);
          }
          return acc;
        },
        {
          [OrderFileType.Confirmation]: [],
          [OrderFileType.Invoice]: [],
          [OrderFileType.Shipping]: [],
        } as OrderFilesByType
      );
    }

    return {
      [OrderFileType.Confirmation]: [],
      [OrderFileType.Invoice]: [],
      [OrderFileType.Shipping]: [],
    } as OrderFilesByType;
  }, [orderFilesList, orderFilesLoading]);

  useEffect(() => {
    if (!order.loading && !order.item) {
      navigate(`${pathPrefix}/orders`);
    }
  }, [navigate, order, pathPrefix]);

  const downloadSelectedHandler = () => {
    downloadOrderFilesBundle(
      selectedDocuments,
      order.item?.orderNumber,
      order.item?.companyName
    );
  };

  const emailSelectedHandler = () => {
    setDisplayEmailDrawer(true);
  };

  const deleteFileHandler = (item: OrderFile) => {
    setDocumentToDelete(item);
    deleteFilesHandler();
  };

  const deleteFilesHandler = () => {
    setDisplayDeleteFilesDrawer(true);
  };

  const deleteDrawerVisibilityHandler = (clearSelected = false) => {
    if (clearSelected) {
      setSelectedDocuments([]);
    }
    setDocumentToDelete(null);
    setDisplayDeleteFilesDrawer(false);
  };

  const selectAllHandler = useCallback(() => {
    if (selectedDocuments.length < orderFilesList.length) {
      setSelectedDocuments(orderFilesList);
    } else {
      setSelectedDocuments([]);
    }
  }, [orderFilesList, selectedDocuments]);

  return (
    <div style={{ paddingBottom: '4em' }}>
      <Container>
        <DecoratedHeader
          icon={<FolderOpenIcon />}
          title={t('pages.order.pageTitle', {
            orderNumber: order.item?.orderNumber,
          })}
          variant="colored"
        >
          <OrderControlHeader
            backTo={`${pathPrefix}/orders`}
            isSelected={selectedDocuments.length > 0}
            selectAll={selectAllHandler}
            deleteSelected={isAdmin ? deleteFilesHandler : undefined}
            downloadSelected={downloadSelectedHandler}
            emailSelected={emailSelectedHandler}
            isAllSelected={
              selectedDocuments.length !== 0 &&
              selectedDocuments.length === orderFilesList.length
            }
          />
        </DecoratedHeader>
        <OrderDetailsCard
          orderInstance={order.item}
          orderFilesByType={orderFilesByType}
          orderFilesLoading={orderFilesLoading}
        />
      </Container>
      {!orderFilesLoading && (
        <>
          <OrderFileTable
            id={OrderFileType.Shipping}
            title={t('orderDetails.table.shippingHeader')}
            files={orderFilesByType[OrderFileType.Shipping]}
            selected={selectedDocuments}
            setSelected={setSelectedDocuments}
            deleteOrderFile={deleteFileHandler}
          />
          <OrderFileTable
            id={OrderFileType.Invoice}
            title={t('orderDetails.table.invoicesHeader')}
            files={orderFilesByType[OrderFileType.Invoice]}
            selected={selectedDocuments}
            setSelected={setSelectedDocuments}
            deleteOrderFile={deleteFileHandler}
          />
          <OrderFileTable
            id={OrderFileType.Confirmation}
            title={t('orderDetails.table.confirmationHeader')}
            files={orderFilesByType[OrderFileType.Confirmation]}
            selected={selectedDocuments}
            setSelected={setSelectedDocuments}
            deleteOrderFile={deleteFileHandler}
          />
          <EmailFiles
            orderId={order.item?.id}
            orderNumber={order.item?.orderNumber}
            visible={displayEmailDrawer}
            visibilitySet={setDisplayEmailDrawer}
            documents={selectedDocuments}
          />
          <DeleteOrderFiles
            orderId={order.item?.id}
            documentToDelete={documentToDelete}
            visible={displayDeleteFilesDrawer}
            visibilitySet={deleteDrawerVisibilityHandler}
            documents={selectedDocuments}
          />
        </>
      )}
      <ScrollToTop />
    </div>
  );
};

export default Order;
