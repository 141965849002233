import { useTranslation } from 'react-i18next';

import { MenuItem } from '..';

export const LanguageSwitcherMenuItem = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const otherLanguage = i18n.language === 'en' ? 'fr' : 'en';
  const otherT = i18n.getFixedT(otherLanguage);

  return (
    <MenuItem
      title={otherT('application.languageSwitcher.language')}
      titleAccess={otherT('application.languageSwitcher.switchTo')}
      onClick={() => changeLanguage(otherLanguage)}
    />
  );
};
