import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UnsubscribeConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h2">{t('unsubscribe.confirmation')}</Typography>

      <Grid mt={5}>
        <Button variant="outlined" onClick={() => navigate('/login')}>
          {t('unsubscribe.goBack')}
        </Button>
      </Grid>
    </>
  );
};

export default UnsubscribeConfirmation;
