import { SxProps } from '@mui/material';

export const button: SxProps = { mr: 2 };

export const grid: SxProps = { my: { xs: 2, md: 0 } };

export const rejectButton: SxProps = { ml: 2 };

export const title: SxProps = {
  mt: { xs: 2, md: 7 },
  mb: {
    xs: 2,
    md: 7,
  },
};
