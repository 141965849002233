import { useFunctions } from 'reactfire';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import { OrderFile } from '../../../models';
import { BottomDrawer } from '../../../components';
import { NotificationLevel } from '../../../enums';
import {
  useCurrentUser,
  useDownloadFiles,
  useNotification,
  useProgress,
} from '../../../hooks';
import {
  confirmButton,
  cancelButton,
  buttonContainer,
  buttonContainerWrapper,
  mainContainer,
} from '../common.styles';
import { input as inputStyle } from './EmailFiles.styles';

const EmailFiles = ({
  orderId,
  orderNumber,
  visible,
  visibilitySet,
  documents,
}: {
  orderId: string | undefined;
  orderNumber: string | undefined;
  visible: boolean;
  visibilitySet: Dispatch<SetStateAction<boolean>>;
  documents: OrderFile[];
}) => {
  const [loading, setLoading] = useState(false);
  const email = useRef<HTMLInputElement>();
  const message = useRef<HTMLInputElement>();
  const functions = useFunctions();
  const user = useCurrentUser();
  const { showProgress } = useProgress();
  const { addNotification } = useNotification();
  const { getStoragePath, getURL } = useDownloadFiles();
  const { t } = useTranslation();
  const emailFilesCall = httpsCallable(functions, 'sendOrderRelatedFiles');

  useEffect(() => {
    showProgress(loading);
  }, [loading, showProgress]);

  const sendEmailHandler = async () => {
    setLoading(true);
    try {
      if (
        !(
          email.current &&
          /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email.current?.value)
        )
      ) {
        throw new Error('orderDetails.email.errorEmailValidation');
      }

      const data = {
        emailTo: email.current?.value,
        message:
          message.current?.value &&
          message.current?.value.replace(/\s+/g, '').length !== 0
            ? message.current?.value
            : t('orderDetails.email.messagePlaceholder', {
                Name: user.name,
                orderNumber: orderNumber,
              }),
        orderId: orderId,
        files: await Promise.all(
          documents.map(async (item) => {
            const { downloadURL, size } = await getURL(getStoragePath(item));
            return {
              url: downloadURL,
              name: item.fileName + item.fileExtension,
              byteSize: size,
            };
          })
        ),
      };

      await emailFilesCall(data)
        .then(() => {
          visibilitySet(false);
          addNotification(
            t('orderDetails.email.success'),
            NotificationLevel.Success
          );
        })
        .catch((e: Error) => {
          if (e.message.includes('MESSAGE_SIZE_EXCEEDED')) {
            throw new Error('orderDetails.email.errorEmailFileSize');
          } else if (e.message.includes('invalid-domain')) {
            throw new Error('orderDetails.email.errorEmailDomain');
          } else {
            throw new Error('orderDetails.email.errorCantSent');
          }
        });
    } catch (e) {
      addNotification(t((e as Error).message), NotificationLevel.Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BottomDrawer
      title={t('orderDetails.email.drawerTitle', { count: documents.length })}
      icon={<HowToRegIcon sx={{ color: 'secondary.main' }} />}
      open={visible}
    >
      <Grid container sx={mainContainer}>
        <Grid item display={'flex'} md={6} xs={12}>
          <Grid
            container
            display="flex"
            direction={'row'}
            alignItems={'center'}
            spacing={4}
          >
            <Grid item md={4} xs={12}>
              <TextField
                sx={inputStyle}
                inputRef={email}
                fullWidth
                required
                type={'email'}
                placeholder={t('orderDetails.email.emailPlaceholder')}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                sx={inputStyle}
                inputRef={message}
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                placeholder={t('orderDetails.email.messagePlaceholder')}
                defaultValue={t('orderDetails.email.messagePlaceholder', {
                  Name: user.name,
                  orderNumber: orderNumber,
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} sx={buttonContainerWrapper}>
          <Grid container spacing={4} sx={buttonContainer}>
            <Grid item mb={6} m={0}>
              <Button
                sx={confirmButton}
                onClick={sendEmailHandler}
                disabled={loading}
              >
                {t('orderDetails.email.sendButton')}
              </Button>
            </Grid>
            <Grid item mb={6} m={0}>
              <Button
                sx={cancelButton}
                onClick={() => visibilitySet(false)}
                disabled={loading}
              >
                {t('orderDetails.email.cancelButton')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default EmailFiles;
