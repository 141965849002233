import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, Switch, Typography } from '@mui/material';
import { OrderFilesPermissions, User } from '../../models';
import { DataTable, DataTableColumn } from '../../tables';
import { CompanyOrderPermissions } from './CompanyUserPermissionsList.component';

interface Props {
  title: string;
  users: User[];
  defaultForRole?: CompanyOrderPermissions;
  editUserPermissions: (updatedUser: User) => void;
}

export const CompanyUsersPermissionsTable = ({
  title,
  users,
  defaultForRole,
  editUserPermissions,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item paddingTop={'3em'}>
        <Typography
          component="h3"
          variant="h3"
          color="primary"
          sx={{ scrollMarginTop: '80px' }}
          id={'permission_section_' + title.replace(/\s+/g, '_').toLowerCase()}
        >
          {title}
        </Typography>
      </Grid>
      <DataTable data={users} loading={!users}>
        <DataTableColumn property="name" title={t('users.fields.name')} />
        <DataTableColumn
          textAlign="center"
          property="orderFilesPermissions.shipping"
          title={t('orders.table.shippingDoc')}
          mobileInlineHeaderDirection="rtl"
          output={(item, user: User) => {
            const state = user.orderFilesPermissions.useDefault
              ? defaultForRole?.shipping
              : item === undefined
              ? defaultForRole?.shipping
              : item;

            return (
              <Checkbox
                color="secondary"
                checked={state}
                disabled={user.orderFilesPermissions.useDefault}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editUserPermissions({
                    ...user,
                    orderFilesPermissions: {
                      ...user.orderFilesPermissions,
                      shipping: e.target.checked,
                    },
                  } as User);
                }}
              />
            );
          }}
        />
        <DataTableColumn
          textAlign="center"
          property="orderFilesPermissions.invoiceCredit"
          title={t('orders.table.invoicesDoc')}
          mobileInlineHeaderDirection="rtl"
          output={(item, user: User) => {
            const state = user.orderFilesPermissions.useDefault
              ? defaultForRole?.invoiceCredit
              : item === undefined
              ? defaultForRole?.invoiceCredit
              : item;

            return (
              <Checkbox
                color="secondary"
                checked={state}
                disabled={user.orderFilesPermissions.useDefault}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editUserPermissions({
                    ...user,
                    orderFilesPermissions: {
                      ...user.orderFilesPermissions,
                      invoiceCredit: e.target.checked,
                    },
                  } as User);
                }}
              />
            );
          }}
        />
        <DataTableColumn
          textAlign="center"
          property="orderFilesPermissions.confirmation"
          title={t('orderDetails.table.confirmationHeader')}
          mobileInlineHeaderDirection="rtl"
          output={(item, user: User) => {
            const state = user.orderFilesPermissions.useDefault
              ? defaultForRole?.confirmation
              : item === undefined
              ? defaultForRole?.confirmation
              : item;

            return (
              <Checkbox
                color="secondary"
                checked={state}
                disabled={user.orderFilesPermissions.useDefault}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editUserPermissions({
                    ...user,
                    orderFilesPermissions: {
                      ...user.orderFilesPermissions,
                      confirmation: e.target.checked,
                    },
                  } as User);
                }}
              />
            );
          }}
        />
        <DataTableColumn
          textAlign="center"
          property="orderFilesPermissions.useDefault"
          title={t('companies.orderFilePermissions.columnTitleOverride')}
          mobileInlineHeaderDirection="rtl"
          output={(item, user: User) => (
            <Switch
              color="secondary"
              checked={!item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const userPermissions = e.target.checked
                  ? ({
                      confirmation: defaultForRole?.confirmation,
                      invoiceCredit: defaultForRole?.invoiceCredit,
                      shipping: defaultForRole?.shipping,
                      useDefault: !e.target.checked,
                    } as OrderFilesPermissions)
                  : ({
                      useDefault: !e.target.checked,
                    } as OrderFilesPermissions);
                editUserPermissions({
                  ...user,
                  orderFilesPermissions: userPermissions,
                } as User);
              }}
            />
          )}
        />
      </DataTable>
    </>
  );
};
