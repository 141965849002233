import { useMemo } from 'react';

import { UserPermission, UserRole } from '../enums';
import { roles } from '../security';
import { usePermission } from './usePermission';

type PermissionRolesOptions = {
  extraCustomerPermissions: UserPermission[];
};

export const usePermissionRoles = (options?: PermissionRolesOptions) => {
  const { hasOneOfPermissions } = usePermission();
  // Role options available to user based on their permissions
  const roleList = useMemo(() => {
    const list: UserRole[] = [];
    if (hasOneOfPermissions([UserPermission.ManageNCUsers])) {
      list.push(...roles.AdminRoles);
    }
    if (hasOneOfPermissions([UserPermission.ManageAgencyUsers])) {
      list.push(...roles.AgentRoles);
    }
    if (
      hasOneOfPermissions([
        UserPermission.ManageCustomerUsers,
        ...(options?.extraCustomerPermissions ?? []),
      ])
    ) {
      list.push(...roles.CustomerRoles);
    }
    return list;
  }, [hasOneOfPermissions, options]);

  return roleList;
};
