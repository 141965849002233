import { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { usePrompt } from '../../navigation/ReactRouterHooks';
import { Container, ScrollToTop, Show } from '../../components';
import firebaseHooks from '../../firebase/hooks';
import { useNotification, useProgress, useUsers } from '../../hooks';
import { NotificationLevel, UserRole } from '../../enums';
import { OrderFilesPermissions } from '../../models';
import { CompanyUserPermissionsListProps } from './CompanyUserPermissionsList.props';
import { CompanyUsersPermissionsTable } from './CompanyUsersPermissionsTable';
import { CompanyPermissionsTable } from './CompanyPermissionsTable';
import { CompanyFormControlHeader } from './CompanyFormControlHeader';

export interface CompanyOrderPermissions extends OrderFilesPermissions {
  id: string;
  name: string;
}

export const CompanyUserPermissionsList = ({
  backTo,
  company,
  headerTitle,
  formHeaderTitle,
}: CompanyUserPermissionsListProps) => {
  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState(false);
  const { showProgress } = useProgress();
  const firestore = firebaseHooks.useFirestore();
  const { addNotification } = useNotification();
  const [users, setUsers] = useState<User[]>([]);
  const [updatedUsers, setUpdatedUsers] = useState<User[]>([]);
  const [companyData, setCompanyData] = useState<CompanyOrderPermissions[]>([]);
  const [isCompanyChanged, setIsCompanyChanged] = useState(false);

  const { list: usersList } = useUsers({
    filters: [
      ['companyRef', '==', doc(firestore, 'Companies', company.item?.id || '')],
    ],
  });

  const updateUserPermissions = useCallback((updatedUser: User) => {
    setUsers((prev) =>
      prev.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    );
    setUpdatedUsers((prev) => [
      ...prev.filter((user) => user.id !== updatedUser.id),
      updatedUser,
    ]);
  }, []);

  const updateCompanyPermissions = useCallback(
    (updatedCompany: CompanyOrderPermissions) => {
      setCompanyData((prev) =>
        prev.map((permission) =>
          permission.id === updatedCompany.id ? updatedCompany : permission
        )
      );
      setIsCompanyChanged(true);
    },
    []
  );

  const cancelChanges = () => {
    const permission = company.item
      ? (Object.entries(company.item.orderFilesPermissions).map((items) => {
          return {
            ...items[1],
            name: items[0],
            id: items[0],
          };
        }) as unknown as CompanyOrderPermissions[])
      : [];
    setCompanyData(permission);
    setIsCompanyChanged(false);
    setUsers(usersList);
    setUpdatedUsers([]);
  };

  useEffect(() => {
    showProgress(inProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  const saveChanges = async () => {
    setInProgress(true);
    addNotification(t('forms.saving'), NotificationLevel.Information);

    try {
      if (isCompanyChanged) {
        const newPermissions = companyData.reduce((obj, item) => {
          return {
            ...obj,
            [item.name]: {
              confirmation: item.confirmation,
              invoiceCredit: item.invoiceCredit,
              shipping: item.shipping,
            },
          };
        }, {});

        updateDoc(doc(firestore, 'Companies', company.item?.id || ''), {
          orderFilesPermissions: newPermissions,
        });
      }

      const promises = updatedUsers.map((user) =>
        updateDoc(doc(firestore, 'Users', user.id), {
          orderFilesPermissions: user.orderFilesPermissions,
        })
      );

      await Promise.all(promises);

      addNotification(t('forms.savedSuccessfully'), NotificationLevel.Success);
      setIsCompanyChanged(false);
      setUpdatedUsers([]);
    } catch (e) {
      addNotification(t('forms.savedUnsuccessfully'), NotificationLevel.Error);
    } finally {
      setInProgress(false);
    }
  };

  useEffect(() => {
    setUsers(usersList);
  }, [usersList]);

  useEffect(() => {
    const permission = company.item
      ? (Object.entries(company.item.orderFilesPermissions).map((items) => {
          return {
            ...items[1],
            name: items[0],
            id: items[0],
          };
        }) as unknown as CompanyOrderPermissions[])
      : [];
    setCompanyData(permission);
  }, [company]);

  usePrompt(
    t('forms.navigationConfirmation'),
    updatedUsers.length > 0 || isCompanyChanged
  );

  return (
    <>
      <CompanyFormControlHeader
        title={formHeaderTitle}
        showSave={updatedUsers.length > 0 || isCompanyChanged}
        onCancel={cancelChanges}
        onSave={saveChanges}
        to={backTo}
      />
      <Container>
        <Grid sx={{ marginTop: 2, marginBottom: 4 }}>
          <Show if={!!headerTitle}>
            <Typography variant="h2">{headerTitle}</Typography>
          </Show>
          <CompanyPermissionsTable
            title={t('companies.orderFilePermissions.titleDefault')}
            companyPermissions={companyData}
            editCompanyPermissions={updateCompanyPermissions}
          />
          <CompanyUsersPermissionsTable
            title={t('users.roles.buyer')}
            users={users.filter((user) => user.role === UserRole.Buyer)}
            defaultForRole={
              companyData.filter((item) => item.name === 'buyer')[0]
            }
            editUserPermissions={updateUserPermissions}
          />
          <CompanyUsersPermissionsTable
            title={t('users.roles.sales')}
            users={users.filter((user) => user.role === UserRole.Sales)}
            defaultForRole={
              companyData.filter((item) => item.name === 'sales')[0]
            }
            editUserPermissions={updateUserPermissions}
          />
          <CompanyUsersPermissionsTable
            title={t('users.roles.general')}
            users={users.filter((user) => user.role === UserRole.General)}
            defaultForRole={
              companyData.filter((item) => item.name === 'general')[0]
            }
            editUserPermissions={updateUserPermissions}
          />
          <ScrollToTop />
        </Grid>
      </Container>
    </>
  );
};
