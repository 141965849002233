import { useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  ButtonGroup,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useDebouncedFormFieldValue } from '../../hooks';
import { NumericTextFieldProps } from './NumericTextField.props';

export const NumericTextField = <T,>({
  defaultValue,
  disabled,
  field,
  form,
  fullWidth,
  label,
  maximum = Number.MAX_SAFE_INTEGER,
  minimum = Number.MIN_SAFE_INTEGER,
  required,
  unitLabel,
  step = 1,
}: NumericTextFieldProps<T>) => {
  const { value, setDelayedValue } = useDebouncedFormFieldValue<
    T,
    number | undefined
  >(form, field);

  const validateAndCallOnChange = (value: number) => {
    setDelayedValue(Math.min(Math.max(value, minimum), maximum));
  };

  const increment = () => {
    if (disabled) return;
    validateAndCallOnChange((value || 0) + step);
  };

  const decrement = () => {
    if (disabled) return;
    validateAndCallOnChange((value || 0) - step);
  };

  const fieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value && value !== '0') {
      setDelayedValue(undefined);
    } else if (step && step > 1) {
      const remaining = Number(value) % step;

      if (remaining > 0) {
        const updatedValue = Math.round(Number(value) / step) * step;

        validateAndCallOnChange(updatedValue);
      }
    } else {
      validateAndCallOnChange(Number(event.target.value) || 0);
    }
  };

  const fieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      validateAndCallOnChange(
        defaultValue || Math.min(Math.max(0, minimum), maximum)
      );
    }
  };

  const fieldKeyDown = (event: React.KeyboardEvent) => {
    if (disabled) return;
    switch (event.key) {
      case 'ArrowUp':
        increment();
        break;
      case 'ArrowDown':
        decrement();
        break;
    }
  };

  useEffect(() => {
    if (
      (value || value === 0) &&
      Math.min(Math.max(value, minimum), maximum) !== value
    ) {
      setDelayedValue(Math.min(Math.max(value, minimum), maximum));
    }
  }, [maximum, minimum, value, setDelayedValue]);

  const spinner = (
    <ButtonGroup orientation="vertical" size="small" sx={{ ml: 1 }}>
      <IconButton onClick={increment} size="small" sx={{ p: 0 }}>
        <KeyboardArrowUpIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={decrement} size="small" sx={{ p: 0 }}>
        <KeyboardArrowDownIcon fontSize="small" />
      </IconButton>
    </ButtonGroup>
  );

  const id = field as string;

  return (
    <TextField
      id={id}
      aria-labelledby={`${id}-label`}
      disabled={disabled}
      error={!!form.errors[field]}
      fullWidth={fullWidth}
      helperText={form.errors[field]}
      label={label}
      onBlur={fieldBlur}
      onChange={fieldChange}
      onKeyDown={fieldKeyDown}
      required={required}
      sx={{ width: !fullWidth ? '10rem' : undefined }}
      value={value === 0 ? value : value || ''}
      inputRef={form.fieldRef(id)}
      InputProps={{
        inputProps: {
          sx: {
            width:
              unitLabel &&
              `${value || value === 0 ? value.toString().length : 1}ch`,
          },
        },
        endAdornment: (
          <>
            {unitLabel && (
              <InputAdornment
                sx={{
                  ml: 0.5,
                  flexGrow: 1,
                  lineHeight: 1.5,
                }}
                disableTypography={true}
                position="start"
              >
                {value || value === 0 ? unitLabel : ''}
              </InputAdornment>
            )}
            {spinner}
          </>
        ),
      }}
    />
  );
};
