import i18next from 'i18next';

export class CurrencyFormatter {
  formatter: Intl.NumberFormat;
  compactFormatter: Intl.NumberFormat;
  thresholdValue?: number;

  constructor(country: 'CA' | 'US' = 'CA', thresholdValue?: number) {
    this.formatter = Intl.NumberFormat(i18next.language + '-' + country, {
      style: 'currency',
      currency: country === 'CA' ? 'CAD' : 'USD',
    });
    this.compactFormatter = Intl.NumberFormat(
      i18next.language + '-' + country,
      {
        style: 'currency',
        currency: country === 'CA' ? 'CAD' : 'USD',
        notation: 'compact',
      }
    );

    this.thresholdValue = thresholdValue;
  }

  format(value: number) {
    if (this.thresholdValue && value >= this.thresholdValue) {
      return this.compactFormatter.format(value);
    }

    return this.formatter.format(value);
  }
}
