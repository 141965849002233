import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePublicAuthentication } from '../hooks';
import { ProgressIndicator } from '../components';

const Logout: React.FC = () => {
  const { logout } = usePublicAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    logout().then(() => {
      navigate('/login');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ProgressIndicator />;
};

export default Logout;
