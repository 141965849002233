import { useState, useRef } from 'react';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material/';

import { useAuthentication } from '../../hooks';
import { ImpersonateButton, UserAvatar } from '../../components';
import { UserMenuItems } from '..';

import * as Sx from './UserMenu.styles';

export const UserMenu = () => {
  const { authUser: auth } = useAuthentication();

  const buttonRef = useRef(null);

  const [visible, setVisible] = useState<boolean>(false);

  if (!auth) return null;

  return (
    <Grid display="flex" alignItems="center">
      {auth && (
        <>
          {auth.uid && <ImpersonateButton />}
          <MenuItem
            ref={buttonRef}
            aria-label="User Menu"
            aria-controls="menu-user"
            aria-haspopup="true"
            onClick={() => setVisible(true)}
            sx={Sx.userMenuItem}
          >
            <Typography
              sx={Sx.userMenuItemTypography}
              variant="body2"
              color="white"
            >
              {auth?.displayName}
            </Typography>
            <UserAvatar />
            <ChevronRight />
          </MenuItem>
        </>
      )}
      <Menu
        id="menu-user"
        color="secondary"
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={visible}
        onClose={() => setVisible(false)}
        onClick={() => setVisible(false)}
        MenuListProps={{
          sx: Sx.userMenuList,
        }}
        sx={Sx.userMenu}
      >
        <UserMenuItems />
      </Menu>
    </Grid>
  );
};
