import { useLocation } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { NeedsPermission } from '../../security';
import { UserPermission } from '../../enums';
import { useCurrentUser } from '../../hooks';

export const ReservationHeaderCounter = () => {
  const location = useLocation();
  const user = useCurrentUser();

  const inAdmin = location.pathname.includes('/admin/');

  const currentReservations = user?.reservationCount ?? 0;
  const maxReservations = user?.maxReservationsPerPeriod;

  if (inAdmin || maxReservations === undefined) {
    return null;
  }

  const iconSx = { fontSize: '1.25rem', marginRight: '.25rem' };
  const containerSx = {
    display: { xs: 'flex', md: 'none' },
    justifyContent: 'flex-end',
  };
  const currentReservationsSx = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: 1,
  };

  return (
    <NeedsPermission oneOf={[UserPermission.ManageOwnReservations]}>
      <Container sx={containerSx}>
        <Typography>
          <Typography
            color="secondary"
            sx={currentReservationsSx}
            component="span"
          >
            <AccessTimeIcon sx={iconSx} />
            {currentReservations}
          </Typography>
          /{maxReservations}
        </Typography>
      </Container>
    </NeedsPermission>
  );
};
