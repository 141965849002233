import JSZip from 'jszip';
import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDownloadURL, getMetadata, getStorage, ref } from 'firebase/storage';

import { FirebaseFile } from '../models';
import { NotificationLevel } from '../enums';
import { useNotification, useProgress } from '.';

export function useDownloadFiles() {
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();
  const { showProgress } = useProgress();
  const { addNotification } = useNotification();
  const storage = getStorage();

  useEffect(() => {
    showProgress(inProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  const getURL = async (partialUrl: string) => {
    const storageRef = ref(storage, partialUrl);
    const downloadURL = await getDownloadURL(storageRef);
    const size = (await getMetadata(storageRef)).size;
    return { downloadURL, size };
  };

  const getStoragePath = (file: FirebaseFile) =>
    `${file.storageBucket}/${file.storageId}${file.fileExtension}`;

  function downloadFromFireStorage(path: string, fileName: string) {
    setInProgress(true);

    getURL(path)
      .then(({ downloadURL: url }) => {
        fetch(url).then(async (response) => {
          const data = await response.blob();
          saveAs(data, fileName);
        });
      })
      .catch(() => {
        addNotification(t('downloadMsg.error'), NotificationLevel.Error);
      })
      .finally(() => {
        setInProgress(false);
      });
  }

  function downloadFile(item: FirebaseFile) {
    downloadFromFireStorage(
      getStoragePath(item),
      item.fileName + item.fileExtension
    );
  }

  function downloadFilesBundle(items: FirebaseFile[], fileName?: string) {
    setInProgress(true);
    const zip = new JSZip();

    try {
      const fileNames = new Set<string>();

      const remoteZips = items.map((item) =>
        getURL(getStoragePath(item))
          .then(({ downloadURL: url }) =>
            fetch(url).then(async (response) => {
              const data = await response.blob();
              const fileName = item.fileName + item.fileExtension;
              if (fileNames.has(fileName)) {
                const newFileName =
                  item.fileName +
                  '_' +
                  new Date().getTime() +
                  item.fileExtension;
                zip.file(newFileName, data);
              } else {
                fileNames.add(fileName);
                zip.file(fileName, data);
              }
              return data;
            })
          )
          .catch(() => {
            addNotification(t('downloadMsg.errorAll'), NotificationLevel.Error);
            // Prevent bundle download
            throw new Error();
          })
      );

      Promise.all(remoteZips)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, fileName + '.zip');
          });
        })
        .catch(() => {
          addNotification(t('downloadMsg.errorAll'), NotificationLevel.Error);
        });
    } finally {
      setInProgress(false);
    }
  }

  function downloadOrderFilesBundle(
    items: FirebaseFile[],
    orderNumber?: string,
    orderCompany?: string
  ) {
    downloadFilesBundle(
      items,
      orderCompany ? orderCompany + '_' + orderNumber : orderNumber
    );
  }

  function downloadFiles(
    files: { name: string; url: string }[],
    archiveName: string
  ) {
    setInProgress(true);
    const zip = new JSZip();

    try {
      const remoteZips = files.map((item) =>
        fetch(item.url).then(async (response) => {
          const data = await response.blob();
          zip.file(item.name, data);
          return data;
        })
      );

      Promise.all(remoteZips).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, archiveName + '.zip');
        });
      });
    } finally {
      setInProgress(false);
    }
  }

  return {
    downloadFiles,
    downloadFromFireStorage,
    downloadFile,
    downloadOrderFilesBundle,
    downloadFilesBundle,
    getStoragePath,
    getURL,
  };
}
