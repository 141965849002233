import { useEffect } from 'react';

/*
 * Based off of https://github.com/matteobad/detect-autofill/blob/master/src/detect-autofill.js
 * Basically copy/pasted code in here, because the original work conflicted with Mui by
 * overwriting animation-name on fields
 */

export const useDetectAutofill = () => {
  useEffect(() => {
    document.addEventListener('animationstart', onAnimationStart, true);
    document.addEventListener('input', onInput, true);

    return () => {
      document.removeEventListener('animationstart', onAnimationStart);
      document.removeEventListener('input', onInput);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Covers browsers that use :-webkit-autofill; (Chrome, Safari)
  const onAnimationStart = (event: AnimationEvent) => {
    'mui-auto-fill-cancel' === event.animationName
      ? autocomplete(event.target)
      : cancelAutocomplete(event.target);
  };

  // Covers other browsers (FF, Edge, IE11)
  const onInput = (event: Event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    'insertReplacementText' === event.inputType || !('data' in event)
      ? autocomplete(event.target)
      : cancelAutocomplete(event.target);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const autocomplete = (element: any) => {
    if (element.hasAttribute('autocompleted')) return;
    element.setAttribute('autocompleted', '');

    const event = new window.CustomEvent('onautocomplete', {
      bubbles: true,
      cancelable: true,
      detail: null,
    });

    // no autofill if preventDefault is called
    if (!element.dispatchEvent(event)) {
      element.value = '';
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cancelAutocomplete = (element: any) => {
    if (!element.hasAttribute('autocompleted')) return;
    element.removeAttribute('autocompleted');

    // dispatch event
    element.dispatchEvent(
      new window.CustomEvent('onautocomplete', {
        bubbles: true,
        cancelable: false,
        detail: null,
      })
    );
  };
};
