import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { FolderOpen } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';

import { Nullable, ReelReturnsFilters as Filters } from '../../types';
import {
  useCompletedReelReturns,
  useLocalStorage,
  useNotification,
  usePageTitle,
  useProgress,
} from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import { BottomDrawer, ReelReturnsTable, Show } from '../../components';
import { RequirePermission } from '../../security';
import {
  NotificationLevel,
  ReelReturnError,
  UserPermission,
} from '../../enums';
import { ReelReturnsReportFilters } from '../../components/ReelReturnsReportFilters/ReelReturnsReportFilters.component';
import { ReelReturn } from '../../models';
import * as Sx from '../modals/common.styles';
import Reports from './partials/Reports';

const ReelReturnsReport: React.FC = () => {
  usePageTitle(getPageTitle('reports.reelReturns'));
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const { item: filters, setItem: setFilters } = useLocalStorage<Filters>(
    'reelReturns',
    'completed'
  );
  const functions = useFunctions();
  const receiveReelReturn = httpsCallable(functions, 'receiveReelReturn');

  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const [reelReturnToReceive, setReelReturnToReceive] =
    useState<ReelReturn | null>(null);

  if (typeof filters?.startDate === 'string') {
    filters.startDate = DateTime.fromISO(filters.startDate);
  }
  if (typeof filters?.endDate === 'string') {
    filters.endDate = DateTime.fromISO(filters.endDate);
  }

  const handleSearch = (filters: Nullable<Filters>) => {
    setFilters(filters);
  };

  const onReceiveReelReturn = (item: ReelReturn) => {
    setReelReturnToReceive(item);
    setShowBottomDrawer(true);
  };

  const closeModalHandler = () => {
    setShowBottomDrawer(false);
  };

  const receiveReelReturnHandler = async () => {
    setSaving(true);

    try {
      await receiveReelReturn(reelReturnToReceive?.id).then(
        () => {
          closeModalHandler();
          addNotification(
            t('reelReturns.receiveReelReturn.success'),
            NotificationLevel.Success
          );
        },
        () => {
          throw new Error(
            `reelReturns.receiveReelReturn.errors.${ReelReturnError.ReceiveProcess}`
          );
        }
      );
    } catch (error) {
      addNotification(t((error as Error).message), NotificationLevel.Error);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    showProgress(saving);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  return (
    <RequirePermission oneOf={[UserPermission.ViewReelReturns]}>
      <Reports>
        <ReelReturnsReportFilters
          onSearch={handleSearch}
          loadedFilters={filters}
        />

        <Show if={!!filters}>
          <ReelReturnsTable
            isAdmin={true}
            isReport={true}
            filters={filters}
            reelReturnsHook={useCompletedReelReturns}
            receiveReelReturn={onReceiveReelReturn}
          />
        </Show>
      </Reports>
      <BottomDrawer
        icon={<FolderOpen sx={{ color: 'secondary.main' }} />}
        title={t('reelReturns.receiveReelReturn.title', {
          nciRma: reelReturnToReceive?.nciRmaNumber,
        })}
        open={showBottomDrawer}
      >
        <Grid container justifyContent={'space-between'}>
          <Grid item display="flex" md={9} py={2}>
            <Typography component={Grid} item my={2}>
              {t('reelReturns.receiveReelReturn.message')}
            </Typography>
          </Grid>
          <Grid item sx={Sx.buttonContainer}>
            <Button
              disabled={saving}
              onClick={() => receiveReelReturnHandler()}
              variant="contained"
              sx={Sx.confirmButton}
            >
              {t('reelReturns.receiveReelReturn.buttons.receive')}
            </Button>
            <Button
              disabled={saving}
              onClick={() => closeModalHandler()}
              variant="outlined"
              sx={Sx.cancelButton}
            >
              {t('reelReturns.receiveReelReturn.buttons.close')}
            </Button>
          </Grid>
        </Grid>
      </BottomDrawer>
    </RequirePermission>
  );
};

export default ReelReturnsReport;
