import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

import { useFormFieldValue } from '../../hooks';

import { CheckboxProps } from './Checkbox.props';
import * as Sx from './Checkbox.styles';

export const Checkbox = <T,>({
  label,
  form,
  field,
  disabled,
  variant = 'large',
}: CheckboxProps<T>) => {
  const { value, setValue } = useFormFieldValue<T, boolean | undefined>(
    form,
    field
  );

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(checked);
  };

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          checked={value || false}
          disabled={disabled}
          onChange={onChange}
          color="secondary"
        />
      }
      label={label}
      disableTypography={true}
      sx={Sx.formLabel(variant)}
    />
  );
};
