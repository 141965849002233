import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';

import { Reel } from '../../models';
import { MeasurementUnits } from '../../enums';
import { InventoryFilters } from '../../types';
import { convertQuantity, convertWeight } from '../../utils/units';

import report from './report';

const PREFIX = 'NC_';
const NAMESPACE = 'reports.inventory';
const EXPORTED_KEYS = ['description', 'quantity', 'reel', 'weight', 'id'];

class inventoryReportCsv extends report<Reel> {
  private readonly reels: Reel[];
  private readonly filters: InventoryFilters;
  private readonly measurementUnits: MeasurementUnits;

  constructor(
    reels: Reel[],
    filters: InventoryFilters,
    measurementUnits: MeasurementUnits,
    t: TFunction<'translation', undefined>
  ) {
    super(PREFIX, NAMESPACE, EXPORTED_KEYS, t);

    this.reels = reels;
    this.filters = filters;
    this.measurementUnits = measurementUnits;
  }

  protected getSanitizedData(): Reel[] {
    return this.reels.map((reel) => {
      return {
        ...reel,
        description: `${reel.description} - ${reel.cableRef.id} ${
          reel.colors ? reel.colors : ''
        }`,
        weight: Number(convertWeight(this.measurementUnits, reel.weight)),
        quantity: Number(convertQuantity(this.measurementUnits, reel.quantity)),
      };
    });
  }

  protected getFilename(): string {
    const productType = this.filters.productType || this.reels[0].productType;

    return this.t('reports.inventory.filename', {
      type: this.t(`productTypes.${productType}`),
      date: DateTime.now().toFormat('LLddyyyyHHmmss'),
      extension: 'csv',
    });
  }

  protected getHeaders(): string[] {
    return [
      `"${this.t(`${this.namespace}.partNumber`)}"`,
      `"${this.t(`${this.namespace}.quantity`)},${this.t(
        `units.${this.measurementUnits}.length`
      )}"`,
      `"${this.t(`${this.namespace}.reel`)}"`,
      `"${this.t(`${this.namespace}.weight`)},${this.t(
        `units.${this.measurementUnits}.weight`
      )}"`,
      `"${this.t(`${this.namespace}.reelId`)}"`,
    ];
  }

  static generate(
    data: Reel[],
    filters: InventoryFilters,
    measurementUnits: MeasurementUnits,
    t: TFunction<'translation', undefined>
  ) {
    const report = new inventoryReportCsv(data, filters, measurementUnits, t);

    report.generateCSV();
  }
}

export default inventoryReportCsv;
