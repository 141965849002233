import { Button, styled } from '@mui/material';

export const ContrastButtonLight = styled(Button)(({ theme }) => {
  return {
    borderColor: 'white',
    color: 'white',
    '&:hover': {
      borderColor: '#ffffff66',
    },
  };
});

export const ContrastButtonMain = styled(Button)(({ theme }) => {
  return {
    backgroundColor: '#e68008',
    color: 'white',
    '&:hover, &:disabled': {
      backgroundColor: '#e68008',
    },
  };
});

export const ContrastButtonSecondary = styled(Button)(({ theme }) => {
  return {
    color: '#e5e5e5',
    borderColor: '#e5e5e5',
    '&:hover, &:disabled': {
      borderColor: '#e5e5e5',
      color: '#e5e5e5',
    },
  };
});
