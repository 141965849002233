import { useMemo } from 'react';

import { useAgencyConversionRates, useCurrentUser } from '../../hooks';
import { ConversionRateTable } from '../ConversionRateTable/ConversionRateTable.component';
import { buildFilters } from './common';
import { ConversionRateProps } from './ConversionRate.props';

export const AgentConversionRate = ({
  filters,
  expandedCompanies,
  setExpandedCompanies,
}: ConversionRateProps) => {
  const currentUser = useCurrentUser();

  const queryFilters = useMemo(() => buildFilters(filters), [filters]);

  const list = useAgencyConversionRates(
    {
      filters: queryFilters,
    },
    currentUser.companyRef.id,
    filters?.companyIds
  );

  return (
    <ConversionRateTable
      expandedCompanies={expandedCompanies}
      setExpandedCompanies={setExpandedCompanies}
      filters={filters}
      conversionRates={list}
    />
  );
};
