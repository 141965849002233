import { Box, IconButton, Stack, styled } from '@mui/material';
import { height, maxWidth, width } from '@mui/system';

export const MAX_HEIGHT_THUMBNAIL = 60;
export const MIN_WIDTH = 600;
export const MAX_WIDTH = 900;
export const ASPECT_RATIO = '3/2';

export const StyledSlideshowStackComponent = styled(Stack)(() => {
  return {
    flexGrow: 1,
    spacing: 5,
  };
});

export const StyledSlideshowContainerComponent = styled(Box)(() => {
  return {
    '& .image-gallery-thumbnails .image-gallery-thumbnails-container': {
      textAlign: 'right',
      marginTop: '20px',
      '& .image-gallery-thumbnail': {
        ':first-of-type': {
          marginLeft: '150px',
        },
        background: '#F9F9F9',
        '&.active, &:hover': {
          borderColor: '#585656 !important',
          borderRadius: '5px',
        },
        '& .image-gallery-thumbnail-image': {
          objectFit: 'cover',
        },
      },
    },
    '.image-gallery-content:not(.fullscreen) .image-gallery-slide-wrapper': {
      position: 'unset',
      '& .image-gallery-slides': {
        border: '1px solid #D8D8D8',
        borderRadius: '5px',
        background: '#F9F9F9',
      },
      '& .image-gallery-slide, & .image-gallery-image': {
        height: '100%',
        maxHeight: '100%',
        // width: '100%',
        // maxWidth: '100%',
        objectFit: 'cover',
        aspectRatio: ASPECT_RATIO,
      },
    },
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    aspectRatio: ASPECT_RATIO,
    paddingBottom: '20px',
    borderBottom: '1px solid #CAD3DA',
  };
});

export const StyledEmptySlideshowContainerComponent = styled(Stack)(() => {
  return {
    justifyContent: 'center',
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    alignItems: 'center',
    background: '#F9F9F9',
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    aspectRatio: ASPECT_RATIO,
    '& div': {
      minHeight: 'unset',
    },
  };
});

export const StyledLeftNavComponent = styled(IconButton)(() => {
  return {
    position: 'absolute',
    bottom: 14,
    zIndex: 100,
  };
});

export const StyledRightNavComponent = styled(IconButton)(() => {
  return {
    position: 'absolute',
    bottom: 14,
    left: 75,
    zIndex: 100,
  };
});

export const StyledOverflowNavContainerComponent = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    bottom: 0,
    height: '90px',
    width: '150px',
    background: theme.palette.background.default,
    zIndex: 50,
  };
});
