/* eslint-disable max-len */
const MobileLogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3238_56078)">
      <title>Northern Cables Logo</title>
      <path
        d="M43.0669 31.9991C46.4535 31.6831 47.9876 29.1604 47.9876 26.6603C47.9876 23.773 46.8819 21.1583 44.7907 19.0974C42.6995 17.0365 40.2188 16 37.3826 16C35.7333 16 33.996 16.4861 32.2265 17.4428C30.7309 18.2553 29.4519 19.4307 28.5013 20.8666C27.3329 22.5351 26.7402 24.3043 26.7402 26.1273V37.871C26.7402 39.9926 27.6394 42.1091 29.4122 44.1613C31.5356 46.6649 34.2974 48 37.3707 48C40.1731 48 42.6555 46.9583 44.7518 44.9113C46.8481 42.8643 47.969 40.2235 47.969 37.3397C47.9876 34.8396 46.4586 32.3169 43.0669 31.9991ZM38.0565 33.6381V30.4157C38.7524 31.1605 39.8548 31.8463 41.5853 32.0009C39.8599 32.1641 38.7524 32.8881 38.0565 33.6381Z"
        fill="#6E6F72"
      />
      <path
        d="M34.8579 26.3461C34.8579 25.8739 35.1577 25.4184 35.7571 24.9797C36.3565 24.5411 36.8989 24.3223 37.3843 24.3235C37.9478 24.3433 38.4914 24.5416 38.9405 24.8912C39.3421 25.201 39.6341 25.637 39.7719 26.1326C39.8182 26.275 39.9102 26.7721 40.0479 27.624C40.3019 28.381 41.0633 28.7589 42.3321 28.7577C43.9701 28.7577 44.7891 28.0604 44.7891 26.6656C44.7891 24.6562 44.0508 22.9235 42.5743 21.4674C41.0977 20.0113 39.3677 19.2821 37.3843 19.2798C36.2555 19.2798 35.0324 19.6351 33.715 20.3458C32.6694 20.9178 31.7782 21.7463 31.1209 22.7574C30.3306 23.8697 29.9355 24.9931 29.9355 26.1274V33.7337C31.5543 33.307 33.1933 32.9657 34.8461 32.7111L34.8579 26.3461Z"
        fill="#FBB041"
      />
      <path
        d="M43.0186 20.9882C41.4253 19.4257 39.5305 18.6235 37.3851 18.6235C36.154 18.6235 34.8197 19.0072 33.4177 19.7642C32.2808 20.3875 31.3109 21.2878 30.5933 22.3859C29.7466 23.6012 29.3047 24.8583 29.3047 26.1274V33.9108L29.6247 33.8205L29.9448 33.7337V26.1274C29.9448 24.9931 30.3399 23.8698 31.1301 22.7574C31.7874 21.7464 32.6786 20.9179 33.7242 20.3459C35.0393 19.6352 36.2624 19.2798 37.3935 19.2798C39.3758 19.2798 41.1058 20.0073 42.5835 21.4622C44.0611 22.9172 44.7994 24.6499 44.7983 26.6604C44.7983 28.0563 43.9793 28.7537 42.3413 28.7526C41.0725 28.7526 40.3111 28.3746 40.0571 27.6188C39.9182 26.7658 39.8262 26.2686 39.7811 26.1274C39.6433 25.6318 39.3513 25.1959 38.9497 24.886C38.5006 24.5364 37.957 24.3381 37.3935 24.3183C36.907 24.3183 36.3646 24.5371 35.7663 24.9746C35.168 25.4121 34.8655 25.8693 34.8587 26.3462V32.7163L35.1787 32.6712L35.4987 32.626V26.3462C35.4987 26.0406 35.8374 25.7246 36.1286 25.5145C36.6146 25.1673 37.0379 24.9798 37.3851 24.9798C37.8115 25.0013 38.2217 25.1544 38.5619 25.419C38.8497 25.6427 39.0584 25.9567 39.1563 26.3132C39.2667 26.7812 39.3537 27.2546 39.417 27.7317L39.4272 27.7855L39.4424 27.8376C39.6846 28.5564 40.3822 29.4141 42.3329 29.4141C45.0269 29.4141 45.4299 27.6917 45.4299 26.6656C45.4299 24.4745 44.6188 22.5665 43.0186 20.9882Z"
        fill="#FFD6A1"
      />
      <path
        d="M31.8116 41.9875C33.357 43.8093 35.2145 44.7203 37.3842 44.7203C39.3439 44.7203 41.0682 43.9928 42.5572 42.5379C44.0462 41.0829 44.7901 39.3502 44.789 37.3397C44.789 35.9438 43.97 35.2458 42.332 35.2458C41.132 35.2458 40.3706 35.6243 40.0477 36.3813C39.9547 36.7905 39.8852 37.205 39.8395 37.6227C39.7008 38.1944 39.3854 38.7048 38.9403 39.0776C38.5113 39.4677 37.9576 39.6827 37.3842 39.6818C36.7974 39.6707 36.2286 39.4723 35.757 39.1141C35.1575 38.687 34.8578 38.202 34.8578 37.6591V32.7109C33.205 32.9656 31.566 33.3069 29.9473 33.7336V37.8709C29.945 39.1731 30.5665 40.5453 31.8116 41.9875Z"
        fill="#FFD6A1"
      />
      <path
        d="M42.3325 34.5911C40.5003 34.5911 39.7604 35.421 39.4623 36.119L39.4369 36.1798L39.4234 36.2423C39.3284 36.6602 39.2566 37.0834 39.2084 37.5097C39.0956 37.9293 38.859 38.3029 38.531 38.5792C38.2174 38.8673 37.8109 39.0263 37.3898 39.0254C36.9338 39.0144 36.4926 38.8571 36.1283 38.5757C35.5034 38.1313 35.5034 37.7753 35.5034 37.659V32.624L35.1834 32.6692L34.8634 32.7143V37.6625C34.8634 38.2053 35.1631 38.6903 35.7625 39.1174C36.2341 39.4756 36.8029 39.6741 37.3898 39.6852C37.9631 39.686 38.5169 39.471 38.9459 39.081C39.3909 38.7082 39.7063 38.1978 39.845 37.626C39.8907 37.2084 39.9603 36.7939 40.0533 36.3846C40.3773 35.6288 41.1387 35.2503 42.3376 35.2492C43.9755 35.2492 44.7945 35.9471 44.7945 37.343C44.7945 39.3536 44.0506 41.0863 42.5628 42.5412C41.0749 43.9962 39.3506 44.7236 37.3898 44.7236C35.2201 44.7236 33.3625 43.8127 31.8171 41.9909C30.5754 40.5475 29.9545 39.1753 29.9545 37.8743V33.7335L29.6345 33.8203L29.3145 33.9106V37.8708C29.3145 39.3327 29.9918 40.8623 31.3464 42.4214C33.0075 44.3816 35.0429 45.373 37.3965 45.373C39.5165 45.373 41.4062 44.5795 43.0098 43.01C44.6133 41.4405 45.4414 39.5254 45.4414 37.3378C45.4295 36.3117 45.0299 34.5911 42.3325 34.5911Z"
        fill="#FBB041"
      />
      <path
        d="M23.827 16.7414C23.5232 16.5926 23.2077 16.4706 22.8838 16.3768C22.1794 16.1348 21.4423 16.0076 20.6995 16C20.564 16 20.4268 16 20.2914 16.0122C20.0577 16.0122 19.8156 16 19.5633 16C12.9984 16 8.83795 19.2241 7.2615 21.9309C6.42671 23.3528 6 24.7678 6 26.1273V43.1577C6 43.9807 6.28617 46.0277 8.91923 47.2621C9.94381 47.7473 11.0587 47.999 12.1873 48C13.255 47.9968 14.3115 47.7773 15.2962 47.3541L15.3673 47.3229C15.5993 47.2187 16.3528 47.1093 16.5526 46.993C16.7219 47.0868 17.2485 47.177 17.4331 47.2639C18.4577 47.749 19.5726 48.0007 20.7012 48.0017C21.769 47.9986 22.8256 47.7785 23.81 47.3541L23.8845 47.3211C26.5938 46.0971 26.885 44.0224 26.885 43.1977V20.8058C26.885 19.7988 26.4837 17.9463 23.827 16.7414Z"
        fill="#6E6F72"
      />
      <path
        d="M23.3867 20.8057C23.3867 20.3786 23.0079 20.0001 22.2505 19.6703L22.22 19.6581C22.0803 19.5829 21.9326 19.5246 21.7797 19.4845C21.4327 19.3564 21.0681 19.2855 20.6994 19.2744H19.5632C15.6517 19.2744 11.9739 20.6807 10.3331 23.4969C9.7777 24.4425 9.5 25.3176 9.5 26.122V43.1524C9.5049 43.3394 9.56383 43.5207 9.66933 43.6733C11.3385 42.3822 13.0793 41.1915 14.883 40.1071V26.3911C14.883 25.7314 15.2995 25.2012 16.1326 24.8008C16.4527 24.6462 17.6837 24.5316 18.0206 24.4535V38.8467C19.7675 37.9065 21.5615 37.0615 23.3951 36.3153L23.3867 20.8057Z"
        fill="#FBB041"
      />
      <path
        d="M16.1246 24.813C15.2915 25.2146 14.875 25.7447 14.875 26.4033V40.1193L15.2255 39.9127L15.576 39.7096V26.4033C15.6539 25.9276 16.5564 25.5352 17.3116 25.4137V39.2356L17.6622 39.0412L18.0127 38.8467V24.4605C17.6401 24.4206 16.4447 24.6584 16.1246 24.813Z"
        fill="#FFD6A1"
      />
      <path
        d="M22.5469 19.0764L22.5266 19.066C22.3584 18.9825 22.1826 18.9162 22.0017 18.8681C21.5841 18.7167 21.1457 18.6346 20.7029 18.625H19.5633C15.3945 18.625 11.483 20.1615 9.71854 23.1895C9.10896 24.2312 8.80078 25.2156 8.80078 26.1289V43.1593C8.80156 43.506 8.91474 43.8425 9.12251 44.116L9.39174 43.9024L9.66097 43.6889C9.55547 43.5363 9.49654 43.355 9.49164 43.168V26.1271C9.49164 25.3239 9.76934 24.4488 10.3247 23.502C11.974 20.6859 15.6518 19.2796 19.5633 19.2796H20.6995C21.0682 19.2906 21.4328 19.3615 21.7799 19.4896C21.9402 19.5372 22.0974 19.5952 22.2506 19.6633C23.0081 19.9931 23.3868 20.3716 23.3868 20.7987V36.3083L23.7356 36.1659L24.0861 36.0287V20.8057C24.0861 20.1042 23.5697 19.5226 22.5469 19.0764Z"
        fill="#FFD6A1"
      />
      <path
        d="M10.4866 44.3279C11.017 44.5816 11.5946 44.7149 12.1799 44.7186C12.7523 44.7171 13.3186 44.5991 13.8461 44.3713C14.5234 44.0658 14.8621 43.6774 14.8621 43.2063V40.1211C13.0584 41.2055 11.3176 42.3962 9.64844 43.6873C9.86358 43.9773 10.1541 44.1994 10.4866 44.3279Z"
        fill="#FFD6A1"
      />
      <path
        d="M18.9955 44.328C19.529 44.5832 20.1103 44.7165 20.699 44.7187C21.2708 44.7172 21.8366 44.5992 22.3635 44.3714C23.0408 44.0624 23.3794 43.6723 23.3794 43.2012V36.3154C21.5481 37.0619 19.7563 37.9069 18.0117 38.8468V43.1578C18.0117 43.6301 18.3385 44.0224 18.9955 44.328Z"
        fill="#FFD6A1"
      />
      <path
        d="M15.2276 39.9145L14.8771 40.1211V43.1994C14.8771 43.6693 14.5384 44.0577 13.8611 44.3644C13.3336 44.5921 12.7673 44.7102 12.1949 44.7116C11.605 44.7124 11.022 44.5815 10.4864 44.3279C10.1594 44.1968 9.87447 43.975 9.66347 43.6873L9.39423 43.9008L9.125 44.1144C9.40343 44.467 9.76386 44.7423 10.1731 44.9148C10.802 45.2142 11.4864 45.3711 12.1797 45.3749C12.8511 45.3705 13.515 45.2307 14.1338 44.9634C15.3191 44.4304 15.568 43.7099 15.568 43.1994V39.7148L15.2276 39.9145Z"
        fill="#FBB041"
      />
      <path
        d="M23.7359 36.1728L23.3871 36.3152V43.1871C23.3871 43.6593 23.0485 44.0494 22.3711 44.3573C21.8442 44.585 21.2784 44.7031 20.7066 44.7045C20.1179 44.7024 19.5367 44.5691 19.0032 44.3139C18.3462 44.0083 18.0194 43.6194 18.0194 43.1437V38.8466L17.6689 39.041L17.3184 39.2355V43.1576C17.3184 43.6663 17.5571 44.3833 18.6899 44.9146C19.3218 45.2156 20.0099 45.3726 20.7066 45.3747C21.3782 45.3708 22.0424 45.2303 22.6607 44.9615C23.846 44.425 24.0932 43.7045 24.0932 43.194V36.0356L23.7359 36.1728Z"
        fill="#FBB041"
      />
      <path
        d="M51.7878 44.2393C51.1867 43.932 50.8887 43.5449 50.8887 43.0691V28.5527V43.0691C50.8875 43.5414 51.1873 43.9315 51.7878 44.2393Z"
        fill="#FFD6A1"
      />
      <path
        d="M56.3337 16.8263C55.4076 16.3438 54.3839 16.091 53.345 16.0884C52.3636 16.0932 51.3947 16.314 50.5037 16.736L50.4394 16.7655C47.9587 17.9808 47.6895 20.0643 47.6895 20.8907V43.069C47.6895 43.8902 47.9502 45.9372 50.3581 47.1734C51.2837 47.6553 52.3069 47.9075 53.345 47.9095C54.3269 47.9054 55.2964 47.6851 56.1881 47.2637L56.2507 47.2324C58.7331 46.0171 59.0006 43.9336 59.0006 43.1089V20.9307C59.0006 20.1077 58.7466 18.0607 56.3337 16.8263ZM55.1332 44.8729C54.5729 45.1404 53.9631 45.2813 53.345 45.2861C52.7043 45.2828 52.0732 45.1252 51.5027 44.826C50.4664 44.2948 50.2497 43.5777 50.2497 43.069V28.5526V20.8907C50.2497 20.3699 50.4749 19.6598 51.5603 19.125C52.1194 18.8567 52.7279 18.7141 53.345 18.7066C53.9875 18.7116 54.6198 18.8716 55.1907 19.1736C56.2253 19.7049 56.4438 20.4219 56.4438 20.9307V35.4453V43.1089C56.4438 43.6211 56.2169 44.3399 55.1332 44.8729Z"
        fill="#6E6F72"
      />
      <path
        d="M50.8879 33.0809C52.5457 33.4286 54.1848 33.8644 55.7984 34.3866V20.931C55.7984 20.4587 55.4987 20.0687 54.8993 19.7608C54.4172 19.5067 53.8835 19.3728 53.3415 19.3701C52.815 19.3722 52.2953 19.4907 51.8175 19.7174C51.1944 20.0229 50.8828 20.4118 50.8828 20.8841V28.546L50.8879 33.0809Z"
        fill="#FBB041"
      />
      <path
        d="M55.8015 20.9309V28.5529V20.9309C55.8015 20.4587 55.5018 20.0686 54.9023 19.7607C55.5018 20.0686 55.8015 20.4587 55.8015 20.9309Z"
        fill="#FFD6A1"
      />
      <path
        d="M50.8881 28.5526V20.8907C50.8881 20.4184 51.1997 20.0295 51.8228 19.724C52.3006 19.4973 52.8203 19.3788 53.3468 19.3767C53.8888 19.3794 54.4225 19.5133 54.9046 19.7674C55.504 20.0753 55.8037 20.4653 55.8037 20.9376V28.5595V34.3932C56.0188 34.4626 56.2321 34.5338 56.4455 34.6067V28.5526V20.9306C56.4455 20.4219 56.2271 19.7049 55.1925 19.1736C54.621 18.8713 53.9881 18.7113 53.3451 18.7065C52.7278 18.7116 52.1187 18.8519 51.5587 19.118C50.4733 19.6528 50.248 20.3716 50.248 20.8837V32.9469C50.4614 32.9886 50.6764 33.0285 50.8881 33.0736V28.5526Z"
        fill="#FFD6A1"
      />
      <path
        d="M55.8012 35.4453V34.3862C54.1875 33.8641 52.5484 33.4283 50.8906 33.0806V35.4453V43.069C50.8906 43.5412 51.1886 43.9371 51.7898 44.2392C52.2712 44.4932 52.8044 44.6271 53.3459 44.6298C53.8722 44.6266 54.3918 44.5082 54.8699 44.2826C55.4907 43.9747 55.8017 43.5858 55.8029 43.1158V35.4453H55.8012Z"
        fill="#FFD6A1"
      />
      <path
        d="M50.2497 35.4456V43.0692C50.2497 43.5779 50.4665 44.295 51.5028 44.8263C52.0733 45.1254 52.7043 45.283 53.3451 45.2864C53.9631 45.2815 54.5729 45.1406 55.1332 44.8732C56.2169 44.3401 56.4438 43.6213 56.4438 43.1092V34.6018C56.2304 34.5288 56.0171 34.4577 55.802 34.3882V43.1109C55.802 43.5808 55.491 43.9697 54.869 44.2776C54.391 44.5035 53.8714 44.6218 53.3451 44.6249C52.8036 44.6221 52.2704 44.4883 51.7889 44.2342C51.1884 43.9263 50.8881 43.5363 50.8881 43.064V35.4456V33.0808C50.6764 33.0357 50.4614 32.9958 50.248 32.9541V35.4456H50.2497Z"
        fill="#FBB041"
      />
    </g>
    <defs>
      <clipPath id="clip0_3238_56078">
        <rect width="53" height="32" fill="white" transform="translate(6 16)" />
      </clipPath>
    </defs>
  </svg>
);

export default MobileLogo;
