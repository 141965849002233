import { SxProps } from '@mui/material';

export const title: SxProps = {
  mt: 2,
  mb: 1,
  display: { xs: 'none', md: 'block' },
};

export const buttons: SxProps = {
  mt: 3,
};

export const desktopHeaderContents: SxProps = {
  display: { xs: 'none', md: 'flex' },
  cursor: 'pointer',
};

export const mobileHeaderContents: SxProps = {
  display: { md: 'none' },
  mx: 1,
  my: 2,
  cursor: 'pointer',
};

export const collapse: SxProps = {
  height: { md: 'auto !important' },
  visibility: { md: 'visible !important' },
};

export const revealFilterIcon: SxProps = {
  color: '#979797',
  mr: 1,
};

export const revealFilterText: SxProps = {
  display: 'inline-block',
};
