import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { Button, TextField } from '@mui/material';
import { Link, useNavigate, useOutlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { useTranslation } from 'react-i18next';

import { DecoratedHeader, Show, UnitaryModal } from '../components';
import { getPageTitle } from '../utils/pages';
import { useNotification, usePageTitle, useProgress } from '../hooks';
import { NotificationLevel } from '../enums';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const functions = useFunctions();
  const { visible, showProgress } = useProgress();
  const forgotPassword = httpsCallable(functions, 'sendResetPasswordEmail');
  usePageTitle(getPageTitle('forgotPassword'));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('email') === '') {
      return;
    }

    showProgress(true);

    try {
      await forgotPassword(data.get('email'));
      navigate('confirmation');
    } catch {
      addNotification('form.savedUnsuccessfully', NotificationLevel.Error);
    }

    showProgress(false);
  };

  return (
    <UnitaryModal>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Show if={!outlet}>
          <DecoratedHeader
            icon={
              <QuestionMarkRoundedIcon titleAccess={t('alt.questionMark')} />
            }
            title={t('users.actions.forgotPassword')}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('users.fields.email')}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" style={{ color: 'black' }}>
                {t('users.actions.login')}
              </Link>
            </Grid>
          </Grid>
          <Button
            disabled={visible}
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('users.actions.sendPasswordResetEmail')}
          </Button>
        </Show>

        <Show if={!!outlet}>{outlet}</Show>
      </Box>
    </UnitaryModal>
  );
};

export default ForgotPassword;
