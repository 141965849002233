import { SxProps } from '@mui/material';

export const container: SxProps = {
  pt: { xs: 2, md: 8 },
  pb: { xs: 3, md: 11 },
  mb: { xs: 2, md: 0 },
};

export const item: SxProps = {
  gap: { xs: 2, md: 4 },
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: { xs: 'stretch', md: 'center' },
  '& .MuiFormControl-root': {
    minWidth: { xs: '100%', md: '14rem' },
    backgroundColor: 'background.paper',
  },
};
