import { useCompanies } from '../../hooks';
import { CompanyType } from '../../enums';
import { ConversionRateFiltersProps } from './ConversionRateFilters.props';
import { ConversionRateFilters } from './ConversionRateFilters.component';

export const AdminConversionRateFilters = (
  props: Omit<ConversionRateFiltersProps, 'companies' | 'loadingCompanies'>
) => {
  const { list: companies, loading: loadingCompanies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer]],
  });

  return (
    <ConversionRateFilters
      {...props}
      companies={companies}
      loadingCompanies={loadingCompanies}
    />
  );
};
