import { useTranslation } from 'react-i18next';
import { Box, List, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { SideDrawer } from '../SideDrawer/SideDrawer.component';
import {
  StyledListItem,
  StyledListTitle,
} from '../SideDrawer/SideDrawer.styles';
import { CustomerShipToDrawerProps } from './CustomerShipToDrawer.props';

export const CustomerShipToDrawer = ({
  customer,
  onClose,
}: CustomerShipToDrawerProps) => {
  const { t } = useTranslation();

  const shipTos = useMemo(
    () => sortBy(customer?.customerAddresses ?? [], 'id'),
    [customer?.customerAddresses]
  );

  const buildBody = () => (
    <>
      {shipTos.length === 0 ? (
        <Box px={3} alignSelf="center">
          <Typography fontWeight="700" variant="h4">
            {t('agencies.fields.emptyShipTo')}
          </Typography>
        </Box>
      ) : (
        <Stack>
          <StyledListTitle>
            <Typography fontWeight="700" variant="h4">
              {t('agencies.fields.shipToList')}
            </Typography>
          </StyledListTitle>
          <List>
            {shipTos.map((shipTo) => (
              <StyledListItem key={shipTo.id}>
                <Typography
                  fontWeight="500"
                  variant="body1"
                >{`${shipTo.id} - ${shipTo.name}`}</Typography>
              </StyledListItem>
            ))}
          </List>
        </Stack>
      )}
    </>
  );

  return (
    <SideDrawer
      headerText={customer?.companyName || t('common.notApplicable')}
      onClose={onClose}
      isOpen={!!customer}
      bodyChildren={buildBody()}
    />
  );
};
