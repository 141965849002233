import { Navigate } from 'react-router-dom';

import { usePublicAuthentication } from '../hooks';
import { Loading } from '../pages';

interface Props {
  children: React.ReactNode;
}

const RequireAuthentication = ({ children }: Props) => {
  const { user, loading } = usePublicAuthentication();

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default RequireAuthentication;
