import { useMemo } from 'react';
import { Country, ProvinceOrState } from '../enums';
import { CountryProvincesOrStates } from '../mappings';

export const useProvincesOrStates = (country?: Country) =>
  useMemo(
    () =>
      (country && CountryProvincesOrStates[country]
        ? CountryProvincesOrStates[country]
        : Object.values(ProvinceOrState)) as ProvinceOrState[],
    [country]
  );
