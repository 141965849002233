import { where } from 'firebase/firestore';

import { UserStatus } from '../enums';
import { User } from '../models';
import { CollectionQueryOptions } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useUsersWaitingApproval(options?: CollectionQueryOptions) {
  return useLocalCollection<User>(
    'Users',
    [
      where('status', '==', UserStatus.WaitingForApproval),
      where('isNCEmployee', '==', false),
    ],
    options?.sorting || { sortField: 'name', sortDirection: 'asc' },
    options?.pagination,
    options?.filters
  );
}
