import { Avatar, Grid, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from 'react-i18next';
import { usePageTitle, usePortalSettings } from '../../../hooks';
import { BulletinBoardSlideshow, Container } from '../../../components';
import { NeedsPermission } from '../../../security';
import { UserPermission } from '../../../enums';
import * as Sx from './Dashboard.styles';
import { ConversionRateCard } from './ConversionRateCard.component';

export const Dashboard: React.FC = () => {
  const settings = usePortalSettings();
  const { t } = useTranslation();
  usePageTitle();

  return (
    <Grid sx={Sx.background}>
      <Container sx={Sx.container} subSx={Sx.innerContainer}>
        <Grid>
          <Grid display="flex" alignItems="center">
            <Avatar sx={Sx.avatar}>
              <DashboardIcon fontSize="large" />
            </Avatar>
            <Typography variant="h1" color="white" ml={2}>
              {t('dashboard.title')}
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={Sx.grid}>
          <NeedsPermission oneOf={[UserPermission.ViewConversionRatesReports]}>
            <ConversionRateCard />
          </NeedsPermission>
          <BulletinBoardSlideshow
            isPreviewMode={false}
            delay={settings.item?.agentBulletinBoardDelay}
            items={settings.item?.agentBulletinBoardItems}
          />
        </Grid>
      </Container>
    </Grid>
  );
};
