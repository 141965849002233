import { Table, styled } from '@mui/material';

export const StyledTable = styled(Table)(({ theme }) => {
  return {
    [theme.breakpoints.up('xs')]: {
      '& .MuiTableHead-root': {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiTableHead-root': {
        display: 'table-header-group',
      },
    },
  };
});
