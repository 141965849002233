import { useTranslation } from 'react-i18next';
import { PortalSettings, SignUpUser } from '../models';
import { Language } from '../enums';
import { useNewDocumentByFunction } from './useNewDocumentByFunction';

export function useSignUpUser(portalSettings: PortalSettings) {
  const { i18n } = useTranslation();

  return useNewDocumentByFunction(
    'signUp',
    new SignUpUser(
      portalSettings.extensionNotificationDelay,
      portalSettings.holdDelay,
      portalSettings.maxReservationsPerPeriod,
      i18n.language.toUpperCase() as Language,
      null
    )
  );
}
