import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FirebaseAppProvider } from 'reactfire';

import theme from './theme';
import './validation/yup';
import firebaseConfig from './firebase/config';
import FirebaseProviders from './firebase/FirebaseProviders';
import Routes from './routing/Routes';
import { ScrollToTopOnRouteChange } from './navigation';
import { ErrorBoundary, Notification, Progress } from './components';
import InternationalizationProvider from './providers/InternationalizationProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import { ProgressProvider } from './providers/ProgressProvider/ProgressProvider.component';
import { useDetectAutofill } from './hooks';

const App: React.FC = () => {
  useDetectAutofill();

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseProviders>
        <ThemeProvider theme={theme}>
          <InternationalizationProvider>
            <NotificationProvider>
              <ProgressProvider>
                <CssBaseline />
                <ScrollToTopOnRouteChange>
                  <ErrorBoundary>
                    <Progress />
                    <Routes />
                    <Notification />
                  </ErrorBoundary>
                </ScrollToTopOnRouteChange>
              </ProgressProvider>
            </NotificationProvider>
          </InternationalizationProvider>
        </ThemeProvider>
      </FirebaseProviders>
    </FirebaseAppProvider>
  );
};

export default App;
