import { SxProps } from '@mui/material';

export const container: SxProps = {
  '.MuiDateCalendar-root': {
    backgroundColor: '#2c2c2c',
    borderRadius: '8px',
    color: '#ffffff !important',

    '.MuiPickersSlideTransition-root': {
      minHeight: '240px',
    },

    '.MuiTypography-root': {
      color: '#e68008',
    },
    button: {
      color: '#ffffff !important',
      backgroundColor: 'transparent',

      '&.Mui-disabled': {
        opacity: '0.3',
      },
    },
  },
};
