/* eslint-disable @typescript-eslint/no-explicit-any */

import { WhereFilterOp } from 'firebase/firestore';
import { get } from 'lodash';

export const FilterOperatorPredicates: Record<
  WhereFilterOp,
  (field: string, value: any) => (object: any) => boolean
> = {
  '==': (field: string, value: any) => (object: any) =>
    get(object, field) === value,
  '!=': (field: string, value: any) => (object: any) =>
    get(object, field) !== value,
  '<=': (field: string, value: any) => (object: any) =>
    get(object, field) <= value,
  '<': (field: string, value: any) => (object: any) =>
    get(object, field) < value,
  '>': (field: string, value: any) => (object: any) =>
    get(object, field) > value,
  '>=': (field: string, value: any) => (object: any) =>
    get(object, field) >= value,
  in: (field: string, value: any) => (object: any) =>
    value.includes(get(object, field)),
  'not-in': (field: string, value: any) => (object: any) =>
    !value.includes(get(object, field)),
  'array-contains': (field: string, value: any) => (object: any) =>
    get(object, field).includes(value),
  'array-contains-any': (field: string, value: any) => (object: any) =>
    get(object, field).some((e: any) => e.includes(value)),
};
