import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import { QuestionDrawer } from '../../../components';
import { useNotification, useProgress } from '../../../hooks';
import { NotificationLevel } from '../../../enums';
import { Modal } from '../../../types';

const MarkReservationAsPurchased: Modal = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();
  const markReservationAsPurchased = httpsCallable(
    functions,
    'markReservationAsPurchased'
  );
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const markAsPurchased = async () => {
    setDisabled(true);

    addNotification(
      t('reservations.markAsPurchased.progress'),
      NotificationLevel.Information
    );

    try {
      await markReservationAsPurchased(params.reservationId);
      addNotification(
        t('reservations.markAsPurchased.success'),
        NotificationLevel.Success
      );
    } catch {
      addNotification(
        t('reservations.markAsPurchased.error'),
        NotificationLevel.Error
      );
    }

    setDisabled(false);
    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <QuestionDrawer
      disabled={disabled}
      open={true}
      icon={<HourglassTopIcon sx={{ color: 'secondary.main' }} />}
      message={t('reservations.markAsPurchased.message')}
      onConfirm={() => markAsPurchased()}
      onCancel={() => navigateBack()}
      title={t('reservations.markAsPurchased.title')}
    />
  );
};

export default MarkReservationAsPurchased;
