import { TableRow, alpha, styled } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => {
  return {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
      borderTop: `solid 1em ${theme.palette.background.paper}`,
      borderBottomLeftRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
    },
    [theme.breakpoints.up('md')]: {
      display: 'table-row',
      flexDirection: 'unset',
      borderBottom: 'none',
      borderTop: 'none',
      borderBottomLeftRadius: 'unset',
      borderBottomRightRadius: 'unset',
    },
    '&.alternate-color': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02),
    },
    '&.active-item': {
      [theme.breakpoints.up('xs')]: {
        borderBottom: `5px solid ${theme.palette.secondary.main}`,
      },
    },
  };
});
