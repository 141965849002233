import {
  Badge,
  MenuItem as MuiMenuItem,
  ListItemIcon,
  ListItemText,
  BadgeOrigin,
} from '@mui/material';
import { Link, useMatch } from 'react-router-dom';

import { TabProps } from './Tab.props';
import * as Sx from './Tab.styles';

export const Tab = ({
  active,
  disabled,
  icon,
  match,
  title,
  to,
  onClick,
  children,
  withBadge,
}: TabProps) => {
  const currentMatch = useMatch({
    path: match || to?.toString() || '',
    end: true,
  });

  const anchorOrigin: BadgeOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  return (
    <Badge
      anchorOrigin={anchorOrigin}
      color="secondary"
      invisible={withBadge !== true}
      sx={Sx.badge}
      variant="dot"
    >
      <MuiMenuItem
        tabIndex={0}
        component={to ? Link : 'a'}
        to={to ? to : undefined}
        onClick={onClick}
        disabled={disabled}
        sx={Sx.menuItem(active || !!currentMatch)}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {!children && (
          <ListItemText
            primaryTypographyProps={{
              sx: Sx.menuItemTypography,
            }}
          >
            {title}
          </ListItemText>
        )}
        {children}
      </MuiMenuItem>
    </Badge>
  );
};
