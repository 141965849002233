import { useMemo } from 'react';

import { CollectionQueryOptions, Filter, Nullable } from '../types';
import { SanitizedConversionRate } from '../models';
import { updateStatistics } from '../components/ConversionRate/common';
import { useReservationLogs } from './useReservationLogs';

export function useAgencyConversionRates(
  options?: CollectionQueryOptions,
  agencyId?: string,
  filterCompanyIds?: Nullable<string[]>
) {
  const agencyFilters = useMemo(
    () =>
      [
        ['agencyId', '==', agencyId],
        filterCompanyIds?.length
          ? ['companyId', 'in', filterCompanyIds]
          : undefined,
        ...(options?.filters || []),
      ] as Filter[],
    [agencyId, filterCompanyIds, options?.filters]
  );

  const { list: reservationLogs } = useReservationLogs({
    filters: agencyFilters,
  });

  const agencyConversionRates = useMemo(() => {
    const records = reservationLogs.reduce((acc, cur) => {
      const id = cur.companyId;
      const record = acc[id] || new SanitizedConversionRate();

      if (!(record.id && record.companyName)) {
        record.id = id;
        record.companyName = cur.companyName;
      }

      updateStatistics(cur.endStatus, record);

      acc[cur.companyId] = record;
      return acc;
    }, {} as Record<string, SanitizedConversionRate>);

    return Object.values(records);
  }, [reservationLogs]);

  return agencyConversionRates;
}
