import { i18n } from 'i18next';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';

import report_logo from '../../assets/report_logo.png';
import cable_icon from '../../assets/CableIcon.png';
import { Reel } from '../../models';
import { InventoryType, MeasurementUnits } from '../../enums';
import { InventoryFilters } from '../../types';
import { convertQuantity, convertWeight } from '../../utils/units';
import formatPackagedReelQuantity from '../../utils/formatPackagedReelQuantity';
import report, { MARGIN, PDF_CONFIG } from './report';

class inventoryReportPdf extends report {
  private readonly reels: Array<Reel>;
  private readonly filters: InventoryFilters;
  private readonly inventoryType: InventoryType;
  private readonly measurementUnits: MeasurementUnits;
  private readonly DEFAULT_ROW_HEIGHT = 13;

  constructor(
    reels: Array<Reel>,
    filters: InventoryFilters,
    inventoryType: InventoryType,
    measurementUnits: MeasurementUnits,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super('reports.inventory', t, i18n);

    this.reels = reels;
    this.filters = filters;
    this.inventoryType = inventoryType;
    this.measurementUnits = measurementUnits;
  }

  protected getFilename(): string {
    const productType = this.filters.productType || this.reels[0].productType;

    return this.t('reports.inventory.filename', {
      type: this.t(`productTypes.${productType}`),
      date: DateTime.now().toFormat('LLddyyyyHHmmss'),
      extension: 'pdf',
    });
  }

  protected buildHeader(): void {
    this.outputImage(report_logo, MARGIN.X, MARGIN.Y);
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(cable_icon, MARGIN.X, this.curHeight, 10);

    const formattedTitle = this.t('reports.inventory.title', {
      type: this.t(`inventoryType.${this.inventoryType}`),
    });

    this.outputH1(formattedTitle, this.curWidth + 15, this.curHeight + 7);

    const formattedResultFound = this.t('reports.inventory.resultsFound', {
      count: this.reels.length,
    });

    this.outputText(formattedResultFound, MARGIN.X, this.curHeight + 15, {
      fontSize: PDF_CONFIG.LABEL,
    });

    if (this.filters.searchByCable) {
      const productType = this.t(`productTypes.${this.filters.productType}`);
      const material = this.t(`materials.${this.filters.material}`);
      const voltage = `${this.filters.voltage} V`;
      const gauge = this.filters.gauge;
      const number =
        this.filters.numberOfConductors &&
        `${this.filters.numberOfConductors}C`;

      const filterText = `${productType} - ${material} - ${voltage} ${
        number ? `- ${number} ` : ' '
      } ${gauge ? `- ${gauge} ` : ' '}`;

      const filterY = this.curHeight + PDF_CONFIG.LINE_SIZE + 3;
      this.outputH2(filterText, MARGIN.X, filterY);

      this.curHeight += PDF_CONFIG.LINE_SIZE + 6;

      this.drawLine(0.5, '#111111');
    } else {
      this.curHeight += PDF_CONFIG.LABEL + 2;
    }
  }

  protected buildBody(): void {
    this.curHeight += PDF_CONFIG.LINE_SIZE / 2;

    this.reels.forEach((reel, index) => this.buildRow(reel, index));
  }

  private buildRow(reel: Reel, index: number): void {
    const {
      cableRef: { id },
      colors,
      description,
      packageLength,
      quantity,
      weight,
    } = reel;

    const convertedQuantity = packageLength
      ? formatPackagedReelQuantity(this.measurementUnits, reel)
      : convertQuantity(this.measurementUnits, quantity);
    const quantityUnit = this.t(`units.${this.measurementUnits}.length`);
    const convertedWeight = convertWeight(this.measurementUnits, reel.weight);
    const weightUnit = this.t(`units.${this.measurementUnits}.weight`);
    const formattedNumber = `${description} - ${id}`;
    const formattedQuantity = `${convertedQuantity}${quantityUnit}`;
    const formattedColors = colors || null;
    const reelId = reel.id;

    const formattedWeight =
      weight === -1 ? 'N/A' : `${convertedWeight}${weightUnit}`;

    this.addDataColumn('partNumber', formattedNumber, MARGIN.X, 85);
    this.addDataColumn('quantity', formattedQuantity, MARGIN.X + 90, 35);
    this.addDataColumn('reel', reel.reel || 'N/A', MARGIN.X + 110, 35);
    this.addDataColumn('weight', formattedWeight, MARGIN.X + 130, 35);
    this.addDataColumn('reelId', reelId, MARGIN.X + 150);

    if (formattedColors) {
      this.curHeight += 3;
      this.addDataColumn(null, formattedColors, MARGIN.X, 85);
      this.curHeight += 8;
    } else {
      this.curHeight += 9;
    }

    this.drawLine(0.1, '#E58008');

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, this.reels.length);
  }

  static generate(
    reels: Array<Reel>,
    filters: InventoryFilters,
    measurementUnits: MeasurementUnits,
    inventoryType: InventoryType,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ): void {
    const report = new inventoryReportPdf(
      reels,
      filters,
      inventoryType,
      measurementUnits,
      t,
      i18n
    );

    report.generate();
  }
}

export default inventoryReportPdf;
