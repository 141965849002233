export class ResetPasswordError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CustomError';
  }
}

export interface ResetPasswordModel {
  newPassword: string;
  confirmPassword: string;
}

export interface ResetPasswordUrlParams {
  oobCode: string;
  userId?: string;
}
