import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Outlet } from 'react-router-dom';

import {
  Container,
  DecoratedHeader,
  OrderFilters,
  OrderTable,
} from '../components';
import { useCurrentUser, usePageTitle, useRouterOutlet } from '../hooks/';
import { UserPermission } from '../enums/';
import { RequirePermission } from '../security';
import { OrderFilters as OrderFiltersType } from '../types';
import { getPageTitle } from '../utils/pages';

const Orders: React.FC = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { showOutletOnly } = useRouterOutlet();
  usePageTitle(getPageTitle('orders'));

  const [filters, setFilters] = useState<OrderFiltersType>({
    companyId: user.companyRef.id,
    searchField: null,
  });

  return (
    <RequirePermission
      oneOf={[
        UserPermission.ManageOrderFilesAccess,
        UserPermission.ViewOrdersConfirmation,
        UserPermission.ViewOrdersInvoice,
        UserPermission.ViewOrdersShipping,
      ]}
    >
      {showOutletOnly || (
        <>
          <Container>
            <DecoratedHeader
              icon={<FolderOpenIcon />}
              title={t('pages.orders.pageTitle')}
              variant="colored"
            />
          </Container>
          <Container>
            <OrderFilters filters={filters} setFilters={setFilters} />
          </Container>
          <OrderTable filters={filters} />
        </>
      )}
      <Outlet />
    </RequirePermission>
  );
};

export default Orders;
