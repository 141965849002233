import { SxProps } from '@mui/material';

export const text: SxProps = {
  mt: 3,
};

export const button: SxProps = {
  width: '100%',
  maxWidth: '290px',
  mt: 7,
};

export const container: SxProps = {
  mt: 5,
  ml: 0,
  maxWidth: '560px',
};

export const footer: SxProps = {
  mt: 12,
};

export const icon: SxProps = {
  verticalAlign: 'middle',
  mr: 1,
  mb: 0.5,
};

export const title: SxProps = {
  fontSize: '1.5rem',
};

export const buttonLink: SxProps = {
  width: '100%',
  maxWidth: '290px',
  mt: 7,
  color: 'white',
  backgroundColor: 'black',
  padding: '6px 24px',
  textDecoration: 'none',
  marginTop: '50px',
  display: 'flex',
  justifyContent: 'center',
};
