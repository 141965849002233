import { Button } from '@mui/material';
import * as Sx from './FileDownloadOutput.styles';

export interface FileDownloadOutputProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const FileDownloadOutput = ({
  children,
  onClick,
}: FileDownloadOutputProps) => (
  <Button variant="contained" onClick={onClick} sx={Sx.downloadButton()}>
    {children}
  </Button>
);
