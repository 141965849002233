import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { FolderOpen } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { BottomDrawer } from '../../../../components';
import { useNotification, useProgress } from '../../../../hooks';
import { NotificationLevel } from '../../../../enums';
import { Modal } from '../../../../types';
import * as Sx from '../../../modals/common.styles';

const DeleteUnmappedOrderFile: Modal = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();
  const deleteFunction = httpsCallable(functions, 'deleteUnmappedOrderFile');
  const navigate = useNavigate();
  const { fileId } = useParams();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const deleteUnmappedFile = async () => {
    setDisabled(true);

    addNotification(
      t('unassignedDocuments.deleteFile.notification.info'),
      NotificationLevel.Information
    );

    try {
      await deleteFunction(fileId);
      addNotification(
        t('unassignedDocuments.deleteFile.notification.success'),
        NotificationLevel.Success
      );
    } catch {
      addNotification(
        t('unassignedDocuments.deleteFile.notification.error'),
        NotificationLevel.Error
      );
    }

    setDisabled(false);
    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <BottomDrawer
      icon={<FolderOpen sx={{ color: 'secondary.main' }} />}
      open={true}
      title={t('unassignedDocuments.deleteFile.modalTitle')}
    >
      <Grid container justifyContent={!isMobile ? 'space-between' : ''}>
        <Typography component={Grid} item my={2}>
          {t('unassignedDocuments.deleteFile.modalMessage')}
        </Typography>
        <Grid item sx={Sx.buttonContainer}>
          <Button
            disabled={disabled}
            onClick={() => deleteUnmappedFile()}
            variant="contained"
            sx={Sx.confirmButton}
          >
            {t('forms.confirm')}
          </Button>
          <Button
            disabled={disabled}
            onClick={navigateBack}
            variant="outlined"
            sx={Sx.cancelButton}
          >
            {t('forms.cancel')}
          </Button>
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default DeleteUnmappedOrderFile;
