import { SxProps } from '@mui/material';

export const totalConversionRateBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'nowrap',
  justifyContent: 'space-around',
  width: '11em',
  height: '7em',
  marginTop: '1em',
  borderRadius: '4px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #d7d7d7',
};
