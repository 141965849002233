import { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Autocomplete,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'reactfire';
import { useDebounce } from 'use-debounce';
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

import { useSearchableFilter } from '../../hooks';
import { PAGE_SIZE } from '../../pages/admin/modals/AssignDocument/constants';
import { AsyncAutocompleteProps } from './AsyncAutocomplete.props';

const AsyncAutocomplete = ({
  collectionName,
  disabled,
  displayFieldName,
  normalizedFieldName,
  onChange,
  queryFilters,
  renderInput,
  selectedDocument,
  setSelectedDocument,
  sx,
  visible,
}: AsyncAutocompleteProps) => {
  const { t } = useTranslation();

  const firestore = useFirestore();

  const [inputValue, setInputValue] = useState<string>('');

  const [displayedDocuments, setDisplayedDocuments] = useState<DocumentData[]>(
    []
  );
  const [lastItem, setLastItem] = useState<
    QueryDocumentSnapshot<DocumentData> | null | undefined
  >(null);
  const [page, setPage] = useState<number>(0);

  const [debouncedInput] = useDebounce(
    inputValue.toLowerCase().replace(/[^a-z0-9]/g, ''),
    500
  );

  const { filters } = useSearchableFilter({
    value: debouncedInput,
    property: normalizedFieldName,
  });

  const filterOptions = (
    options: DocumentData[],
    _: FilterOptionsState<DocumentData>
  ) =>
    options.filter((option) =>
      option[normalizedFieldName].includes(debouncedInput)
    );

  const allFilters = useMemo(() => {
    let allFilters = filters.map((filter) =>
      where(filter[0], filter[1], filter[2])
    );
    if (queryFilters) {
      allFilters = allFilters.concat(
        queryFilters.map((queryFilter) =>
          where(queryFilter[0], queryFilter[1], queryFilter[2])
        )
      );
    }
    return allFilters;
  }, [filters, queryFilters]);

  // Fetch documents based on user input
  useEffect(() => {
    // usage of ignore variable:
    // https://react.dev/reference/react/useEffect#fetching-data-with-effects
    let ignore = false;
    async function getData() {
      const q = query(
        collection(firestore, collectionName),
        ...allFilters,
        orderBy(normalizedFieldName, 'asc'),
        limit(PAGE_SIZE)
      );

      return await getDocs(q);
    }

    if (visible) {
      getData().then((documents) => {
        if (!ignore) {
          setLastItem(documents.docs[documents.docs.length - 1]);
          setDisplayedDocuments(documents.docs.map((d) => d.data()));
          if (page !== 0) {
            setPage(0);
          }
        }
      });
    }
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilters, visible]);

  // Fetch orders based on scrolling (or when the page is reset)
  useEffect(() => {
    let ignore = false;
    async function getData() {
      const q = query(
        collection(firestore, collectionName),
        ...allFilters,
        orderBy(normalizedFieldName, 'asc'),
        startAfter(lastItem),
        limit(PAGE_SIZE)
      );

      return await getDocs(q);
    }

    if (page > 0 && lastItem !== undefined) {
      getData().then((documents) => {
        if (!ignore) {
          setLastItem(documents.docs[documents.docs.length - 1]);
          setDisplayedDocuments((prevValue) =>
            prevValue.concat(documents.docs.map((d) => d.data()))
          );
        }
      });
    } else if (visible && page === 0 && !debouncedInput) {
      getData().then((documents) => {
        if (!ignore) {
          setLastItem(documents.docs[documents.docs.length - 1]);
          setDisplayedDocuments(documents.docs.map((d) => d.data()));
        }
      });
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (!selectedDocument) {
      setInputValue('');
    }
  }, [selectedDocument]);

  const changeHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: DocumentData | null
  ) => {
    setSelectedDocument(value);
    setInputValue(value?.[displayFieldName] ?? '');
    if (onChange) {
      onChange(_, value);
    }
  };

  const resetToBeginning = () => {
    if (!selectedDocument) {
      setLastItem(null);
      setPage(0);
    }
  };

  return (
    <Autocomplete
      fullWidth
      clearOnBlur
      disabled={disabled}
      options={displayedDocuments ?? []}
      noOptionsText={t('common.noItemsFound')}
      value={selectedDocument}
      popupIcon={<KeyboardArrowDown />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option[displayFieldName]}
      onChange={changeHandler}
      filterOptions={filterOptions}
      renderOption={(props, item) => (
        <li {...props} key={item.id}>
          <Typography>{item[displayFieldName]}</Typography>
        </li>
      )}
      ListboxProps={{
        onScrollCapture: (event) => {
          const listboxNode = event.currentTarget;
          if (
            // add arbitrary value (10) and ">=" operator to accomodate for touchpad scrolling
            listboxNode.scrollTop + listboxNode.clientHeight + 10 >=
            listboxNode.scrollHeight
          ) {
            setPage((currentPage) => ++currentPage);
          }
        },
        /** Fix for scrolling to top when loading new page of options */
        role: 'list-box',
      }}
      inputValue={inputValue}
      onInputChange={(
        event: React.SyntheticEvent,
        value: string,
        reason: string
      ) => {
        if (event && event.type === 'blur') {
          setInputValue('');
        } else if (reason !== 'reset') {
          setInputValue(value);
          if (selectedDocument) {
            setSelectedDocument(null);
          }
        }
        if (reason === 'reset' && !value) {
          resetToBeginning();
        }
      }}
      renderInput={renderInput ?? ((params) => <TextField {...params} />)}
      sx={sx}
    />
  );
};

export default AsyncAutocomplete;
