import { useState, MouseEvent } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { MoreMenuProps } from './MoreMenu.props';

export const MoreMenu = ({ children }: MoreMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const closeMenu = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        {children}
      </Menu>
    </>
  );
};
