import { useEffect, useState } from 'react';
import { usePageTitle } from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import { Nullable, ReportFilesFilters as Filters } from '../../types';
import { RequirePermission } from '../../security';
import { ReportFileType, UserPermission } from '../../enums';
import {
  Container,
  ReportFilesFilters,
  ReportFilesTable,
  Show,
} from '../../components';
import { ReportFile } from '../../models';
import { Reports } from './partial';

interface ReportFilesProp {
  fileType: ReportFileType;
}

const ReportFiles: React.FC<ReportFilesProp> = ({
  fileType,
}: ReportFilesProp) => {
  usePageTitle(getPageTitle(`reports.${fileType}`));
  const [filters, setFilters] = useState<Nullable<Filters>>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<ReportFile[]>([]);

  const handleSearch = (filters: Nullable<Filters>) => {
    setFilters(filters);
    setSelectedDocuments([]);
  };

  useEffect(() => {
    setSelectedDocuments([]);
  }, [fileType]);

  return (
    <RequirePermission
      oneOf={[
        fileType === ReportFileType.AgencySalesReport
          ? UserPermission.ViewSalesReports
          : UserPermission.ViewCommissionsReports,
      ]}
    >
      <Reports>
        <ReportFilesFilters fileType={fileType} onSearch={handleSearch} />

        <Container>
          <Show if={!!filters}>
            <ReportFilesTable
              selected={selectedDocuments}
              setSelected={setSelectedDocuments}
              fileType={fileType}
              filters={filters}
            />
          </Show>
        </Container>
      </Reports>
    </RequirePermission>
  );
};

export default ReportFiles;
