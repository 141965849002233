import { SxProps } from '@mui/material';

export const input: SxProps = {
  backgroundColor: '#ffffff',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  borderColor: '#979797',
  '& fieldset': { border: 'none' },
};
