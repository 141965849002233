import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import { Timestamp } from 'firebase/firestore';

import {
  ConsultationMode,
  MeasurementUnits,
  NotificationLevel,
  ReservationStatus,
} from '../../enums/';
import {
  Container,
  ExportMenu,
  FormattedTimestamp,
  ReelQuantity,
  ReelWeight,
  ReservationRowDialog,
  Show,
  StatusIndicator,
  TimestampCountdown,
} from '..';
import {
  useCurrentUser,
  useNotification,
  usePortalSettings,
  useProgress,
  useReservationsAreAllowed,
} from '../../hooks/';
import { DataTable, DataTableColumn } from '../../tables';
import { ReservationStatusIcons } from '../../mappings';
import { Reservation } from '../../models';
import { Response } from '../../types';
import formatReservationNumber from '../../utils/formatReservationNumber';
import calculateReservationStatusToDisplay from '../../utils/calculateReservationStatusToDisplay';
import ExtendIcon from '../../assets/ExtendIcon';
import { ReservationTableProps } from './ReservationTable.props';

export const ReservationTable = ({
  isAdmin = false,
  isAgent = false,
  mode,
  reservations,
  sorting,
  pagination,
  generatePdfReport,
  generateCsvReport,
}: ReservationTableProps) => {
  const { t } = useTranslation();
  const { item: portalSettingsData } = usePortalSettings();
  const user = useCurrentUser();
  const reservationsAreAllowed = useReservationsAreAllowed();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const isAdminOrAgent = isAdmin || isAgent;

  // Used to re-render the component when countdown expires
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [exportingPdf, setExportingPdf] = useState(false);
  const [exportingCsv, setExportingCsv] = useState(false);

  const [openedCollapse, setOpenedCollapse] = useState<string | null>(null);
  const [openedCollapseMode, setOpenedCollapseMode] = useState<
    'details' | 'extend' | 'cancel'
  >('details');

  useEffect(() => {
    if (!exportingPdf) return;

    generatePdfReport();

    setExportingPdf(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportingPdf]);

  useEffect(() => {
    if (!exportingCsv) return;

    generateCsvReport();

    setExportingCsv(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportingCsv]);

  const [inProgress, setInProgress] = useState(false);
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();

  const cancelReservationFunction = httpsCallable(
    functions,
    'cancelReservation'
  );

  useEffect(() => {
    showProgress(inProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  const cancelReservation = (reservation: Reservation) => {
    setInProgress(true);
    addNotification(
      t('reservations.cancelReservation.inProgress'),
      NotificationLevel.Information
    );
    cancelReservationFunction(reservation.id)
      .then((res) => {
        if (!(res.data as Response).succeeded) {
          addNotification(
            t('reservations.cancelReservation.error'),
            NotificationLevel.Error
          );
        } else {
          addNotification(
            t('reservations.cancelReservation.success'),
            NotificationLevel.Success
          );
          setOpenedCollapse(null);
        }
      })
      .catch(() => {
        addNotification(
          t('reservations.cancelReservation.error'),
          NotificationLevel.Error
        );
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const extendReservationFunction = httpsCallable(
    functions,
    'extendReservation'
  );

  const extendReservation = (reservation: Reservation) => {
    setInProgress(true);
    addNotification(
      t('reservations.extendReservation.inProgress'),
      NotificationLevel.Information
    );
    extendReservationFunction(reservation.id)
      .then(() => {
        addNotification(
          t('reservations.extendReservation.success'),
          NotificationLevel.Success
        );
        setOpenedCollapse(null);
      })
      .catch(() => {
        addNotification(
          t('reservations.extendReservation.error'),
          NotificationLevel.Error
        );
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const actionsAreDisabled = (item: Reservation) =>
    inProgress ||
    (item.expirationTime < Timestamp.now() &&
      (!item.releaseTime || item.releaseTime < Timestamp.now())) ||
    (item.status !== ReservationStatus.Reserved &&
      item.status !== ReservationStatus.TemporarilyHeld);

  const renderRowCollapse = (item: Reservation) => (
    <ReservationRowDialog
      isAdmin={isAdmin}
      disabled={inProgress}
      mode={openedCollapseMode}
      onCancel={() => cancelReservation(item)}
      onClose={() => setOpenedCollapse(null)}
      onExtend={() => extendReservation(item)}
      reservation={item}
      unitsType={user.measurementUnits ?? MeasurementUnits.Metric}
    />
  );

  const menuItems = (item: Reservation) => (
    <MenuItem
      component={Link}
      to={`/admin/reservations/${item.id}/purchase`}
      disabled={actionsAreDisabled(item)}
    >
      <ListItemIcon>
        <CheckIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('reservations.markAsPurchased.label')}</ListItemText>
    </MenuItem>
  );

  return (
    <Container>
      <Show if={!!reservations && reservations.length > 0}>
        <Grid display="flex" justifyContent="flex-end">
          <ExportMenu
            onPDFClicked={() => setExportingPdf(true)}
            onCSVClicked={() => setExportingCsv(true)}
          />
        </Grid>
      </Show>

      <DataTable
        data={reservations}
        menuItems={
          isAdmin && mode === ConsultationMode.Current ? menuItems : undefined
        }
        sorting={sorting}
        pagination={pagination}
        rowCollapseContent={renderRowCollapse}
        rowCollapseOpened={(item: Reservation) =>
          (!isMobile || openedCollapseMode !== 'details') &&
          item.id === openedCollapse
        }
        rowOnClick={(item) => {
          if (!isMobile) {
            setOpenedCollapse(
              item.id === openedCollapse && openedCollapseMode === 'details'
                ? null
                : item.id
            );
            setOpenedCollapseMode('details');
          }
        }}
      >
        <DataTableColumn
          property="reservationNumber"
          title={t('reservations.fields.reservationNumber')}
          width="15%"
          mobileWidth="50%"
          output={(reservationNumber: number, item: Reservation) => {
            const active =
              item.id === openedCollapse && openedCollapseMode === 'details';
            return (
              <Grid container alignItems="center" flexWrap="nowrap">
                <Typography
                  variant="body1"
                  sx={{
                    color: active ? 'secondary.main' : undefined,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {formatReservationNumber(reservationNumber)}
                </Typography>
                {active ? (
                  <KeyboardArrowDown sx={{ color: 'secondary.main' }} />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Grid>
            );
          }}
        />
        <DataTableColumn
          property="madeForName"
          title={t('reservations.fields.madeForName')}
          width={isAdminOrAgent ? '12.5%' : '15%'}
          mobileHidden
          mobileVisibleWhenActive
          mobileWidth="50%"
          mobileOrder={7}
        />
        <DataTableColumn
          property="madeByName"
          title={t('reservations.fields.madeByName')}
          mobileHidden={isAdminOrAgent}
          width={isAdminOrAgent ? '12.5%' : '15%'}
          mobileWidth="50%"
          mobileOrder={1}
        />
        <DataTableColumn
          property="companyName"
          title={t('reservations.fields.companyName')}
          hidden={!isAdminOrAgent}
          mobileHidden={!isAdminOrAgent}
          width="15%"
          mobileWidth="50%"
          mobileOrder={1}
        />
        <DataTableColumn
          property="reservedTime"
          title={t('reservations.fields.reservedTime')}
          width="15%"
          mobileOrder={0}
          output={(timestamp: Timestamp) => (
            <FormattedTimestamp
              timestamp={timestamp}
              timeZone={user?.timeZone}
            />
          )}
        />
        <DataTableColumn
          property="status"
          title={t('reservations.fields.status')}
          width={isAdminOrAgent ? '10%' : '15%'}
          mobileWidth="50%"
          mobileHidden
          mobileVisibleWhenActive
          hasMobileLabel={false}
          mobileOrder={8}
          output={(_, item: Reservation) => {
            const statusToDisplay = calculateReservationStatusToDisplay(item);
            return (
              <StatusIndicator
                icon={
                  ReservationStatusIcons[statusToDisplay as ReservationStatus]
                }
                title={t(`reservations.statuses.${statusToDisplay}`)}
              />
            );
          }}
        />
        <DataTableColumn
          property="expirationTime"
          title={t('reservations.fields.remainingTime')}
          width={isAdminOrAgent ? '10%' : '15%'}
          mobileWidth="50%"
          mobileOrder={-1}
          hasMobileLabel={false}
          hidden={mode === ConsultationMode.History}
          mobileHidden={mode === ConsultationMode.History}
          output={(timestamp: Timestamp, item: Reservation) =>
            item.expirationTime >= Timestamp.now() ||
            (item.releaseTime && item.releaseTime >= Timestamp.now()) ? (
              <Grid
                container
                alignItems="center"
                flexWrap="nowrap"
                justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
              >
                <TimestampCountdown
                  maxDelay={portalSettingsData?.maxReservationHoldPeriod || 99}
                  onExpire={forceUpdate}
                  timestamp={
                    item.expirationTime >= Timestamp.now()
                      ? timestamp
                      : item.releaseTime
                  }
                  timeZone={user?.timeZone}
                  dayOffs={portalSettingsData?.daysOff}
                  weekDaysOff={portalSettingsData?.weekDaysOff}
                  status={calculateReservationStatusToDisplay(item)}
                >
                  {reservationsAreAllowed &&
                    ((item.expirationNotificationTime < Timestamp.now() &&
                      !item.hasBeenExtendedByCustomer) ||
                      isAdmin) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) => {
                          setOpenedCollapse(item.id);
                          setOpenedCollapseMode('extend');
                          e.stopPropagation();
                        }}
                        disabled={
                          inProgress ||
                          (item.status !== ReservationStatus.Reserved &&
                            (!isAdmin ||
                              item.status !==
                                ReservationStatus.TemporarilyHeld)) ||
                          (item.expirationTime < Timestamp.now() &&
                            (!isAdmin ||
                              !item.releaseTime ||
                              item.releaseTime < Timestamp.now()))
                        }
                        sx={{
                          borderRadius: '50%',
                          ml: 1,
                          pl: '2px',
                          pr: 0,
                          minWidth: '2.25rem',
                        }}
                      >
                        <ExtendIcon />
                      </Button>
                    )}
                </TimestampCountdown>
              </Grid>
            ) : (
              <></>
            )
          }
        />
        <DataTableColumn
          property="reel.id"
          title={t('inventory.fields.reelId')}
          hidden
          mobileHidden
          mobileVisibleWhenActive
          mobileWidth="100%"
          mobileOrder={3}
        />
        <DataTableColumn
          property="reel.description"
          title={t('inventory.fields.description')}
          hidden
          mobileHidden
          mobileVisibleWhenActive
          hasMobileLabel={false}
          mobileWidth="100%"
          mobileOrder={4}
          output={(value) => <strong>{value}</strong>}
        />
        <DataTableColumn
          property="reel.quantity"
          title={t('inventory.fields.quantity')}
          hidden
          mobileHidden
          mobileVisibleWhenActive
          mobileWidth="50%"
          mobileOrder={5}
          output={(_, item: Reservation) => (
            <ReelQuantity
              item={item.reel}
              unitsType={user.measurementUnits ?? MeasurementUnits.Metric}
            />
          )}
        />
        <DataTableColumn
          property="reel.weight"
          title={t('inventory.fields.weight')}
          hidden
          mobileHidden
          mobileVisibleWhenActive
          mobileWidth="50%"
          mobileOrder={6}
          output={(_, item: Reservation) => (
            <ReelWeight
              item={item.reel}
              unitsType={user.measurementUnits ?? MeasurementUnits.Metric}
            />
          )}
        />
        <DataTableColumn
          property="note"
          title={t('reservations.fields.reservationNote')}
          hidden
          mobileHidden
          mobileVisibleWhenActive
          mobileWidth="100%"
          mobileOrder={9}
          output={(_, item: Reservation) => item.note}
        />
        <DataTableColumn
          property="action"
          title=""
          width="10%"
          disableSort
          mobileHeight={0}
          mobileWidth="50%"
          mobileOrder={2}
          textAlign="right"
          hidden={mode === ConsultationMode.History || !reservationsAreAllowed}
          mobileHidden={
            mode === ConsultationMode.History || !reservationsAreAllowed
          }
          output={(_, item: Reservation) => (
            <Button
              variant="outlined"
              onClick={(e) => {
                setOpenedCollapse(item.id);
                setOpenedCollapseMode('cancel');
                e.stopPropagation();
              }}
              disabled={actionsAreDisabled(item)}
            >
              {t('inventory.actions.cancel')}
            </Button>
          )}
        />
      </DataTable>
    </Container>
  );
};
