import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  useCurrentRole,
  useDownloadFiles,
  usePagination,
  useSorting,
  useUnmappedFiles,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import {
  AssignDocumentOutput,
  Container,
  FormattedTimestamp,
  FileDownloadOutput,
  Show,
} from '..';
import { OrderFile, ReportFile } from '../../models';
import AssignDocument from '../../pages/admin/modals/AssignDocument/AssignDocument.modal';
import { UserRole } from '../../enums';

type UnassignedFile = OrderFile | ReportFile;

export const UnassignedDocuments: React.FC = () => {
  const { t } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('dateTimeUploaded');
  const { downloadFile } = useDownloadFiles();
  const currentUserRole = useCurrentRole();
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState<UnassignedFile | null>(null);
  const [displayAssignDrawer, setDisplayAssignDrawer] = useState(false);

  const dateOutput = (timestamp: Timestamp) => (
    <FormattedTimestamp timestamp={timestamp} />
  );

  const downloadOutput = (_: string, file: UnassignedFile) => (
    <FileDownloadOutput
      children={t('orderDetails.actions.download')}
      onClick={() => downloadFile(file)}
    />
  );

  const fileNameOutput = (_: string, file: UnassignedFile) => (
    <Typography>{file.fileName + file.fileExtension}</Typography>
  );

  const assignOutput = (unassignedFile: UnassignedFile) => (
    <AssignDocumentOutput
      children={t('unassignedDocuments.actions.assign')}
      onClick={() => {
        setSelectedFile(unassignedFile);
        setDisplayAssignDrawer(true);
      }}
    />
  );

  const { list: filesList, loading } = useUnmappedFiles({
    sorting,
    pagination,
  });

  const menuItems = (item: UnassignedFile) => (
    <Show if={currentUserRole === UserRole.NciAdmin}>
      <MenuItem onClick={() => onDeleteClick(item)}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('orderDetails.actions.delete')}</ListItemText>
      </MenuItem>
    </Show>
  );

  const onDeleteClick = (item: UnassignedFile) => {
    navigate(`${item.id}/delete`);
  };

  return (
    <Container>
      <DataTable
        data={!loading ? filesList : undefined}
        pagination={pagination}
        sorting={sorting}
        menuItems={menuItems}
      >
        <DataTableColumn
          property="fileName"
          title={t('unassignedDocuments.table.fileName')}
          width={500}
          output={fileNameOutput}
        />
        <DataTableColumn
          property="dateTimeUploaded"
          title={t('unassignedDocuments.table.dateTimeUploaded')}
          output={dateOutput}
        />
        <DataTableColumn
          property="downloadAction"
          title=""
          disableSort
          textAlign="center"
          output={downloadOutput}
        />
        <DataTableColumn
          property="assignAction"
          title=""
          disableSort
          textAlign="center"
          output={(_, unassignedFile: UnassignedFile) =>
            assignOutput(unassignedFile)
          }
        />
      </DataTable>
      {!!selectedFile && (
        <AssignDocument
          selectedFile={selectedFile as UnassignedFile}
          visible={displayAssignDrawer}
          visibilitySet={setDisplayAssignDrawer}
        />
      )}
      <Outlet />
    </Container>
  );
};
