export const CONDUCTOR_GAUGES = [
  '#18',
  '#16',
  '#14',
  '#12',
  '#10',
  '#8',
  '#6',
  '#4',
  '#3',
  '#2',
  '#1',
  '1/0',
  '2/0',
  '3/0',
  '4/0',
  '250',
  '300',
  '350',
  '400',
  '500',
  '600',
  '750',
  '1000',
];
