import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { Button, Checkbox, Typography } from '@mui/material';
import { Info } from 'luxon';
import {
  useCurrentUser,
  useDownloadFiles,
  usePagination,
  useReportFiles,
  useSorting,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { Filter } from '../../types';
import { FileDownloadOutput, FormattedTimestamp } from '..';
import { ReportFile } from '../../models';
import { ReportFilesTableProps } from './ReportFilesTable.props';
import { dateToYearMonth } from './utils';

export const ReportFilesTable = ({
  filters,
  fileType,
  selected,
  setSelected,
}: ReportFilesTableProps) => {
  const { t } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('yearMonth', { sortDirection: 'desc' });
  const currentUser = useCurrentUser();
  const { downloadFile, downloadFilesBundle } = useDownloadFiles();

  const queryFilters = useMemo(() => {
    if (!filters) {
      return [];
    }

    const result = [];
    // need the - 1 and + 1 to avoid the inequality issue of firestore while sorting with the same field
    if (filters.startDate) {
      result.push(['yearMonth', '>', dateToYearMonth(filters.startDate) - 1]);
    }
    if (filters.endDate) {
      result.push(['yearMonth', '<', dateToYearMonth(filters.endDate) + 1]);
    }

    result.push(['fileType', '==', fileType]);
    return result;
  }, [filters, fileType]) as Filter[];

  const onDownloadSelected = () => {
    downloadFilesBundle(
      selected,
      currentUser.companyName + ' - ' + t(`reports.fileTypes.${fileType}Title`)
    );
  };

  const { list } = useReportFiles(currentUser.companyRef.id, {
    pagination,
    sorting,
    filters: queryFilters,
  });

  const uploadedDateOutput = (timestamp: Timestamp) => (
    <FormattedTimestamp timestamp={timestamp} />
  );

  const reportDateOutput = (_: string, file: ReportFile) => {
    const year = file.yearMonth.toString().substring(0, 4);
    const month = parseInt(file.yearMonth.toString().substring(4, 6));
    return <Typography>{`${Info.months()[month - 1]} ${year}`}</Typography>;
  };

  const fileNameOutput = (_: string, file: ReportFile) => (
    <Typography>{file.fileName + file.fileExtension}</Typography>
  );

  const downloadOutput = (_: string, file: ReportFile) =>
    selected.length === 0 ? (
      <FileDownloadOutput
        children={t('orderDetails.actions.download')}
        onClick={() => downloadFile(file)}
      />
    ) : (
      <></>
    );

  const selectFile = (_: string, item: ReportFile) => (
    <Checkbox
      checked={selected.includes(item)}
      onChange={(e) => {
        if (e.target.checked) {
          setSelected((prev) => [...prev, item]);
        } else {
          setSelected((prev) =>
            prev.filter((filterItem) => item !== filterItem)
          );
        }
      }}
    />
  );

  return (
    <>
      {selected.length > 0 && (
        <Button
          variant="outlined"
          sx={{ float: 'right', mt: 2, mb: 1 }}
          onClick={onDownloadSelected}
        >
          {t('orders.forms.downloadDocs')}
        </Button>
      )}
      <DataTable
        data={list}
        selected={selected}
        setSelectAll={setSelected}
        pagination={pagination}
        sorting={sorting}
      >
        <DataTableColumn
          disableSort
          property="selectable"
          output={selectFile}
        />
        <DataTableColumn
          property="yearMonth"
          title={t('reports.files.reportDate')}
          output={reportDateOutput}
        />
        <DataTableColumn
          property="fileName"
          title={t('reports.files.fileName')}
          output={fileNameOutput}
        />
        <DataTableColumn
          property="dateTimeUploaded"
          title={t('reports.files.fileUploaded')}
          output={uploadedDateOutput}
        />
        <DataTableColumn
          disableSort
          textAlign="center"
          width="20%"
          property="fileLocation"
          title=""
          output={downloadOutput}
        />
      </DataTable>
    </>
  );
};
