import { useState } from 'react';

import {
  Container,
  UserActivitiesFilters,
  UserActivitiesTable,
  Show,
} from '../../components';
import { usePageTitle } from '../../hooks';
import { UserPermission } from '../../enums';
import { getPageTitle } from '../../utils/pages';
import { RequirePermission } from '../../security';
import { Nullable, UserActivitiesFilters as Filters } from '../../types';
import Reports from './partials/Reports';

const UserActivity = () => {
  usePageTitle(getPageTitle('reports.userActivity'));
  const [filters, setFilters] = useState<Nullable<Filters>>(null);

  const handleSearch = (filters: Nullable<Filters>) => {
    setFilters(filters);
  };

  return (
    <RequirePermission oneOf={[UserPermission.ViewLogs]}>
      <Reports>
        <UserActivitiesFilters onSearch={handleSearch} />
        <Container>
          <Show if={!!filters}>
            <UserActivitiesTable filters={filters} />
          </Show>
        </Container>
      </Reports>
    </RequirePermission>
  );
};

export default UserActivity;
