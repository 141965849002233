import { where } from 'firebase/firestore';

import { ReservationStatus } from '../enums';
import { Reservation } from '../models';
import { CollectionQueryOptions } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useCurrentReservations(options?: CollectionQueryOptions) {
  return useLocalCollection<Reservation>(
    'Reservations',
    [
      where('status', 'in', [
        ReservationStatus.Reserved,
        ReservationStatus.TemporarilyHeld,
        ReservationStatus.Unknown,
      ]),
    ],
    options?.sorting,
    options?.pagination,
    options?.filters
  );
}
