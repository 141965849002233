import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { KeyboardArrowDown, SupervisedUserCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FilterContainer } from '..';
import { useAuthentication } from '../../hooks';
import { CompanyType } from '../../enums';
import { UserFiltersProps } from './UserFilters.props';

export const UserFilters = ({
  companies,
  filters,
  setFilters,
  roles,
  statuses,
}: UserFiltersProps) => {
  const { isAdmin, isAgent } = useAuthentication();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filterButtons =
    isAdmin || isAgent ? (
      <Button
        startIcon={<SupervisedUserCircle />}
        variant="contained"
        onClick={() => navigate(`/${isAdmin ? 'admin' : 'agent'}/users/new`)}
      >
        {t('users.actions.add')}
      </Button>
    ) : undefined;

  return (
    <FilterContainer buttons={filterButtons} title={t('users.list.title')}>
      <Grid
        container
        spacing={2}
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        {companies && (
          <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
            <Autocomplete
              fullWidth={true}
              popupIcon={<KeyboardArrowDown />}
              options={companies}
              getOptionLabel={(c) =>
                c.type === CompanyType.Agency ? `${c.name} (${c.type})` : c.name
              }
              value={companies.find((c) => c.id === filters.companyId)}
              onChange={(_, value) => {
                setFilters((prevValue) => {
                  return {
                    ...prevValue,
                    companyId: value?.id || null,
                  };
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('users.fields.company')} />
              )}
            />
          </Grid>
        )}
        {roles && (
          <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
            <Autocomplete
              fullWidth={true}
              popupIcon={<KeyboardArrowDown />}
              options={roles}
              getOptionLabel={(role) => t(`users.roles.${role}`)}
              value={filters.role}
              onChange={(_, value) => {
                setFilters((prevValue) => {
                  return {
                    ...prevValue,
                    role: value,
                  };
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('users.fields.role')} />
              )}
            />
          </Grid>
        )}
        {statuses && (
          <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
            <Autocomplete
              fullWidth={true}
              popupIcon={<KeyboardArrowDown />}
              options={statuses}
              getOptionLabel={(status) => t(`users.statuses.${status}`)}
              value={filters.status}
              onChange={(_, value) => {
                setFilters((prevValue) => {
                  return {
                    ...prevValue,
                    status: value,
                  };
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('users.fields.status')} />
              )}
            />
          </Grid>
        )}
      </Grid>
    </FilterContainer>
  );
};
