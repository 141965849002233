/* eslint-disable max-len */
const GetWired = () => (
  <svg
    width="60"
    height="16"
    viewBox="0 0 60 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_489_4256)">
      <path
        d="M6.7742 12.7589C6.75997 13.2995 6.5798 13.8226 6.25813 14.2572C5.95007 14.6961 5.5451 15.0581 5.07461 15.3152C4.56369 15.6074 3.9891 15.7704 3.40083 15.79C2.95996 15.7933 2.52282 15.7091 2.11474 15.5423C1.70665 15.3754 1.33573 15.1293 1.02346 14.818C0.700739 14.5259 0.443239 14.169 0.267764 13.7706C0.0922884 13.3722 0.00278218 12.9414 0.0050881 12.506C0.0050881 11.885 0.380099 11.5737 1.12668 11.5737C1.33288 11.5778 1.53649 11.6204 1.72704 11.6993C1.97991 11.8059 2.12097 11.9263 2.15366 12.0639C2.23795 12.4957 2.28439 12.7211 2.29472 12.7417C2.3653 12.9586 2.50408 13.1468 2.69037 13.2784C2.89204 13.4422 3.14126 13.5363 3.40083 13.5468C3.67127 13.5277 3.92858 13.4228 4.13536 13.2475C4.40372 13.0479 4.5379 12.8415 4.5379 12.6316V11.2898C2.6422 11.2795 1.36809 10.8477 0.715544 9.9945C0.243054 9.37293 0.00623492 8.28861 0.0050881 6.74155C-0.0131329 6.11982 0.00812515 5.49757 0.0687358 4.87854C0.227187 4.12256 0.655175 3.45001 1.2729 2.98629C1.87436 2.49474 2.62749 2.22667 3.40426 2.22766C3.65331 2.2312 3.9015 2.25769 4.14569 2.30679C4.75694 2.42148 5.34927 2.79018 5.92268 3.4129C6.49609 4.03562 6.7828 4.65147 6.7828 5.26043L6.7742 12.7589ZM4.5379 9.00021V5.3688C4.5379 5.16926 4.40028 4.96799 4.12676 4.76844C3.9217 4.59577 3.668 4.49116 3.40083 4.46913C2.61067 4.46913 2.21559 5.01099 2.21559 6.09474C2.21559 6.16871 2.21558 6.27709 2.23279 6.41815C2.24999 6.5592 2.24827 6.6693 2.24827 6.74155V7.78401C2.27923 8.26911 2.39449 8.57876 2.59231 8.71638C2.88705 8.91592 3.07111 9.01054 3.14451 9.00021H4.5379Z"
        fill="white"
      />
      <path
        d="M14.3019 10.2628C14.3051 10.6985 14.216 11.13 14.0405 11.5288C13.865 11.9275 13.607 12.2846 13.2835 12.5766C12.9736 12.886 12.6055 13.1311 12.2004 13.2976C11.7954 13.4641 11.3613 13.5488 10.9234 13.5468C9.93481 13.5468 9.08731 13.1419 8.38087 12.3323C7.81319 11.6889 7.52075 11.0799 7.52075 10.5002V5.27418C7.53474 4.73305 7.71492 4.20933 8.03682 3.77413C8.33693 3.32371 8.7442 2.95486 9.22206 2.70071C9.73806 2.4072 10.318 2.24421 10.9113 2.22593C11.3563 2.22076 11.7975 2.30712 12.2077 2.47964C12.6178 2.65217 12.9881 2.90717 13.2956 3.22882C13.6172 3.53829 13.8723 3.9101 14.0453 4.32153C14.2184 4.73295 14.3057 5.17535 14.3019 5.62166C14.3019 6.69508 13.9154 7.54775 13.1425 8.17965C12.42 8.76797 11.5105 9.0627 10.4142 9.06384H9.8293C9.79947 9.27529 9.78396 9.48851 9.78285 9.70205C9.78285 10.7709 10.1751 11.3053 10.9595 11.3053C11.2195 11.3053 11.4705 11.2098 11.6648 11.037C11.8657 10.8699 12.0084 10.6434 12.0725 10.3901C12.0925 10.2038 12.1235 10.0189 12.1654 9.83623C12.3122 9.50021 12.6562 9.3322 13.1975 9.3322C13.9338 9.3322 14.3019 9.64242 14.3019 10.2628ZM12.0656 5.61822C12.0658 5.46773 12.0353 5.31877 11.9762 5.18038C11.9171 5.04199 11.8304 4.91706 11.7216 4.81316C11.62 4.70362 11.4967 4.61646 11.3596 4.55723C11.2224 4.49799 11.0745 4.46798 10.9251 4.46911C10.6507 4.48444 10.3881 4.5862 10.1751 4.75983C9.90671 4.95593 9.77253 5.16236 9.77253 5.38427V6.76046H11.1625C11.362 6.76046 11.5616 6.62284 11.7611 6.34932C11.9364 6.14469 12.0429 5.89014 12.0656 5.62166V5.61822Z"
        fill="white"
      />
      <path
        d="M20.2455 12.4183C20.2455 12.9378 20.0929 13.2716 19.7879 13.4195C19.6159 13.5055 19.2718 13.5468 18.7557 13.5468C18.3597 13.5571 17.9635 13.5312 17.5722 13.4694C16.9518 13.3421 16.3572 12.9705 15.7883 12.3547C15.2195 11.7388 14.9328 11.1207 14.9282 10.5003V0.679481C14.9282 0.479934 15.0744 0.311351 15.3634 0.173732C15.584 0.06274 15.827 0.0038856 16.0739 0.00170898C16.6983 0.00170898 17.063 0.2305 17.1714 0.689802V2.1692C17.3913 2.21592 17.6159 2.2367 17.8406 2.23113H18.2293C18.656 2.23113 18.9639 2.30682 19.1566 2.4582C19.3492 2.60958 19.4628 2.90374 19.4628 3.32348C19.4628 3.95136 19.234 4.30573 18.7747 4.39002C18.2586 4.40034 17.7511 4.41582 17.2385 4.43647L17.192 10.2766C17.2976 10.678 17.4524 10.9498 17.6565 11.092C17.8606 11.2342 18.1795 11.3054 18.613 11.3054H19.0224C19.4444 11.3054 19.7552 11.385 19.9547 11.5445C20.1508 11.7027 20.2455 11.9952 20.2455 12.4183Z"
        fill="white"
      />
      <path
        d="M32.5366 10.5157C32.5224 11.0568 32.3422 11.5805 32.0205 12.0158C31.7109 12.4538 31.3055 12.8156 30.8353 13.0737C30.3247 13.3651 29.7507 13.5275 29.1632 13.5468C28.3233 13.5363 27.5155 13.2223 26.8891 12.6626C26.2675 13.2247 25.4615 13.5393 24.6235 13.5468C23.6349 13.5468 22.7874 13.142 22.081 12.3323C21.5133 11.6889 21.2295 11.08 21.2295 10.5003V2.92093C21.2295 2.72138 21.3706 2.5528 21.6561 2.41518C21.867 2.29786 22.103 2.23296 22.3442 2.22595C22.57 2.23762 22.7908 2.29621 22.9927 2.39798C23.3075 2.54591 23.4658 2.7145 23.4658 2.90372V10.4039C23.4658 10.6459 23.6028 10.8615 23.8769 11.0507C24.0872 11.2223 24.3524 11.3121 24.6236 11.3036C24.8948 11.295 25.1538 11.1887 25.3529 11.0043C25.6212 10.803 25.7554 10.5983 25.7554 10.3884V2.92093C25.7554 2.71106 25.8982 2.53732 26.182 2.40486C26.395 2.29504 26.6305 2.23616 26.8701 2.23283C27.0954 2.24454 27.3157 2.30313 27.5169 2.40486C27.8335 2.5528 27.99 2.72138 27.99 2.91061V10.4108C27.99 10.6528 28.127 10.8684 28.4011 11.0576C28.6115 11.2281 28.8758 11.3178 29.1464 11.3105C29.4171 11.3033 29.6762 11.1995 29.8771 11.018C30.1455 10.8237 30.2796 10.6155 30.2796 10.3953V2.92093C30.2796 2.71106 30.4224 2.53732 30.7062 2.40486C30.9192 2.29504 31.1547 2.23616 31.3943 2.23283C31.6458 2.24421 31.8915 2.31181 32.1134 2.43066C32.3818 2.56312 32.5159 2.7231 32.5159 2.91232L32.5366 10.5157Z"
        fill="#F6992B"
      />
      <path
        d="M35.719 12.869C35.719 13.0789 35.5762 13.2526 35.2923 13.3851C35.0767 13.4857 34.8422 13.5391 34.6043 13.5416C34.3571 13.5415 34.1136 13.4825 33.8938 13.3696C33.6203 13.2337 33.4827 13.06 33.4827 12.8535V2.92093C33.4827 2.72138 33.6254 2.5528 33.9093 2.41518C34.1203 2.29829 34.3562 2.23342 34.5974 2.22595C34.8226 2.23766 35.0429 2.29625 35.2442 2.39798C35.5607 2.54592 35.719 2.7145 35.719 2.90372V12.869Z"
        fill="#F6992B"
      />
      <path
        d="M41.9978 3.35442C41.9978 3.75868 41.9135 4.04252 41.7449 4.21454C41.5763 4.38656 41.2976 4.46914 40.9071 4.46914H40.3549C40.0416 4.46807 39.7327 4.54367 39.4552 4.68932C39.1112 4.86135 38.9392 5.1039 38.9392 5.39634V12.8707C38.9392 13.0806 38.7981 13.2544 38.5126 13.3868C38.297 13.4875 38.0624 13.5409 37.8245 13.5433C37.5774 13.543 37.3339 13.484 37.114 13.3713C36.8405 13.2354 36.7046 13.0617 36.7046 12.8553V5.2742C36.7187 4.86203 36.8371 4.46017 37.0486 4.10616C37.7367 2.85154 38.8945 2.22481 40.5218 2.22595C41.0791 2.22595 41.4542 2.28444 41.6434 2.39798C41.8808 2.54936 41.9978 2.8676 41.9978 3.35442Z"
        fill="#F6992B"
      />
      <path
        d="M48.9215 10.2628C48.9247 10.6985 48.8357 11.13 48.6601 11.5288C48.4846 11.9275 48.2266 12.2846 47.9032 12.5766C47.5933 12.886 47.2252 13.1311 46.8201 13.2976C46.415 13.4641 45.981 13.5488 45.543 13.5468C44.5521 13.5468 43.7046 13.1419 43.0005 12.3323C42.4311 11.6889 42.1404 11.0799 42.1404 10.5002V5.27418C42.1546 4.73309 42.3347 4.20944 42.6565 3.77413C42.9561 3.32333 43.3635 2.95439 43.8417 2.70071C44.3576 2.40694 44.9376 2.24393 45.531 2.22593C45.9759 2.22076 46.4171 2.30712 46.8273 2.47964C47.2374 2.65217 47.6077 2.90717 47.9152 3.22882C48.2346 3.53932 48.4875 3.91159 48.6585 4.32297C48.8294 4.73434 48.9148 5.17621 48.9095 5.62166C48.9095 6.69508 48.5224 7.54775 47.7483 8.17965C47.0281 8.76797 46.1187 9.0627 45.0201 9.06384H44.4249C44.395 9.27529 44.3795 9.48851 44.3784 9.70205C44.3784 10.7709 44.77 11.3053 45.5533 11.3053C45.8135 11.3059 46.0647 11.2103 46.2586 11.037C46.4606 10.8709 46.6036 10.644 46.6663 10.3901C46.6871 10.2038 46.7186 10.0189 46.7609 9.83623C46.9077 9.50021 47.2518 9.3322 47.7931 9.3322C48.5454 9.3322 48.9215 9.64242 48.9215 10.2628ZM46.6852 5.61822C46.6854 5.46773 46.655 5.31877 46.5958 5.18038C46.5367 5.04199 46.4501 4.91706 46.3412 4.81316C46.2395 4.70379 46.1162 4.61674 45.9791 4.55752C45.842 4.4983 45.6941 4.46819 45.5447 4.46911C45.2703 4.48444 45.0078 4.5862 44.7947 4.75983C44.5263 4.95593 44.3922 5.16236 44.3922 5.38427V6.76046H45.7821C45.9834 6.76046 46.1829 6.62284 46.3825 6.34932C46.5546 6.14339 46.6585 5.88916 46.6801 5.62166L46.6852 5.61822Z"
        fill="#F6992B"
      />
      <path
        d="M56.329 10.5158C56.3148 11.0569 56.1346 11.5805 55.8129 12.0158C55.5033 12.4539 55.0979 12.8156 54.6277 13.0738C54.1171 13.3651 53.5431 13.5275 52.9556 13.5468C51.9647 13.5468 51.1172 13.142 50.4131 12.3323C49.8437 11.689 49.553 11.08 49.553 10.5003V5.27423C49.5672 4.73314 49.7473 4.20949 50.069 3.77419C50.3681 3.32345 50.7749 2.95449 51.2526 2.70076C51.7691 2.40698 52.3496 2.24398 52.9436 2.22598C53.3235 2.21771 53.7006 2.29349 54.0479 2.44789C54.0479 2.25866 54.0583 1.70475 54.0789 0.789585C54.0892 0.263195 54.4642 0 55.2005 0C55.4398 0.00571176 55.6748 0.064466 55.8886 0.172023C56.1724 0.309641 56.3152 0.471344 56.3152 0.66229L56.329 10.5158ZM54.0927 10.4057V5.36884C54.0927 5.16929 53.9568 4.96803 53.6833 4.76848C53.484 4.57885 53.2195 4.47309 52.9444 4.47309C52.6694 4.47309 52.4048 4.57885 52.2056 4.76848C51.9372 4.96459 51.803 5.17101 51.803 5.39292V10.3988C51.803 10.6087 51.9407 10.8134 52.2142 11.0146C52.4244 11.1898 52.6844 11.2945 52.9573 11.314C53.2263 11.2962 53.4828 11.1938 53.6901 11.0215C53.9516 10.8168 54.0858 10.6155 54.0858 10.4057H54.0927Z"
        fill="#F6992B"
      />
      <path
        d="M59.0142 12.4837C59.0142 12.6767 58.957 12.8653 58.8497 13.0257C58.7425 13.1862 58.59 13.3112 58.4117 13.385C58.2334 13.4587 58.0372 13.4779 57.8479 13.4402C57.6587 13.4024 57.4849 13.3093 57.3485 13.1728C57.2122 13.0362 57.1195 12.8622 57.082 12.6729C57.0446 12.4836 57.0641 12.2874 57.1382 12.1092C57.2123 11.931 57.3376 11.7788 57.4982 11.6718C57.6588 11.5649 57.8476 11.508 58.0406 11.5083C58.1686 11.5081 58.2955 11.5332 58.4138 11.5821C58.5322 11.6311 58.6397 11.7029 58.7301 11.7936C58.8206 11.8842 58.8923 11.9918 58.9411 12.1103C58.9898 12.2287 59.0147 12.3556 59.0142 12.4837Z"
        fill="#F6992B"
      />
    </g>
    <defs>
      <clipPath id="clip0_489_4256">
        <rect width="59.0142" height="15.79" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GetWired;
