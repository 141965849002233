import { i18n } from 'i18next';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';

import { Reservation, User } from '../../models';
import { convertWeight } from '../../utils/units';
import report_logo from '../../assets/report_logo.png';
import reservation_icon from '../../assets/ReservationIcon.png';
import formatReservationNumber from '../../utils/formatReservationNumber';
import calculateReservationStatusToDisplay from '../../utils/calculateReservationStatusToDisplay';
import { ReservationFilters } from '../../types';
import { ConsultationMode } from '../../enums';
import formatPackagedReelQuantity from '../../utils/formatPackagedReelQuantity';
import report, { MARGIN, PDF_CONFIG, FONT_WEIGHT } from './report';

class reservationReportPdf extends report {
  private reservations: Reservation[];
  private filters: ReservationFilters;
  private user: User;
  private mode: ConsultationMode;
  private readonly DEFAULT_ROW_HEIGHT = 13;

  constructor(
    reservations: Reservation[],
    filters: ReservationFilters,
    user: User,
    mode: ConsultationMode,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super('reports.reservations', t, i18n);

    this.user = user;
    this.mode = mode;
    this.filters = filters;
    this.reservations = reservations;
  }

  protected getFilename(): string {
    return `NC_Reservations_${DateTime.now().toFormat('LLddyyyyHHmmss')}.pdf`;
  }

  protected buildHeader(): void {
    this.outputImage(report_logo, MARGIN.X, MARGIN.Y);
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(reservation_icon, MARGIN.X, this.curHeight, 10);

    const formattedTitle =
      this.mode === 'current'
        ? this.t('reports.reservations.current')
        : this.t('reports.reservations.history');

    this.outputH1(formattedTitle, this.curWidth + 15, this.curHeight + 7);
  }

  protected buildBody(): void {
    const texts = [];

    if (this.filters.partNumber) {
      texts.push(
        `${this.t('reports.reservations.partNumber')} - ${
          this.filters.partNumber
        }`
      );
    }

    if (this.filters.reservationNumber) {
      texts.push(
        `${this.t(
          'reports.reservations.reservationNumber'
        )} - ${formatReservationNumber(this.filters.reservationNumber)}`
      );
    }

    this.curHeight += texts.length > 0 ? PDF_CONFIG.LINE_SIZE : 10;

    if (texts.length > 0) {
      this.outputText(texts.join(' - '), MARGIN.X, this.curHeight, {
        fontWeight: FONT_WEIGHT.BOLD,
      });
    }

    this.curHeight += 4;

    this.drawLine(0.5);

    this.curHeight += 5;

    this.reservations.forEach((reservation, index) =>
      this.buildRow(reservation, index)
    );
  }

  private buildRow(reservation: Reservation, index: number): void {
    const { madeByName, madeForName, note, reservationNumber } = reservation;
    const formattedNumber = formatReservationNumber(reservationNumber);
    const statusToDisplay = calculateReservationStatusToDisplay(reservation);
    const formattedStatus = this.t(
      `reservations.shortStatuses.${statusToDisplay}`
    );
    const formattedDate = DateTime.fromMillis(
      reservation.reservedTime.toMillis()
    )
      .setLocale(this.user.language || '')
      .setZone(this.user.timeZone)
      .toLocaleString(DateTime.DATETIME_MED);
    const reelId = `${reservation.reelId} - ${reservation.reel.reel}`;
    const reelDescription = `${
      reservation.reel.description
    } - Quantity: ${formatPackagedReelQuantity(
      this.user.measurementUnits,
      reservation.reel
    )}${this.t(`units.${this.user.measurementUnits}.length`)}
    Weight: ${convertWeight(
      this.user.measurementUnits,
      reservation.reel.weight
    )}${this.t(`units.${this.user.measurementUnits}.weight`)}`;

    this.addDataColumn('reservationNumber', formattedNumber, MARGIN.X);
    this.addDataColumn('reservedFor', madeForName, MARGIN.X + 30, 35);
    this.addDataColumn('reservedBy', madeByName, MARGIN.X + 70, 35);
    this.addDataColumn('status', formattedStatus, MARGIN.X + 110);
    this.addDataColumn('date', formattedDate, MARGIN.X + 130);

    this.curHeight += 10;
    this.addDataColumn('reelId', reelId, MARGIN.X);
    this.addDataColumn('product', reelDescription, MARGIN.X + 30);

    if (note?.trim()) {
      this.curHeight += 10;
      const lines = this.addDataColumn('note', note, MARGIN.X, undefined, true);

      this.curHeight += 4 * ((lines as number) - 1);
    }

    this.curHeight += 9;

    this.drawLine(0.1);
    this.doc.setLineWidth(0.1);

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, this.reservations.length);
  }

  static generate = (
    reservations: Reservation[],
    filters: ReservationFilters,
    user: User,
    mode: ConsultationMode,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) => {
    const report = new reservationReportPdf(
      reservations,
      filters,
      user,
      mode,
      t,
      i18n
    );

    report.generate();
  };
}

export default reservationReportPdf;
