export enum UserPermission {
  DisableCompanyUsers = 'disableCompanyUsers',
  ImpersonateCustomer = 'impersonateCustomer',
  ManageAgency = 'manageAgency',
  ManageAgencies = 'manageAgencies',
  ManageAgencyCustomerReservations = 'manageAgencyCustomerReservations',
  ManageAgencyUsers = 'manageAgencyUsers',
  ManageAgentBulletinBoard = 'manageAgentBulletinBoard',
  ManageCompanies = 'manageCompanies',
  ManageCompanyReservations = 'manageCompanyReservations',
  ManageCustomerUsers = 'manageCustomerUsers',
  ManageInventoryClasses = 'manageInventoryClasses',
  ManageNCUsers = 'manageNCUsers',
  ManageOrderFilesAccess = 'manageOrderFilesAccess',
  ManageOwnReservations = 'manageOwnReservations',
  ManagePortalSettings = 'managePortalSettings',
  ManageReelReturns = 'manageReelReturns',
  ManageRolesAndPermissions = 'manageRolesAndPermissions',
  ViewAllReservations = 'viewAllReservations',
  ViewCommissionsReports = 'viewCommissionsReports',
  ViewCompanyUsers = 'viewCompanyUsers',
  ViewConversionRatesReports = 'viewConversionRatesReports',
  ViewInventory = 'viewInventory',
  ViewLogs = 'viewLogs',
  ViewOrders = 'viewOrders',
  ViewOrdersConfirmation = 'viewOrdersConfirmation',
  ViewOrdersInvoice = 'viewOrdersInvoice',
  ViewOrdersShipping = 'viewOrdersShipping',
  ViewReelReturns = 'viewReelReturns',
  ViewReservationReports = 'viewReservationReports',
  ViewSalesReports = 'viewSalesReports',
}
