import { UserStatus } from '../enums';

export const Statuses = [
  UserStatus.Active,
  UserStatus.Inactive,
  UserStatus.Pending,
  UserStatus.PendingEmailValidation,
  UserStatus.WaitingForApproval,
];

export const CustomerStatuses = [UserStatus.Active, UserStatus.Inactive];
