import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useLocalStorage, usePageTitle } from '../../hooks';
import { Container, AdminConversionRateFilters, Show } from '../../components';
import { UserPermission } from '../../enums';
import { getPageTitle } from '../../utils/pages';
import { RequirePermission } from '../../security';
import { ConversionRateFilters as Filters, Nullable } from '../../types';
import { AdminConversionRate } from '../../components/ConversionRate/AdminConversionRate';
import Reports from './partials/Reports';

const ConversionRate = () => {
  usePageTitle(getPageTitle('reports.conversionRate'));
  const { item: filters, setItem: setFilters } = useLocalStorage<Filters>(
    'conversionRates',
    'conversion'
  );
  const { item: expandedCompanies, setItem: setExpandedCompanies } =
    useLocalStorage<string[]>('conversionRates', 'expandedCompanies');

  if (typeof filters?.startDate === 'string') {
    filters.startDate = DateTime.fromISO(filters.startDate);
  }
  if (typeof filters?.endDate === 'string') {
    filters.endDate = DateTime.fromISO(filters.endDate);
  }

  const handleSearch = (newFilters: Nullable<Filters>) => {
    if (filters) {
      const { startDate, endDate } = filters;
      if (startDate && typeof startDate === 'string') {
        filters.startDate = DateTime.fromISO(startDate);
      }

      if (endDate && typeof endDate === 'string') {
        filters.endDate = DateTime.fromISO(endDate);
      }

      if (!isEqual(newFilters, filters)) {
        setExpandedCompanies([]);
      }
    }
    setFilters(newFilters);
  };

  return (
    <RequirePermission oneOf={[UserPermission.ViewReservationReports]}>
      <Reports>
        <AdminConversionRateFilters
          onSearch={handleSearch}
          loadedFilters={filters}
        />

        <Show if={!!filters}>
          <Container>
            <AdminConversionRate
              filters={filters}
              expandedCompanies={expandedCompanies}
              setExpandedCompanies={setExpandedCompanies}
            />
          </Container>
        </Show>
      </Reports>
    </RequirePermission>
  );
};

export default ConversionRate;
