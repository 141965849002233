import { useMemo } from 'react';
import { normalizeSync } from 'normalize-diacritics';
import { Filter } from '../types';

interface SearchableProps {
  value: string;
  property: string;
  operator?: string;
}

export function useSearchableFilter({
  value,
  property,
  operator,
}: SearchableProps) {
  const filters = useMemo(() => {
    if (!value) return [];

    const normalizedValue = normalizeSync(value)
      .toLowerCase()
      .replace(/ /g, '');

    if (operator) {
      return [[property, operator, normalizedValue] as Filter];
    }

    return [
      [property, '>=', normalizedValue] as Filter,
      [property, '<=', normalizedValue + '\uf8ff'] as Filter,
    ];
  }, [value, property, operator]);

  return { filters };
}
