import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FormControlHeader,
  ProgressIndicator,
  UserForm,
} from '../../components';
import {
  useCompany,
  useCurrentUser,
  useForm,
  usePageTitle,
  useUser,
} from '../../hooks';
import { ModificationMode } from '../../enums';
import { getPageTitle } from '../../utils/pages';
import { Formable } from '../../types';
import { User as UserModel } from '../../models';
import { getUserSchema } from '../../validation';

interface Props {
  form: Formable<UserModel>;
  user?: UserModel;
}

const UserWrapper = ({ form, user }: Props) => {
  const currentUser = useCurrentUser();
  const { item: company } = useCompany(currentUser.companyRef.id);
  const pageTitleOptions = {
    user: user?.name,
  };
  usePageTitle(getPageTitle('user.edit', pageTitleOptions));

  return (
    <>
      {company && (
        <UserForm
          form={form}
          mode={ModificationMode.Edit}
          companies={[company]}
          isAdmin={true}
          isAgent
        />
      )}
      <Outlet context={form} />
    </>
  );
};

const User: React.FC = () => {
  const { t } = useTranslation();

  const { userId } = useParams();
  const user = useUser(userId || '');
  const form = useForm(user, {
    validations: getUserSchema(ModificationMode.Edit),
  });

  return (
    <>
      <FormControlHeader
        backTo={'/agent/users'}
        form={form}
        title={t('users.form.title', { name: user.item?.name })}
      />
      {user.loading ? (
        <ProgressIndicator />
      ) : (
        user.item && <UserWrapper form={form} user={user.item} />
      )}
    </>
  );
};

export default User;
