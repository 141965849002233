import { useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';

import { Container, FilterBar, UnderlinedLink } from '../../components';
import { UserPermission } from '../../enums/';
import { RequirePermission } from '../../security';
import {
  useProductTypes,
  useSorting,
  usePagination,
  useSpecifications,
  useRouterOutlet,
  usePageTitle,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { Specification } from '../../models';
import { Filter } from '../../types';
import { getPageTitle } from '../../utils/pages';
import PortalSettings from './partials/PortalSettings';

const Specifications: React.FC = () => {
  const [productType, setProductType] = useState<string | null>(null);
  const { t } = useTranslation();
  usePageTitle(getPageTitle('portalSettings.specifications'));
  const { item, loading: productTypeLoading } = useProductTypes();
  const navigate = useNavigate();
  const pagination = usePagination();
  const { outlet, showOutletOnly } = useRouterOutlet();
  const sorting = useSorting('name.EN');
  const { list: specifications, loading: specLoading } = useSpecifications({
    pagination,
    sorting,
    filters: [
      productType && ['productType', '==', parseInt(productType)],
    ] as Array<Filter>,
  });

  const productTypes = useMemo(() => {
    if (!item || productTypeLoading) return [];

    return Object.keys(item.types);
  }, [item, productTypeLoading]);

  const filterButtons = () => (
    <>
      <Button
        startIcon={<NoteAddIcon />}
        variant="contained"
        onClick={() => navigate('/admin/portal/specifications/new')}
      >
        {t('portalSettings.specifications.actions.addSpecification')}
      </Button>
    </>
  );

  const menuItems = (spec: Specification) => (
    <MenuItem onClick={() => navigate(`${spec.id}/delete`)}>
      <ListItemIcon>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('users.actions.delete')}</ListItemText>
    </MenuItem>
  );

  return (
    <RequirePermission oneOf={[UserPermission.ManagePortalSettings]}>
      <PortalSettings>
        {outlet}
        {showOutletOnly || (
          <>
            <FilterBar
              title={t('portalSettings.specifications.title')}
              buttons={filterButtons()}
            >
              <Autocomplete
                fullWidth={true}
                popupIcon={<KeyboardArrowDownIcon />}
                getOptionLabel={(value) => t(`productTypes.${value}`)}
                options={productTypes}
                value={productType}
                onChange={(_, value) => setProductType(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label={t(
                      'portalSettings.specifications.filters.productType'
                    )}
                  />
                )}
              />
            </FilterBar>

            <Container>
              <DataTable
                data={!specLoading ? specifications : undefined}
                pagination={pagination}
                sorting={sorting}
                menuItems={menuItems}
              >
                <DataTableColumn
                  property="name.EN"
                  title={t(
                    'portalSettings.specifications.table.specificationName'
                  )}
                  width="55%"
                  output={(value, item: Specification) => (
                    <UnderlinedLink to={item.id}>{value}</UnderlinedLink>
                  )}
                />
                <DataTableColumn
                  property="productType"
                  title={t('portalSettings.specifications.table.productType')}
                  output={(value) => t(`productTypes.${value}`)}
                  width="20%"
                />
                <DataTableColumn
                  property="name.FR"
                  title={t('portalSettings.specifications.table.language')}
                  output={(value) => (
                    <Grid display="flex" alignItems="center" gap="0.5em">
                      <LanguageIcon fontSize="small" />
                      <>{value ? 2 : 1}</>
                    </Grid>
                  )}
                  width="15%"
                />
                <DataTableColumn
                  property="url.FR"
                  title={t('portalSettings.specifications.table.url')}
                  output={(value) => (
                    <Grid display="flex" alignItems="center" gap="0.5em">
                      <LinkIcon fontSize="small" />
                      <>{value ? 2 : 1}</>
                    </Grid>
                  )}
                  width="10%"
                />
              </DataTable>
            </Container>
          </>
        )}
      </PortalSettings>
    </RequirePermission>
  );
};

export default Specifications;
