import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { UserAvatarProps } from './UserAvatar.props';

export const UserAvatar = ({ variant = 'large' }: UserAvatarProps) => {
  const isLargeVariant = variant === 'large';

  return (
    <Avatar
      sx={{
        m: isLargeVariant ? 0.76 : 0,
        backgroundColor: 'transparent',
        borderColor: 'secondary.main',
        borderWidth: '3px',
        borderStyle: 'solid',
        width: isLargeVariant ? '2em' : '1.5em',
        height: isLargeVariant ? '2em' : '1.5em',
      }}
    >
      <PersonIcon
        sx={{
          color: 'secondary.main',
          width: isLargeVariant ? '1em' : '0.75em',
          height: isLargeVariant ? '1em' : '0.75em',
        }}
      />
    </Avatar>
  );
};
