import { useCallback } from 'react';
import { UserPermission, UserRole } from '../enums';
import { roles } from '../security';
import { CompanyOrderFilePermission } from '../models/Company';
import { useCurrentRole } from './useCurrentRole';
import { usePortalSettings } from './usePortalSettings';
import { useCurrentUser } from './useCurrentUser';
import { useCompany } from './useCompany';

export const usePermission = () => {
  const currentUserRole = useCurrentRole();
  const { item } = usePortalSettings();
  const currentUser = useCurrentUser();
  const { item: currentCompany } = useCompany(currentUser.companyRef.id);

  const roleHasPermission = useCallback(
    (role: UserRole, permission: UserPermission): boolean | undefined => {
      if (!role || !item) {
        return undefined;
      }

      const permissions = roles.AdminRoles.includes(role)
        ? item.northernCablesPermissions
        : roles.AgentRoles.includes(role)
        ? item.agencyUserPermissions
        : item.customerPermissions;
      return permissions[permission] && permissions[permission][role];
    },
    [item]
  );

  const userHasPermission = useCallback(
    (permission: UserPermission): boolean | undefined => {
      if (!currentCompany || !currentUser || !currentUserRole) {
        return undefined;
      }

      if (
        currentUserRole === UserRole.Manager ||
        currentUserRole === UserRole.NciAdmin ||
        currentUserRole === UserRole.NciCustomerService ||
        currentUserRole === UserRole.NciStaff ||
        currentUserRole === UserRole.AgencyAdmin ||
        currentUserRole === UserRole.AgencyCustomerService
      ) {
        return undefined;
      }

      if (!currentUser.orderFilesPermissions.useDefault) {
        return (
          (permission === UserPermission.ViewOrdersConfirmation &&
            currentUser.orderFilesPermissions.confirmation) ||
          (permission === UserPermission.ViewOrdersInvoice &&
            currentUser.orderFilesPermissions.invoiceCredit) ||
          (permission === UserPermission.ViewOrdersShipping &&
            currentUser.orderFilesPermissions.shipping)
        );
      } else {
        return (
          (permission === UserPermission.ViewOrdersConfirmation &&
            currentCompany.orderFilesPermissions[
              currentUserRole.toString() as keyof CompanyOrderFilePermission
            ].confirmation) ||
          (permission === UserPermission.ViewOrdersInvoice &&
            currentCompany.orderFilesPermissions[
              currentUserRole.toString() as keyof CompanyOrderFilePermission
            ].invoiceCredit) ||
          (permission === UserPermission.ViewOrdersShipping &&
            currentCompany.orderFilesPermissions[
              currentUserRole.toString() as keyof CompanyOrderFilePermission
            ].shipping)
        );
      }
    },
    [currentCompany, currentUser, currentUserRole]
  );

  const hasPermission = useCallback(
    (permission: UserPermission): boolean | undefined =>
      roleHasPermission(currentUserRole, permission),
    [roleHasPermission, currentUserRole]
  );

  const userHasOneOfPermission = useCallback(
    (permissions: Array<UserPermission>): boolean | undefined => {
      if (!currentCompany || !currentUser) {
        return undefined;
      }
      return permissions.some((permission) => userHasPermission(permission));
    },
    [currentCompany, currentUser, userHasPermission]
  );

  const hasOneOfPermissions = useCallback(
    (permissions: Array<UserPermission>): boolean | undefined => {
      if (!currentUserRole || !item) {
        return undefined;
      }
      return permissions.some((permission) => hasPermission(permission));
    },
    [currentUserRole, hasPermission, item]
  );

  const hasAllPermissions = useCallback(
    (permissions: Array<UserPermission>): boolean | undefined => {
      if (!currentUserRole || !item) {
        return undefined;
      }

      return permissions.every((permission) => hasPermission(permission));
    },
    [currentUserRole, hasPermission, item]
  );

  return {
    hasOneOfPermissions,
    hasPermission,
    hasAllPermissions,
    userHasPermission,
    userHasOneOfPermission,
    roleHasPermission,
    loading: !item || !currentUserRole || !currentUser || !currentCompany,
  };
};
