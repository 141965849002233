import { useTranslation } from 'react-i18next';
import { convertWeight } from '../../utils/units';
import { Show } from '..';
import { ReelWeightProps } from './ReelWeight.props';

export const ReelWeight = ({ item, unitsType }: ReelWeightProps) => {
  const { t } = useTranslation();

  const value = convertWeight(unitsType, item.weight);

  return (
    <Show if={value !== ''}>
      {value}
      {t(`units.${unitsType}.weight`)}
    </Show>
  );
};
