import * as yup from 'yup';

const PortalSchema = yup.object({
  contactName: yup.object({
    EN: yup.string().required(),
    FR: yup.string().required(),
  }),
  contactPhoneNumber: yup.object({
    EN: yup.string().required(),
    FR: yup.string().required(),
  }),
  contactExtension: yup.object({
    EN: yup.string(),
    FR: yup.string(),
  }),
  contactEmail: yup.object({
    EN: yup.string().required().email(),
    FR: yup.string().required().email(),
  }),
  contactEmailUS: yup.object({
    EN: yup.string().required().email(),
    FR: yup.string().email(),
  }),
  contactURL: yup.object({
    EN: yup.string().required().url(),
    FR: yup.string().required().url(),
  }),
});

export default PortalSchema;
