import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCompany,
  useCurrentUser,
  usePageTitle,
  usePermission,
} from '../../hooks';
import { UserPermission } from '../../enums';
import { AgencyCustomersList, ProgressIndicator, Show } from '../../components';
import { getPageTitle } from '../../utils/pages';
import { RequirePermission } from '../../security';
import { MyAgencyPartial } from './partial';

const MyAgency = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const { agencyId } = useParams();

  const currentUser = useCurrentUser();
  const agency = useCompany(agencyId || '');

  usePageTitle(getPageTitle('agency.edit', { name: agency.item?.name }));

  const showCustomerList = Boolean(hasPermission(UserPermission.ManageAgency));

  useEffect(() => {
    if (!agency.loading && !agency.item) {
      navigate('/agent/dashboard');
    }
    if (agencyId !== currentUser.companyRef.id) {
      navigate('/access-denied', { replace: true });
    }
  }, [
    agency.item,
    agency.loading,
    agencyId,
    currentUser.companyRef.id,
    navigate,
  ]);

  return (
    <RequirePermission oneOf={[UserPermission.ManageAgency]}>
      {agency.loading ? (
        <ProgressIndicator />
      ) : (
        <MyAgencyPartial agency={agency}>
          <Show if={showCustomerList}>
            <AgencyCustomersList agencyId={agencyId} />
          </Show>
        </MyAgencyPartial>
      )}
    </RequirePermission>
  );
};

export default MyAgency;
