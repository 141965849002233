import { useState } from 'react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { BottomDrawer, Show } from '../../../../components';
import { useCompanies } from '../../../../hooks';
import { Company, User } from '../../../../models';
import { CompanyType } from '../../../../enums';
import { OutletContext } from './ImpersonateCustomer.interface';
import {
  cancelButton,
  confirmButton,
  input,
} from './ImpersonateCustomer.styles';
import UserSelect from './components/UserSelect.component';

const ImpersonateCustomer = () => {
  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const { fromCollapse, inventoryType, onCancel, onImpersonate, title } =
    useOutletContext<OutletContext>();

  const { t } = useTranslation();

  const { list: companies } = useCompanies({
    includeDeleted: false,
    filters: [['type', '==', CompanyType.Customer]],
  });

  const impersonate = () => {
    if (!user) return;

    onImpersonate && onImpersonate(user);
  };

  const cancel = () => {
    onCancel && onCancel();
  };

  const formButtons = () => (
    <Grid marginBottom={1.5}>
      <Button disabled={!user} onClick={() => impersonate()} sx={confirmButton}>
        {t('forms.confirm')}
      </Button>

      <Button onClick={() => cancel()} sx={cancelButton}>
        {t('companies.actions.cancel')}
      </Button>
    </Grid>
  );

  return (
    <BottomDrawer
      title={t(title)}
      icon={<HowToRegIcon sx={{ color: 'secondary.main' }} />}
      open={true}
      rightContent={formButtons()}
    >
      <Grid display="flex">
        <Grid display="flex" width="50%" minWidth="500px">
          <Autocomplete
            fullWidth={true}
            popupIcon={<KeyboardArrowDownIcon />}
            options={companies ?? []}
            value={company}
            onChange={(e, value) => {
              setCompany(value);
              setUser(null);
            }}
            getOptionLabel={(company) => company.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                variant="filled"
                sx={input}
              />
            )}
            sx={{ mr: 5 }}
          />

          <Show if={!!company}>
            <UserSelect
              // eslint-disable-next-line
              company={company!}
              inventoryType={inventoryType}
              onSelect={setUser}
              user={user}
              fromCollapse={fromCollapse}
            />
          </Show>

          <Show if={!company}>
            <Autocomplete
              fullWidth={true}
              disabled={true}
              popupIcon={<KeyboardArrowDownIcon />}
              options={[]}
              value={null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User"
                  variant="filled"
                  sx={input}
                />
              )}
            />
          </Show>
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default ImpersonateCustomer;
