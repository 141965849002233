import { Trans, useTranslation } from 'react-i18next';
import { Button, Link, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import * as Sx from '../../VerifyEmail.styles';
import { translateString } from '../../../../i18n';
import { usePortalSettings } from '../../../../hooks';

import { FailureContentProps } from './FailureContent.props';

export const FailureContent = (props: FailureContentProps) => {
  const { t, i18n } = useTranslation();
  const { item: portalSettings } = usePortalSettings();

  return (
    <>
      <Typography variant="h2" sx={Sx.title}>
        <WarningIcon
          color="secondary"
          sx={Sx.icon}
          titleAccess={t('alt.warningIcon')}
        />
        {t('verifyEmail.failure.title')}
      </Typography>
      <Typography sx={Sx.text}>{t('verifyEmail.failure.body1')}</Typography>
      <Typography sx={Sx.text}>{t('verifyEmail.failure.body2')}</Typography>
      <Button
        onClick={props.resendVerificationEmail}
        variant="contained"
        sx={Sx.button}
      >
        {t('verifyEmail.failure.button')}
      </Button>
      <Typography sx={Sx.footer}>
        <Trans
          i18nKey="verifyEmail.failure.footer"
          components={[
            <Link
              href={translateString(portalSettings?.contactURL, i18n)}
              target="_blank"
            />,
          ]}
        />
      </Typography>
    </>
  );
};
