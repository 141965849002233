import { ReelReturn } from '../models';
import { CollectionQueryOptions } from '../types/';
import { useCollection } from './useCollection';

export function useCompletedReelReturns(options?: CollectionQueryOptions) {
  return useCollection<ReelReturn>(
    'CompletedReelReturns',
    [],
    options?.sorting || { sortField: 'returnByDate', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
