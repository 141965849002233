import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Box, Button, Grid, Typography } from '@mui/material';

import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { getPageTitle } from '../../utils/pages';
import { ModificationMode } from '../../enums';
import { queryStringToObject } from '../../utils/strings';
import {
  DecoratedHeader,
  TextField,
  Show,
  UnitaryModal,
} from '../../components';
import { useForm, usePageTitle, usePublicAuthentication } from '../../hooks';

import * as Sx from './ResetPassword.styles';
import { ResetPasswordProps } from './ResetPassword.props';
import { ResetPasswordUrlParams } from './types/ResetPassword.interface';
import { ResetPasswordValidation } from './types/ResetPassword.validation';
import { useResetPassword } from './types/ResetPassword.hooks';

export const ResetPassword = (props: ResetPasswordProps) => {
  const { context = 'resetPassword' } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const verifiedRef = useRef(false);
  const { oobCode, userId } =
    queryStringToObject<ResetPasswordUrlParams>(search);
  const resetPassword = useResetPassword(oobCode);
  const navigate = useNavigate();
  const { verifyPasswordResetCode } = usePublicAuthentication();
  const functions = useFunctions();
  const updatePendingStatus = httpsCallable(functions, 'updatePendingStatus');

  const [verifying, setVerifying] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);

  usePageTitle(getPageTitle(context));

  const form = useForm(resetPassword, {
    validations: ResetPasswordValidation,
    mode: ModificationMode.Add,
    onSaveSuccess: async () => {
      setSuccess(true);
      if (userId && context === 'setPassword') {
        await updatePendingStatus(userId);
      }
    },
  });

  useEffect(() => {
    if (!verifiedRef.current) {
      verifiedRef.current = true;

      verifyPasswordResetCode(oobCode)
        .then(async () => setVerifying(false))
        .catch((error) => {
          if (error.code === 'auth/expired-action-code') {
            navigate('/expired-link', { replace: true });
          } else {
            navigate('/access-denied', { replace: true });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UnitaryModal>
      <Show if={!verifying && success}>
        <Grid>
          <Typography variant="h2">
            {t(`pages.${context}.pageTitle`)}
          </Typography>

          <Typography>{t(`pages.${context}.successMessage`)}</Typography>

          <Grid mt={5}>
            <Button variant="outlined" onClick={() => navigate('/login')}>
              {t('users.actions.returnToLogin')}
            </Button>
          </Grid>
        </Grid>
      </Show>

      <Show if={!verifying && !success}>
        <Box sx={Sx.container}>
          <DecoratedHeader
            icon={<LockRoundedIcon titleAccess={t('alt.lockIcon')} />}
            title={
              <>
                {t('application.title.line1')}
                <br />
                {t('application.title.line2')}
              </>
            }
          />

          <Typography variant="h2">
            {t(`pages.${context}.pageTitle`)}
          </Typography>

          <TextField
            autocomplete="new-password"
            autoFocus
            field="newPassword"
            form={form}
            label={t('users.fields.newPassword')}
            required
            type="password"
          />
          <Typography component="span" sx={Sx.passwordHelpText}>
            {t('signUp.passwordLength')}
          </Typography>

          <TextField
            autocomplete="new-password"
            field="confirmPassword"
            form={form}
            label={t('users.fields.confirmPassword')}
            required
            type="password"
          />

          <Button
            disabled={
              form.saving ||
              !form.item?.newPassword ||
              form.item?.newPassword !== form.item?.confirmPassword
            }
            fullWidth
            onClick={() => form.save()}
            type="submit"
            variant="contained"
            sx={Sx.button}
          >
            {t('users.actions.savePassword')}
          </Button>
        </Box>
      </Show>
    </UnitaryModal>
  );
};
