import { usePermission } from '../hooks';
import { UserPermission } from '../enums/';

interface Props {
  children: React.ReactNode;
  oneOf?: Array<UserPermission>;
  allOf?: Array<UserPermission>;
}

const NeedsPermission = ({ oneOf, allOf, children }: Props) => {
  const { hasOneOfPermissions, hasAllPermissions, userHasOneOfPermission } =
    usePermission();

  if (!oneOf && !allOf) {
    return null;
  }

  if (oneOf && !hasOneOfPermissions(oneOf) && !userHasOneOfPermission(oneOf)) {
    return null;
  }

  if (allOf && !hasAllPermissions(allOf)) {
    return null;
  }

  return <>{children}</>;
};

export default NeedsPermission;
