import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';

import { useMemo } from 'react';
import {
  useAuthentication,
  useDownloadFiles,
  usePermission,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { Container, FormattedTimestamp, FileDownloadOutput, Show } from '..';
import { OrderFile } from '../../models';
import { UserPermission } from '../../enums';
import { OrderFileTableProps } from './OrderFileTable.props';

export const OrderFileTable = ({
  id,
  title,
  files,
  selected,
  setSelected,
  deleteOrderFile,
}: OrderFileTableProps) => {
  const { t } = useTranslation();
  const { downloadFile } = useDownloadFiles();
  const { hasPermission } = usePermission();
  const { isAdmin } = useAuthentication();

  const showMenuItems = useMemo(
    () => isAdmin && hasPermission(UserPermission.ViewOrders),
    [hasPermission, isAdmin]
  );

  const dateOutput = (timestamp: Timestamp) => (
    <FormattedTimestamp timestamp={timestamp} />
  );

  const downloadOutput = (_: string, file: OrderFile) => (
    <FileDownloadOutput
      children={t('orderDetails.actions.download')}
      onClick={() => downloadFile(file)}
    />
  );

  const fileNameOutput = (_: string, file: OrderFile) => (
    <Typography>{file.fileName + file.fileExtension}</Typography>
  );

  const selectFile = (_: string, item: OrderFile) => (
    <Checkbox
      checked={selected.includes(item)}
      onChange={(e) => {
        if (e.target.checked) {
          setSelected((prev) => [...prev, item]);
        } else {
          setSelected((prev) =>
            prev.filter((filterItem) => item !== filterItem)
          );
        }
      }}
    />
  );

  const menuItems = (item: OrderFile) => (
    <Show if={!!showMenuItems}>
      <MenuItem onClick={() => deleteOrderFile && deleteOrderFile(item)}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('orderDetails.actions.delete')}</ListItemText>
      </MenuItem>
    </Show>
  );

  return (
    <Container>
      <Grid item paddingTop={'3em'}>
        <Typography
          component="h2"
          variant="h2"
          color="primary"
          id={`order_section_${id}`}
          sx={{ scrollMarginTop: '80px' }}
        >
          {title} ({files.length})
        </Typography>
      </Grid>
      <DataTable
        data={files}
        selected={selected}
        setSelectAll={setSelected}
        mobileBorder="none"
        menuItems={showMenuItems ? menuItems : undefined}
      >
        <DataTableColumn
          property="selectable"
          output={selectFile}
          textAlign="center"
          mobileWidth="5rem"
          mobileTextAlign="right"
          mobileOrder={0}
        />
        <DataTableColumn
          mobileWidth="calc(100% - 5rem)"
          property="fileName"
          title={t('orderDetails.table.fileName')}
          mobileOrder={-1}
          width={hasPermission(UserPermission.ViewOrders) ? '50%' : undefined}
          output={fileNameOutput}
        />
        <DataTableColumn
          property="dateTimeUploaded"
          width="25%"
          title={t('orderDetails.table.uploadedOn')}
          output={dateOutput}
        />
        <DataTableColumn
          textAlign="center"
          width="20%"
          property="fileLocation"
          title=""
          output={downloadOutput}
        />
      </DataTable>
    </Container>
  );
};
