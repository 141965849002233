import { SxProps } from '@mui/material';

export const input: SxProps = {
  mt: 1.5,
  backgroundColor: '#ffffff',
};

export const confirmButton: SxProps = {
  minWidth: '80px',
  backgroundColor: 'secondary.main',
  color: 'primary.contrastText',
  marginRight: 2,
  '&:hover': {
    backgroundColor: 'secondary.main',
  },
};

export const cancelButton: SxProps = {
  minWidth: '80px',
  color: 'primary.contrastText',
  border: 1,
  borderColor: 'primary.contrastText',
  '&:hover': {
    borderColor: 'primary.contrastText',
  },
};
