import { SxProps } from '@mui/material';

export const alert: SxProps = {
  borderTopLeftRadius: '0.75rem',
  borderTopRightRadius: '0.75rem',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  flexDirection: 'row-reverse',
  fontSize: '1.25rem',
  fontWeight: 400,
  alignItems: 'center',
  '& .MuiAlert-message': {
    padding: '0.5rem 0 0.25rem',
    flexGrow: 1,
    textAlign: 'right',
  },
  '& .MuiAlert-action': {
    padding: 0,
    marginLeft: '-0.5rem',
    marginRight: '0.5rem',
    fontSize: '1.5rem',
  },
  '& .MuiAlert-icon': {
    padding: 0,
    marginLeft: '1rem',
    fontSize: '1.5rem',
  },
  width: '100%',
};

export const snackbar = (elevated: boolean): SxProps => {
  return {
    mb: {
      xs: !elevated ? '-8px' : '110px',
      sm: !elevated ? '-24px' : '96px',
    },
    minWidth: '30vw',
    zIndex: 6000,
  };
};
