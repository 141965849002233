import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container, DecoratedHeader, Show } from '../../../components';
import { Tab } from '../../../navigation';
import { usePortalMetadata } from '../../../hooks';

interface Props {
  children: React.ReactNode;
}

const Users = ({ children }: Props) => {
  const { t } = useTranslation();
  const { item: metaData } = usePortalMetadata();
  const { userId } = useParams();

  const awaitingApprovals = metaData?.usersAwaitingApprovalCount ?? 0;

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<SupervisedUserCircleIcon />}
          title={t('navigation.users')}
          to="/admin/users"
          variant="colored"
        >
          <Show if={!userId}>
            <Tab title={t('users.list.title')} to={'/admin/users'} />
            <Tab
              title={t('users.awaitingApproval.title', {
                count: awaitingApprovals,
              })}
              to={'/admin/users/awaitingApproval'}
              withBadge={awaitingApprovals > 0}
            />
          </Show>
        </DecoratedHeader>
      </Container>
      {children}
    </>
  );
};

export default Users;
