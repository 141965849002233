import { useFirestore } from 'reactfire';
import { useTranslation } from 'react-i18next';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  useCurrentUser,
  usePagination,
  useReservationFilters,
  useReservationHistory,
} from '../../hooks/';
import { ReservationTable } from '..';

import { ReservationStatus } from '../../enums';
import { reservationReportCsv, reservationReportPdf } from '../../reports';
import { Reservation } from '../../models';
import { ReservationHistoryTableProps } from './ReservationHistoryTable.props';

export const ReservationHistoryTable = ({
  canViewCompanyReservations = false,
  canViewAgencyReservations = false,
  filters,
  isAdmin = false,
  isAgent = false,
  mode,
}: ReservationHistoryTableProps) => {
  const firestore = useFirestore();
  const user = useCurrentUser();
  const { t, i18n } = useTranslation();
  const pagination = usePagination();
  const reservationFilters = useReservationFilters({
    canViewCompanyReservations,
    canViewAgencyReservations,
    filters,
    isAdmin,
    isAgent,
    mode,
  });
  const { list, loading } = useReservationHistory({
    filters: reservationFilters,
    pagination,
  });

  const generatePdfReport = () => {
    const q = query(
      collection(firestore, 'Reservations'),
      ...reservationFilters.map((queryFilter) =>
        where(queryFilter[0], queryFilter[1], queryFilter[2])
      ),
      where('status', 'in', [
        ReservationStatus.Cancelled,
        ReservationStatus.Expired,
        ReservationStatus.Purchased,
        ReservationStatus.UnknownExpired,
      ])
    );

    getDocs(q).then((res) => {
      reservationReportPdf.generate(
        res.docs.map((doc) => doc.data() as Reservation),
        filters,
        user,
        mode,
        t,
        i18n
      );
    });
  };

  const generateCsvReport = () => {
    const q = query(
      collection(firestore, 'Reservations'),
      ...reservationFilters.map((queryFilter) =>
        where(queryFilter[0], queryFilter[1], queryFilter[2])
      ),
      where('status', 'in', [
        ReservationStatus.Cancelled,
        ReservationStatus.Expired,
        ReservationStatus.Purchased,
        ReservationStatus.UnknownExpired,
      ])
    );

    getDocs(q).then((res) => {
      reservationReportCsv.generate(
        res.docs.map((doc) => doc.data() as Reservation),
        user.measurementUnits,
        t,
        true
      );
    });
  };

  return (
    <ReservationTable
      filters={filters}
      generatePdfReport={generatePdfReport}
      generateCsvReport={generateCsvReport}
      isAdmin={isAdmin}
      isAgent={isAgent}
      mode={mode}
      pagination={pagination}
      reservations={!loading ? list : undefined}
      queryFilters={reservationFilters}
    />
  );
};

export default ReservationHistoryTable;
