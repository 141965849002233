import { Box, Fab, Stack, styled } from '@mui/material';

const MIN_HEIGHT = 450;

export const StyledImageComponent = styled(Box)(() => {
  return {
    '& .delete-button': {
      opacity: 0,
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      width: '100%',
      color: 'white',
      background: 'rgba(0, 0, 0, 0.8)',
      ':hover': {
        background: 'rgba(0, 0, 0, 0.8)',
      },
    },
    '&:hover ': {
      '.delete-button': {
        opacity: 1,
        transition: '0.3s',
      },
    },
  };
});

export const StyledImageOrderCheckComponent = styled(Fab)(() => {
  return {
    background: 'white',
    position: 'absolute',
    top: 7,
    right: 7,
  };
});

export const StyledBulletinBoardContainer = styled(Box)(() => {
  return {
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    minHeight: MIN_HEIGHT,
    maxHeight: '800px',
    background: '#F9F9F9',
    overflow: 'scroll',
    padding: '20px',
  };
});

export const StyledEmptyGalleryContainer = styled(Stack)(() => {
  return {
    height: MIN_HEIGHT,
  };
});

export const StyledProgressIndicatorContainer = styled(Box)(() => {
  return {
    '& div': { minHeight: MIN_HEIGHT },
  };
});
