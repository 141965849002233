import { orderBy } from 'firebase/firestore';
import { Reel } from '../models';
import { CollectionQueryOptions } from '../types';
import { useCollection } from './useCollection';

export function useAvailableReels(options?: CollectionQueryOptions) {
  return useCollection<Reel>(
    'AvailableReels',
    options?.filters && options?.sorting?.sortField !== 'quantity'
      ? [
          orderBy(
            'quantity',
            options?.sorting?.sortDirection === 'desc' ? 'asc' : 'desc'
          ),
        ]
      : [],
    options?.sorting || { sortField: 'id', sortDirection: 'asc' },
    options?.pagination,
    options?.filters
  );
}
