import { useState } from 'react';

export function usePagination(defaultItemsPerPage = 25) {
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultItemsPerPage);
  const [startAt, setStartAt] = useState<unknown>();
  const [nextStartAt, setNextStartAt] = useState<unknown>();
  const [endAt, setEndAt] = useState<unknown>();
  const [nextEndAt, setNextEndAt] = useState<unknown>();
  const [atEnd, setAtEnd] = useState<boolean>();

  const previousPage = () => {
    setEndAt(nextEndAt);
    setStartAt(undefined);
    setNextStartAt(undefined);
    setNextEndAt(undefined);
    setAtEnd(undefined);
  };

  const nextPage = () => {
    setStartAt(nextStartAt);
    setNextStartAt(undefined);
    setEndAt(undefined);
    setNextEndAt(undefined);
    setAtEnd(undefined);
  };

  const returnToBeginning = () => {
    setStartAt(undefined);
    setNextStartAt(undefined);
    setEndAt(undefined);
    setNextEndAt(undefined);
    setAtEnd(undefined);
  };

  const goToEnd = () => {
    setAtEnd(true);
    setStartAt(undefined);
    setNextStartAt(undefined);
    setEndAt(undefined);
    setNextEndAt(undefined);
  };

  const nextPageAvailable = !!nextStartAt;
  const previousPageAvailable = !!nextEndAt || nextEndAt === 0;
  const returnToBeginningAvailable =
    !!startAt || !!nextEndAt || nextEndAt === 0;
  const goToEndAvailable = nextPageAvailable;

  return {
    atEnd,
    endAt,
    goToEnd,
    goToEndAvailable,
    itemsPerPage,
    nextEndAt,
    nextPage,
    nextPageAvailable,
    nextStartAt,
    previousPage,
    previousPageAvailable,
    returnToBeginning,
    returnToBeginningAvailable,
    setEndAt,
    setItemsPerPage,
    setNextEndAt,
    setNextStartAt,
    setStartAt,
    startAt,
  };
}
