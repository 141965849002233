import { DateTime } from 'luxon';

export const getDefaultReservationFilters = () => {
  return {
    companyId: null,
    partNumber: null,
    reservationNumber: null,
    showOnlyMyReservations: false,
    startDate: DateTime.now()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .minus({ month: 1 }),
    endDate: DateTime.now().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
  };
};
