import {
  Box,
  TableContainer,
  TableBody,
  TableHead,
  Typography,
  Button,
  Checkbox as MuiCheckbox,
  ListItemIcon,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OutboundIcon from '@mui/icons-material/Outbound';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { Timestamp } from 'firebase/firestore';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import JsZip from 'jszip';
import { saveAs } from 'file-saver';

import {
  Checkbox,
  CompanyClassLengthRow,
  Container,
  FormControlHeader,
  FormattedTimestamp,
  InventoryWarningMessageCard,
  NumericTextField,
  ProgressIndicator,
  Select,
  Show,
  TextField,
  TranslatedFields,
} from '../../components';
import { PortalSettings as PortalSettingsModel } from '../../models/PortalSettings';
import { StaticDatePickerField } from '../../components/StaticDatePickerField/StaticDatePickerField.component';
import {
  useForm,
  usePortalSettings,
  useNotification,
  usePortalMetadata,
  usePageTitle,
} from '../../hooks';
import { NeedsPermission, RequirePermission } from '../../security';
import {
  UserPermission,
  ProductType,
  NotificationLevel,
  SynchronizationStatus,
  InventoryWarningMessageColor,
} from '../../enums/';
import { getPageTitle } from '../../utils/pages';
import { StyledTable, StyledTableRow, StyledTableCell } from '../../tables';
import PortalSettings from './partials/PortalSettings';

const ReservationSettings: React.FC = () => {
  const { t } = useTranslation();
  usePageTitle(getPageTitle('portalSettings.inventoryReservations'));
  const { addNotification } = useNotification();
  const portalSettings = usePortalSettings();
  const { item: portalMetadata } = usePortalMetadata();
  const form = useForm(portalSettings);
  const functions = useFunctions();
  const syncOrdersCall = httpsCallable(functions, 'synchronizeOrders');

  const sxContainer = {
    width: '430px',
    margin: '0',
    padding: '0',
    '&.MuiContainer-root, .MuiContainer-root, .css-epd502': {
      padding: '0 !important',
      margin: '0 0 20px 0 !important',
    },
  };

  const disableDays = (date: any): boolean => {
    if (form.item?.weekDaysOff) {
      const day = date.weekday === 7 ? 0 : date.weekday;
      return form.item.weekDaysOff.includes(day);
    }

    return false;
  };

  const checked = (
    portalSettings: PortalSettingsModel | null,
    index: number
  ): boolean =>
    (portalSettings?.weekDaysOff &&
      portalSettings?.weekDaysOff.includes(index)) ||
    false;

  const toggleForm = (checked: boolean, index: number): void => {
    if (form.item) {
      const weekDaysOff = form.item['weekDaysOff'] || [];

      if (checked && !weekDaysOff.includes(index)) {
        weekDaysOff.push(index);
      } else if (!checked && weekDaysOff.includes(index)) {
        weekDaysOff.splice(weekDaysOff.indexOf(index), 1);
      }

      const updatedData = {
        ...form.item,
        weekDaysOff: weekDaysOff,
        weekDaysOffLength: weekDaysOff.length,
      };

      form.setItem(updatedData as PortalSettingsModel);
    }
  };

  const sameDay = (first: any, second: any) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const pushNewDay = (value: any): void => {
    if (form.item) {
      const dayList = form.item['daysOff'] || [];
      const day = new Date(value);
      const val = Timestamp.fromDate(day);

      if (!dayList.map((dl) => dl.toDate()).some((d) => sameDay(d, day))) {
        dayList.push(val);
      }

      const updatedData = {
        ...form.item,
        daysOff: dayList,
        daysOffLength: dayList.length,
      };

      form.setItem(updatedData as PortalSettingsModel);
    }
  };

  const onDeleteClick = (day: any): void => {
    if (form.item) {
      let dayList = form.item['daysOff'];

      dayList = dayList.filter((item) => item.seconds !== day.seconds);
      const updatedData = {
        ...form.item,
        daysOff: dayList,
        daysOffLength: dayList.length,
      };

      form.setItem(updatedData as PortalSettingsModel);
    }
  };

  const generateInventoryReport = httpsCallable(
    functions,
    'generateInventoryReport'
  );

  const onGenerateInventoryReport = () => {
    addNotification(
      t('portalSettings.generateReports.inProgress'),
      NotificationLevel.Information
    );
    generateInventoryReport()
      .then(() => {
        addNotification(
          t('portalSettings.generateReports.success'),
          NotificationLevel.Success
        );
      })
      .catch((_e) => {
        addNotification(
          t('portalSettings.generateReports.error'),
          NotificationLevel.Error
        );
      });
  };

  const listFileAndZip = async (listRef: any, zip: any) => {
    const blobArray: any[] = [];
    return listAll(listRef).then(async (res: any) => {
      for (const imgRef of res.items) {
        await getDownloadURL(imgRef).then((url: any) => {
          blobArray.push({
            name: imgRef['name'],
            blob: fetch(url).then((resp) => resp.blob()),
          });
        });
      }

      await Promise.all(blobArray);
      blobArray.forEach((blobObj, i) => {
        zip.file(blobObj['name'], blobObj['blob']);
      });
    });
  };

  const onDownloadInventoryReport = async () => {
    const storage = getStorage();
    const zip = JsZip();
    const csa = zip.folder('CSA');
    const ul = zip.folder('UL');
    const listRefCsa = ref(storage, 'reports/CSA');
    const listRefUl = ref(storage, 'reports/UL');

    listFileAndZip(listRefCsa, csa)
      .then(() => listFileAndZip(listRefUl, ul))
      .then(() => {
        zip.generateAsync({ type: 'blob' }).then((zipFile: any) => {
          const currentDate = new Date().getTime();
          const fileName = `reports-${currentDate}.zip`;
          return saveAs(zipFile, fileName);
        });
      });
  };

  const syncOrdersHandler = async () => {
    await syncOrdersCall()
      .then(() => {
        addNotification(
          t('portalSettings.syncOrders.success'),
          NotificationLevel.Success
        );
      })
      .catch(() => {
        addNotification(
          t('portalSettings.syncOrders.error'),
          NotificationLevel.Error
        );
      });
  };

  return (
    <RequirePermission
      oneOf={[
        UserPermission.ManagePortalSettings,
        UserPermission.ManageInventoryClasses,
      ]}
    >
      <PortalSettings>
        <FormControlHeader
          form={form}
          title={t('portalSettings.reservations.title')}
        />
        <Container>
          {portalSettings.loading || !portalSettings.item ? (
            <ProgressIndicator />
          ) : (
            <Box sx={{ m: 2 }}>
              <NeedsPermission oneOf={[UserPermission.ManagePortalSettings]}>
                <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                  {t('portalSettings.reservations.reservations')}
                </Typography>
                <Checkbox
                  form={form}
                  field="allowReservations"
                  label={t('portalSettings.fields.allowReservations.title')}
                />
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t(
                    'portalSettings.fields.minimumCutLengthRemainingCsa.title'
                  )}
                </Typography>
                <NumericTextField
                  form={form}
                  field="minimumCutLengthRemainingCsa"
                  label={t(
                    'portalSettings.fields.minimumCutLengthRemainingCsa.label'
                  )}
                  unitLabel={t('units.metric.length')}
                  defaultValue={0}
                  minimum={0}
                />
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.minimumCutLengthRemainingUl.title')}
                </Typography>
                <NumericTextField
                  form={form}
                  field="minimumCutLengthRemainingUl"
                  label={t(
                    'portalSettings.fields.minimumCutLengthRemainingUl.label'
                  )}
                  unitLabel={t('units.imperial.length')}
                  defaultValue={0}
                  minimum={0}
                />

                <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                  {t('portalSettings.fields.businessDays.title')}
                </Typography>
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.businessDays.subtitle')}
                </Typography>

                <Container sx={sxContainer}>
                  <MuiCheckbox
                    checked={checked(form.item, 1)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 1)}
                    sx={{ padding: '8px', marginLeft: '21px' }}
                  />
                  <MuiCheckbox
                    checked={checked(form.item, 2)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 2)}
                    sx={{ padding: '8px' }}
                  />
                  <MuiCheckbox
                    checked={checked(form.item, 3)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 3)}
                    sx={{ padding: '8px' }}
                  />
                  <MuiCheckbox
                    checked={checked(form.item, 4)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 4)}
                    sx={{ padding: '8px' }}
                  />
                  <MuiCheckbox
                    checked={checked(form.item, 5)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 5)}
                    sx={{ padding: '8px' }}
                  />
                  <MuiCheckbox
                    checked={checked(form.item, 6)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 6)}
                    sx={{ padding: '8px' }}
                  />
                  <MuiCheckbox
                    checked={checked(form.item, 0)}
                    onChange={(e) => toggleForm(e.currentTarget.checked, 0)}
                    sx={{ padding: '8px' }}
                  />
                  <StaticDatePickerField
                    field="endOrderProcessingHours"
                    form={form}
                    shouldDisableDate={disableDays}
                    onChange={pushNewDay}
                    selectedDays={form.item?.daysOff}
                  />
                </Container>
                <TableContainer sx={{ maxHeight: '221px' }}>
                  <StyledTable>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell width="90%">
                          {t('portalSettings.fields.dayOff.title')}
                        </StyledTableCell>
                        <StyledTableCell width="10%"></StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {form.item?.daysOff &&
                        form.item?.daysOff
                          .sort((d) => d.seconds)
                          .map((day, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                {day.toDate().toDateString()}
                              </StyledTableCell>
                              <StyledTableCell>
                                <ListItemIcon
                                  onClick={() => onDeleteClick(day)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
                <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                  {t('portalSettings.reservations.reservationPermission')}
                </Typography>
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.maxReservationsPerPeriod.title')}
                </Typography>
                <NumericTextField
                  form={form}
                  field="maxReservationsPerPeriod"
                  label={t(
                    'portalSettings.fields.maxReservationsPerPeriod.label'
                  )}
                  defaultValue={10}
                  minimum={1}
                  maximum={99}
                />
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.holdDelay.title')}
                </Typography>
                <NumericTextField
                  form={form}
                  field="holdDelay"
                  label={t('portalSettings.fields.holdDelay.label')}
                  unitLabel={t('common.units.hours')}
                  defaultValue={24}
                  minimum={2}
                  maximum={99}
                />
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.extensionNotificationDelay.title')}
                </Typography>
                <NumericTextField
                  form={form}
                  field="extensionNotificationDelay"
                  label={t(
                    'portalSettings.fields.extensionNotificationDelay.label'
                  )}
                  unitLabel={t('common.units.hours')}
                  defaultValue={2}
                  minimum={1}
                  maximum={Math.min(
                    9,
                    form.item?.holdDelay
                      ? Math.max(form.item.holdDelay - 1, 1)
                      : 9
                  )}
                />

                <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                  {t('portalSettings.reservations.inventory')}
                </Typography>

                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.defaultRefreshDelay.title')}
                </Typography>

                <NumericTextField
                  form={form}
                  field="defaultRefreshDelay"
                  label={t('portalSettings.fields.defaultRefreshDelay.label')}
                  unitLabel={t('common.units.minutes')}
                  defaultValue={10}
                  minimum={10}
                  maximum={1440}
                  step={10}
                />

                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.triggerInventoryReport.title')}
                </Typography>

                <Button
                  startIcon={<OutboundIcon />}
                  variant="contained"
                  onClick={() => onGenerateInventoryReport()}
                >
                  {t('portalSettings.fields.triggerInventoryReport.label')}
                </Button>
                <Button
                  startIcon={<CloudDownloadIcon />}
                  variant="contained"
                  onClick={() => onDownloadInventoryReport()}
                  sx={{ marginLeft: '20px' }}
                >
                  {t(
                    'portalSettings.fields.triggerInventoryReport.labelDownload'
                  )}
                </Button>

                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.fields.triggerOrderDetailsSync.title')}
                </Typography>
                <Button
                  startIcon={<SyncIcon />}
                  variant="contained"
                  disabled={
                    portalMetadata?.syncOrdersStatus ===
                    SynchronizationStatus.Progress
                  }
                  onClick={syncOrdersHandler}
                >
                  {t('portalSettings.fields.triggerOrderDetailsSync.labelSync')}
                </Button>
                <Show if={portalMetadata?.syncOrdersDate !== undefined}>
                  <Typography variant="subtitle1" sx={{ mt: 3, mb: 3 }}>
                    {t(
                      'portalSettings.fields.triggerOrderDetailsSync.labelLastSync'
                    )}
                    <FormattedTimestamp
                      timestamp={portalMetadata?.syncOrdersDate}
                    />
                    {` (${t(
                      `portalSettings.syncOrders.status.${portalMetadata?.syncOrdersStatus}`
                    )})`}
                  </Typography>
                </Show>
                <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                  {t('portalSettings.inventoryWarningMessage.title')}
                </Typography>
                <Checkbox
                  form={form}
                  field="inventoryWarningMessageShow"
                  label={t(
                    'portalSettings.inventoryWarningMessage.showCheckboxLabel'
                  )}
                />
                <Grid sx={{ width: '10rem' }}>
                  <Select
                    form={form}
                    disabled={!form.item?.inventoryWarningMessageShow}
                    field="inventoryWarningMessageColor"
                    label={t(
                      'portalSettings.inventoryWarningMessage.colorLabel'
                    )}
                    options={Object.values(InventoryWarningMessageColor)}
                    getOptionLabel={(color: InventoryWarningMessageColor) =>
                      t(`portalSettings.inventoryWarningMessage.color.${color}`)
                    }
                  />
                </Grid>
                <TranslatedFields
                  icon={<HelpOutlinedIcon color="secondary" fontSize="small" />}
                  tooltipText={t(
                    'portalSettings.inventoryWarningMessage.headerTooltip'
                  )}
                  form={form}
                  field="inventoryWarningMessageHeading"
                  label={t(
                    'portalSettings.inventoryWarningMessage.headerLabel'
                  )}
                  render={(options) => (
                    <TextField
                      multiline // Set to avoid jumping cursor
                      maxLength={255}
                      disabled={!form.item?.inventoryWarningMessageShow}
                      {...options}
                    />
                  )}
                />
                <TranslatedFields
                  icon={<HelpOutlinedIcon color="secondary" fontSize="small" />}
                  tooltipText={t(
                    'portalSettings.inventoryWarningMessage.bodyTooltip'
                  )}
                  form={form}
                  field="inventoryWarningMessageBody"
                  label={t('portalSettings.inventoryWarningMessage.bodyLabel')}
                  render={(options) => (
                    <TextField
                      multiline
                      maxLength={1000}
                      minRows={3}
                      disabled={!form.item?.inventoryWarningMessageShow}
                      {...options}
                    />
                  )}
                />
                <Show if={!!form.item?.inventoryWarningMessageShow}>
                  <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                    {t('portalSettings.inventoryWarningMessage.previewTitle')}
                  </Typography>
                  {form.item && (
                    <InventoryWarningMessageCard
                      header={form.item?.inventoryWarningMessageHeading}
                      body={form.item?.inventoryWarningMessageBody}
                      color={form.item?.inventoryWarningMessageColor}
                    />
                  )}
                </Show>
              </NeedsPermission>
              <NeedsPermission oneOf={[UserPermission.ManageInventoryClasses]}>
                <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                  {t('portalSettings.companyClassLengths.title')}
                </Typography>
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  {t('portalSettings.companyClassLengths.subtitle')}
                </Typography>

                <TableContainer>
                  <StyledTable>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell width="25%">
                          {t(
                            'portalSettings.companyClassLengths.fields.productType'
                          )}
                        </StyledTableCell>
                        <StyledTableCell width="25%">
                          {t(
                            'portalSettings.companyClassLengths.fields.classA'
                          )}
                        </StyledTableCell>
                        <StyledTableCell width="25%">
                          {t(
                            'portalSettings.companyClassLengths.fields.classB'
                          )}
                        </StyledTableCell>
                        <StyledTableCell width="25%">
                          {t(
                            'portalSettings.companyClassLengths.fields.classC'
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(ProductType)
                        .filter((value) => !isNaN(Number(value)))
                        .map((productType) => (
                          <CompanyClassLengthRow
                            key={productType}
                            form={form}
                            field="companyClassLengths"
                            productType={productType as ProductType}
                          />
                        ))}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </NeedsPermission>
            </Box>
          )}
        </Container>
      </PortalSettings>
    </RequirePermission>
  );
};

export default ReservationSettings;
