import { DateTime } from 'luxon';

export const formatDateSpan = (
  language = 'en',
  value: DateTime,
  short = false
) => {
  const localizedValue = value.setLocale(language.toLowerCase() + '-CA');
  const valuePlusOneWeek = localizedValue.plus({ days: 6 });

  const startDay = localizedValue.day;
  const startMonth = short
    ? localizedValue.monthShort
    : localizedValue.monthLong;
  const endDay = valuePlusOneWeek.day;
  const endMonth = short
    ? valuePlusOneWeek.monthShort
    : valuePlusOneWeek.monthLong;
  const year = valuePlusOneWeek.year;
  const daySpan = `${localizedValue.day}-${valuePlusOneWeek.day}`;

  if (localizedValue.month !== valuePlusOneWeek.month) {
    if (language.toLowerCase() === 'fr') {
      return `${startDay} ${startMonth} - ${endDay} ${endMonth}, ${valuePlusOneWeek.year}`;
    }

    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`;
  }

  if (language.toLowerCase() === 'fr') {
    return `${daySpan} ${endMonth}, ${year}`;
  }

  return `${endMonth} ${daySpan}, ${year}`;
};
