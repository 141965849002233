import { ReelSize } from '../../models';

export const getLinearFeet = (quantity: number, reel: ReelSize): number =>
  round(
    (quantity / reel.quantityFactor / reel.orderMultiple) *
      reel.linearFtCoefficient
  );

export const round = (value: number, decimals = 2): number =>
  +(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
