import { useCallback, useEffect, useMemo } from 'react';

import Formable from '../types/Formable';

/**
 * Creates a form based on an object in the item of an existing form,
 * allowing access to nested fields in form item.
 *
 * @param form - Parent form
 * @param field - Field on parent form item that contains object that
 *                should be edited
 * @param defaultItem - Default item that should be used if item does
 *                      contain an object for the given field
 */
export function useObjectSubform<T, K extends string | number | symbol, V>(
  form: Formable<T>,
  field: keyof T,
  defaultItem: V
) {
  // Get item from parent form
  const item = useMemo(() => form.item?.[field] as unknown as V, [form, field]);

  // Set item in parent form
  const setItem = useCallback(
    (newItem: V, dirty = true) => {
      if (JSON.stringify(newItem) !== JSON.stringify(item)) {
        const newValues = {} as Partial<T>;
        newValues[field] = newItem as unknown as Extract<K, T[keyof T]>;
        form.setItem({ ...form.item, ...(newValues as T) }, dirty);
      }
    },
    [field, form, item]
  );

  // Set default value if no value received
  useEffect(() => {
    if (form.item && !item) {
      // Make sure the form is not dirtied when setting the default value
      setItem(defaultItem, false);
    }
  }, [form.item, item, defaultItem, setItem]);

  // Copy errors from parent form into new object with parsed key paths, i.e.
  // { 'field.key': error } becomes { 'key': error }
  const errors = useMemo(() => {
    // Allow any here, as transforming invalid key (key paths) from error
    // object into new object:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newErrors = {} as any;
    for (const [key, value] of Object.entries(form.errors)) {
      const splitKey = key.split('.');
      const baseKey = splitKey.shift();
      if (baseKey === field) {
        newErrors[splitKey.join('.')] = value;
      }
    }
    return newErrors as Record<keyof V, string>;
  }, [field, form.errors]);

  return { ...form, item, setItem, errors };
}
