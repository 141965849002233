import { DateTime } from 'luxon';
import { i18n } from 'i18next';
import { TFunction } from 'react-i18next';

import report_logo from '../../assets/report_logo.png';
import reservation_icon from '../../assets/ReservationIcon.png';
import { TimeZone } from '../../enums';
import { ReservationLog } from '../../models';
import { Nullable } from '../../types';
import formatReservationNumber from '../../utils/formatReservationNumber';
import report, { MARGIN, PDF_CONFIG } from './report';

class reservationLogsPdf extends report {
  private readonly reservationLogs: ReservationLog[];
  private readonly fromDate: Nullable<DateTime>;
  private readonly toDate: Nullable<DateTime>;
  private readonly DEFAULT_ROW_HEIGHT = 21;

  constructor(
    reservationLogs: ReservationLog[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super('reports.reservationLogs', t, i18n);

    this.reservationLogs = reservationLogs;
    this.fromDate = fromDate?.setLocale('en') ?? null;
    this.toDate = toDate?.setLocale('en') ?? null;
  }

  protected getFilename(): string {
    const from = this.fromDate?.toFormat('LLddyyyy');
    const to = this.toDate?.toFormat('LLddyyyy');
    return `NC_ReservationActivityLog_${from}_${to}.pdf`;
  }

  protected buildHeader(): void {
    this.outputImage(report_logo, MARGIN.X, MARGIN.Y);
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(reservation_icon, MARGIN.X, this.curHeight, 10);

    const formattedTitle = this.t('reports.reservationLogs.title');
    this.outputH1(formattedTitle, this.curWidth + 15, this.curHeight + 7);

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 2;

    const formattedFilters = this.t('reports.userActivity.dateFilter', {
      from: this.fromDate?.toLocaleString(),
      to: this.toDate?.toLocaleString(),
    });
    this.outputH3(formattedFilters, this.curWidth, this.curHeight + 7);

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 5;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;

    this.reservationLogs.forEach((value, index) => this.buildRow(value, index));
  }

  private buildRow(log: ReservationLog, index: number): void {
    const {
      companyName,
      date,
      endComment,
      endStatus,
      initialComment,
      initialStatus,
      madeByName,
      madeForName,
      reelNumber,
      reservationNumber,
    } = log;

    const formattedNumber = formatReservationNumber(reservationNumber);

    const formattedDate = DateTime.fromMillis(date.toMillis())
      .setLocale('en')
      .setZone(TimeZone.EasternTime)
      .toLocaleString(DateTime.DATETIME_MED);

    const formattedComments =
      (initialComment ?? 'N/A') +
      (initialComment !== endComment ? ` -> ${endComment}` : '');
    const formattedStatus = `${
      initialStatus
        ? this.t(`reservations.shortStatuses.${initialStatus}`)
        : 'N/A'
    } -> ${
      endStatus ? this.t(`reservations.shortStatuses.${endStatus}`) : 'N/A'
    }`;

    this.addDataColumn('reservationNumber', formattedNumber, MARGIN.X);
    this.addDataColumn('eventDate', formattedDate, MARGIN.X + 30);
    this.addDataColumn('byName', madeByName, MARGIN.X + 65, 25);
    this.addDataColumn('forName', madeForName, MARGIN.X + 95, 25);
    this.addDataColumn('companyName', companyName, MARGIN.X + 125, 40);

    this.curHeight += 12;

    this.addDataColumn('reelNumber', reelNumber, MARGIN.X);
    this.addDataColumn('comments', formattedComments, MARGIN.X + 30, 60, true);
    this.addDataColumn('status', formattedStatus, MARGIN.X + 95);

    this.curHeight += formattedComments.search('\n') ? 12 : 8;

    this.drawLine(0.2);

    this.curHeight += 6;

    const count = this.reservationLogs.length;
    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, count);
  }

  static generate = (
    reservationLogs: ReservationLog[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) => {
    const report = new reservationLogsPdf(
      reservationLogs,
      fromDate,
      toDate,
      t,
      i18n
    );

    report.generate();
  };
}

export default reservationLogsPdf;
