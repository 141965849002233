import { Avatar, Grid, Paper, Skeleton, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

import { Show } from '..';

import { DashboardCardProps } from './DashboardCard.props';
import * as Sx from './DashboardCard.styles';

export const DashboardCard = ({
  icon,
  innerMessage,
  link,
  linkText,
  message,
  title,
}: DashboardCardProps) => (
  <Paper elevation={1} sx={Sx.paper}>
    <Grid sx={Sx.grid}>
      <Typography variant="h4" sx={Sx.multiligne}>
        {title}
      </Typography>

      <Grid display="flex" alignItems="center">
        <Avatar sx={Sx.avatar}>{icon}</Avatar>

        <Grid sx={{ ml: 2 }}>
          <Typography sx={Sx.message}>
            {message ? message : <Skeleton width="60px" variant="text" />}
          </Typography>

          <Show if={!!innerMessage}>
            <Typography sx={Sx.innerMessage}>{innerMessage}</Typography>
          </Show>
        </Grid>
      </Grid>

      <Link to={link} style={Sx.link}>
        <Grid display="flex" alignItems="center">
          <Typography variant="caption" mr={1}>
            {linkText}
          </Typography>
          <ArrowForwardIcon sx={Sx.arrowIcon} fontSize="small" />
        </Grid>
      </Link>
    </Grid>
  </Paper>
);
