import {
  Box,
  Checkbox,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  FormControlHeader,
  ProgressIndicator,
} from '../../components';
import { UserPermission, UserRole } from '../../enums';
import PortalSettingsModel from '../../models/PortalSettings';
import { Permissions, PermissionGroup } from '../../models/Permission';
import RequirePermission from '../../security/RequirePermission';
import { useForm, usePageTitle, usePortalSettings } from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import PortalSettings from './partials/PortalSettings';

const PermissionsSettings: React.FC = () => {
  const { t } = useTranslation();
  usePageTitle(getPageTitle('portalSettings.rolesPermissions'));
  const settings = usePortalSettings();
  const form = useForm<PortalSettingsModel>(settings);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const permissionChanged = (
    checked: boolean,
    permissionGroup: PermissionGroup,
    permission: string,
    role: UserRole
  ) => {
    if (form.item) {
      const updatedData = {
        ...form.item,
        [permissionGroup]: {
          ...form.item[permissionGroup],
          [permission]: {
            ...form.item[permissionGroup][permission as UserPermission],
            [role]: checked,
          },
        },
      };

      form.setItem(updatedData as PortalSettingsModel);
    }
  };

  const renderHeaderCell = (permission: string) => (
    <TableCell key={permission}>{t(`users.roles.${permission}`)}</TableCell>
  );

  const checked = (
    permission: UserPermission,
    role: UserRole,
    group: PermissionGroup
  ): boolean =>
    (form.item &&
      form.item[group] &&
      form.item[group][permission] &&
      form.item[group][permission][role]) ||
    false;

  const renderPermissionCell = (
    permission: UserPermission,
    role: UserRole,
    group: PermissionGroup
  ) => (
    <TableCell
      width={group === PermissionGroup.AgencyUsers ? '30%' : '20%'}
      key={permission + role}
      sx={{ pl: 1 }}
    >
      <Checkbox
        disabled={(role as UserRole) === UserRole.NciAdmin}
        checked={checked(permission, role, group)}
        onChange={(e) =>
          permissionChanged(e.currentTarget.checked, group, permission, role)
        }
      />
    </TableCell>
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const permissionGroups = Permissions.map((permission) => permission.group);

  const renderTabs = () => (
    <Box>
      <Box>
        <Tabs value={selectedTab} onChange={handleChange}>
          {permissionGroups.map((group) => (
            <Tab
              key={group}
              style={{
                fontWeight: '700',
                padding: '0 10px',
              }}
              label={t(`portalSettings.permissions.${group}`)}
            />
          ))}
        </Tabs>
      </Box>
      {renderPermissionTables(selectedTab)}
    </Box>
  );

  const renderPermissionTables = (showIndex: number) =>
    Permissions.map(({ group, roles, subGroups }, index) => {
      if (showIndex === index) {
        return (
          <Box sx={{ marginBottom: 10 }} key={group}>
            <Typography variant="h3" sx={{ mt: 5, mb: 4 }}>
              {t(`portalSettings.permissions.${group}`)}
            </Typography>

            {subGroups.map((subGroup) => (
              <React.Fragment key={subGroup.section}>
                {subGroup.section !== 'default' && (
                  <Typography
                    variant="h4"
                    sx={{
                      color: 'primary.main',
                      fontSize: '1.25rem',
                      fontWeight: 500,
                      mt: 7,
                    }}
                  >
                    {t(`portalSettings.permissions.${subGroup.section}`)}
                  </Typography>
                )}

                <TableContainer>
                  <Table>
                    {subGroup.section === 'default' && (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t('portalSettings.permissions.permissions')}
                          </TableCell>
                          {roles.map((role) => renderHeaderCell(role))}
                        </TableRow>
                      </TableHead>
                    )}

                    <TableBody>
                      {subGroup.permissions.map((permission) => (
                        <TableRow key={permission}>
                          <TableCell sx={{ pl: 0, fontWeight: 500 }}>
                            {t(`portalSettings.permissions.${permission}`)}
                          </TableCell>
                          {roles.map((role) =>
                            renderPermissionCell(permission, role, group)
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            ))}
          </Box>
        );
      } else return null;
    });

  return (
    <RequirePermission oneOf={[UserPermission.ManageRolesAndPermissions]}>
      <PortalSettings>
        <FormControlHeader
          form={form}
          title={t('portalSettings.permissions.title')}
        />
        <Container>
          {settings.loading || !form.item ? (
            <ProgressIndicator />
          ) : (
            <Box sx={{ m: 2 }}>{renderTabs()}</Box>
          )}
        </Container>
      </PortalSettings>
    </RequirePermission>
  );
};

export default PermissionsSettings;
