import { SxProps } from '@mui/material';
import { ReelReturnStatus } from '../../enums';

const statusBackgroundColors: { [key in ReelReturnStatus]: string } = {
  [ReelReturnStatus.Cancelled]: '#f6cccd',
  [ReelReturnStatus.Draft]: '#e6e8e7',
  [ReelReturnStatus.Generated]: '#c6e2f7',
  [ReelReturnStatus.Received]: '#d0f4d8',
  [ReelReturnStatus.Unsubmitted]: '#feebcb',
};
const statusColors: { [key in ReelReturnStatus]: string } = {
  [ReelReturnStatus.Cancelled]: '#7e211a',
  [ReelReturnStatus.Draft]: '#4c524e',
  [ReelReturnStatus.Generated]: '#314364',
  [ReelReturnStatus.Received]: '#30533d',
  [ReelReturnStatus.Unsubmitted]: '#744210',
};

export const statusBadge = (status: ReelReturnStatus | null): SxProps => {
  return {
    wordBreak: 'break-word',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '2px',
    display: 'inline',
    backgroundColor: (!!status && statusBackgroundColors[status]) || '#ffffff',
    color: (!!status && statusColors[status]) || '#000000',
  };
};
