import { SxProps, alpha } from '@mui/material';
import theme from '../../theme';

export const tableBodyStyles: SxProps = {
  '&.MuiTableBody-root': {
    '& .MuiTableRow-root': {
      borderLeft: 'solid',
      borderLeftWidth: 5,
      borderLeftColor: 'transparent',

      '&.selected-item': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.15),
        borderLeftColor: 'secondary.main',
        borderBottomLeftRadius: '0.75rem',
        borderTopLeftRadius: '0.75rem',
      },

      '&.disabled-item': {
        backgroundColor: alpha('#979797', 0.5),
        opacity: 0.6,
        cursor: 'default',
      },
    },
  },
};

export const tableStyles: SxProps = {
  '&.MuiTableContainer-root': {
    '& .MuiTable-root': {
      borderCollapse: 'collapse',
    },
  },
};
