import { SxProps } from '@mui/material';

export const avatar: SxProps = {
  border: 2,
  borderColor: 'secondary.light',
  backgroundColor: 'secondary.main',
  width: '60px',
  height: '60px',
};

export const background: SxProps = {
  alignItems: 'stretch',
  background: 'linear-gradient(to bottom, #e68008 50%, #F9F9F9 125px 50%)',
  display: 'flex',
  flexGrow: 1,
  paddingTop: '45px',
};

export const button: SxProps = {
  width: '11rem',
};

export const buttonMR: SxProps = {
  ...button,
  mr: 2,
};

export const container: SxProps = {
  maxWidth: '1500px',
};

export const grid = (spaceBetween: boolean): SxProps => {
  return {
    display: 'flex',
    gap: '1em',
    justifyContent: spaceBetween ? 'space-between' : 'left',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: { sm: '100%', md: 'calc(100% - 4rem)' },
  };
};

export const icon: SxProps = {
  fontSize: '50px',
};

export const innerContainer: SxProps = {
  height: '100%',
  width: '100%',
  minHeight: '575px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: '20px',
  position: 'relative',
};
