/* eslint-disable max-len */
interface Props {
  color?: string;
}

const TruckIcon: React.FC<Props> = ({ color = 'white' }) => (
  <svg
    width="30"
    height="22"
    viewBox="0 0 30 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66658 0H13.3333V12H29.3333V17.3333H26.6666C26.6666 18.44 26.2666 19.3733 25.4799 20.1733C24.6666 20.96 23.7466 21.3333 22.6666 21.3333C21.5866 21.3333 20.6666 20.96 19.8533 20.1733C19.0666 19.3733 18.6666 18.44 18.6666 17.3333H11.3333C11.3333 18.44 10.9599 19.3733 10.1599 20.1733C9.37325 20.96 8.43992 21.3333 7.33325 21.3333C6.26658 21.3333 5.33325 20.96 4.54659 20.1733C3.74658 19.3733 3.33325 18.44 3.33325 17.3333H-8.2016e-05V8L6.66658 0ZM21.2266 18.76C21.5999 19.16 22.0799 19.3333 22.6666 19.3333C23.2533 19.3333 23.7333 19.16 24.1066 18.76C24.4799 18.36 24.6666 17.8933 24.6666 17.3333C24.6666 16.8133 24.4799 16.3467 24.1066 15.9467C23.7333 15.5467 23.2533 15.3333 22.6666 15.3333C22.0799 15.3333 21.5999 15.5467 21.2266 15.9467C20.8533 16.3467 20.6666 16.8133 20.6666 17.3333C20.6666 17.8933 20.8533 18.36 21.2266 18.76ZM5.94658 18.76C6.34658 19.16 6.81325 19.3333 7.33325 19.3333C7.89325 19.3333 8.35992 19.16 8.75992 18.76C9.15992 18.36 9.33325 17.8933 9.33325 17.3333C9.33325 16.8133 9.15992 16.3467 8.75992 15.9467C8.35992 15.5467 7.89325 15.3333 7.33325 15.3333C6.81325 15.3333 6.34658 15.5467 5.94658 15.9467C5.54658 16.3467 5.33325 16.8133 5.33325 17.3333C5.33325 17.8933 5.54658 18.36 5.94658 18.76ZM10.6666 8V2.66667H7.91992L3.47992 8H10.6666Z"
      fill={color}
    />
  </svg>
);

export default TruckIcon;
