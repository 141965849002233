import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

import { DecoratedHeader } from '../components';

const ForgotPasswordConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid>
      <DecoratedHeader
        icon={<QuestionMarkRoundedIcon titleAccess={t('alt.questionMark')} />}
        title={t('users.actions.forgotPassword')}
      />
      <Typography>{t('users.confirmations.passwordResetEmailSent')}</Typography>

      <Grid mt={5}>
        <Button variant="outlined" onClick={() => navigate('/login')}>
          {t('signUp.confirmation.goBack')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordConfirmation;
