import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../../assets/Logo';
import SmallLogo from '../../assets/SmallLogo';
import MobileLogo from '../../assets/MobileLogo';
import { useAuthentication } from '../../hooks';
import { AppBarBrandingProps } from './AppBarBranding.props';

export const AppBarBranding = ({
  hideApplicationName,
}: AppBarBrandingProps) => {
  const { t } = useTranslation();
  const { isAgent } = useAuthentication();

  return (
    <Link to="/" title="Back to Inventory" style={{ textDecoration: 'none' }}>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
        {hideApplicationName ? <Logo /> : <SmallLogo />}
        {!hideApplicationName && (
          <Typography
            color="secondary"
            component="div"
            sx={{
              ml: 4,
              mr: 1,
              lineHeight: 1,
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
          >
            {isAgent ? (
              t('application.title.agent')
            ) : (
              <>
                {t('application.title.line1')}
                <br />
                {t('application.title.line2')}
              </>
            )}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
        <MobileLogo />
      </Box>
    </Link>
  );
};
