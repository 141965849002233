import {
  Autocomplete,
  Grid,
  Switch,
  TextField,
  Typography,
  InputAdornment,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useProductTypes } from '../../hooks';
import {
  Filter,
  InventoryFilters as InventoryFiltersType,
} from '../../types/InventoryFilters';
import { Material } from '../../enums';
import { Nullable } from '../../types';

import { FilterContainer } from '..';

import * as Sx from './InventoryFilters.styles';
import { CONDUCTOR_GAUGES } from './InventoryFilters.constants';
import { emptyFilters, PageParameters } from './InventoryFilters.interface';
import { InventoryFiltersProps } from './InventoryFilters.props';

export const InventoryFilters = ({
  onSearch,
  filters = emptyFilters,
  units,
  setUnits,
}: InventoryFiltersProps) => {
  const { t, i18n } = useTranslation();
  const { type } = useParams<PageParameters>();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [loadedFilters, setLoadedFilters] =
    useState<Nullable<InventoryFiltersType>>(filters);
  const [inventoryFilters, setInventoryFilters] =
    useState<InventoryFiltersType>(filters);

  useEffect(() => {
    setInventoryFilters(filters);
    setLoadedFilters(filters);
  }, [filters]);

  const setFilter = (
    filter: Filter,
    value: string | boolean | number | null
  ) => {
    setLoadedFilters(null);
    setInventoryFilters((prev) => {
      return { ...prev, [filter]: value };
    });
  };

  const {
    productType,
    productSubType,
    partNumber,
    material,
    voltage,
    numberOfConductors,
    gauge,
    searchByCable,
  } = inventoryFilters;

  const { item, loading } = useProductTypes();

  const selectedMaterial = useMemo(() => {
    if (!material || !productType || !item) return null;

    return item.types[productType].materials[material];
  }, [material, productType, item]);

  const selectedVoltage = useMemo(() => {
    if (!material || !productType || !item || !voltage) return null;

    return item.types[productType].materials[material]?.voltages[voltage];
  }, [item, material, productType, voltage]);

  const selectedNumberOfConductors = useMemo(() => {
    if (!material || !productType || !item || !voltage || !numberOfConductors)
      return null;

    return item.types[productType].materials[material]?.voltages[voltage]
      ?.numberOfConductors[numberOfConductors];
  }, [item, material, productType, voltage, numberOfConductors]);

  const productTypeOptions = useMemo(() => {
    if (loading || !item || !type) return [];
    const productTypes = Object.entries(item.types)
      .map(([key, value]) => {
        value.id = parseInt(key);
        return value;
      })
      .filter((r) => r && r[type])
      .sort((a, b) => a.name.localeCompare(b.name));

    return productTypes.map((p) => p.id.toString());
  }, [loading, type, item]);

  const productSubTypeOptions = useMemo<string[]>(() => {
    if (
      !item ||
      !productType ||
      !item.types[productType].subTypes ||
      item.types[productType].subTypes.length === 0
    )
      return [];
    return item.types[productType].subTypes.map((s) => s.toString());
  }, [productType, item]);

  const materialOptions = useMemo(() => {
    if (!productType || !item) return [];

    return Object.keys(item.types[productType].materials);
  }, [productType, item]);

  const voltageOptions = useMemo(() => {
    const options = Object.keys(selectedMaterial?.voltages ?? []);
    if (options.length === 0) {
      return ['N/A'];
    }

    return options;
  }, [selectedMaterial]);

  const numberOfConductorOptions = useMemo(
    () =>
      selectedVoltage ? Object.keys(selectedVoltage.numberOfConductors) : [],
    [selectedVoltage]
  );

  const gaugeOptions = useMemo(() => {
    const minMaxGaugesSource =
      selectedVoltage && !selectedNumberOfConductors
        ? selectedVoltage
        : selectedNumberOfConductors;
    return minMaxGaugesSource
      ? CONDUCTOR_GAUGES.slice(
          CONDUCTOR_GAUGES.indexOf(minMaxGaugesSource.minGauge),
          CONDUCTOR_GAUGES.indexOf(minMaxGaugesSource.maxGauge) + 1
        )
      : [];
  }, [selectedVoltage, selectedNumberOfConductors]);

  const getValue = (
    value: Nullable<string | number | undefined>,
    options: string[],
    raw = false
  ): Nullable<string | number> => {
    if (value && options.includes(value.toString())) {
      return raw
        ? value
        : value === 'N/A'
        ? t('inventory.filters.nonApplicable')
        : value.toString();
    }

    return null;
  };

  useEffect(() => {
    setInventoryFilters((prev) => {
      return {
        ...prev,
        material:
          materialOptions.length === 1
            ? (parseInt(materialOptions[0]) as Material)
            : (getValue(
                material ?? loadedFilters?.material,
                materialOptions,
                true
              ) as Material),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialOptions]);

  useEffect(() => {
    setInventoryFilters((prev) => {
      return {
        ...prev,
        voltage:
          voltageOptions.length === 1
            ? voltageOptions[0] !== 'N/A'
              ? parseInt(voltageOptions[0])
              : 'N/A'
            : getValue(voltage ?? loadedFilters?.voltage, voltageOptions, true),
        numberOfConductors:
          numberOfConductorOptions.length === 1
            ? numberOfConductorOptions[0]
            : getValue(
                numberOfConductors ?? loadedFilters?.numberOfConductors,
                numberOfConductorOptions,
                true
              ),
        gauge:
          gaugeOptions.length === 1
            ? gaugeOptions[0]
            : getValue(gauge ?? loadedFilters?.gauge, gaugeOptions, true),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMaterial, voltage, numberOfConductors]);

  const clearFilters = (searchByCable: Nullable<boolean> = null) => {
    onSearch({
      ...emptyFilters,
      searchByCable: searchByCable ?? inventoryFilters.searchByCable,
    });
  };

  const searchDisabled = () => {
    if (searchByCable) {
      return (
        !productType ||
        !material ||
        (!voltage &&
          selectedMaterial &&
          (Object.keys(selectedMaterial.voltages).length ?? 0) > 0)
      );
    }

    return partNumber?.length !== 6;
  };

  const switchLabel = (text: keyof InventoryFiltersType, enabled: boolean) => (
    <Typography variant="h4" sx={Sx.switchLabel(i18n, enabled)}>
      <label htmlFor="searchByCable">{text}</label>
    </Typography>
  );

  const switches = (
    <>
      {switchLabel(
        t(
          isMobile
            ? 'inventory.filters.partNumber'
            : 'inventory.filters.searchByPartNumber'
        ),
        !searchByCable
      )}
      <Switch
        id="searchByCable"
        checked={searchByCable}
        onChange={(e) => {
          clearFilters(e.currentTarget.checked);
        }}
      />
      {switchLabel(
        t(
          isMobile
            ? 'inventory.filters.cables'
            : 'inventory.filters.searchByCable'
        ),
        searchByCable
      )}
    </>
  );

  return (
    <FilterContainer
      headerContents={switches}
      title={type?.toUpperCase()}
      onSearch={searchDisabled() ? null : () => onSearch(inventoryFilters)}
      onClear={clearFilters}
    >
      <Grid sx={Sx.mainContainer}>
        {searchByCable ? (
          <Grid mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={Sx.fieldContainer}>
                <Autocomplete
                  fullWidth={true}
                  popupIcon={<KeyboardArrowDownIcon />}
                  getOptionLabel={(value) => t(`productTypes.${value}`)}
                  options={productTypeOptions}
                  value={getValue(productType, productTypeOptions) as string}
                  onChange={(_, value) => {
                    setFilter('productType', value && parseInt(value));
                    setFilter('productSubType', null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      label={t('inventory.filters.productType')}
                      {...(isMobile && {
                        inputProps: { ...params.inputProps, readOnly: true },
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={Sx.fieldContainer}>
                <Autocomplete
                  fullWidth={true}
                  popupIcon={<KeyboardArrowDownIcon />}
                  getOptionLabel={(value) => t(`productSubTypes.${value}`)}
                  options={productSubTypeOptions}
                  value={
                    getValue(productSubType, productSubTypeOptions) as string
                  }
                  onChange={(_, value) => {
                    setFilter('productSubType', value && parseInt(value));
                  }}
                  disabled={productSubTypeOptions.length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('inventory.filters.subType')}
                      {...(isMobile && {
                        inputProps: { ...params.inputProps, readOnly: true },
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={Sx.fieldContainer}>
                <Autocomplete
                  fullWidth={true}
                  popupIcon={<KeyboardArrowDownIcon />}
                  getOptionLabel={(value) => t(`materials.${value}`)}
                  options={materialOptions}
                  value={getValue(material, materialOptions) as string}
                  onChange={(_, value) =>
                    setFilter('material', value && parseInt(value))
                  }
                  disabled={!productType || materialOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      label={t('inventory.filters.conductorMaterial')}
                      {...(isMobile && {
                        inputProps: { ...params.inputProps, readOnly: true },
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={Sx.fieldContainer}>
                <Autocomplete
                  fullWidth={true}
                  popupIcon={<KeyboardArrowDownIcon />}
                  options={voltageOptions}
                  value={getValue(voltage, voltageOptions) as string}
                  onChange={(_, value) =>
                    setFilter('voltage', value && parseInt(value))
                  }
                  disabled={!material || (voltageOptions.length ?? 0) <= 1}
                  renderInput={(params) =>
                    (getValue(voltage, voltageOptions) as string) !== 'N/A' ? (
                      <TextField
                        {...params}
                        required={true}
                        label={t('inventory.filters.voltage')}
                        {...(isMobile && {
                          inputProps: { ...params.inputProps, readOnly: true },
                        })}
                      />
                    ) : (
                      <TextField
                        {...params}
                        required={true}
                        label={t('inventory.filters.voltage')}
                        {...(isMobile && {
                          inputProps: { ...params.inputProps, readOnly: true },
                        })}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={6} sx={Sx.fieldContainer}>
                <Autocomplete
                  fullWidth={true}
                  popupIcon={<KeyboardArrowDownIcon />}
                  options={numberOfConductorOptions}
                  value={
                    getValue(
                      numberOfConductors,
                      numberOfConductorOptions
                    ) as string
                  }
                  onChange={(_, value) =>
                    setFilter('numberOfConductors', value && parseInt(value))
                  }
                  disabled={
                    !material ||
                    !voltage ||
                    numberOfConductorOptions.length === 1
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('inventory.filters.numberOfConductor')}
                      {...(isMobile && {
                        inputProps: { ...params.inputProps, readOnly: true },
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={Sx.fieldContainer}>
                <Autocomplete
                  fullWidth={true}
                  popupIcon={<KeyboardArrowDownIcon />}
                  options={gaugeOptions}
                  value={getValue(gauge, gaugeOptions) as string}
                  onChange={(_, value) => setFilter('gauge', value)}
                  disabled={!material || !voltage || gaugeOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('inventory.filters.sizeOfConductor')}
                      {...(isMobile && {
                        inputProps: { ...params.inputProps, readOnly: true },
                      })}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" sx={Sx.pleaseApply}>
              {t('inventory.filters.pleaseApply')}
            </Typography>
          </Grid>
        ) : (
          <Grid mt={4}>
            <TextField
              label={t('inventory.filters.partNumber')}
              value={partNumber}
              onChange={(e) =>
                setFilter(
                  'partNumber',
                  e.currentTarget.value.replace(/[^0-9]/g, '')
                )
              }
              inputProps={{
                maxLength: 6,
                minLength: 6,
                placeholder: '800001',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
    </FilterContainer>
  );
};
