import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { usePagination, useSorting, useUserActivities } from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { CompanyType, TimeZone } from '../../enums';
import { Filter } from '../../types';
import { User, UserActivity } from '../../models';
import { userActivitiesCsv, userActivitiesPdf } from '../../reports';
import { ExportMenu, FormattedTimestamp, Show } from '..';
import { roles } from '../../security';
import { UserActivitesTableProps } from './UserActivitiesTable.props';

export const UserActivitiesTable = ({ filters }: UserActivitesTableProps) => {
  const { t, i18n } = useTranslation();
  const firestore = useFirestore();
  const pagination = usePagination();
  const sorting = useSorting('date', { sortDirection: 'desc' });

  const queryFilters = useMemo(() => {
    if (!filters) return [];

    const result = [];

    const { startDate, endDate, email, role, companyId } = filters;

    if (startDate)
      result.push(['date', '>=', Timestamp.fromMillis(startDate.toMillis())]);

    if (endDate)
      result.push([
        'date',
        '<=',
        Timestamp.fromMillis(endDate.endOf('day').toMillis()),
      ]);

    if (email) result.push(['email', '==', email]);
    if (role) result.push(['role', '==', role]);
    if (companyId) result.push(['companyId', '==', companyId]);

    return result;
  }, [filters]) as Filter[];

  const { list } = useUserActivities({
    pagination,
    sorting,
    filters: queryFilters,
  });

  const exportCSV = async () => {
    if (!filters) return;

    const data = await getData();

    userActivitiesCsv.generate(data, filters?.startDate, filters?.endDate, t);
  };

  const exportPDF = async () => {
    if (!filters) return;

    const data = await getData();

    userActivitiesPdf.generate(
      data,
      filters?.startDate,
      filters?.endDate,
      t,
      i18n
    );
  };

  const getData = async () => {
    const q = query(
      collection(firestore, 'UserActivities'),
      ...queryFilters.map((queryFilter) =>
        where(queryFilter[0], queryFilter[1], queryFilter[2])
      ),
      orderBy(sorting?.sortField, sorting.sortDirection)
    );

    const result = await getDocs(q);

    return result.docs.map((doc) => doc.data() as UserActivity);
  };

  const companyNameOutput = (name: string, user: User) =>
    user.role && roles.AgentRoles.includes(user.role)
      ? `${name} (${CompanyType.Agency})`
      : name ?? 'N/A';

  return (
    <>
      <Show if={list.length > 0}>
        <Grid display="flex" justifyContent="flex-end">
          <ExportMenu
            onCSVClicked={() => exportCSV()}
            onPDFClicked={() => exportPDF()}
          />
        </Grid>
      </Show>

      <DataTable data={list} pagination={pagination} sorting={sorting}>
        <DataTableColumn
          disableSort
          property="email"
          title={t('reports.userActivity.email')}
          output={(value) => value || 'N/A'}
        />

        <DataTableColumn
          disableSort
          property="name"
          title={t('reports.userActivity.userName')}
          output={(value) => value || 'N/A'}
        />

        <DataTableColumn
          disableSort
          property="role"
          title={t('reports.userActivity.role')}
          output={(value) => (value ? t(`users.roles.${value}`) : 'N/A')}
        />

        <DataTableColumn
          disableSort
          property="companyName"
          title={t('reports.userActivity.company')}
          output={companyNameOutput}
        />

        <DataTableColumn
          property="date"
          title={t('reports.userActivity.date')}
          output={(value) => (
            <FormattedTimestamp
              timestamp={value}
              timeZone={TimeZone.EasternTime}
            />
          )}
        />

        <DataTableColumn
          disableSort
          property="success"
          title={t('reports.userActivity.successFailure')}
          output={(value) =>
            t(`reports.userActivity.${value ? 'success' : 'failure'}`)
          }
        />
      </DataTable>
    </>
  );
};
