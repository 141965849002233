import React from 'react';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useTranslation } from 'react-i18next';

import { Container, DecoratedHeader } from '../../../components';
import { Tab } from '../../../navigation';
import { Persistable } from '../../../types';
import { Company as CompanyModel } from '../../../models';
import { MyAgencyDetails } from '../../../components/MyAgency/MyAgencyDetails.component';

interface Props {
  children: React.ReactNode;
  agency: Persistable<CompanyModel>;
}

const MyAgencyPartial = ({ children, agency }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<BusinessCenterIcon />}
          title={t('navigation.myAgency')}
          variant="colored"
        ></DecoratedHeader>
      </Container>
      <MyAgencyDetails agency={agency} />
      <Container sx={{ mt: 5 }}>
        <Tab title={t('navigation.associatedCustomers')} active />
      </Container>
      {children}
    </>
  );
};

export default MyAgencyPartial;
