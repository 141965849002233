import { useEffect, useState } from 'react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { BottomDrawer } from '../../../../components';
import { useCompany, useCurrentUser } from '../../../../hooks';
import { OutletContext } from '../../../admin/modals/ImpersonateCustomer/ImpersonateCustomer.interface';
import {
  confirmButton,
  cancelButton,
  input,
} from '../../../modals/common.styles';
import { AssociatedCompany } from '../../../../types';

import { Company } from '../../../../models';
import * as Sx from './ImpersonateCustomer.styles';

const ImpersonateCustomer = () => {
  const firestore = useFirestore();
  const currentUser = useCurrentUser();

  const [company, setCompany] = useState<AssociatedCompany | null>(null);
  const [personName, setPersonName] = useState<string>('');
  const [selectableCompanies, setSelectableCompanies] = useState<
    AssociatedCompany[]
  >([]);

  const { onCancel, onAgentImpersonate, title } =
    useOutletContext<OutletContext>();

  const { t } = useTranslation();

  const { item: currentCompany } = useCompany(currentUser.companyRef.id);

  const getCompanyInfo = async (companyId: string) => {
    const docRef = doc(firestore, 'Companies', companyId);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as Company;
  };

  useEffect(() => {
    const filterAssociatedCompanies = async () => {
      if (!currentCompany?.associatedCompanies) return [];

      const associatedCompaniesArr = Object.values(
        currentCompany.associatedCompanies
      ).filter((a) => !a.isDeleted) as AssociatedCompany[];

      const companyData = await Promise.all(
        associatedCompaniesArr.map(
          async (company) => await getCompanyInfo(company.companyId)
        )
      );

      return associatedCompaniesArr.filter((AssociatedCompany) => {
        const company = companyData.find(
          (company) => company.id === AssociatedCompany.companyId
        );
        return (
          !!company &&
          ((currentUser.canSeeCSA &&
            company.canSeeCSA === currentUser.canSeeCSA) ||
            (company &&
              company.canSeeUL &&
              company.canSeeUL === currentUser.canSeeUL))
        );
      });
    };

    filterAssociatedCompanies().then((companies) =>
      setSelectableCompanies(companies)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany?.associatedCompanies, currentUser]);

  const impersonate = async () => {
    if (!(personName && currentCompany && company)) return;

    const customerInfo = await getCompanyInfo(company.companyId);

    onAgentImpersonate &&
      onAgentImpersonate({
        company: company,
        personName,
        canSeeCSA: customerInfo.canSeeCSA,
        canSeeUL: customerInfo.canSeeUL,
      });
  };

  const cancel = () => {
    onCancel && onCancel();
  };

  const formButtons = () => (
    <Grid marginBottom={1.5}>
      <Button
        disabled={!personName}
        onClick={() => impersonate()}
        sx={confirmButton}
      >
        {t('forms.confirm')}
      </Button>

      <Button onClick={() => cancel()} sx={cancelButton}>
        {t('companies.actions.cancel')}
      </Button>
    </Grid>
  );

  return (
    <BottomDrawer
      title={t(title)}
      icon={<HowToRegIcon sx={{ color: 'secondary.main' }} />}
      open={true}
      rightContent={formButtons()}
    >
      <Grid display="flex">
        <Grid display="flex" width="50%" minWidth="500px" mt={2}>
          <Autocomplete
            fullWidth={true}
            popupIcon={<KeyboardArrowDownIcon />}
            options={selectableCompanies ?? []}
            value={company}
            onChange={(e, value) => {
              setCompany(value);
              setPersonName('');
            }}
            getOptionLabel={(company) => company.companyName || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('agencies.fields.customer')}
                variant="filled"
                sx={input}
              />
            )}
            sx={Sx.companyInput}
          />

          <TextField
            disabled={!company}
            inputProps={{ maxLength: 255 }}
            label={t('agencies.fields.personName')}
            onChange={(e) => setPersonName(e.target.value)}
            value={personName}
            sx={Sx.userInput}
            variant="filled"
            fullWidth
          />
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default ImpersonateCustomer;
