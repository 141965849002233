import {
  Grid,
  IconButton,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

import { PaginationProps } from './Pagination.props';
import * as Sx from './Pagination.styles';

export const Pagination = ({ disabled, pagination }: PaginationProps) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={Sx.container}>
      <Grid item container xs={12} sx={Sx.item}>
        <Typography>{t('pagination.rowsPerPage')}:</Typography>
        <FormControl>
          <Select
            disabled={disabled}
            value={pagination.itemsPerPage}
            label={t('pagination.rowsPerPage')}
            variant="standard"
            MenuProps={{
              sx: Sx.selectMenu,
            }}
            sx={Sx.select}
            onChange={(event) => {
              pagination.setItemsPerPage(event.target.value as number);
              pagination.returnToBeginning();
            }}
          >
            {process.env.NODE_ENV !== 'production' && (
              <MenuItem value={5}>5</MenuItem>
            )}
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item container xs={12} sx={Sx.buttons}>
        <IconButton
          disabled={!pagination.returnToBeginningAvailable || disabled}
          onClick={pagination.returnToBeginning}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          disabled={!pagination.previousPageAvailable || disabled}
          onClick={pagination.previousPage}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Grid item flexGrow={{ xs: 1, md: 'unset' }} />
        <IconButton
          disabled={!pagination.nextPageAvailable || disabled}
          onClick={pagination.nextPage}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
        <IconButton
          disabled={!pagination.goToEndAvailable || disabled}
          onClick={pagination.goToEnd}
        >
          <LastPageIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
