import { SxProps } from '@mui/material';

export const avatar: SxProps = {
  backgroundColor: 'secondary.contrastText',
  border: 1,
  borderColor: 'secondary.main',
};

export const container: SxProps = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
};

export const drawerPaper = (height?: string): SxProps => {
  return {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height,
    background: '#2C2C2C',
    color: '#ffffff',
    overflow: 'hidden',
  };
};
