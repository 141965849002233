import { CSSProperties } from 'react';
import { SxProps } from '@mui/material';

export const paper: SxProps = {
  maxWidth: '375px',
  height: '250px',
  borderRadius: '10px',
  flexGrow: 1,
};

export const grid: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  py: '20px',
  px: '25px',
};

export const avatar: SxProps = {
  backgroundColor: 'secondary.main',
  width: '75px',
  height: '75px',
};

export const message: SxProps = {
  fontWeight: 800,
  fontSize: '60px',
};

export const innerMessage: SxProps = {
  color: '#979797',
};

export const link: CSSProperties = {
  color: 'inherit',
  textDecoration: 'inherit',
};

export const arrowIcon: SxProps = {
  color: 'secondary.main',
};

export const multiligne: SxProps = {
  whiteSpace: 'pre-wrap',
  height: '50px',
};
