import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompaniesTableType, CompanyType } from '../../enums';
import {
  useSearchableFilter,
  useSorting,
  usePagination,
  useCompanies,
} from '../../hooks';
import { Company } from '../../models';
import { DataTable } from '../../tables';
import { Filter } from '../../types';
import { CompaniesTableProps } from './CompaniesTable.props';
import { getCompaniesTableColumns } from './CompaniesTableColumns';
import { CompaniesTableMenuItems } from './CompaniesTableMenuItems.component';

export const CompaniesTable = React.memo(
  ({ code, name, onDeleteClick }: CompaniesTableProps) => {
    const { t } = useTranslation();
    const { filters: codeFilters } = useSearchableFilter({
      value: code,
      property: 'codeNormalized',
    });

    const { filters: nameFilters } = useSearchableFilter({
      value: name,
      property: 'nameNormalized',
    });

    const forcedSortField = code
      ? 'codeNormalized'
      : name
      ? 'nameNormalized'
      : undefined;

    const sorting = useSorting('name', { forcedSortField });

    const filters = useMemo(
      () => [
        ['type', '==', CompanyType.Customer] as Filter,
        ...(nameFilters.length === 0 ? codeFilters : []),
        ...(codeFilters.length === 0 ? nameFilters : []),
      ],
      [nameFilters, codeFilters]
    );

    const pagination = usePagination();

    const { list: companyList, loading } = useCompanies({
      sorting,
      pagination,
      filters,
    });

    const menuItems = (company: Company) => (
      <CompaniesTableMenuItems
        company={company}
        onDeleteClick={onDeleteClick}
      />
    );
    return (
      <DataTable
        data={!loading ? companyList : undefined}
        sorting={sorting}
        pagination={pagination}
        menuItems={menuItems}
      >
        {getCompaniesTableColumns(CompaniesTableType.Companies, t)}
      </DataTable>
    );
  }
);
