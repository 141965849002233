import { useMemo } from 'react';

import Formable from '../types/Formable';

export function useFormFieldValue<T, K>(form: Formable<T>, field: keyof T) {
  const value = useMemo(
    () => form.item?.[field] as unknown as K,
    [form, field]
  );

  const setValue = (value: K) => {
    const newValues = {} as Partial<T>;
    newValues[field] = value as Extract<K, T[keyof T]>;
    form.setItem({ ...form.item, ...(newValues as T) });
  };

  return { value, setValue };
}
