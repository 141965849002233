import {
  Paper,
  PaperProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

interface StyledPaperProps extends PaperProps {
  component?: React.ElementType;
}

interface StyledTypographyProps extends TypographyProps {
  component?: React.ElementType;
}

export const StyledPaper = styled(Paper)<StyledPaperProps>(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: '16px',
  };
});

export const StyledTypography = styled(Typography)<StyledTypographyProps>(
  () => {
    return {
      marginTop: '64px',
      marginBottom: '32px',
    };
  }
);
