import { useEffect, useState } from 'react';

import { filter, orderBy } from 'lodash';
import { Filter, Nullable, Pageable, Sortable } from '../types';
import { FilterOperatorPredicates } from '../mappings';

export function useDataCollection<T>(
  data: T[],
  sorting?: Nullable<Sortable>,
  pagination?: Pageable,
  localFilters?: (Filter | undefined)[] | undefined,
  transformData?: (data: T[]) => T[]
) {
  const [list, setList] = useState<T[]>([]);
  const [unpaginatedResults, setUnpaginatedResults] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Reset the pagination if the sorting or filters change
  useEffect(() => {
    if (pagination) {
      pagination.returnToBeginning();
    }
    // No not apply exhaustive deps rule to this hook, as it can cause
    // an infinite loop, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting?.sortField, sorting?.sortDirection, localFilters]);

  // Filter and pagination the data locally
  useEffect(() => {
    if (data) {
      let results = data;

      if (localFilters) {
        for (const additionalFilter of localFilters) {
          if (additionalFilter) {
            results = filter(
              results,
              FilterOperatorPredicates[additionalFilter[1]](
                additionalFilter[0].toString(),
                additionalFilter[2]
              )
            );
          }
        }
      }

      if (sorting) {
        results = orderBy(results, [sorting.sortField], sorting.sortDirection);
      }

      if (transformData) {
        results = transformData(results);
      }

      setUnpaginatedResults(results);

      if (pagination) {
        let currentStart = 0;

        if (pagination.startAt) {
          currentStart = pagination.startAt as number;
        } else if (pagination.endAt) {
          currentStart =
            (pagination.endAt as number) - pagination.itemsPerPage + 1;
        } else if (pagination.atEnd) {
          currentStart =
            results.length % pagination.itemsPerPage === 0
              ? results.length - pagination.itemsPerPage
              : results.length - (results.length % pagination.itemsPerPage);
        } else {
          currentStart = 0;
        }

        pagination.setNextStartAt(
          pagination.atEnd ||
            currentStart + pagination.itemsPerPage >= results.length
            ? undefined
            : currentStart + pagination.itemsPerPage
        );

        pagination.setNextEndAt(
          currentStart > 0 ? currentStart - 1 : undefined
        );

        if (currentStart === results.length) {
          currentStart -= pagination.itemsPerPage;
          pagination.setNextStartAt(currentStart);
        }

        results = results.slice(
          currentStart,
          currentStart + pagination.itemsPerPage
        );
      }

      setList(results);

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    pagination?.startAt,
    pagination?.endAt,
    pagination?.itemsPerPage,
    pagination?.atEnd,
    sorting?.sortField,
    sorting?.sortDirection,
    localFilters,
  ]);

  return {
    list,
    loading,
    unpaginatedResults,
  };
}
