import { FirebaseApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { AppCheckProvider } from 'reactfire';
import { env } from '../env';

const FirebaseAppCheck = (props: {
  app: FirebaseApp;
  children: React.ReactNode;
}) => {
  const appCheck =
    process.env.NODE_ENV !== 'development'
      ? initializeAppCheck(props.app, {
          provider: new ReCaptchaV3Provider(env.REACT_APP_SITE_KEY as string),
          isTokenAutoRefreshEnabled: true,
        })
      : null;

  if (appCheck) {
    return <AppCheckProvider sdk={appCheck}>{props.children}</AppCheckProvider>;
  }

  return <>{props.children}</>;
};

export default FirebaseAppCheck;
