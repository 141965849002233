import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GetWired from '../../assets/GetWired';
import { usePortalSettings } from '../../hooks';
import { translateString } from '../../i18n';

export const MobileFooter = () => {
  const { t, i18n } = useTranslation();
  const { item: portalSettings } = usePortalSettings();

  return (
    <Grid
      container
      alignItems="end"
      justifyContent="space-between"
      sx={{ p: 2 }}
    >
      <Typography
        color="white"
        sx={{
          fontWeight: '400',
          color: '#919191',
          letterSpacing: 0,
          mr: 1,
          ml: 0,
          '& a': {
            color: 'inherit',
          },
          '& a:hover': {
            color: 'secondary.main',
          },
        }}
      >
        &copy; Northern Cables Inc.
        <br />
        <a
          href={translateString(portalSettings?.termsAndConditionsURL, i18n)}
          target="_blank"
        >
          {t('termsAndConditions.title')}
        </a>
      </Typography>
      <Box sx={{ zoom: '1.5', mr: 1 }}>
        <GetWired />
      </Box>
    </Grid>
  );
};
