import { useCurrentUser } from './useCurrentUser';
import { usePortalSettings } from './usePortalSettings';

export const useReservationsAreAllowed = () => {
  const user = useCurrentUser();
  const { item: settings } = usePortalSettings();
  if (user) {
    return user.canReserve && settings?.allowReservations;
  }
};
