import { Navigate } from 'react-router-dom';

import { useAuthentication } from '../hooks';
import { Loading } from '../pages';
import { UserRole } from '../enums/';

interface Props {
  children: React.ReactNode;
  roles: UserRole[];
}

const RequireRole = ({ roles, children }: Props) => {
  const { role } = useAuthentication();

  if (!role) {
    return <Loading />;
  }

  if (!roles.includes(role)) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default RequireRole;
