export enum ProductType {
  AC90 = 1,
  AC90Randoms = 2,
  ACWU = 4,
  BareGround = 5,
  BX = 6,
  Feeder = 7,
  HCF = 8,
  MC = 9,
  NMD90 = 10,
  RW90 = 11,
  TECK90 = 12,
  USEI = 13,
  RWU90 = 14,
  ACIC_PCS = 15,
  ACIC_CONTROL = 16,
  ACIC_INSTRUMENTATION = 17,
  FAS = 18,
  NMWU = 19,
  FA = 20,
  HCF_ABG = 21,
  MC_ABG = 22,
  THHN = 23,
  WC70 = 24,
  XHHW2 = 25,
}
