import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useFormFieldValue } from '../../hooks';
import { SelectProps } from './Select.props';

export const Select = <T, K>({
  autocomplete = 'off',
  contrast = false,
  customError,
  defaultValue,
  disabled,
  displayCustomError = false,
  label,
  field,
  form,
  options,
  getOptionLabel,
  getOptionValue,
  isOptionEqualToValue,
  required,
  fullWidth = true,
}: SelectProps<T, K>) => {
  const { value, setValue } = useFormFieldValue<T, K | undefined>(form, field);

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: K | null
  ) => {
    setValue(getOptionValue ? getOptionValue(value) : value);
  };

  const id = field as string;

  return (
    <Autocomplete
      id={id}
      aria-labelledby={`${id}-label`}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={contrast ? 'filled' : 'outlined'}
          error={!!form.errors[field] || displayCustomError}
          helperText={form.errors[field] || (displayCustomError && customError)}
          inputProps={{ ...params.inputProps, autoComplete: autocomplete }}
          inputRef={form.fieldRef(id)}
          label={label}
          required={required}
          sx={{
            mt: 1.5,
            mb: 1.5,
            ...(contrast && {
              backgroundColor: '#ffffff',
              borderRadius: form.errors[field] ? '0 0 5px 5px' : 0,
              marginBottom: 0,
            }),
          }}
        />
      )}
      disabled={disabled}
      options={options}
      popupIcon={disabled ? null : <KeyboardArrowDownIcon />}
      value={value || null}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      defaultValue={defaultValue}
      sx={{
        ...(contrast && {
          marginBottom: '0px',
        }),
      }}
    />
  );
};
