import { useTranslation } from 'react-i18next';
import { Theme, useMediaQuery } from '@mui/material';
import { Settings, Logout, Phone } from '@mui/icons-material';
import { useAuth } from 'reactfire';

import { roles } from '../../security/';
import { useAuthentication, usePortalSettings } from '../../hooks';
import { translateString } from '../../i18n';
import { MenuItem } from '..';
import { Show } from '../../components';

export const UserMenuItems = () => {
  const { t, i18n } = useTranslation();
  const { role } = useAuthentication();
  const { currentUser } = useAuth();
  const { item: portalSettings } = usePortalSettings();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      <Show if={!!currentUser && currentUser.emailVerified}>
        <MenuItem
          title={t('navigation.settings')}
          icon={<Settings />}
          to={
            roles.AgentRoles.includes(role)
              ? '/agent/settings'
              : roles.AdminRoles.includes(role)
              ? '/admin/settings'
              : '/settings'
          }
        />
      </Show>
      <MenuItem title={t('navigation.logOut')} icon={<Logout />} to="/logout" />

      <Show if={isMobile && !!currentUser && currentUser.emailVerified}>
        <MenuItem
          title={t('contact.title')}
          icon={<Phone />}
          href={translateString(portalSettings?.contactURL, i18n)}
          target="_blank"
        />
      </Show>
    </>
  );
};
