import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { DataTable, DataTableColumn } from '../../tables';
import { User } from '../../models';
import {
  useSorting,
  usePagination,
  useUsersWaitingApproval,
  useCurrentUser,
} from '../../hooks';
import { Container, FormattedTimestamp, UnderlinedLink } from '..';

export const UserApprovalTable = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const sorting = useSorting('creationDate', { sortDirection: 'desc' });
  const pagination = usePagination();
  const { list, loading } = useUsersWaitingApproval({
    sorting,
    pagination,
  });

  const dateOutput = (timestamp: Timestamp) => (
    <FormattedTimestamp timestamp={timestamp} timeZone={currentUser.timeZone} />
  );

  const nameOutput = (name: string, user: User) => (
    <UnderlinedLink to={`/admin/users/${user.id}`}>{name}</UnderlinedLink>
  );

  const menuItems = (item: User) => (
    <MenuItem component={Link} to={`${item.id}/reject`}>
      <ListItemIcon>
        <PersonRemoveIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('users.actions.reject')}</ListItemText>
    </MenuItem>
  );

  return (
    <Container>
      <DataTable
        data={!loading ? list : undefined}
        sorting={sorting}
        pagination={pagination}
        menuItems={menuItems}
      >
        <DataTableColumn
          property="name"
          title={t('users.fields.name')}
          output={nameOutput}
          width="50%"
        />
        <DataTableColumn
          property="suggestedCompanyName"
          title={t('users.fields.companyProvided')}
          width="30%"
        />
        <DataTableColumn
          property="creationDate"
          title={t('users.fields.creationDate')}
          output={dateOutput}
          width="20%"
        />
      </DataTable>
    </Container>
  );
};
