import { Specification } from '../models';
import { CollectionQueryOptions } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useSpecifications(options?: CollectionQueryOptions) {
  return useLocalCollection<Specification>(
    'Specifications',
    [undefined],
    options?.sorting,
    options?.pagination,
    options?.filters
  );
}
