import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import CableIcon from '@mui/icons-material/Cable';
import { Theme, useMediaQuery } from '@mui/material';
import { RequirePermission } from '../security';
import { InventoryType, UserPermission } from '../enums/';
import {
  Container,
  DecoratedHeader,
  ImpersonateDrawer,
  InventoryFilters,
  InventoryLastSyncDate,
  InventorySpecifications,
  InventoryTable,
  InventoryWarningMessageCard,
  MultipleReservationsDrawer,
  ShoppingTruck,
  Show,
} from '../components';
import { Tab } from '../navigation';
import {
  ImpersonatedCustomer,
  InventoryFilters as InventoryFiltersType,
  Nullable,
} from '../types';
import {
  useAuthentication,
  useCurrentUser,
  useLocalStorage,
  useNotification,
  usePageTitle,
  usePortalSettings,
  useSorting,
} from '../hooks';
import { getPageTitle } from '../utils/pages';
import { Reel, User } from '../models';
import { ReelWithInventory } from '../models/Reel';
import { isSelectedFunction } from '../components/InventoryTable/utils';
import { usePrompt } from '../navigation/ReactRouterHooks';
import {
  UserRoleGroup,
  UserRoleGroupPath,
  getUserRoleGroup,
} from '../utils/userRoleGroup';
import { AgentImpersonateDrawer } from '../components/AgentImpersonateDrawer/AgentImpersonateDrawer.component';

const Inventory: React.FC = () => {
  const { t } = useTranslation();
  const { isAdmin, isAgent } = useAuthentication();
  const { type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { clearNotification } = useNotification();
  const user = useCurrentUser();
  const { item: portalSettings, loading: loadingPortalSettings } =
    usePortalSettings();
  const { item: filters, setItem: setFilters } =
    useLocalStorage<InventoryFiltersType>('inventory', type);
  const sorting = useSorting('description');

  const { item: lastType, setItem: setLastType } = useLocalStorage<string>(
    'inventory',
    'type'
  );

  const [show, setShow] = useState<boolean>(false);
  const [multipleReservations, setMultipleReservations] = useState(false);
  const [shoppingTruck, setShoppingTruck] = useState(false);
  const [selectedReels, setSelectedReels] = useState<ReelWithInventory[]>([]);
  const [openedCollapse, setOpenedCollapse] = useState<Nullable<string>>(null);
  const [units, setUnits] = useState(user.measurementUnits);
  const [impersonatedUser, setImpersonatedUser] =
    useState<Nullable<User>>(null);
  const [impersonatedCustomer, setImpersonatedCustomer] =
    useState<Nullable<ImpersonatedCustomer>>(null);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const pathPrefix =
    UserRoleGroupPath[getUserRoleGroup(user.role) ?? UserRoleGroup.Customer];

  const handleDoneClick = () => {
    setImpersonatedUser(null);
    setImpersonatedCustomer(null);
    clearNotification();
  };

  const handleSearch = (filters: Nullable<InventoryFiltersType>) => {
    setLastType(type || '');
    setFilters(filters);
    setShow(!!filters?.productType || !!filters?.partNumber);
  };

  usePrompt(
    t('forms.navigationConfirmation'),
    (multipleReservations && selectedReels.length > 0) || false
  );

  const inventoryType = type === 'csa' ? InventoryType.CSA : InventoryType.UL;

  const pageTitleOptions = {
    type: t(`inventoryType.${inventoryType}`),
  };
  usePageTitle(getPageTitle('inventory', pageTitleOptions));

  useEffect(() => {
    if (user && inventoryType === InventoryType.CSA && !user.canSeeCSA) {
      navigate(`${pathPrefix}/inventory/ul`);
    }

    if (user && inventoryType === InventoryType.UL && !user.canSeeUL) {
      navigate(`${pathPrefix}/inventory/csa`);
    }

    if (user && !user?.canSeeCSA && !user?.canSeeUL) {
      navigate('/');
    }

    if (!location.pathname.startsWith(pathPrefix)) {
      navigate(`${pathPrefix}/inventory/${user?.canSeeCSA ? 'csa' : 'ul'}`);
    }
  }, [user, inventoryType, navigate, pathPrefix, location.pathname]);

  useEffect(() => {
    if (lastType === 'ul') {
      navigate(
        `${pathPrefix}/inventory/ul${
          location.pathname.endsWith('/reserve') ? '/reserve' : ''
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastType]);

  useEffect(() => {
    if (!isDesktop) {
      setMultipleReservations(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  useEffect(() => {
    if (selectedReels.length > 0) {
      resetSelectedReels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const showSpecifications = show && !!filters?.productType;
  const showTable = show && !!filters && !!type && !!user;

  const displaymultipleReservationsDrawer = useMemo(() => {
    if ((isAdmin && !impersonatedUser) || (isAgent && !impersonatedCustomer)) {
      return false;
    }

    return multipleReservations && isDesktop;
  }, [
    impersonatedCustomer,
    impersonatedUser,
    isAdmin,
    isAgent,
    isDesktop,
    multipleReservations,
  ]);

  const toggleShoppingTruck = () => {
    setShoppingTruck(!shoppingTruck);
  };

  const toggleItemSelection = (item: Reel) => {
    const itemToToggle: ReelWithInventory = {
      ...item,
      inventoryType,
    };

    if (!isSelectedFunction(selectedReels, itemToToggle)) {
      setSelectedReels((prev) => [...prev, itemToToggle]);
    } else {
      setSelectedReels((prev) =>
        prev.filter((filterItem) => itemToToggle.id !== filterItem.id)
      );
    }
  };

  const resetSelectedReels = () => {
    if (selectedReels.length) {
      setSelectedReels([]);
    }
  };

  const resetMultipleReservations = () => {
    resetSelectedReels();
    setMultipleReservations(false);
  };

  const resetShoppingTruck = (failedReservations: string[]) => {
    setSelectedReels((prev) =>
      prev.filter((filterItem) => failedReservations.includes(filterItem.id))
    );
    setShoppingTruck(false);
  };

  const onImpersonate = (user: User) => {
    resetSelectedReels();
    setImpersonatedUser(user);
    navigate(
      `/admin/inventory/${user?.canSeeCSA && type === 'csa' ? 'csa' : 'ul'}`
    );
  };

  const onAgentImpersonate = (customer: ImpersonatedCustomer) => {
    resetSelectedReels();
    setImpersonatedCustomer(customer);
    navigate(
      `/agent/inventory/${customer?.canSeeCSA && type === 'csa' ? 'csa' : 'ul'}`
    );
  };

  const onCancel = () => {
    resetMultipleReservations();
    navigate(location.pathname.replace('/reserve', ''));
  };

  return (
    <RequirePermission oneOf={[UserPermission.ViewInventory]}>
      <Container>
        <DecoratedHeader
          icon={<CableIcon />}
          title={t('navigation.inventory')}
          variant="colored"
        >
          <Show if={user?.canSeeCSA}>
            <Tab
              disabled={
                (!!impersonatedUser && !impersonatedUser.canSeeCSA) ||
                (!!impersonatedCustomer && !impersonatedCustomer.canSeeCSA)
              }
              title="CSA"
              to={`${pathPrefix}/inventory/csa`}
              match={`${pathPrefix}/inventory/csa/*`}
            />
          </Show>
          <Show if={user?.canSeeUL}>
            <Tab
              disabled={
                (!!impersonatedUser && !impersonatedUser.canSeeUL) ||
                (!!impersonatedCustomer && !impersonatedCustomer.canSeeUL)
              }
              title="UL"
              to={`${pathPrefix}/inventory/ul`}
              match={`${pathPrefix}/inventory/ul/*`}
            />
          </Show>
        </DecoratedHeader>

        {/* Temporary notice about delayed shipments begin */}
        <Show
          if={
            !loadingPortalSettings &&
            !!portalSettings?.inventoryWarningMessageShow
          }
        >
          {portalSettings && (
            <InventoryWarningMessageCard
              header={portalSettings?.inventoryWarningMessageHeading}
              body={portalSettings?.inventoryWarningMessageBody}
              color={portalSettings?.inventoryWarningMessageColor}
            />
          )}
        </Show>
        {/* Temporary notice about delayed shipments end */}
      </Container>

      <InventoryFilters
        units={units}
        setUnits={setUnits}
        onSearch={handleSearch}
        filters={filters ?? undefined}
      />

      <Show if={showSpecifications}>
        <InventorySpecifications filters={filters as InventoryFiltersType} />
      </Show>

      <Container
        sx={{ paddingBottom: showTable && multipleReservations ? '4.5em' : '' }}
      >
        <Show if={showTable}>
          <InventoryTable
            openedCollapse={openedCollapse}
            setOpenedCollapse={setOpenedCollapse}
            type={inventoryType}
            filters={filters}
            setFilters={setFilters}
            sorting={sorting}
            user={user}
            impersonatedCustomer={impersonatedCustomer}
            impersonatedUser={impersonatedUser}
            measureUnits={units}
            setMeasureUnits={setUnits}
            multipleReservations={multipleReservations}
            setMultipleReservations={setMultipleReservations}
            resetMultipleReservations={resetMultipleReservations}
            selectedReels={selectedReels as Reel[]}
            toggleItemSelection={toggleItemSelection}
            isDesktop={isDesktop}
            isAdmin={isAdmin}
            isAgent={isAgent}
          />

          <InventoryLastSyncDate timeZone={user?.timeZone} />
        </Show>
      </Container>

      <Show if={displaymultipleReservationsDrawer}>
        <MultipleReservationsDrawer
          impersonatedUser={impersonatedUser}
          impersonatedCustomer={impersonatedCustomer}
          isAdmin={isAdmin}
          isAgent={isAgent}
          selectedReels={selectedReels}
          shoppingTruck={shoppingTruck}
          toggleShoppingTruck={toggleShoppingTruck}
          user={user}
        />

        <Show if={shoppingTruck}>
          <ShoppingTruck
            impersonatedUser={impersonatedUser}
            impersonatedCustomer={impersonatedCustomer}
            measureUnits={units}
            resetShoppingTruck={resetShoppingTruck}
            selectedReels={selectedReels}
            toggleItemSelection={toggleItemSelection}
            toggleShoppingTruck={toggleShoppingTruck}
            user={user}
          />
        </Show>
      </Show>
      <Show if={!!impersonatedUser && !multipleReservations}>
        <ImpersonateDrawer
          id={impersonatedUser?.id || ''}
          onDoneClicked={handleDoneClick}
        />
      </Show>
      <Show if={!!impersonatedCustomer && !multipleReservations}>
        <AgentImpersonateDrawer
          impersonatedCustomer={impersonatedCustomer}
          onDoneClicked={handleDoneClick}
        />
      </Show>

      <Outlet
        context={{
          fromCollapse: !!openedCollapse,
          inventoryType,
          onCancel,
          onAgentImpersonate,
          onImpersonate,
          title: 'impersonateCustomer.reserve',
        }}
      />
    </RequirePermission>
  );
};

export default Inventory;
