import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import * as Sx from '../MultipleReservationsDrawer.styles';
import { User } from '../../../models';

export const MultipleReservationsDrawerAdmin = ({
  effectiveUser,
  reservationsCount,
}: {
  effectiveUser: User;
  reservationsCount: {
    customer: number;
    northernCables: number;
    total: number;
  };
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Icon Section */}
      <Grid
        item
        md={1}
        display="flex"
        textAlign="center"
        alignItems="center"
        sx={Sx.iconSectionAdmin}
      >
        <HowToRegIcon style={{ fontSize: '4rem' }} />
      </Grid>

      {/* Data Section */}
      <Grid
        container
        item
        justifyContent={'space-evenly'}
        alignItems={'center'}
        md={8}
        sx={Sx.dataSection}
      >
        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.reservingFor')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {effectiveUser?.name}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.company')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {effectiveUser?.companyName}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid item>{t('inventory.multipleReservations.market')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {effectiveUser?.canSeeCSA ? 'CSA' : ''}
            {effectiveUser?.canSeeCSA && effectiveUser?.canSeeUL ? ', ' : ''}
            {effectiveUser?.canSeeUL ? 'UL' : ''}
          </Grid>
        </Grid>

        <Grid item md={1}>
          <Grid item>{t('inventory.multipleReservations.customer')}</Grid>
          <Grid
            container
            display="flex"
            flexDirection={'row'}
            justifyContent={'center'}
          >
            <Typography sx={Sx.valueStyles}>
              {reservationsCount.customer}
            </Typography>
            <Typography sx={Sx.valueStyles} color={'secondary'}>
              /{effectiveUser?.maxReservationsPerPeriod}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={1}>
          <Grid item>{t('inventory.multipleReservations.nc')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {reservationsCount.northernCables}
          </Grid>
        </Grid>
        <Grid item md={1}>
          <Grid item>{t('inventory.multipleReservations.total')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {reservationsCount.total}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
