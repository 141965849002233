import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  FormControlHeader,
  PhoneNumberField,
  ProgressIndicator,
  TextField,
  TranslatedFields,
} from '../../components';
import { Language, UserPermission } from '../../enums';
import PortalSettingsModel from '../../models/PortalSettings';
import RequirePermission from '../../security/RequirePermission';
import { useForm, usePageTitle, usePortalSettings } from '../../hooks';
import { PortalSettingsValidations } from '../../validation';
import { getPageTitle } from '../../utils/pages';
import PortalSettings from './partials/PortalSettings';

const Contacts: React.FC = () => {
  const { t } = useTranslation();
  usePageTitle(getPageTitle('portalSettings.contacts'));
  const settings = usePortalSettings();
  const form = useForm<PortalSettingsModel>(settings, {
    validations: PortalSettingsValidations,
  });

  return (
    <RequirePermission oneOf={[UserPermission.ManagePortalSettings]}>
      <PortalSettings>
        <FormControlHeader
          form={form}
          title={t('portalSettings.contacts.title')}
        />
        <Container>
          {settings.loading || !form.item ? (
            <ProgressIndicator />
          ) : (
            <Box sx={{ mt: 6, mb: 6 }}>
              <TranslatedFields
                form={form}
                field="contactName"
                label={t('portalSettings.contacts.fields.contactName')}
                render={(options) => <TextField {...options} maxLength={255} />}
              />
              <TranslatedFields
                form={form}
                field="contactPhoneNumber"
                label={t('portalSettings.contacts.fields.contactPhoneNumber')}
                render={(options) => <PhoneNumberField {...options} />}
              />
              <TranslatedFields
                form={form}
                field="contactExtension"
                label={t('portalSettings.contacts.fields.contactExtension')}
                render={(options) => (
                  <PhoneNumberField {...options} variant="extension" />
                )}
              />
              <TranslatedFields
                form={form}
                field="contactURL"
                label={t('portalSettings.contacts.fields.contactURL')}
                render={(options) => <TextField {...options} />}
              />
              <TranslatedFields
                form={form}
                field="contactEmail"
                label={t('portalSettings.contacts.fields.contactEmail')}
                render={(options) => <TextField {...options} />}
              />
              <TranslatedFields
                form={form}
                field="contactEmailUS"
                label={t('portalSettings.contacts.fields.contactEmailUS')}
                render={(options) => (
                  <Grid
                    display={options.field === Language.English ? '' : 'none'}
                  >
                    <TextField {...options} />
                  </Grid>
                )}
              />
            </Box>
          )}
        </Container>
      </PortalSettings>
    </RequirePermission>
  );
};

export default Contacts;
