import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { DataTable, DataTableColumn } from '../../tables';
import { ReelSize } from '../../models/ReelSize';
import { NULL_VALUE } from './constants';
import { ReelReturnTableProps } from './ReelReturnTable.props';
import { getReelSizeTotal, getTotalPerReelSize } from './utils';
import * as Sx from './ReelReturnTable.styles';

export const ReelReturnTable = React.memo(
  ({
    currencyFormatter,
    form,
    loadingReelSizes,
    reelSizes,
    setQuantity,
  }: ReelReturnTableProps) => {
    const { t } = useTranslation();

    const unreturnableReelsAmount = reelSizes.filter(
      (reel) => !reel.isReturnable
    ).length;

    const NullableOutput = (
      reelSize: ReelSize,
      output: (reelSize: ReelSize) => JSX.Element | string
    ): JSX.Element | string =>
      reelSize.isReturnable ? (
        output(reelSize)
      ) : (
        <Typography align="center" color={'primary.light'}>
          {NULL_VALUE}
        </Typography>
      );

    const quantityOutput = (reelSize: ReelSize) => (
      <TextField
        variant="outlined"
        value={form.item?.reels?.[reelSize.id] ?? 0}
        onChange={(e) => {
          setQuantity(reelSize, Number(e.target.value.replace(/[^0-9]/g, '')));
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: 0,
          maxLength: 4,
        }}
      />
    );

    const descriptionOutput = (reelSize: ReelSize) =>
      (reelSize.isReturnable
        ? t(
            `reelReturns.edit.table.description${
              reelSize.id.endsWith('PAN') ? 'Pan' : ''
            }`,
            { flange: reelSize.flange }
          )
        : t('reelReturns.edit.table.doNotReturn', {
            chargeAmount: currencyFormatter.format(reelSize.deposit),
          })
      ).toUpperCase();

    const depositOutput = (reelSize: ReelSize) =>
      currencyFormatter.format(reelSize.deposit);

    const serviceFeeOutput = (reelSize: ReelSize) =>
      `${reelSize.serviceFee * 100}%`;

    const totalPerReelOutput = (reelSize: ReelSize) =>
      currencyFormatter.format(getTotalPerReelSize(reelSize));

    const totalOutput = (reelSize: ReelSize) =>
      currencyFormatter.format(
        getReelSizeTotal(form.item?.reels?.[reelSize.id] ?? 0, reelSize)
      );

    return (
      <DataTable
        data={!loadingReelSizes ? reelSizes : undefined}
        tableStyles={Sx.tableStyles}
        tableBodyStyles={Sx.tableBodyStyles(unreturnableReelsAmount)}
        tableHeaderStyles={Sx.tableHeaderStyles}
      >
        <DataTableColumn
          mobileOrder={1}
          mobileWidth="20%"
          property="quantity"
          width="10%"
          title={t('reelReturns.edit.table.labels.quantity')}
          otherStyling={{
            my: {
              xs: 'auto',
            },
          }}
          output={(_, reelSize: ReelSize) =>
            NullableOutput(reelSize, quantityOutput)
          }
        />

        <DataTableColumn
          property="reelType"
          title={t('reelReturns.edit.table.labels.reelType')}
          mobileOrder={0}
          mobileWidth="15%"
          hasMobileLabel={false}
          otherStyling={{
            my: {
              xs: 'auto',
            },
          }}
          textAlign="center"
        />
        <DataTableColumn
          property="arrow-icon"
          mobileOrder={2}
          mobileWidth="20%"
          hasMobileLabel={false}
          hidden={true}
          mobileHidden={false}
          otherStyling={{
            ml: {
              xs: 'auto',
            },
          }}
          activeOutput={() => <KeyboardArrowDownIcon fontSize="large" />}
          output={() => <KeyboardArrowUpIcon fontSize="large" />}
        />
        <DataTableColumn
          mobileHidden
          mobileVisibleWhenActive
          property="description"
          title={t('reelReturns.edit.table.labels.description')}
          output={(_, reelSize: ReelSize) => descriptionOutput(reelSize)}
        />
        <DataTableColumn
          mobileHidden
          mobileVisibleWhenActive
          property="deposit"
          textAlign="center"
          title={t('reelReturns.edit.table.labels.deposit')}
          output={(_, reelSize: ReelSize) =>
            NullableOutput(reelSize, depositOutput)
          }
        />
        <DataTableColumn
          mobileHidden
          mobileVisibleWhenActive
          property="serviceFee"
          textAlign="center"
          width="5%"
          title={t('reelReturns.edit.table.labels.serviceFee')}
          output={(_, reelSize: ReelSize) =>
            NullableOutput(reelSize, serviceFeeOutput)
          }
        />
        <DataTableColumn
          mobileHidden
          mobileVisibleWhenActive
          property="totalPerReel"
          width="5%"
          textAlign="center"
          title={t('reelReturns.edit.table.labels.totalPerReel')}
          output={(_, reelSize: ReelSize) =>
            NullableOutput(reelSize, totalPerReelOutput)
          }
        />
        <DataTableColumn
          mobileHidden
          mobileVisibleWhenActive
          property="total"
          width="15%"
          textAlign="center"
          title={t('reelReturns.edit.table.labels.total')}
          output={(_, reelSize: ReelSize) =>
            NullableOutput(reelSize, totalOutput)
          }
        />
      </DataTable>
    );
  }
);
