import { styled, Typography } from '@mui/material';

export const StyledTextButton = styled(Typography)(({ theme }) => {
  return {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  };
});
