import {
  Drawer,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledBodyContainer,
  drawerPaper,
} from './SideDrawer.styles';
import { SideDrawerProps } from './SideDrawer.props';

export const SideDrawer = ({
  headerText,
  onClose,
  isOpen,
  bodyChildren,
  footerChildren,
}: SideDrawerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Drawer
      transitionDuration={100}
      open={isOpen}
      PaperProps={{ sx: drawerPaper(isMobile) }}
      anchor="right"
    >
      <StyledHeaderContainer display="flex" justifyContent="space-between">
        <Grid
          container
          alignItems="center"
          display="flex"
          gap={3}
          wrap="nowrap"
          mr={3}
        >
          <Typography variant="h3" color={theme.palette.common.white}>
            {headerText}
          </Typography>
        </Grid>
        <IconButton onClick={onClose}>
          <CloseIcon htmlColor={theme.palette.common.white} />
        </IconButton>
      </StyledHeaderContainer>

      <StyledBodyContainer>{bodyChildren}</StyledBodyContainer>

      {!!footerChildren && (
        <StyledFooterContainer>{footerChildren}</StyledFooterContainer>
      )}
    </Drawer>
  );
};
