import { SxProps } from '@mui/material';

export const reservationCommentField: SxProps = {
  backgroundColor: '#ffffff',
  flex: '4 1',
  mx: { xs: 0, md: 1 },
  mt: { xs: 2, md: 1 },
  mb: { xs: 0, md: 1 },
};

export const mobileDialogContent: SxProps = {
  color: 'white',
  display: { xs: 'block', md: 'none' },
};

export const quantitySelectionField: SxProps = {
  backgroundColor: '#ffffff',
  flex: '1 1',
  mx: { xs: 0, md: 1 },
  mt: { xs: 2, md: 1 },
  mb: { xs: 0, md: 1 },
  minWidth: 120,
};
