import { Timestamp } from 'firebase/firestore';
import { ReservationStatus } from '../enums';
import { Reservation } from '../models';

export default function calculateReservationStatusToDisplay(
  reservation: Reservation
): string {
  return reservation.expirationTime < Timestamp.now() &&
    reservation.releaseTime &&
    reservation.releaseTime > Timestamp.now() &&
    reservation.status === ReservationStatus.Reserved
    ? ReservationStatus.TemporarilyHeld
    : reservation.expirationTime < Timestamp.now() &&
      (!reservation.releaseTime || reservation.releaseTime < Timestamp.now()) &&
      [ReservationStatus.Reserved, ReservationStatus.TemporarilyHeld].includes(
        reservation.status
      )
    ? ReservationStatus.Expired
    : reservation.status;
}
