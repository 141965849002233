import { Functions } from 'firebase/functions';
import * as yup from 'yup';
import {
  Country,
  Language,
  MeasurementUnits,
  ModificationMode,
  ProvinceOrState,
  UserRole,
  UserStatus,
} from '../enums';
import { CountryProvincesOrStates } from '../mappings';

const canReserveValidation = yup.number().when(['isNCEmployee', 'canReserve'], {
  is: (isNCEmployee: boolean, canReserve: boolean) =>
    !isNCEmployee && canReserve,
  then: (schema) =>
    schema.notOneOf([undefined], 'common.validations.fieldRequired'),
});

const UserSchema = yup.object({
  name: yup.string().required(),
  status: yup.mixed<UserStatus>().required(),
  role: yup.mixed<UserRole>().required(),
  language: yup.mixed<Language>().required(),
  companyRef: yup.mixed().required(),
  timeZone: yup.mixed().required(),
  phoneNumber: yup
    .string()
    .matches(
      /^(1|)?(\d{3})(\d{3})(\d{4})$/,
      'common.validations.fieldMustBePhoneNumber'
    )
    .required(),
  city: yup.string().required(),
  provinceOrState: yup
    .mixed<ProvinceOrState>()
    .required()
    .test({
      name: 'provinceOrState',
      exclusive: true,
      message: 'common.validations.fieldNotAValidChoice',
      test: (state, context) => {
        const country = context.parent.country;

        if (!country || !state) {
          return true;
        }

        return CountryProvincesOrStates[country as Country].includes(state);
      },
    }),
  country: yup.mixed<Country>().required(),
  canSeeCSA: yup.boolean().when(['canSeeUL'], {
    is: (canSeeUL: boolean) => !canSeeUL,
    then: (schema) =>
      schema.notOneOf([false], 'common.validations.fieldRequired'),
  }),
  extensionNotificationDelay: canReserveValidation,
  holdDelay: canReserveValidation,
  maxReservationsPerPeriod: canReserveValidation,
  measurementUnits: yup.mixed<MeasurementUnits>().required(),
});

const getUserSchema = (mode: ModificationMode, functions?: Functions) => {
  if (mode === ModificationMode.Edit || !functions) {
    return UserSchema;
  }

  return UserSchema.shape({
    status: yup.mixed<UserStatus>(),
    email: yup
      .string()
      .required()
      .email()
      .callableFunction(
        functions,
        'emailUsableForUserCreation',
        false,
        'common.validations.fieldShouldBeUnique'
      ),
  });
};

export default getUserSchema;
