import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeSync } from 'normalize-diacritics';
import {
  Chip,
  Grid,
  ListItem,
  Paper,
  Typography,
  TextField as MuiTextField,
  InputAdornment,
  Button,
  Stack,
  FormLabel,
} from '@mui/material';
import {
  Container,
  DropDownCheckboxList,
  Select,
  TextField,
  Checkbox,
  FormControlHeader,
  Show,
} from '../../components';
import { useForm } from '../../hooks';
import firebaseHooks from '../../firebase/hooks';
import { CompanyType, InventoryClass, ModificationMode } from '../../enums';
import { Company as CompanyModel } from '../../models';
import getCompanySchema from '../../validation/CompanyValidations';
import { CompanyDetailsProps } from './CompanyDetails.props';

export const CompanyDetails = ({
  company,
  companyType,
}: CompanyDetailsProps) => {
  const { t } = useTranslation();
  const firestore = firebaseHooks.useFirestore();

  const agenciesOrCompanies =
    companyType === CompanyType.Agency ? 'agencies' : 'companies';

  const companyValidations = useMemo(
    () => getCompanySchema(firestore, companyType),
    [companyType, firestore]
  );

  const [shippingEmailField, setShippingEmailField] = useState('');

  const form = useForm(company, {
    mode: ModificationMode.Edit,
    validations: companyValidations,
    beforeSave: (item) => {
      item.type = companyType;

      if (!item.code) {
        item.code = null;
        item.codeNormalized = null;
      } else {
        item.codeNormalized = normalizeSync(item.code)
          .toLowerCase()
          .replace(/ /g, '');
      }

      item.orderFilesPermissions = {
        buyer: {
          confirmation: true,
          invoiceCredit: true,
          shipping: true,
        },
        general: {
          confirmation: false,
          invoiceCredit: false,
          shipping: false,
        },
        sales: {
          confirmation: false,
          invoiceCredit: false,
          shipping: true,
        },
      };

      item.nameNormalized = normalizeSync(item.name ?? '')
        .toLowerCase()
        .replace(/ /g, '');
    },
  });

  const updateShippingEmails = (emails: string[]) => {
    const duplicateFree =
      emails?.filter(
        (email, index) => email && emails.indexOf(email) === index
      ) || [];

    form.setItem({
      ...form.item,
      shippingDocEmailRecipient: duplicateFree,
    } as CompanyModel);
  };

  const addEmailsToList = () => {
    const updatedEmailsArr = shippingEmailField
      ?.toLowerCase()
      .replace(/ /g, '')
      .split(',')
      .concat(form.item?.shippingDocEmailRecipient || []);
    setShippingEmailField('');
    updateShippingEmails(updatedEmailsArr || []);
  };

  return (
    <>
      <FormControlHeader
        backTo={`/admin/${agenciesOrCompanies}`}
        form={form}
        title={t('companies.form.title', { name: form.item?.name })}
      />
      <Container backgroundColor="#f9f9f9" sx={{ pb: 5 }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ pr: { md: '20%' } }}>
            <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
              {t(
                `users.form.sections.${
                  companyType === CompanyType.Agency ? 'agency' : 'company'
                }ProfileInformation`
              )}
            </Typography>

            <TextField
              form={form}
              field="name"
              label={t(
                `${agenciesOrCompanies}.fields.${
                  companyType === CompanyType.Agency
                    ? 'agencyName'
                    : 'companyName'
                }`
              )}
              maxLength={255}
              required
            />

            <Show if={companyType === CompanyType.Customer}>
              <TextField
                form={form}
                field="code"
                label={t('companies.fields.companyCode')}
                maxLength={4}
              />
            </Show>

            <Stack mb={2}>
              <FormLabel>
                {companyType === CompanyType.Customer
                  ? t('companies.fields.accountNumber')
                  : t('agencies.fields.vendorId')}
              </FormLabel>
              <Typography>{company.item?.accountNumber}</Typography>
            </Stack>

            <Paper
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0,
                m: 0,
              }}
              component="ul"
            >
              <MuiTextField
                fullWidth
                type="text"
                label={t('companies.fields.shippingEmail')}
                value={shippingEmailField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        disabled={shippingEmailField.length <= 0}
                        onClick={() => addEmailsToList()}
                      >
                        {t('companies.actions.add')}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addEmailsToList();
                  }
                }}
                onChange={(e) => setShippingEmailField(e.target.value)}
              />
              {form.item?.shippingDocEmailRecipient?.map((email, key) => (
                <ListItem key={key} sx={{ width: 'inherit' }}>
                  <Chip
                    label={email}
                    color={
                      form.errors &&
                      Object.keys(form.errors).includes(
                        `shippingDocEmailRecipient[${key}]`
                      )
                        ? 'error'
                        : 'default'
                    }
                    onDelete={() => {
                      const updatedEmailsArr =
                        form.item?.shippingDocEmailRecipient?.filter(
                          (element) => element !== email
                        );
                      updateShippingEmails(updatedEmailsArr || []);
                    }}
                  />
                </ListItem>
              ))}
            </Paper>

            <Show if={companyType === CompanyType.Agency}>
              <Stack mt={3}>
                <FormLabel>{t('agencies.fields.salesRepIds')}</FormLabel>
                {company.item?.salesRepIds?.map((s) => (
                  <Typography key={s}>{s}</Typography>
                ))}
              </Stack>
            </Show>
          </Grid>

          <Grid item xs={12} md={6} sx={{ pr: { md: '20%' } }}>
            <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
              {t('users.form.sections.inventory')}
            </Typography>

            <DropDownCheckboxList
              form={form}
              label={t('companies.fields.canSeeCSAOrUL')}
              fields={['canSeeCSA', 'canSeeUL']}
              fieldLabels={[
                t('companies.fields.canSeeCSA'),
                t('companies.fields.canSeeUL'),
              ]}
            />

            <Select
              required
              form={form}
              field="class"
              label={t('companies.fields.inventoryClass')}
              options={Object.values(InventoryClass)}
              getOptionLabel={(inventory) => t(`inventoryClasses.${inventory}`)}
            />

            <Checkbox
              field="allowPartialCuts"
              form={form}
              variant="medium"
              label={t('companies.fields.partialCuts')}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
