import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { ProgressIndicator, FormControlHeader, UserForm } from '../components';
import { useCompany, useForm, usePageTitle, useUser } from '../hooks';
import { ModificationMode } from '../enums/';
import getUserSchema from '../validation/getUserSchema';
import { User as UserModel } from '../models';
import { Formable } from '../types';
import { getPageTitle } from '../utils/pages';

interface Props {
  form: Formable<UserModel>;
  user: UserModel;
}

const UserWrapper = ({ form, user }: Props) => {
  const { item: company } = useCompany(user.companyRef.id);
  const pageTitleOptions = {
    user: user.name,
  };
  usePageTitle(getPageTitle('user', pageTitleOptions));

  return (
    <>
      {company && (
        <UserForm
          form={form}
          mode={ModificationMode.Edit}
          companies={[company]}
          isAdmin={false}
          isAgent={false}
        />
      )}
      <Outlet context={form} />
    </>
  );
};

const User: React.FC = () => {
  const { t } = useTranslation();

  const { userId } = useParams();
  const user = useUser(userId || '');
  const form = useForm(user, {
    validations: getUserSchema(ModificationMode.Edit),
  });

  return (
    <>
      <FormControlHeader
        backTo={'/users'}
        form={form}
        title={t('users.form.title', { name: user.item?.name })}
      />
      {user.loading ? (
        <ProgressIndicator />
      ) : (
        user.item && <UserWrapper form={form} user={user.item} />
      )}
    </>
  );
};

export default User;
