import { useState, useEffect } from 'react';
import { Paper, Popper, MenuList } from '@mui/material';

import { ExpanderMenuItem } from '..';
import { SideMenuProps } from './SideMenu.props';

export const SideMenu = ({ anchorElRef, children }: SideMenuProps) => {
  const [open, setOpen] = useState<boolean>();

  // Force a re-render when anchorElRef has the toolbar DOM element
  useEffect(() => {
    setOpen(false);
  }, [anchorElRef]);

  if (!anchorElRef.current) {
    return null;
  }

  return (
    <Popper
      open={true}
      anchorEl={anchorElRef.current}
      placement="bottom-start"
      style={{
        zIndex: 1,
      }}
    >
      <Paper
        sx={{
          backgroundColor: 'primary.main',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: '1.5rem',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <MenuList
          sx={{
            pb: 4,
            '& .MuiMenuItem-root': {
              minWidth: open ? '10rem' : '0px !important',
              transition: 'min-width 500ms ease',
              overflow: 'hidden',
            },
            '& .MuiSvgIcon-root': {
              width: '1rem !important',
              height: '1rem !important',
            },
            '& .MuiMenuItem-root:after': {
              width: open ? '0.5rem' : '0',
              transition: 'width 250ms',
            },
            '& .MuiListItemText-root': {
              pr: open ? 5 : 0,
              width: open ? 'auto' : '0px',
              opacity: open ? '1' : '0',
              transition: '250ms all',
              letterSpacing: '0',
            },
          }}
        >
          <ExpanderMenuItem onClick={() => setOpen(!open)} open={!!open} />
          {children}
        </MenuList>
      </Paper>
    </Popper>
  );
};
