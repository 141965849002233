import {
  collection,
  CollectionReference,
  DocumentData,
  query,
  QueryConstraint,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { differenceWith, isEqual, xorWith } from 'lodash';
import { Filter, Nullable, Pageable, Sortable } from '../types';
import notEmpty from '../utils/notEmpty';
import { useDataCollection } from './useDataCollection';

/**
 * Access a Firestore collection, which is sorted, paginated, and subsequently filtered locally
 *
 * @param path - Firestore path
 * @param defaultQueryConstaints - Default query constraints to apply to query sent to Firestore
 * @param sorting - Sorting to apply locally to query
 * @param pagination - Pagination to apply locally to query
 * @param filters - Filters to apply to query. The first set of filters are sent to Firestore;
 * subsequent changes are applied locally
 * @param transformData - Callback to transform the data (after sorting, before pagniation)
 */
export function useLocalCollection<T>(
  path: string,
  defaultQueryConstaints: (QueryConstraint | undefined)[],
  sorting?: Nullable<Sortable>,
  pagination?: Pageable,
  filters: (Filter | undefined)[] = [],
  transformData?: (data: T[]) => T[]
) {
  const [firestoreFilters, setFirestoreFilters] = useState(filters);
  const [localFilters, setLocalFilters] = useState<(Filter | undefined)[]>();

  const firestore = useFirestore();
  const firestoreCollection = collection(
    firestore,
    path
  ) as CollectionReference<T>;

  const queryConstraints = [
    ...firestoreFilters.map((filter) =>
      filter ? where(...filter) : undefined
    ),
    ...defaultQueryConstaints,
  ].filter(notEmpty);

  const firestoreQuery = query<T, DocumentData>(
    firestoreCollection,
    ...queryConstraints
  );

  const { data } = useFirestoreCollectionData<T>(firestoreQuery);

  // Determine the difference between the original filters received and the
  // current filters, in order to trigger filtering on the new ones. Also
  // remove original filters from the Firestore filters if no longer in
  // the list.
  useEffect(() => {
    const newLocalFilters = differenceWith(filters, firestoreFilters, isEqual);
    const firestoreFiltersToRemove = differenceWith(
      firestoreFilters,
      filters,
      isEqual
    );

    if (firestoreFiltersToRemove.length > 0) {
      const newFirestoreFilters = xorWith(
        firestoreFilters,
        firestoreFiltersToRemove,
        isEqual
      );
      if (!isEqual(firestoreFilters, newFirestoreFilters)) {
        setFirestoreFilters(newFirestoreFilters);
      }
    }

    if (!isEqual(localFilters, newLocalFilters)) {
      setLocalFilters(newLocalFilters);

      if (pagination) {
        pagination.returnToBeginning();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilters, filters, firestoreFilters]);

  return useDataCollection(
    data,
    sorting,
    pagination,
    localFilters,
    transformData
  );
}
