import { useCallback, useMemo } from 'react';
import { getAuth } from 'firebase/auth';

import authFunctions from '../../../firebase/auth';
import { Persistable } from '../../../types';

import {
  ResetPasswordError,
  ResetPasswordModel,
} from './ResetPassword.interface';

export const useResetPassword = (
  oobCode: string
): Persistable<ResetPasswordModel> => {
  const item = useMemo(() => {
    return { newPassword: '', confirmPassword: '' };
  }, []);

  const save = useCallback(async (item: ResetPasswordModel) => {
    const auth = getAuth();

    try {
      await authFunctions.confirmPasswordReset(auth, oobCode, item.newPassword);

      return item;
    } catch (e) {
      throw new ResetPasswordError('users.errors.invalidPassword');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    item,
    save,
  };
};
