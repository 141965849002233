import { SxProps } from '@mui/material';

export const tableStyles: SxProps = {
  '&.MuiTableContainer-root': {
    overflowX: 'visible',
  },

  '& .MuiTable-root': {
    borderCollapse: 'collapse',
  },
};

export const tableHeaderStyles: SxProps = {
  '&.MuiTableHead-root': {
    borderBottom: 'inherit',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium': {
        paddingLeft: '16px',
      },
    },
  },
};

export const tableBodyStyles = (nbofRows: number): SxProps => {
  return {
    '&.MuiTableBody-root': {
      '& .MuiTableRow-root': {
        backgroundColor: 'inherit',
        py: { xs: '0.2em' },
      },

      [`& .MuiTableRow-root:not(:nth-of-type(n+${nbofRows + 1}))`]: {
        height: { md: '70px' },
        opacity: 0.7,
      },

      'tr > td': {
        border: 'none',
        '&:nth-of-type(n+4)': {
          py: {
            xs: '0.5em',
          },
        },

        '& .MuiInputBase-root': {
          height: {
            xs: '40px',
          },
        },
      },

      'tr > td:last-of-type': {
        backgroundColor: '#f9f9f9',
      },

      'tr.active-item': {
        border: {
          md: 'none',
        },
      },
    },
  };
};

export const tableCellStyles: SxProps = {
  backgroundColor: '#f9f9f9',
  '&.MuiTableBody-root &:last-child': {
    backgroundColor: '#f9f9f9',
  },
};
