import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { useCurrentUser } from '../../hooks';
import { FormattedTimestampProps } from './FormattedTimestamp.props';

export const FormattedTimestamp = ({
  timestamp,
  timeZone,
}: FormattedTimestampProps) => {
  const { i18n } = useTranslation();
  const user = useCurrentUser();
  const timezone = timeZone || user.timeZone;

  if (!timestamp || !timezone) {
    return null;
  }

  const dateTime = DateTime.fromMillis(timestamp.toMillis());

  const formattedDate = dateTime
    .setLocale(i18n.language)
    .setZone(timezone)
    .toLocaleString(DateTime.DATETIME_MED);

  return <>{formattedDate}</>;
};
