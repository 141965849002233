import { SxProps } from '@mui/material';

export const formControlRoot = (): SxProps => {
  return {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    marginBottom: '5px',
  };
};

export const formLabel = (): SxProps => {
  return {
    color: '#000',
  };
};

export const formRadioGroup = (): SxProps => {
  return {
    padding: '5px 14px 10px 14px',
  };
};
