import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useCompany, useCurrentUser } from '../../hooks';
import { Company } from '../../models';
import { ConversionRateFiltersProps } from './ConversionRateFilters.props';
import { ConversionRateFilters } from './ConversionRateFilters.component';

export const AgentConversionRateFilters = (
  props: Omit<ConversionRateFiltersProps, 'companies' | 'loadingCompanies'>
) => {
  const currentUser = useCurrentUser();
  const { item: currentCompany } = useCompany(currentUser.companyRef.id);

  const companies = useMemo(
    (): Partial<Company>[] =>
      sortBy(
        Object.values(currentCompany?.associatedCompanies ?? [])
          .filter((c) => !c.isDeleted)
          .map((c) => {
            return {
              id: c.companyId,
              name: c.companyName,
              accountNumber: c.companyAccountNumber,
              isDeleted: c.isDeleted,
              customerAddresses: c.customerAddresses,
            };
          }),
        'name'
      ),
    [currentCompany]
  );

  return (
    <ConversionRateFilters {...props} companies={companies as Company[]} />
  );
};
