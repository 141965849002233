import { useParams } from 'react-router-dom';
import { User } from '../models';
import { useNewDocumentByFunction } from './useNewDocumentByFunction';
import { usePortalSettings } from '.';

/**
 * Hook to allow creation of a new User
 */
export function useNewUser(canReserveDefault = false) {
  const { agencyId } = useParams();
  const { item: portalSettingsData } = usePortalSettings();

  const persistable = useNewDocumentByFunction(
    'createUser',
    portalSettingsData
      ? {
          ...new User(
            portalSettingsData.extensionNotificationDelay,
            portalSettingsData.holdDelay,
            portalSettingsData.maxReservationsPerPeriod
          ),
          canReserve: canReserveDefault || !!agencyId,
        }
      : null
  );

  return persistable;
}
