import { SxProps } from '@mui/material';

export const tableBodyStyles: SxProps = {
  '&.MuiTableBody-root': {
    '& .MuiTableRow-root': {
      wordBreak: 'normal',

      '& .MuiTableCell-root': {
        padding: { md: '10px' },
        maxWidth: { md: '300px' },
        overflow: { md: 'hidden' },
        textOverflow: { md: 'ellipsis' },
        overflowWrap: { xs: 'anywhere', md: 'unset' },

        '& #reel-returns-badge': {
          display: 'inline-block',
          textAlign: 'left',
          wordBreak: 'normal',
        },
      },
    },
  },
};
