import React from 'react';
import { BulletinBoardGallery } from '../../components';
import { UserPermission } from '../../enums';
import RequirePermission from '../../security/RequirePermission';
import { usePageTitle } from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import PortalSettings from './partials/PortalSettings';

const AgentBulletinBoardSettings: React.FC = () => {
  usePageTitle(getPageTitle('portalSettings.agentBulletinBoard'));

  return (
    <RequirePermission oneOf={[UserPermission.ManageAgentBulletinBoard]}>
      <PortalSettings>
        <BulletinBoardGallery />
      </PortalSettings>
    </RequirePermission>
  );
};

export default AgentBulletinBoardSettings;
