import { useTranslation } from 'react-i18next';
import { To, Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { ContrastButtonMain, Show } from '..';
import { OrderControlHeaderProps } from './OrderControlHeader.props';

export const OrderControlHeader = ({
  backTo,
  isSelected: showBTN,
  selectAll,
  deleteSelected,
  downloadSelected,
  emailSelected,
  isAllSelected,
}: OrderControlHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Grid item maxWidth="xl" sx={{ my: { xs: 2, md: 0 } }} flexGrow={1}>
      <Grid container justifyContent={'flex-end'}>
        <Grid
          item
          maxWidth="xl"
          sx={{
            my: { xs: 2, md: 0 },
          }}
        >
          <Button variant="outlined" sx={{ mr: 2 }} onClick={selectAll}>
            {isAllSelected
              ? t('orders.forms.unselectAll')
              : t('orders.forms.selectAll')}
          </Button>
        </Grid>

        <Show if={!!showBTN && downloadSelected !== undefined}>
          <Grid
            item
            maxWidth="xl"
            sx={{
              my: { xs: 2, md: 0 },
              display: { xs: 'none', md: 'inline-flex' },
            }}
          >
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={downloadSelected}
            >
              {t('orders.forms.downloadDocs')}
            </Button>
          </Grid>
        </Show>

        <Show if={!!showBTN && deleteSelected !== undefined}>
          <Grid item maxWidth="xl" sx={{ my: { xs: 2, md: 0 } }}>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={deleteSelected}>
              {t('orders.forms.deleteDocs')}
            </Button>
          </Grid>
        </Show>

        <Show if={!!showBTN}>
          <Grid item maxWidth="xl" sx={{ my: { xs: 2, md: 0 } }}>
            <ContrastButtonMain
              variant="contained"
              sx={{ mr: 2 }}
              onClick={emailSelected}
            >
              {t('orders.forms.emailDocs')}
            </ContrastButtonMain>
          </Grid>
        </Show>

        <Grid item maxWidth="xl" sx={{ my: { xs: 2, md: 0 } }}>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            component={Link}
            to={backTo as To}
          >
            {t('forms.back')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
