import { Button } from '@mui/material';
import * as Sx from './AssignDocumentOutput.styles';

export interface AssignDocumentOutputProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const AssignDocumentOutput = ({
  children,
  onClick,
}: AssignDocumentOutputProps) => (
  <Button variant="contained" sx={Sx.assignButton()} onClick={onClick}>
    {children}
  </Button>
);
