import React, { useState, useEffect } from 'react';
import { TextField as MuiTextField } from '@mui/material';

import { useFormFieldValue } from '../../hooks';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import { PhoneNumberFieldProps } from './PhoneNumberField.props';

export const PhoneNumberField = <T,>({
  label,
  field,
  form,
  disabled,
  required,
  variant = 'number',
}: PhoneNumberFieldProps<T>) => {
  const { value: formValue, setValue: setFormValue } = useFormFieldValue<
    T,
    string | null
  >(form, field);

  const [value, setValue] = useState<string | null>(formValue);

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (form.fieldRef(id)?.current?.hasAttribute('autocompleted')) {
      return onBlur(event);
    }

    setValue(event.target.value.replace(/[^()+-. \d]/g, ''));
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const cleanedValue = event.target.value.replace(/\D/g, '');
    setFormValue(cleanedValue);
    if (cleanedValue === '') {
      setValue(null);
    }
  };

  useEffect(() => {
    let phoneNumber = formValue;

    if (variant === 'number') {
      phoneNumber = formatPhoneNumber(phoneNumber);
    }

    setValue(phoneNumber);
  }, [formValue, variant]);

  const id = field as string;

  return (
    <MuiTextField
      id={id}
      aria-labelledby={`${id}-label`}
      disabled={disabled}
      error={!!form.errors[field]}
      fullWidth
      helperText={form.errors[field]}
      inputProps={{ maxLength: 50 }}
      inputRef={form.fieldRef(id)}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      sx={{ mt: 1.5, mb: 1.5 }}
      value={value || ''}
    />
  );
};
