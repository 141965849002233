import { styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import { CustomPickerDayProps } from './ReelReturnDatePicker.props';

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' && prop !== 'isTheFollowingWeek',
})<CustomPickerDayProps>(({ theme, isSelected, isTheFollowingWeek }) => {
  return {
    borderRadius: 0,
    ...(isSelected && {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.secondary.main,
      },
    }),
    ...(isTheFollowingWeek && {
      backgroundColor: '#E2730588',
    }),
  };
}) as React.ComponentType<CustomPickerDayProps>;
