import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupervisedUserCircle } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { QuestionDrawer } from '../../../components';
import { useNotification, useProgress } from '../../../hooks';
import { NotificationLevel } from '../../../enums';
import { Modal } from '../../../types';

const DeleteUser: Modal = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();
  const deleteFunction = httpsCallable(functions, 'deleteUser');
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const deleteUser = async () => {
    setDisabled(true);

    addNotification(
      t('users.deleteUser.progress'),
      NotificationLevel.Information
    );

    try {
      await deleteFunction(params.userId);
      addNotification(t('users.deleteUser.success'), NotificationLevel.Success);
    } catch {
      addNotification(t('users.deleteUser.error'), NotificationLevel.Error);
    }

    setDisabled(false);
    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <QuestionDrawer
      disabled={disabled}
      open={true}
      icon={<SupervisedUserCircle sx={{ color: 'secondary.main' }} />}
      message={t('users.deleteUser.message')}
      onConfirm={() => deleteUser()}
      onCancel={() => navigateBack()}
      title={t('users.deleteUser.title')}
    />
  );
};

export default DeleteUser;
