import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { Outlet } from 'react-router-dom';
import {
  Container,
  DecoratedHeader,
  OrderFilters,
  OrderTable,
} from '../../components';
import { getPageTitle } from '../../utils/pages';
import {
  useCompany,
  useCurrentUser,
  usePageTitle,
  useRouterOutlet,
} from '../../hooks/';
import {
  AssociatedCompany,
  OrderFilters as OrderFiltersType,
} from '../../types';
import { RequirePermission } from '../../security';
import { UserPermission } from '../../enums';

const Orders: React.FC = () => {
  const { t } = useTranslation();
  usePageTitle(getPageTitle('orders'));
  const { showOutletOnly } = useRouterOutlet();

  const [filters, setFilters] = useState<OrderFiltersType>({
    companyId: null,
    searchField: null,
  });

  const currentUser = useCurrentUser();
  const { item: agency } = useCompany(currentUser.companyRef.id);

  const associatedCompanies: AssociatedCompany[] = useMemo(() => {
    if (!agency?.associatedCompanies) return [];

    return Object.values(agency.associatedCompanies) as AssociatedCompany[];
  }, [agency?.associatedCompanies]);

  return (
    <RequirePermission oneOf={[UserPermission.ViewOrders]}>
      {showOutletOnly || (
        <>
          <Container>
            <DecoratedHeader
              icon={<FolderOpenIcon />}
              title={t('pages.orders.pageTitle')}
              variant="colored"
            />
          </Container>

          <Container>
            <OrderFilters
              companies={associatedCompanies}
              filters={filters}
              setFilters={setFilters}
            />
          </Container>
          <OrderTable isAgent filters={filters} />
        </>
      )}
      <Outlet />
    </RequirePermission>
  );
};

export default Orders;
