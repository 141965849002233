import { httpsCallable } from 'firebase/functions';
import { createContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFunctions } from 'reactfire';

import { User } from 'firebase/auth';
import { UserRole, UserStatus } from '../enums';
import { useAppLanguage, useUser } from '../hooks';
import { useAuthentication } from '../hooks/useAuthentication';
import { User as UserModel } from '../models';

export interface CurrentUserContextValue {
  authUser: User | null;
  user: UserModel;
  role: UserRole;
  loading: boolean;
  logout: () => Promise<void>;
  refresh: () => void;
  isAdmin: boolean;
  isAgent: boolean;
}

export const CurrentUserContext = createContext({} as CurrentUserContextValue);

interface Props {
  children: React.ReactNode;
}

export const CurrentUserProvider = ({ children }: Props) => {
  const {
    user: authUser,
    refresh,
    logout,
    role,
    ...propAuth
  } = useAuthentication();
  // This should never be null when used inside of an authentication check, therefore:
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const persistentAuthUser = useRef(authUser!);
  const location = useLocation();
  const { item: user } = useUser(persistentAuthUser.current.uid);
  const [storedUser, setStoredUser] = useState(user);

  const navigate = useNavigate();
  const functions = useFunctions();
  const activateUser = httpsCallable(functions, 'activateUser');

  useAppLanguage(user?.language);

  useEffect(() => {
    if (storedUser?.status === UserStatus.Inactive) {
      logout();
    }
  }, [storedUser?.status, logout]);

  useEffect(() => {
    if (
      !!authUser &&
      !authUser?.emailVerified &&
      location.pathname !== '/validate-email'
    ) {
      navigate('/not-verified');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, storedUser, location.pathname]);

  useEffect(() => {
    if (
      authUser?.emailVerified &&
      (storedUser?.status === UserStatus.Pending ||
        storedUser?.status === UserStatus.PendingEmailValidation)
    ) {
      activateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, storedUser]);

  useEffect(() => {
    if (
      storedUser &&
      user &&
      role &&
      (storedUser.status !== user.status ||
        storedUser.role !== user.role ||
        storedUser.role !== role)
    ) {
      refresh();
    }

    setStoredUser(user);
  }, [refresh, storedUser, user, role]);

  if (!user || !role) {
    return null;
  }

  return (
    <CurrentUserContext.Provider
      value={{ authUser, user, refresh, logout, role, ...propAuth }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
