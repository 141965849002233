import React, { useEffect } from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  useCompany,
  usePageTitle,
  usePermission,
  useRouterOutlet,
} from '../../hooks';
import { AgencyPageView, CompanyType, UserPermission } from '../../enums';
import {
  AgencyCustomersList,
  CompanyUserList,
  ProgressIndicator,
  Show,
} from '../../components';
import { getPageTitle } from '../../utils/pages';
import { RequirePermission } from '../../security';
import AgencyPartial from './partials/AgencyPartial';

interface Props {
  view?: AgencyPageView;
}

const Agency: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { showOutletOnly } = useRouterOutlet();
  const { agencyId } = useParams();
  const [searchParams] = useSearchParams();

  const { loading: agencyLoading, item: agency } = useCompany(agencyId || '');
  const viewParam = searchParams.get('view');

  const [view, setView] = React.useState<AgencyPageView>(
    AgencyPageView.AssociatedCompanies
  );

  usePageTitle(getPageTitle('agency.edit', { name: agency?.name }));

  useEffect(() => {
    if (agency && agency?.type !== CompanyType.Agency) {
      navigate(`/admin/companies/${agencyId}`);
    }
    if (!agencyLoading && !agency) {
      navigate('/admin/agencies');
    }
  }, [agencyId, agency, navigate, agencyLoading]);

  useEffect(() => {
    if (viewParam) {
      setView(viewParam as AgencyPageView);
      navigate(`/admin/agencies/${agencyId}`);
    }
  }, [agencyId, navigate, viewParam]);

  const showUserList = Boolean(
    hasPermission(UserPermission.ManageAgencyUsers) &&
      view === AgencyPageView.AssignedUsers
  );

  const showCustomerList = Boolean(
    hasPermission(UserPermission.ManageAgencies) &&
      view === AgencyPageView.AssociatedCompanies
  );

  return (
    <RequirePermission oneOf={[UserPermission.ManageAgencies]}>
      {showOutletOnly || (
        <AgencyPartial agencyId={agencyId} view={view} setView={setView}>
          <>
            {agencyLoading ? (
              <ProgressIndicator />
            ) : (
              <>
                <Show if={showCustomerList}>
                  <AgencyCustomersList agencyId={agencyId} />
                </Show>
                <Show if={showUserList}>
                  <CompanyUserList companyId={agencyId} isAgency />
                </Show>
              </>
            )}
          </>
        </AgencyPartial>
      )}

      <Outlet />
    </RequirePermission>
  );
};

export default Agency;
