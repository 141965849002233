import { Autocomplete, TextField } from '@mui/material';
import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { InventoryType } from '../../../../../enums';
import { useUsers } from '../../../../../hooks';
import { Filter } from '../../../../../types';
import { input } from '../ImpersonateCustomer.styles';
import { UserSelectProps } from './UserSelect.props';

const UserSelect = ({
  company,
  fromCollapse,
  inventoryType,
  onSelect,
  user,
}: UserSelectProps) => {
  const firestore = useFirestore();

  const userFilters = useMemo(() => {
    const queryFilters = [] as Filter[];
    const companyRef = doc(firestore, 'Companies', company.id);

    queryFilters.push(['status', '==', 'active']);
    queryFilters.push(['companyRef', '==', companyRef]);
    queryFilters.push(['isNCEmployee', '==', false]);

    if (fromCollapse && inventoryType === InventoryType.CSA) {
      queryFilters.push(['canSeeCSA', '==', true]);
    }

    if (fromCollapse && inventoryType === InventoryType.UL) {
      queryFilters.push(['canSeeUL', '==', true]);
    }

    return queryFilters;
  }, [company, firestore, fromCollapse, inventoryType]);

  const { list: users } = useUsers({
    includeDeleted: false,
    filters: userFilters,
  });

  return (
    <Autocomplete
      fullWidth={true}
      disabled={!company || users.length === 0}
      popupIcon={<KeyboardArrowDownIcon />}
      options={users ?? []}
      value={user}
      onChange={(e, value) => onSelect(value)}
      getOptionLabel={(user) => user.name || ''}
      renderInput={(params) => (
        <TextField {...params} label="User" variant="filled" sx={input} />
      )}
    />
  );
};

export default UserSelect;
