import { UserRole } from '../enums';
import { roles } from '../security';

export enum UserRoleGroup {
  Admin = 'admin',
  Agent = 'agent',
  Customer = 'customer',
}

export const getUserRoleGroup = (role?: UserRole) => {
  if (!role) {
    return null;
  }
  if (roles.AdminRoles.includes(role)) {
    return UserRoleGroup.Admin;
  }
  if (roles.AgentRoles.includes(role)) {
    return UserRoleGroup.Agent;
  }
  if (roles.CustomerRoles.includes(role)) {
    return UserRoleGroup.Customer;
  }

  return null;
};

export const UserRoleGroupPath: { [key in UserRoleGroup]: string } = {
  [UserRoleGroup.Admin]: '/admin',
  [UserRoleGroup.Agent]: '/agent',
  [UserRoleGroup.Customer]: '',
};
