import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollToTopOnRouteChangeProps } from './ScrollToTopOnRouteChange.props';

export const ScrollToTopOnRouteChange = ({
  children,
}: ScrollToTopOnRouteChangeProps) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};
