/* eslint-disable max-len */
interface Props {
  color?: string;
}

const ExtendIcon: React.FC<Props> = ({ color = 'white' }) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58317 6.66699V12.417L13.0873 15.1962L13.854 14.0462L10.0207 11.7462V6.66699H8.58317Z"
      fill={color}
    />
    <path
      d="M16.1732 10.5003C16.2211 10.8166 16.2498 11.1328 16.2498 11.4587C16.2498 15.1962 13.279 18.167 9.5415 18.167C5.804 18.167 2.83317 15.1962 2.83317 11.4587C2.83317 7.72116 5.804 4.75033 9.5415 4.75033C10.2123 4.75033 10.8544 4.84616 11.4582 5.02824V3.05408C10.8448 2.91033 10.2028 2.83366 9.5415 2.83366C4.74984 2.83366 0.916504 6.66699 0.916504 11.4587C0.916504 16.2503 4.74984 20.0837 9.5415 20.0837C14.3332 20.0837 18.1665 16.2503 18.1665 11.4587C18.1665 11.1328 18.1473 10.8166 18.109 10.5003H16.1732Z"
      fill={color}
    />
    <path
      d="M18.1665 3.79199V0.916992H16.2498V3.79199H13.3748V5.70866H16.2498V8.58366H18.1665V5.70866H21.0415V3.79199H18.1665Z"
      fill={color}
    />
  </svg>
);

export default ExtendIcon;
