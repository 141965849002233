import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationLevel } from '../../enums';

import { useNotification } from '../../hooks';

import * as Sx from './Notification.styles';

export const Notification = () => {
  const { i18n } = useTranslation();
  const { notification, clearNotification } = useNotification();

  return notification ? (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={
        notification.level === NotificationLevel.Success ? 6000 : null
      }
      onClose={clearNotification}
      sx={Sx.snackbar(notification.elevated)}
    >
      <Alert
        variant="filled"
        onClose={clearNotification}
        severity={notification.level}
        sx={Sx.alert}
        lang={i18n.language}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  ) : null;
};
