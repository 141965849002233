import { SxProps } from '@mui/material';
import { MIN_TABLE_ROW_HEIGHT } from './constants';

export const tableBodyStyles: SxProps = {
  '&.MuiTableBody-root': {
    '& .MuiTableRow-root': {
      minHeight: { md: MIN_TABLE_ROW_HEIGHT },
      height: { md: MIN_TABLE_ROW_HEIGHT },
    },
  },
};
