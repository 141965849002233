import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import * as Sx from '../ReelReturnDrawer.styles';
import { ReelReturnAdminDrawerProps } from '../ReelReturnDrawer.props';

export const ReelReturnDrawerAdmin = ({
  company,
  isNewReelReturn,
  linearFeet,
  reelReturnStats,
  totalTrailerNeeded,
  user,
}: ReelReturnAdminDrawerProps) => {
  const { t } = useTranslation();
  const { quantity, subTotal, tax, taxPercentage, total } = reelReturnStats;

  return (
    <>
      {/* Data Section  */}
      <Grid
        container
        item
        justifyContent={'space-between'}
        alignItems={'center'}
        md={10}
        columnSpacing={12}
        pl={3}
      >
        {/* Customer sub-section */}
        <Grid container item md={4} sx={Sx.adminDataSection}>
          <Grid item md={6}>
            <Grid item>
              {t(
                `reelReturns.edit.drawer.admin.${
                  isNewReelReturn ? 'creatingFor' : 'createdBy'
                }`
              )}
            </Grid>
            <Grid item sx={Sx.valueStyles} textAlign={'center'}>
              {user ?? '--'}
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid item>{t('reelReturns.edit.drawer.admin.company')}</Grid>
            <Grid item sx={Sx.valueStyles}>
              {company ?? '--'}
            </Grid>
          </Grid>
        </Grid>

        {/* Trailer sub-section */}
        <Grid container item md={3} sx={Sx.adminDataSection}>
          <Grid item md={4}>
            <Grid item>{t('reelReturns.edit.drawer.quantity')}</Grid>
            <Grid item sx={Sx.valueStyles}>
              {quantity}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid item>{t('reelReturns.edit.drawer.linearFeet')}</Grid>
            <Grid item sx={Sx.valueStyles}>
              {linearFeet}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid item>{t('reelReturns.edit.drawer.totalTrailerNeeded')}</Grid>
            <Grid item sx={Sx.valueStyles}>
              {totalTrailerNeeded}
            </Grid>
          </Grid>
        </Grid>

        {/* Total sub-section */}
        <Grid container item md={4} sx={Sx.adminDataSection}>
          <Grid item md={4}>
            <Grid item>{t('reelReturns.edit.drawer.subTotal')}</Grid>
            <Grid item sx={Sx.valueStyles}>
              {subTotal}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid item>
              {t('reelReturns.edit.drawer.tax', {
                taxPercent: taxPercentage,
              })}
            </Grid>
            <Grid item sx={Sx.valueStyles}>
              {tax}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid item>{t('reelReturns.edit.drawer.totalCredit')}</Grid>
            <Grid item sx={Sx.valueStyles}>
              {total}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
