import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Autocomplete,
  Checkbox,
  FilterOptionsState,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ReelReturnsFilters as Filters } from '../../types';
import { useCompanies, useReelDepots, useReelSizes } from '../../hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer.component';
import { Company } from '../../models';
import { formatReelDepotLabel } from '../../models/ReelDepot';
import { CompanyType, ReelReturnStatus } from '../../enums';
import { ReelReturnsReportFiltersProps } from './ReelReturnsReportFilters.props';

const emptyFilters: Filters = {
  companyIds: null,
  depotLocation: null,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now().plus({ month: 1 }),
  reelSize: null,
  status: null,
};

const PAGE_SIZE = 30;

export const ReelReturnsReportFilters = ({
  onSearch,
  loadedFilters,
}: ReelReturnsReportFiltersProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState<Filters>(
    loadedFilters || emptyFilters
  );
  const [selectedOptions, setSelectedOptions] = useState<Company[]>([]);
  const [companyInputValue, setCompanyInputValue] = useState<string>('');
  const { list: companies, loading: loadingCompanies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer]],
  });
  const { list: reelSizes } = useReelSizes({
    filters: [['isReturnable', '==', true]],
  });
  const { list: reelDepots } = useReelDepots();

  const reelReturnStatuses = [
    ReelReturnStatus.Cancelled,
    ReelReturnStatus.Generated,
    ReelReturnStatus.Received,
  ];

  const clearFilters = () => {
    setFilters(emptyFilters);
    setSelectedOptions([]);
    onSearch(null);
  };

  const _filterOptions = createFilterOptions<Company>();

  const filterOptions = (
    options: Company[],
    state: FilterOptionsState<Company>
  ) => {
    const results = _filterOptions(options, state);
    return [...selectedOptions, ...results.slice(0, page * PAGE_SIZE)];
  };

  useEffect(() => {
    if (loadedFilters) {
      setSelectedOptions(() =>
        companies.filter((company) =>
          loadedFilters?.companyIds?.includes(company.id)
        )
      );

      setFilters(loadedFilters);
    }
  }, [companies, loadedFilters]);

  return (
    <FilterContainer
      buttonsDisabled={!filters.startDate || !filters.endDate}
      title={t('reports.reelReturns.title')}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.userActivity.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate}
          />

          <DatePicker
            label={t('reports.userActivity.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(reelSize) => reelSize.reelType}
            options={reelSizes}
            value={filters.reelSize}
            onChange={(_, reelSize) =>
              setFilters((prev) => {
                return { ...prev, reelSize };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.reelReturns.reelSize')}
              />
            )}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            options={companies ?? []}
            loading={loadingCompanies}
            value={selectedOptions}
            onClose={() => setPage(1)}
            ListboxProps={{
              onScrollCapture: (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  setPage((page) => page + 1);
                }
              },
              /** Fix for scrolling to top when loading new page of options */
              role: 'list-box',
            }}
            getOptionDisabled={(option) =>
              selectedOptions.length === 30 && !selectedOptions.includes(option)
                ? true
                : false
            }
            getOptionLabel={(company) => company.name}
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Typography sx={{ width: 'calc(100% - 10px)' }}>
                  {option.name}
                </Typography>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
              </li>
            )}
            onChange={(_, item) => {
              setSelectedOptions(item);
              setFilters((prev) => {
                return {
                  ...prev,
                  companyIds:
                    item.length > 0 ? item.map((val) => val.id) : null,
                };
              });
            }}
            renderTags={(value) => (
              <Typography pl={'5px'}>
                {value.length === 1
                  ? value[0].name
                  : t('reports.conversionRate.companySelected', {
                      count: value.length,
                    })}
              </Typography>
            )}
            sx={{ flex: '1.5 1 0' }}
            inputValue={companyInputValue}
            onInputChange={(
              event: React.SyntheticEvent,
              value: string,
              reason: string
            ) => {
              if (event && event.type === 'blur') {
                setCompanyInputValue('');
              } else if (reason !== 'reset') {
                setCompanyInputValue(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.conversionRate.company')}
              />
            )}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(reelDepot) => formatReelDepotLabel(reelDepot)}
            options={reelDepots}
            value={filters.depotLocation}
            onChange={(_, reelDepot) =>
              setFilters((prev) => {
                return { ...prev, depotLocation: reelDepot };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.reelReturns.reelDepot')}
              />
            )}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(status) =>
              t(`reports.reelReturns.statusValue.${status}`)
            }
            options={reelReturnStatuses}
            value={filters.status}
            onChange={(_, status) =>
              setFilters((prev) => {
                return { ...prev, status: status };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField {...params} label={t('reports.reelReturns.status')} />
            )}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
