import { SxProps, Theme } from '@mui/material';

export const avatar: SxProps = {
  backgroundColor: 'secondary.contrastText',
  border: 1,
  borderColor: 'secondary.main',
};

export const container = (
  sx?: SxProps<Theme>,
  background?: string,
  backgroundColor?: string,
  roundedTopCorners?: boolean
): SxProps<Theme> => {
  return {
    ...sx,
    background,
    backgroundColor,
    borderTopLeftRadius: { xs: 0, md: roundedTopCorners ? '1rem' : 0 },
    borderTopRightRadius: { xs: 0, md: roundedTopCorners ? '1rem' : 0 },
  };
};

export const wrapper = (
  subSx?: SxProps<Theme>,
  handleMenu?: boolean
): SxProps<Theme> => {
  return {
    ...subSx,
    pl: { xs: 0, md: handleMenu ? '4rem !important' : undefined },
    pr: { xs: 0 },
  };
};
