import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/system';
import { TFunction } from 'react-i18next';
import { Company } from '../../models';
import { DataTableColumn } from '../../tables';
import { UnderlinedLink } from '../UnderlinedLink/UnderlinedLink.component';
import { CompaniesTableType } from '../../enums';

export const getCompaniesTableColumns = (
  type: CompaniesTableType,
  t: TFunction
) => {
  const companiesCountItem = (count: number) => (
    <Box display="flex">
      <BusinessCenterIcon fontSize="small" sx={{ mr: 1 }} />
      {count || 0}
    </Box>
  );

  const employeeCountItem = (count: number) => (
    <Box display="flex">
      <PersonIcon fontSize="small" sx={{ mr: 1 }} />
      {count}
    </Box>
  );

  const nameItem = (name: string, company: Company) => (
    <UnderlinedLink to={`/admin/${type}/${company.id}`}>{name}</UnderlinedLink>
  );

  const columns: { [key: string]: JSX.Element | undefined } = {
    accountNumber: (
      <DataTableColumn
        key="accountNumber"
        property="accountNumber"
        title={t('companies.fields.accountNumber')}
      />
    ),
    associatedCompaniesCount: (
      <DataTableColumn
        key="associatedCompaniesCount"
        property="associatedCompaniesCount"
        title={t('companies.fields.numberOfCompanies')}
        output={companiesCountItem}
      />
    ),
    code: (
      <DataTableColumn
        key="code"
        property="code"
        title={t('companies.fields.code')}
      />
    ),
    employeeCount: (
      <DataTableColumn
        key="employeeCount"
        property="employeeCount"
        title={t(
          `companies.fields.numberOf${
            type === CompaniesTableType.Companies ? 'Users' : 'Agents'
          }`
        )}
        output={employeeCountItem}
      />
    ),
    inventoryClass: (
      <DataTableColumn
        key="class"
        property="class"
        title={t('companies.fields.inventoryClass')}
        output={(value) => `Class ${value}`}
      />
    ),
    name: (
      <DataTableColumn
        key="name"
        property="name"
        title={t('companies.fields.name')}
        output={nameItem}
      />
    ),
    salesRepIds: (
      <DataTableColumn
        key="salesRepIds"
        property="salesRepIds"
        title={t('companies.fields.salesRepName')}
        output={(value) => value.join(', ')}
      />
    ),
  };

  const companiesColumns = [
    columns.name,
    columns.code,
    columns.accountNumber,
    columns.employeeCount,
    columns.inventoryClass,
  ];
  const agenciesColumns = [
    columns.name,
    columns.salesRepIds,
    columns.associatedCompaniesCount,
    columns.employeeCount,
  ];

  if (type === CompaniesTableType.Agencies) {
    return agenciesColumns;
  } else {
    return companiesColumns;
  }
};
