import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessCenter } from '@mui/icons-material';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import { doc, deleteDoc } from 'firebase/firestore';
import { QuestionDrawer } from '../../../components';
import { useNotification, useProgress } from '../../../hooks';
import { NotificationLevel } from '../../../enums';
import { Modal } from '../../../types';

const DeleteSpecification: Modal = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const firestore = useFirestore();
  const resolvedPath = useResolvedPath('..');
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const navigate = useNavigate();
  const { specId } = useParams();

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const deleteSpec = async () => {
    setDisabled(true);

    try {
      if (specId) {
        await deleteDoc(doc(firestore, 'Specifications', specId));
      }

      addNotification(
        t('portalSettings.specifications.deleteSpec.success'),
        NotificationLevel.Success
      );
    } catch {
      addNotification(
        t('portalSettings.specifications.deleteSpec.error'),
        NotificationLevel.Error
      );
    }

    navigateBack();
  };

  const navigateBack = () => {
    navigate(resolvedPath);
  };

  return (
    <QuestionDrawer
      disabled={disabled}
      open={true}
      icon={<BusinessCenter sx={{ color: 'secondary.main' }} />}
      message={t('portalSettings.specifications.deleteSpec.message')}
      onConfirm={() => deleteSpec()}
      onCancel={() => navigateBack()}
      title={t('portalSettings.specifications.deleteSpec.title')}
    />
  );
};

export default DeleteSpecification;
