import { SxProps } from '@mui/material';

export const container: SxProps = {
  alignItems: 'center',
  flexWrap: 'wrap',
  mt: { xs: 2, md: 5 },
  mb: { xs: 0, md: 5 },
};

export const avatar = (variant?: 'monochrome' | 'colored'): SxProps => {
  return {
    m: 1,
    mr: 2,
    backgroundColor:
      variant === 'monochrome' ? 'transparent' : 'secondary.main',
    borderColor: variant === 'monochrome' ? 'primary.main' : 'secondary.main',
    borderWidth: '3px',
    borderStyle: 'solid',
    width: { xs: '2em', md: '3em' },
    height: { xs: '2em', md: '3em' },
    '& .MuiSvgIcon-root': {
      color: variant === 'monochrome' ? 'primary.main' : 'background.paper',
      width: { xs: '1em', md: '1.5em' },
      height: { xs: '1em', md: '1.5em' },
    },
  };
};

export const title: SxProps = {
  '& a': { textDecoration: 'none', color: 'inherit' },
};

export const contentItem: SxProps = {
  flexBasis: { xs: '100%', md: 'unset' },
  ml: 'auto',
  mt: { xs: -2, md: 0 },
};

export const status: SxProps = {
  ml: '24px',
};
