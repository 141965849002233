import { Button, Grid, Typography } from '@mui/material';
import { Link, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, ContrastButtonLight, Show } from '..';

interface Props {
  title: string;
  onCancel: () => void;
  onSave: () => void;
  showSave: boolean;
  to: string;
}

export const CompanyFormControlHeader = ({
  title,
  onCancel,
  onSave,
  showSave,
  to,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Container
        background="linear-gradient(270deg, #ee891b 0%, #e27305 100%)"
        roundedTopCorners={true}
        sx={{ pb: { xs: '1em', md: 'inherit' } }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item maxWidth="xl">
            <Typography
              component="h2"
              variant="h2"
              sx={{
                mt: { xs: 2, md: 7 },
                mb: {
                  xs: 2,
                  md: 7,
                },
              }}
              color="white"
            >
              {title}
            </Typography>
          </Grid>
          <Grid>
            <Show if={showSave}>
              <Button sx={{ mr: 2 }} variant="contained" onClick={onSave}>
                {t('forms.save')}
              </Button>
              <ContrastButtonLight
                sx={{ mr: 2 }}
                variant="outlined"
                onClick={onCancel}
              >
                {t('forms.cancel')}
              </ContrastButtonLight>
            </Show>
            <Show if={!showSave}>
              <Button
                sx={{ mr: 2 }}
                component={Link}
                to={to as To}
                variant="contained"
              >
                {t('forms.back')}
              </Button>
            </Show>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
