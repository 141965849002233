import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCompanies,
  usePageTitle,
  usePermission,
  usePermissionRoles,
  usePortalMetadata,
  useRouterOutlet,
} from '../../hooks';
import { CompanyType, UserPermission } from '../../enums/';
import { RequirePermission, statuses } from '../../security';
import { Filter, UserFilters as UserFiltersType } from '../../types';
import {
  FilterContainer,
  UserFilters,
  UserTable,
  UserApprovalTable,
} from '../../components';
import { getPageTitle } from '../../utils/pages';

import UsersPartial from './partials/Users';

interface UsersProps {
  mode?: 'all' | 'approvals';
}

const Users: React.FC<UsersProps> = ({ mode = 'all' }) => {
  const { t } = useTranslation();
  usePageTitle(
    mode === 'approvals'
      ? getPageTitle('users.awaitingApproval')
      : getPageTitle('users')
  );
  const { outlet, showOutletOnly } = useRouterOutlet();
  const { hasPermission } = usePermission();
  const { item: metaData } = usePortalMetadata();
  const awaitingApprovals = metaData?.usersAwaitingApprovalCount ?? 0;
  const filteredRoles = usePermissionRoles({
    extraCustomerPermissions: [UserPermission.ViewCompanyUsers],
  });

  const [filters, setFilters] = useState<UserFiltersType>({
    companyId: null,
    role: null,
    status: null,
  });

  const companyFilters = useMemo(() => {
    const companyTypes: CompanyType[] = [];
    if (hasPermission(UserPermission.ManageNCUsers)) {
      companyTypes.push(CompanyType.NorthernCables);
    }
    if (hasPermission(UserPermission.ManageCustomerUsers)) {
      companyTypes.push(CompanyType.Customer);
    }
    if (hasPermission(UserPermission.ManageAgencyUsers)) {
      companyTypes.push(CompanyType.Agency);
    }

    return (
      companyTypes.length ? [['type', 'in', companyTypes]] : []
    ) as Filter[];
  }, [hasPermission]);

  // Company filter
  const { list: companies } = useCompanies({
    filters: companyFilters,
  });

  const renderList = () => {
    if (mode === 'approvals') {
      return (
        <>
          <FilterContainer
            title={t('users.awaitingApproval.title', {
              count: awaitingApprovals,
            })}
          />
          <UserApprovalTable />
        </>
      );
    }

    return (
      <>
        <UserFilters
          companies={companies}
          roles={filteredRoles}
          statuses={statuses.Statuses}
          filters={filters}
          setFilters={setFilters}
        />
        <UserTable filters={filters} />
      </>
    );
  };

  return (
    <RequirePermission
      oneOf={[
        UserPermission.ManageCustomerUsers,
        UserPermission.ManageNCUsers,
        UserPermission.ManageAgencyUsers,
      ]}
    >
      <UsersPartial>
        {outlet}
        {showOutletOnly || renderList()}
      </UsersPartial>
    </RequirePermission>
  );
};

export default Users;
