import * as yup from 'yup';
import { Country, Language, ProvinceOrState, TimeZone } from '../enums';
import { CountryProvincesOrStates } from '../mappings';

const ProfileSchema = yup.object({
  city: yup.string().required(),
  country: yup.mixed<Country>().required(),
  language: yup.mixed<Language>().required(),
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  provinceOrState: yup
    .mixed<ProvinceOrState>()
    .required()
    .test({
      name: 'provinceOrState',
      exclusive: true,
      message: 'common.validations.fieldNotAValidChoice',
      test: (state, context) => {
        const country = context.parent.country;

        if (!country || !state) {
          return true;
        }

        return CountryProvincesOrStates[country as Country].includes(state);
      },
    }),
  timeZone: yup.mixed<TimeZone>().required(),
});

export default ProfileSchema;
