import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { PersonAdd } from '@mui/icons-material';
import { useMemo } from 'react';
import { Container, UnderlinedLink } from '../../components';
import firebaseHooks from '../../firebase/hooks';
import {
  usePagination,
  usePermission,
  useSorting,
  useUsers,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { Filter } from '../../types';
import { UserPermission } from '../../enums';
import { CompanyUserListProps } from './CompanyUserList.props';

export const CompanyUserList = ({
  companyId,
  isAgency,
}: CompanyUserListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const firestore = firebaseHooks.useFirestore();
  const sorting = useSorting('name');
  const pagination = usePagination();
  const { hasPermission } = usePermission();

  const userFilters = useMemo(
    () =>
      [
        ['companyRef', '==', doc(firestore, 'Companies', companyId || '')],
      ] as Filter[],
    [companyId, firestore]
  );

  const { loading, list: users } = useUsers({
    sorting,
    pagination,
    filters: userFilters,
  });

  const hideTitle = isAgency;
  const hideAddButton =
    isAgency && !hasPermission(UserPermission.ManageAgencyUsers);

  return (
    <Container sx={{ marginTop: isAgency ? 0 : 10 }}>
      <Grid
        display="flex"
        justifyContent={hideTitle ? 'flex-end' : 'space-between'}
        alignItems="end"
      >
        {!hideTitle && (
          <Typography variant="h3">{t('companies.assignedUsers')}</Typography>
        )}
        {!hideAddButton && (
          <Button
            startIcon={<PersonAdd />}
            variant="contained"
            onClick={() => navigate('users/new')}
          >
            {t('companies.actions.addUser')}
          </Button>
        )}
      </Grid>

      <Grid sx={{ marginTop: 2 }}>
        <DataTable
          data={!loading ? users : undefined}
          pagination={pagination}
          sorting={sorting}
        >
          <DataTableColumn
            property="name"
            title={t('users.fields.name')}
            width="25%"
            output={(name, user) => (
              <UnderlinedLink to={`users/${user.id}`}>{name}</UnderlinedLink>
            )}
          />
          <DataTableColumn
            property="role"
            title={t('users.fields.role')}
            output={(role) => (role ? t(`users.roles.${role}`) : 'N/A')}
            width="25%"
          />
          {!isAgency && (
            <DataTableColumn
              property="canReserve"
              title={t('users.fields.canReserve.title')}
              output={(canReserve) => t(`common.boolean.${canReserve}`)}
              width="25%"
            />
          )}
          <DataTableColumn
            property="status"
            title={t('users.fields.status')}
            output={(status) => t(`users.statuses.${status}`)}
            width="25%"
          />
        </DataTable>
      </Grid>
    </Container>
  );
};
