export enum ReelReturnCancelReason {
  CombiningMultipleReturns = 'combining-multiple-returns',
  NotReady = 'not-ready',
  WrongDepot = 'wrong-depot',
  Other = 'other',
}

export const ReelReturnCancelReasonMapping: {
  [key in ReelReturnCancelReason]: string;
} = {
  [ReelReturnCancelReason.CombiningMultipleReturns]: 'combining',
  [ReelReturnCancelReason.NotReady]: 'notReady',
  [ReelReturnCancelReason.Other]: 'other',
  [ReelReturnCancelReason.WrongDepot]: 'wrongDepot',
};

export enum ReelReturnCancelErrorFields {
  NoReasonSelected = 'noReasonSelected',
  OtherNoText = 'otherNoText',
}
