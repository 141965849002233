import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessCenter } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { QuestionDrawer } from '../../../components';
import { useNotification, useProgress } from '../../../hooks';
import { NotificationLevel } from '../../../enums';
import { Modal } from '../../../types';

const DeleteCompany: Modal = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();
  const deleteFunction = httpsCallable(functions, 'deleteCompany');
  const navigate = useNavigate();
  const { companyId, agencyId } = useParams();

  const translationPrefix = agencyId
    ? 'agencies.deleteAgency'
    : 'companies.deleteCompany';

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const deleteCompany = async () => {
    setDisabled(true);

    try {
      await deleteFunction({ companyId: companyId || agencyId || '' });
      addNotification(
        t(`${translationPrefix}.success`),
        NotificationLevel.Success
      );
    } catch {
      addNotification(t(`${translationPrefix}.error`), NotificationLevel.Error);
    }

    setDisabled(false);
    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <QuestionDrawer
      disabled={disabled}
      open={true}
      icon={<BusinessCenter sx={{ color: 'secondary.main' }} />}
      message={t(`${translationPrefix}.message`)}
      onConfirm={() => deleteCompany()}
      onCancel={() => navigateBack()}
      title={t(`${translationPrefix}.title`)}
    />
  );
};

export default DeleteCompany;
