import { SxProps } from '@mui/material';

export const userInput: SxProps = {
  backgroundColor: '#ffffff',
  '&.MuiTextField-root': {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
};

export const companyInput: SxProps = {
  backgroundColor: '#ffffff',
  mr: 5,
};
