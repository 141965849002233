import { SxProps } from '@mui/material';

export const userMenu: SxProps = {
  display: { xs: 'none', md: 'block' },
};

export const userMenuItem: SxProps = {
  pt: 1,
  pb: 1,
  display: { xs: 'none', md: 'flex' },
};

export const userMenuItemTypography: SxProps = {
  letterSpacing: '1px',
  maxWidth: { md: '6em', lg: '22em' },
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const userMenuList: SxProps = {
  backgroundColor: 'primary.main',
  minWidth: '12rem',
};
