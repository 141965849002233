import { SxProps } from '@mui/material';

export const container = (hasButtons: boolean): SxProps => {
  return {
    alignItems: { xs: 'top', md: 'center' },
    backgroundColor: hasButtons ? 'primary.main' : 'secondary.light',
    borderBottomLeftRadius: { xs: 0, md: 10 },
    borderBottomRightRadius: { xs: 0, md: 10 },
    borderTopColor: 'secondary.main',
    borderTopLeftRadius: { xs: '1rem', md: 0 },
    borderTopRightRadius: { xs: '1rem', md: 0 },
    borderTopStyle: 'solid',
    borderTopWidth: { xs: 0, md: 5 },
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    height: { xs: 'auto' },
    justifyContent: 'space-between',
    marginBottom: { xs: 0, md: 2 },
    paddingX: { xs: 3, md: 2 },
    width: '100%',
  };
};

export const title = (hasButtons: boolean): SxProps => {
  return {
    mt: 3,
    mb: 3,
    ...(!hasButtons && { fontWeight: 700 }),
    '& strong': { color: 'secondary.main' },
  };
};

export const buttons = (hasButtons: boolean): SxProps => {
  return {
    display: 'flex',
    flexDirection: { xs: 'row-reverse', md: 'row' },
    gap: 2,
    justifyContent: 'space-between',
    mb: 3,
    mt: 3,
    '& .MuiButton-root': { fontSize: { xs: '1.25rem', md: '1rem' } },
  };
};
