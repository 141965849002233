import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import {
  Container,
  DecoratedHeader,
  OrderControlHeader,
  OrderDetailsCard,
  OrderFileTable,
  ScrollToTop,
} from '../components';
import {
  useDownloadFiles,
  useOrder,
  useOrderFilePermissions,
  useOrderFiles,
  usePageTitle,
} from '../hooks';
import { OrderFileType } from '../enums';
import { OrderFile } from '../models';
import { OrderFilesByType } from '../types';
import { EmailFiles } from './modals';

const Order: React.FC = () => {
  const [selectedDocuments, setSelectedDocuments] = useState<OrderFile[]>([]);
  const [displayEmailDrawer, setDisplayEmailDrawer] = useState(false);
  const { t } = useTranslation();
  const { downloadOrderFilesBundle } = useDownloadFiles();
  const { orderId } = useParams();
  const navigate = useNavigate();

  const order = useOrder(orderId || '');
  const { list: orderFilesList, loading: orderFileLoading } = useOrderFiles(
    orderId ?? ''
  );

  const { getOrderFilePermissions } = useOrderFilePermissions();
  const orderFilesPermissions = getOrderFilePermissions();

  const orderFilesByType = useMemo(() => {
    if (!orderFileLoading && orderFilesList.length > 0) {
      return orderFilesList.reduce(
        (acc, curr) => {
          if (curr.fileType !== OrderFileType.Unidentified) {
            acc[curr.fileType].push(curr);
          }
          return acc;
        },
        {
          [OrderFileType.Confirmation]: [],
          [OrderFileType.Invoice]: [],
          [OrderFileType.Shipping]: [],
        } as OrderFilesByType
      );
    }

    return {
      [OrderFileType.Confirmation]: [],
      [OrderFileType.Invoice]: [],
      [OrderFileType.Shipping]: [],
    } as OrderFilesByType;
  }, [orderFilesList, orderFileLoading]);

  useEffect(() => {
    if (!order.loading && !order.item) {
      navigate('/orders');
    }
  }, [navigate, order]);

  usePageTitle(
    t('pages.order.pageTitle', { orderNumber: order.item?.orderNumber })
  );

  const downloadSelectedHandler = () => {
    downloadOrderFilesBundle(selectedDocuments, order.item?.orderNumber);
  };

  const emailSelectedHandler = () => {
    setDisplayEmailDrawer(true);
  };

  const selectAllHandler = useCallback(() => {
    if (selectedDocuments.length < orderFilesList.length) {
      setSelectedDocuments(orderFilesList);
    } else {
      setSelectedDocuments([]);
    }
  }, [orderFilesList, selectedDocuments]);

  return (
    <div style={{ paddingBottom: '4em' }}>
      <Container>
        <DecoratedHeader
          icon={<FolderOpenIcon />}
          title={t('pages.order.pageTitle', {
            orderNumber: order.item?.orderNumber,
          })}
          variant="colored"
        >
          <OrderControlHeader
            backTo={'/orders'}
            isSelected={selectedDocuments.length > 0}
            selectAll={selectAllHandler}
            emailSelected={emailSelectedHandler}
            downloadSelected={downloadSelectedHandler}
            isAllSelected={
              selectedDocuments.length !== 0 &&
              selectedDocuments.length === orderFilesList.length
            }
          />
        </DecoratedHeader>
        <OrderDetailsCard
          orderInstance={order.item}
          orderFilesByType={orderFilesByType}
          orderFilesLoading={orderFileLoading}
        />
      </Container>
      {!orderFileLoading && (
        <>
          {orderFilesPermissions.shipping && (
            <OrderFileTable
              id={OrderFileType.Shipping}
              title={t('orderDetails.table.shippingHeader')}
              files={orderFilesByType[OrderFileType.Shipping]}
              selected={selectedDocuments}
              setSelected={setSelectedDocuments}
            />
          )}
          {orderFilesPermissions.invoiceCredit && (
            <OrderFileTable
              id={OrderFileType.Invoice}
              title={t('orderDetails.table.invoicesHeader')}
              files={orderFilesByType[OrderFileType.Invoice]}
              selected={selectedDocuments}
              setSelected={setSelectedDocuments}
            />
          )}
          {orderFilesPermissions.confirmation && (
            <OrderFileTable
              id={OrderFileType.Confirmation}
              title={t('orderDetails.table.confirmationHeader')}
              files={orderFilesByType[OrderFileType.Confirmation]}
              selected={selectedDocuments}
              setSelected={setSelectedDocuments}
            />
          )}
          <EmailFiles
            orderId={order.item?.id}
            orderNumber={order.item?.orderNumber}
            visible={displayEmailDrawer}
            visibilitySet={setDisplayEmailDrawer}
            documents={selectedDocuments}
          />
        </>
      )}
      <ScrollToTop />
    </div>
  );
};

export default Order;
