import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import * as Sx from '../ReelReturnDrawer.styles';
import { ReelReturnCustomerDrawerProps } from '../ReelReturnDrawer.props';

export const ReelReturnDrawerCustomer = ({
  isDesktop,
  isMobile,
  linearFeet,
  reelReturnStats,
  totalTrailerNeeded,
}: ReelReturnCustomerDrawerProps) => {
  const { t } = useTranslation();
  const { quantity, subTotal, tax, taxPercentage, total } = reelReturnStats;

  return (
    <>
      {/* Icon Section */}
      {isDesktop && (
        <Grid
          item
          md={2}
          display="flex"
          textAlign="center"
          alignItems="center"
          sx={Sx.iconSection}
        >
          <CheckCircleOutlineIcon style={{ fontSize: '4rem' }} />
        </Grid>
      )}
      {/* Data Section */}
      <Grid
        container
        item
        justifyContent={isMobile ? 'flex-start' : 'space-evenly'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        rowSpacing={isMobile ? 1 : undefined}
        md={8}
        xs={12}
        sx={Sx.dataSection}
      >
        <Grid item xs={4} md={2}>
          <Grid item>{t('reelReturns.edit.drawer.quantity')}</Grid>
          <Grid item sx={Sx.valueStyles}>
            {quantity}
          </Grid>
        </Grid>
        <Grid item xs={4} md={2}>
          <Grid item xs={12}>
            {t('reelReturns.edit.drawer.linearFeet')}
          </Grid>
          <Grid item xs={12} sx={Sx.valueStyles}>
            {linearFeet}
          </Grid>
        </Grid>
        <Grid item xs={4} md={2}>
          <Grid item xs={12}>
            {t('reelReturns.edit.drawer.totalTrailerNeeded')}
          </Grid>
          <Grid item xs={12} sx={Sx.valueStyles}>
            {totalTrailerNeeded}
          </Grid>
        </Grid>
        <Grid item xs={4} md={2}>
          <Grid item xs={12}>
            {t('reelReturns.edit.drawer.subTotal')}
          </Grid>
          <Grid item xs={12} sx={Sx.valueStyles}>
            {subTotal}
          </Grid>
        </Grid>
        <Grid item xs={4} md={2}>
          <Grid item xs={12}>
            {t('reelReturns.edit.drawer.tax', {
              taxPercent: taxPercentage,
            })}
          </Grid>
          <Grid item xs={12} sx={Sx.valueStyles}>
            {tax}
          </Grid>
        </Grid>
        <Grid item xs={4} md={2}>
          <Grid item xs={12}>
            {t('reelReturns.edit.drawer.totalCredit')}
          </Grid>
          <Grid item xs={12} sx={Sx.valueStyles}>
            {total}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
