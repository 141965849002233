import { Drawer, Grid, Typography } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useTranslation } from 'react-i18next';

import { ContrastButtonMain } from '..';
import * as Sx from '../ImpersonateDrawer/ImpersonateDrawer.styles';
import { useCompany, useCurrentUser } from '../../hooks';
import { AgentImpersonateDrawerProps } from './AgentImpersonateDrawer.props';

export const AgentImpersonateDrawer = ({
  impersonatedCustomer,
  onDoneClicked,
}: AgentImpersonateDrawerProps) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const { item: company } = useCompany(
    impersonatedCustomer?.company?.companyId
  );

  if (!(impersonatedCustomer && company)) return null;

  return (
    <Drawer
      open={true}
      variant="permanent"
      anchor="bottom"
      PaperProps={{
        sx: Sx.drawerPaper,
      }}
    >
      <Grid
        display="flex"
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        {/* Icon section */}
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexShrink={0}
          sx={Sx.iconSection}
        >
          <HowToRegIcon style={{ fontSize: 60 }} />
        </Grid>

        {/* Info section */}
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          maxWidth="1200px"
        >
          <Grid display="flex" width="75%" justifyContent="space-around">
            <Grid>
              <Typography>{t('inventory.impersonate.reservingFor')}</Typography>
              <Typography variant="h3">
                {impersonatedCustomer.personName}
              </Typography>
            </Grid>

            <Grid display="flex" gap={12}>
              <Grid>
                <Typography>{t('inventory.impersonate.company')}</Typography>
                <Typography variant="h3">
                  {impersonatedCustomer.company.companyName}
                </Typography>
              </Grid>

              <Grid>
                <Typography>{t('inventory.impersonate.market')}</Typography>
                <Typography variant="h3">
                  {currentUser.canSeeCSA && company.canSeeCSA ? 'CSA' : ''}
                  {currentUser.canSeeCSA &&
                  company.canSeeCSA &&
                  currentUser.canSeeUL &&
                  company.canSeeUL
                    ? ', '
                    : ''}
                  {currentUser.canSeeUL && company.canSeeUL ? 'UL' : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Button section */}
        <Grid sx={{ width: '15%' }} display="flex" alignItems="center">
          <ContrastButtonMain onClick={onDoneClicked}>
            {t('inventory.impersonate.done')}
          </ContrastButtonMain>
        </Grid>
      </Grid>
    </Drawer>
  );
};
