import { SxProps } from '@mui/material';

export const BORDER_COLOR = '#979797';

const borderColor: SxProps = {
  color: BORDER_COLOR,
};

const buttonContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: {
    xs: 'space-between',
    md: 'end',
  },
};

const buttonContainerWrapper: SxProps = {
  display: 'flex',
  margin: 0,
  paddingTop: {
    xs: '32px',
    md: 'inherit',
  },
};

const confirmButton: SxProps = {
  minWidth: '80px',
  backgroundColor: 'secondary.main',
  color: 'primary.contrastText',
  marginRight: 2,
  '&:hover': {
    backgroundColor: 'secondary.main',
  },
};

const cancelButton: SxProps = {
  minWidth: '80px',
  color: 'primary.contrastText',
  border: 1,
  borderColor: 'primary.contrastText',
  '&:hover': {
    borderColor: 'primary.contrastText',
  },
};

const input: SxProps = {
  '&.MuiTextField-root': {
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderWidth: '3px',
      },
    },
  },
};

const mainContainer: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '2em 0 1em 0',
};

export {
  borderColor,
  buttonContainer,
  buttonContainerWrapper,
  confirmButton,
  cancelButton,
  input,
  mainContainer,
};
