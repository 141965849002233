import { useState } from 'react';
import { Box, IconButton, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { useAuthentication } from '../../hooks';
import { CurrentUserMenuItem, MobileFooter, UserMenuItems } from '..';
import { MobileMenuProps } from './MobileMenu.props';

export const MobileMenu = ({ anchorElRef, children }: MobileMenuProps) => {
  const { authUser } = useAuthentication();

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
      }}
    >
      <IconButton
        size="large"
        aria-label="Application Menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setVisible(true)}
        color={visible ? 'secondary' : 'inherit'}
      >
        {visible ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          sx: {
            backgroundColor: 'primary.main',
            minWidth: '85vw',
          },
        }}
        open={visible}
        onClose={() => setVisible(false)}
        onClick={() => setVisible(false)}
        sx={{
          ml: 2,
          display: { xs: 'block', md: 'none' },
        }}
      >
        {children}
        {authUser && (
          <Box
            sx={{
              mb: -1,
              pb: 1,
              backgroundColor: 'primary.light',
            }}
          >
            <CurrentUserMenuItem />
            <UserMenuItems />
            <MobileFooter />
          </Box>
        )}
      </Menu>
    </Box>
  );
};
