import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Gallery, Image, ThumbnailImageProps } from 'react-grid-gallery';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { sortBy } from 'lodash';
import {
  Container,
  FormControlHeader,
  NumericTextField,
  ProgressIndicator,
} from '..';
import { NotificationLevel, UserPermission } from '../../enums';
import PortalSettingsModel, {
  PortalSettings,
} from '../../models/PortalSettings';
import { useForm, useNotification, usePortalSettings } from '../../hooks';
import { NeedsPermission } from '../../security';
import {
  BulletinBoardItem,
  BulletinBoardItemConverter,
} from '../../models/BulletinBoardItem';
import {
  StyledBulletinBoardContainer,
  StyledEmptyGalleryContainer,
  StyledProgressIndicatorContainer,
} from './BulletinBoardGallery.styles';
import {
  ACCEPTED_FILE_EXTENSIONS,
  AGENT_BULLETIN_BOARD_STORAGE_PREFIX,
  DEFAULT_DELAY,
  MAX_FILE_SIZE,
  ROW_HEIGHT,
  THUMBNAIL_LOADING,
} from './constants';
import { BulletinBoardGalleryImage } from './BulletinBoardGalleryImage.component';
import { BulletinBoardSlideshow } from './BulletinBoardSlideshow.component';

interface ImageGallery extends Image {
  storageId: string;
}

export const BulletinBoardGallery: React.FC = () => {
  const { t } = useTranslation();
  const settings = usePortalSettings();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const storage = getStorage();
  const [loadingImages, setLoadingImages] = useState(true);
  const [images, setImages] = useState<ImageGallery[]>([]);
  const { addNotification } = useNotification();

  const getRef = useCallback(
    (storageId: string) =>
      ref(storage, `${AGENT_BULLETIN_BOARD_STORAGE_PREFIX}/${storageId}`),
    [storage]
  );

  const deleteItems = async (settings: PortalSettingsModel) => {
    const deletedItems = form.originalItem?.agentBulletinBoardItems?.filter(
      (item) =>
        !settings.agentBulletinBoardItems.some(
          (newItem) => newItem.storageId === item.storageId
        )
    );

    for (const deletedItem of deletedItems ?? []) {
      await Promise.all([
        deleteObject(getRef(deletedItem.storageId)),
        deleteObject(getRef(deletedItem.thumbnailStorageId)),
      ]);
      settings.agentBulletinBoardItems =
        settings.agentBulletinBoardItems.filter((item) => item !== deletedItem);
    }
  };

  const uploadItems = async (settings: PortalSettingsModel) => {
    for (const newItem of settings.agentBulletinBoardItems) {
      if (!newItem.file) {
        continue;
      }
      const thumbnailFile = await imageCompression(newItem.file, {
        maxWidthOrHeight: Math.max(
          ROW_HEIGHT,
          newItem.width * (ROW_HEIGHT / newItem.height)
        ),
      });
      await Promise.all([
        uploadBytes(getRef(newItem.storageId), newItem.file),
        uploadBytes(getRef(newItem.thumbnailStorageId), thumbnailFile),
      ]);
    }
  };

  const beforeSave = async (settings: PortalSettingsModel) => {
    await deleteItems(settings);
    await uploadItems(settings);
    settings.agentBulletinBoardItems = settings.agentBulletinBoardItems.map(
      BulletinBoardItemConverter.toFirestore
    );
  };

  const form = useForm<PortalSettingsModel>(settings, { beforeSave });

  const setAgentBulletinBoard = useCallback(
    (agentBulletinBoardItems: BulletinBoardItem[] = []) => {
      form.setItem({ ...form.item, agentBulletinBoardItems } as PortalSettings);
    },
    [form]
  );

  useEffect(() => {
    (async () => {
      const items = form.item?.agentBulletinBoardItems ?? [];
      const imageGallery: ImageGallery[] = [];
      for (const item of items) {
        imageGallery.push({
          src:
            item.src ??
            images.find((img) => img.storageId === item.storageId)?.src ??
            (await getDownloadURL(getRef(item.thumbnailStorageId))),
          height: item.height,
          width: item.width,
          nano: THUMBNAIL_LOADING,
          storageId: item.storageId,
        });
      }
      setImages(imageGallery);
      setLoadingImages(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.item?.agentBulletinBoardItems, getRef]);

  const onDelete = (index: number) => {
    onOrderClick(index, true);
  };

  const loadFromFile = async (file: File) => {
    const img = await imageCompression.loadImage(URL.createObjectURL(file));
    setAgentBulletinBoard([
      new BulletinBoardItem(file, img, AGENT_BULLETIN_BOARD_STORAGE_PREFIX),
      ...(form.item?.agentBulletinBoardItems ?? []),
    ]);
  };

  const onFileSelected = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files?.length) {
      const file = ev.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        addNotification(
          t('portalSettings.agentBulletinBoard.invalidSize'),
          NotificationLevel.Error
        );
      } else if (
        !ACCEPTED_FILE_EXTENSIONS.includes(
          `.${file.name.toLowerCase().split('.').pop()}`
        )
      ) {
        addNotification(
          t('portalSettings.agentBulletinBoard.invalidExtension', {
            extensions: ACCEPTED_FILE_EXTENSIONS.replace(/,/g, ', '),
          }),
          NotificationLevel.Error
        );
      } else {
        loadFromFile(file);
      }
    }
    ev.target.value = '';
  };

  const onOrderClick = (index: number, remove = false) => {
    const items = [...(form.item?.agentBulletinBoardItems ?? [])];
    const order = items[index].order ?? 0;
    const lastOrder = sortBy(items, (b) => b.order).pop()?.order ?? 0;
    setAgentBulletinBoard(
      items
        .map((a, i) => {
          if (i === index) {
            return { ...a, order: !order ? lastOrder + 1 : 0 };
          }
          return {
            ...a,
            order: !!order && a.order > order ? a.order - 1 : a.order,
          };
        })
        .filter((_, i) => !remove || i !== index)
    );
  };

  const renderImageComponent = (props: ThumbnailImageProps) => (
    <BulletinBoardGalleryImage
      onDelete={onDelete}
      onOrderClick={onOrderClick}
      order={form.item?.agentBulletinBoardItems?.at(props.index)?.order}
      {...props}
    />
  );

  const renderEmptyImages = () => (
    <StyledEmptyGalleryContainer spacing={2} justifyContent="center">
      <Typography variant="h1">
        {t('portalSettings.agentBulletinBoard.empty')}
      </Typography>
      {renderUploadButton()}
    </StyledEmptyGalleryContainer>
  );

  const renderGallery = () => (
    <Gallery
      images={images}
      enableImageSelection={false}
      thumbnailImageComponent={renderImageComponent}
      rowHeight={ROW_HEIGHT}
    />
  );

  const renderUploadButton = (isVisible = true) => (
    <Grid
      item
      display="flex"
      justifyContent="center"
      sx={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      <Button variant="contained" onClick={() => inputRef.current?.click()}>
        {t('portalSettings.agentBulletinBoard.upload')}
      </Button>
      <input
        type="file"
        hidden
        ref={(ref) => (inputRef.current = inputRef.current ?? ref)}
        onChange={onFileSelected}
        accept={ACCEPTED_FILE_EXTENSIONS}
      />
    </Grid>
  );

  const renderDelay = () => (
    <NumericTextField
      form={form}
      field="agentBulletinBoardDelay"
      label={t('portalSettings.agentBulletinBoard.delay')}
      defaultValue={DEFAULT_DELAY}
      minimum={1}
      maximum={30}
      unitLabel="sec"
      required
    />
  );

  return (
    <NeedsPermission oneOf={[UserPermission.ManageAgentBulletinBoard]}>
      <FormControlHeader
        form={form}
        title={t('portalSettings.agentBulletinBoard.title')}
      />
      <Container>
        {settings.loading || !settings.item ? (
          <ProgressIndicator />
        ) : (
          <Box sx={{ m: 2, mb: 15 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" sx={{ mt: 7, mb: 4 }}>
                {t('portalSettings.agentBulletinBoard.mediaLibrary')}
              </Typography>
              {renderUploadButton(images.length > 0)}
            </Stack>
            <StyledBulletinBoardContainer
              display={images.length > 0 ? 'inherit' : 'flex'}
              justifyContent={images.length === 0 ? 'center' : 'flex-start'}
              alignItems={images.length === 0 ? 'center' : 'flex-start'}
            >
              {loadingImages ? (
                <StyledProgressIndicatorContainer>
                  <ProgressIndicator />
                </StyledProgressIndicatorContainer>
              ) : images.length > 0 ? (
                renderGallery()
              ) : (
                renderEmptyImages()
              )}
            </StyledBulletinBoardContainer>

            <BulletinBoardSlideshow
              isPreviewMode
              delay={form.item?.agentBulletinBoardDelay}
              items={form.item?.agentBulletinBoardItems}
              title={
                <Stack
                  pt={10}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h3">
                    {t('portalSettings.agentBulletinBoard.preview')}
                  </Typography>
                  {renderDelay()}
                </Stack>
              }
            />
          </Box>
        )}
      </Container>
    </NeedsPermission>
  );
};
