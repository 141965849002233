import { ReactElement, useMemo } from 'react';
import { useOutlet } from 'react-router-dom';

function elementHasModalChild(element: ReactElement | null): boolean {
  if (element?.props.children) {
    return elementHasModalChild(element?.props.children);
  }

  return element?.props?.modal || false;
}

export function useRouterOutlet() {
  const outlet = useOutlet();

  const showOutletOnly = useMemo(
    () => outlet && !elementHasModalChild(outlet),
    [outlet]
  );

  return { outlet, showOutletOnly };
}
