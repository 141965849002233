import { Stack } from '@mui/material';
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
  pickersDayClasses,
} from '@mui/x-date-pickers';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { useFormFieldValue } from '../../hooks';
import { StaticDatePickerFieldProps } from './StaticDatePickerField.props';

import * as Sx from './StaticDatePickerField.styles';

export const StaticDatePickerField = <T,>({
  disabled,
  field,
  form,
  shouldDisableDate,
  onChange,
  selectedDays,
}: StaticDatePickerFieldProps<T>) => {
  const { value } = useFormFieldValue<T, Timestamp>(form, field);

  const formattedValue = value
    ? DateTime.fromJSDate(value.toDate())
    : DateTime.now();

  const dateIsSelected = (date: DateTime): boolean => {
    if (selectedDays?.length) {
      return selectedDays.some((d) =>
        DateTime.fromJSDate(d.toDate()).hasSame(date, 'day')
      );
    }

    return false;
  };

  const renderWeekPickerDay = (pickersDayProps: PickersDayProps<DateTime>) => (
    <PickersDay
      {...pickersDayProps}
      selected={dateIsSelected(pickersDayProps.day)}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: '#e68008',
        },
      }}
    />
  );

  return (
    <Stack sx={Sx.container} alignItems={'flex-start'}>
      <StaticDatePicker
        slots={{ day: renderWeekPickerDay }}
        slotProps={{
          toolbar: {
            hidden: true,
          },
          actionBar: {
            actions: [],
          },
        }}
        orientation="landscape"
        disabled={disabled}
        value={formattedValue}
        shouldDisableDate={shouldDisableDate}
        openTo="day"
        onChange={onChange}
        disableHighlightToday={true}
      />
    </Stack>
  );
};
