import { Box, Grid, Typography } from '@mui/material';

import { TableRowDialogProps } from './TableRowDialog.props';
import * as Sx from './TableRowDialog.styles';

export const TableRowDialog = ({
  buttons,
  children,
  icon,
  title,
}: TableRowDialogProps) => (
  <Grid sx={Sx.container(!!buttons)}>
    <Typography
      variant={buttons ? 'h4' : 'body1'}
      color={buttons ? 'white' : 'black'}
      sx={Sx.title(!!buttons)}
    >
      {icon && (
        <Box component="span" mr={1}>
          {icon}
        </Box>
      )}
      {title}
    </Typography>

    {children}

    <Grid sx={Sx.buttons(!!buttons)}>{buttons}</Grid>
  </Grid>
);
