import { Box, Grid } from '@mui/material';
import { UnitaryModalProps } from './UnitaryModal.props';

export const UnitaryModal = ({ children }: UnitaryModalProps) => (
  <Grid container sx={{ flex: 1 }}>
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      </Box>
    </Grid>
    <Grid
      item
      xs={0}
      md={6}
      sx={{
        background:
          'radial-gradient(100% 147.63% at 0% 100%, #F6992B 0%, #E27305 100%)',
      }}
    ></Grid>
  </Grid>
);
