import { Button, Grid, Collapse, Typography, IconButton } from '@mui/material';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { httpsCallable } from 'firebase/functions';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useFunctions, useFirestore } from 'reactfire';

import { Container } from '..';

import { UserStatus } from '../../enums';
import { UserActivity } from '../../models';
import { useAuthentication, useUser } from '../../hooks';
import * as Sx from './FilterContainer.styles';
import { FilterContainerProps } from './FilterContainer.props';

export const FilterContainer = ({
  buttons,
  buttonsDisabled = false,
  onClear,
  onSearch,
  headerContents,
  title,
  children,
}: FilterContainerProps) => {
  const { t } = useTranslation();
  const { authUser } = useAuthentication();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const persistentAuthUser = useRef(authUser!);
  const { item: user } = useUser(persistentAuthUser.current.uid);
  const [open, setOpen] = useState<boolean>(true);
  const functions = useFunctions();
  const firestore = useFirestore();
  const logUserActivity = httpsCallable(functions, 'logUserActivity');

  const logActivity = () => {
    if (user?.status === UserStatus.Active) {
      const startOfToday = new Date();
      startOfToday.setUTCHours(0, 0, 0, 0);
      const q = query(
        collection(firestore, 'UserActivities'),
        where('email', '==', user?.email),
        where('date', '>=', startOfToday)
      );
      getDocs(q).then((res) => {
        const results = res.docs.map((doc) => doc.data() as UserActivity);
        if (!results.length) {
          logUserActivity({
            email: user?.email,
            success: true,
          });
        }
      });
    }
  };

  return (
    <Container backgroundColor="#f9f9f9" roundedTopCorners={true}>
      {(title || buttons) && (
        <Grid container alignItems="center" justifyContent="space-between">
          {title && (
            <Typography variant="h2" sx={Sx.title}>
              {title}
            </Typography>
          )}
          {buttons && (
            <Grid item sx={Sx.buttons}>
              {buttons}
            </Grid>
          )}
        </Grid>
      )}
      <Grid container alignItems="center" sx={Sx.desktopHeaderContents}>
        {headerContents}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={Sx.mobileHeaderContents}
      >
        <Grid
          display="flex"
          alignItems="center"
          sx={{ flexBasis: 'calc(100% - 4rem)' }}
        >
          {open && headerContents ? (
            headerContents
          ) : (
            <>
              <SearchIcon fontSize="large" sx={Sx.revealFilterIcon} />
              <Typography variant="h3" sx={Sx.revealFilterText}>
                {t('inventory.filters.revealFilters')}
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          item
          sx={{
            flexBasis: '3rem',
            textAlign: 'right',
            mr: 1,
          }}
        >
          <IconButton onClick={() => setOpen((prevValue) => !prevValue)}>
            {open ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open} sx={Sx.collapse}>
        <Grid mt={{ xs: -3, md: 4 }} mb={{ xs: 2, md: 4 }}>
          {children}
          {(onSearch || onSearch === null || onClear || onClear === null) && (
            <Grid
              display="flex"
              justifyContent={{ xs: 'space-between', md: 'flex-start' }}
              gap={2}
              mt={5}
            >
              {(onSearch || onSearch === null) && (
                <Button
                  variant="contained"
                  onClick={() => {
                    if (onSearch) {
                      logActivity();
                      onSearch();
                      setOpen(false);
                    }
                  }}
                  disabled={onSearch === null || buttonsDisabled}
                >
                  {t('inventory.filters.showResults')}
                </Button>
              )}
              {(onClear || onClear === null) && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (onClear) {
                      onClear();
                    }
                  }}
                  disabled={onClear === null || buttonsDisabled}
                >
                  {t('inventory.filters.clearFilters')}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Container>
  );
};
