import { useCurrentUser } from '../hooks';
import { ErrorCategory, Country } from '../enums/';
import { HttpError } from '../pages';

interface Props {
  children: React.ReactNode;
  country: Country;
  hide?: boolean;
}

const CountrySpecific = ({ country, children, hide = false }: Props) => {
  const user = useCurrentUser();

  const countryIsValid = user.country === country;

  if (!countryIsValid) {
    return hide ? null : (
      <HttpError errorCategory={ErrorCategory.AccessDenied} />
    );
  }

  return <>{children}</>;
};

export default CountrySpecific;
