import { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import TruckIcon from '../../assets/TruckIcon';
import { Show } from '../Show/Show.component';
import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import { MultipleReservationsParameters } from '../../types/ReservationParameters';
import { useNotification } from '../../hooks';
import { NotificationLevel } from '../../enums';
import { MultipleReservationsResponse } from '../../types/Response';
import { ShoppingTruckProps } from './ShoppingTruck.props';
import { ShoppingTruckCard } from './components/ShoppingTruckCard.component';
import * as Sx from './ShoppingTruck.styles';

export const ShoppingTruck = ({
  impersonatedCustomer,
  impersonatedUser,
  measureUnits,
  resetShoppingTruck,
  selectedReels,
  toggleItemSelection,
  toggleShoppingTruck,
  user,
}: ShoppingTruckProps) => {
  const { t } = useTranslation();
  const functions = useFunctions();
  const { addNotification } = useNotification();
  const multipleReservationsFunction =
    httpsCallable<MultipleReservationsParameters>(
      functions,
      'reserveMultipleReels'
    );

  const [note, setNote] = useState<string>('');
  const [showNote, setShowNote] = useState<boolean>(false);
  const [reserving, setReserving] = useState(false);

  const reserveMultipleReels = () => {
    if (
      !impersonatedUser &&
      !impersonatedCustomer &&
      user.reservationCount + selectedReels.length >
        user.maxReservationsPerPeriod
    ) {
      addNotification(
        t('inventory.multipleReservations.results.reservationsExceeded', {
          number:
            user.reservationCount +
            selectedReels.length -
            user.maxReservationsPerPeriod,
        }),
        NotificationLevel.Error,
        true
      );

      return;
    }
    setReserving(true);
    addNotification(
      t('inventory.reserveInfo'),
      NotificationLevel.Information,
      true
    );

    let failedReservations: MultipleReservationsResponse[] = [];

    multipleReservationsFunction({
      impersonatedUserId: impersonatedUser?.id,
      madeForCompanyId: impersonatedCustomer?.company.companyId,
      madeForCustomerName: impersonatedCustomer?.personName,
      note,
      reservations: selectedReels.map(
        ({ id, inventoryType, packageLength }) => {
          return {
            reelId: id,
            inventoryType,
            note: '',
          };
        }
      ),
    })
      .then((res) => {
        const results = res.data as MultipleReservationsResponse[];
        failedReservations = results.filter(
          (reservation) => reservation.succeeded === false
        );

        if (failedReservations.length) {
          addNotification(
            t('inventory.multipleReservations.results.partial', {
              failed: failedReservations.length,
              total: selectedReels.length,
            }),
            NotificationLevel.Error,
            true
          );
        } else {
          addNotification(
            t('inventory.multipleReservations.results.success'),
            NotificationLevel.Success,
            true
          );
        }
      })
      .catch(() => {
        addNotification(
          t('inventory.multipleReservations.results.globalFail'),
          NotificationLevel.Error,
          true
        );
      })
      .finally(() => {
        setReserving(false);
        resetShoppingTruck(
          failedReservations.map((reservation) => reservation.reelId ?? '')
        );
      });
  };

  return (
    <Drawer open={true} anchor="right" PaperProps={{ sx: Sx.drawerPaper }}>
      {/* Header */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={Sx.titleBox}
        onClick={toggleShoppingTruck}
      >
        <Grid
          container
          alignItems="center"
          display="flex"
          gap={1}
          wrap="nowrap"
        >
          <Typography>
            {t('inventory.shoppingTruck.yourTruck', {
              count: selectedReels.length,
            })}
          </Typography>
          <TruckIcon color="black" />
        </Grid>
        <CloseIcon />
      </Box>

      {/* Cards Section */}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        rowGap={1.5}
        sx={Sx.cardSection(showNote)}
      >
        <Show if={selectedReels.length === 0}>
          <Box>
            <Typography variant="h4">
              {t('inventory.shoppingTruck.emptyTruck')}
            </Typography>
          </Box>
        </Show>

        {selectedReels.map((reel) => (
          <Grid item key={reel.id} sx={{ height: '180px', width: '100%' }}>
            <ShoppingTruckCard
              measureUnits={measureUnits}
              reel={reel}
              toggleItemSelection={toggleItemSelection}
            />
          </Grid>
        ))}
      </Grid>

      {/* Footer */}
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
        sx={Sx.buttonsSection(showNote)}
      >
        <Show if={showNote}>
          <TextField
            inputProps={{ maxLength: 255 }}
            label={t('inventory.shoppingTruck.reservationNote')}
            multiline
            fullWidth
            autoFocus
            minRows={5}
            onChange={(e) => setNote(e.target.value)}
            value={note}
            variant="filled"
            sx={{ overflow: 'auto' }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setShowNote(false)}
                >
                  <KeyboardArrowDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </Show>
        <Show if={!showNote}>
          <Button variant="outlined" onClick={() => setShowNote(!showNote)}>
            {t(
              `inventory.shoppingTruck.${note !== '' ? 'editNote' : 'addNote'}`
            )}
          </Button>
        </Show>
        <ContrastButtonMain
          disabled={selectedReels.length === 0 || reserving}
          onClick={reserveMultipleReels}
        >
          {t('inventory.shoppingTruck.reserve', {
            count: selectedReels.length,
          })}
        </ContrastButtonMain>
      </Box>
    </Drawer>
  );
};
