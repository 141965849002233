import { i18n } from 'i18next';
import { SxProps } from '@mui/material';

export const mainContainer: SxProps = { my: { xs: 2, md: 4 } };

export const fieldContainer: SxProps = { flexBasis: { md: '16.666667%' } };

export const pleaseApply: SxProps = { mt: 1 };

export const switchLabel = (i18n: i18n, enabled: boolean): SxProps => {
  return {
    ...(enabled ? { fontWeight: 500 } : { color: '#C2C2C2' }),
    ...(i18n.language === 'fr' ? { letterSpacing: '-1px' } : {}),
  };
};
