import { useEffect, useState } from 'react';
import { Nullable } from '../types';

export type storageType =
  | 'inventory'
  | 'reservation'
  | 'conversionRates'
  | 'reelReturns';

export function useLocalStorage<T>(storageKey: storageType, subKey?: string) {
  const key = `${storageKey}:${subKey}`;
  const [data, setData] = useState<Nullable<T>>(null);

  useEffect(() => {
    getItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const getItem = (): Nullable<T> => {
    const data = JSON.parse(localStorage.getItem(key) as string);

    setData(data);
    return data;
  };

  const removeItem = (): void => {
    setData(null);
    localStorage.removeItem(key);
  };

  const setItem = (data: Nullable<T>): void => {
    if (data === null) {
      return removeItem();
    }

    setData(data);
    localStorage.setItem(key, JSON.stringify(data));
  };

  return {
    getItem,
    item: data,
    key,
    removeItem,
    setItem,
  };
}
