import { useState } from 'react';

type SortDirection = 'asc' | 'desc';

interface SortOptions {
  forcedSortField?: string;
  sortDirection?: SortDirection;
}

export function useSorting(
  defaultSortField: string,
  options: SortOptions = {}
) {
  const { forcedSortField, sortDirection: defaultSort = 'asc' } = options;

  const [sortField, setSortField] = useState<string>(defaultSortField);
  const [sortDirection, setSortDirection] =
    useState<SortDirection>(defaultSort);

  const sortByField = (property: string) => {
    if (sortField === property) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(property);
      setSortDirection('asc');
    }
  };

  return {
    defaultSortField,
    setSortDirection,
    setSortField,
    sortByField,
    sortDirection,
    sortField: forcedSortField ?? sortField,
  };
}
