import { Footer, Navigation } from '../../navigation/';
import { Layout } from '../../components';
import { EmptyLayoutProps } from './EmptyLayout.props';

export const EmptyLayout = ({ children }: EmptyLayoutProps) => (
  <Layout>
    <Navigation hideApplicationName={true} />
    {children}
    <Footer />
  </Layout>
);
