import { Firestore } from 'firebase/firestore';
import { CompanyType, InventoryClass } from '../enums';
import yup from './yup';

const getCompanySchema = (
  firestore: Firestore,
  companyType = CompanyType.Customer
) => {
  const schema = {
    class: yup.mixed<InventoryClass>().required(),
    name: yup
      .string()
      .required()
      .max(255)
      .unique(firestore, 'Companies', 'nameNormalized', true),
    accountNumber: yup
      .string()
      .required()
      .unique(firestore, 'Companies', 'accountNumber', false),
    shippingDocEmailRecipient: yup
      .array()
      .of(yup.string().max(255).email())
      .nullable(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  if (companyType !== CompanyType.Agency) {
    schema['code'] = yup
      .string()
      .nullable()
      .max(4)
      .unique(firestore, 'Companies', 'codeNormalized', true);
  }

  return yup.object(schema);
};

export default getCompanySchema;
