import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router';

import {
  LanguageSwitcherMenuItem,
  MenuItem,
  Navigation,
} from '../../navigation/';
import { Layout } from '../../components';
import { usePublicAuthentication, usePortalSettings } from '../../hooks';
import { translateString } from '../../i18n';

import { ClientLayout } from '../ClientLayout/ClientLayout.component';

import { PublicLayoutProps } from './PublicLayout.props';

export const PublicLayout = (props: PublicLayoutProps) => {
  const { t, i18n } = useTranslation();
  const { item: portalSettings } = usePortalSettings();
  const { user } = usePublicAuthentication();

  if (user) {
    if (props.strictlyPublic) {
      return <Navigate to="/" replace />;
    }

    return <ClientLayout />;
  }

  if (user === null) {
    return (
      <Layout>
        <Navigation hideApplicationName={props.hideAppName} publicFacing={true}>
          <MenuItem
            href={translateString(portalSettings?.contactURL, i18n)}
            target="_blank"
            title={t('contact.title')}
          />
          <LanguageSwitcherMenuItem />
        </Navigation>
        <Outlet />
      </Layout>
    );
  }

  // Prevents public UI flashing for like 2 frames if we switch to client UI
  // This causes the page to appear loading a tiny bit longer but won't cause seizures
  return <></>;
};
