import { SxProps } from '@mui/system';

export const MULTIPLE_RESERVATIONS_DRAWER_HEIGHT = '120px';

export const drawerPaper = (idAdminOrAgent: boolean): SxProps => {
  return {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: MULTIPLE_RESERVATIONS_DRAWER_HEIGHT,
    backgroundColor: idAdminOrAgent ? 'primary.main' : '#FAA72D',
    border: 'none',
    padding: 'unset',
    color: idAdminOrAgent ? 'white' : 'black',
  };
};

const inheritSize: SxProps = {
  height: 'inherit',
  width: 'inherit',
};

export const iconSection: SxProps = {
  ...inheritSize,
  textAlign: 'center',
  justifyContent: 'center',
  color: 'white',
};

export const iconSectionAdmin: SxProps = {
  ...iconSection,
  backgroundColor: '#e68008',
};
export const iconSectionUser: SxProps = {
  ...iconSection,
  backgroundColor: '#E27305',
};

export const dataSection: SxProps = {
  ...inheritSize,
  '& .MuiGrid-item': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const buttonSection: SxProps = {
  ...inheritSize,
};

export const valueStyles: SxProps = {
  fontSize: '1.3rem',
  fontWeight: 'bold',
};

export const valueStylesAgent: SxProps = {
  ...valueStyles,
  fontSize: '1.5rem',
};

export const buttonStyles: SxProps = {
  color: 'white',
  width: { xs: '80vw', md: 'inherit' },
  height: 'inherit',
};
