import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useProgress } from '../../hooks';
import spinner from '../../assets/LoadingReel.png';
import * as Sx from './Progress.styles';

export const Progress = () => {
  const { t } = useTranslation();
  const { visible } = useProgress();

  return (
    <Box sx={Sx.box(visible)}>
      <Box sx={Sx.loadingBox()}>
        <Box sx={Sx.imageBox()}>
          <Box
            sx={Sx.image()}
            component="img"
            alt="Loading reel spinner"
            src={spinner}
          />
          <Typography sx={Sx.loadingMessage()}>
            {t('common.loading')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
