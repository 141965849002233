import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';
import { Show } from '..';
import * as Sx from './ScrollToTop.style';

export const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShowTopBtn(window.scrollY > 100);
    });
  }, []);

  return (
    <Show if={showTopBtn}>
      <IconButton
        aria-label="scroll-to-top"
        size="large"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        sx={Sx.iconButton()}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
    </Show>
  );
};
