import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { setLocale } from 'yup';
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { normalizeSync } from 'normalize-diacritics';
import { Functions, httpsCallable } from 'firebase/functions';

setLocale({
  mixed: {
    // Fallback value if no error message is set for a field
    default: 'common.validations.fieldError',
    required: 'common.validations.fieldRequired',
  },
  string: {
    email: 'common.validations.fieldMustBeEmail',
    min: ({ min }) => {
      return { key: 'common.validations.fieldMinLength', values: { min } };
    },
    max: ({ max }) => {
      return { key: 'common.validations.fieldMaxLength', values: { max } };
    },
    url: 'common.validations.fieldMustBeURL',
  },
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'unique',
  function (firestore, path, property, normalize, errorMessage) {
    return this.test(
      'unique',
      errorMessage ?? 'common.validations.fieldShouldBeUnique',
      async (value, context) => {
        if (!value) return true;

        if (normalize) {
          value = normalizeSync(value).toLowerCase().replace(/ /g, '');
        }

        const queryConstraints = [where(property, '==', value)];

        const modelQuery = query(
          collection(firestore, path),
          ...queryConstraints
        );

        const companies = await getDocs(modelQuery);

        return (
          companies.docs.length === 0 ||
          companies.docs[0].id === context.parent.id
        );
      }
    );
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'callableFunction',
  function (functions, functionName, normalize, errorMessage) {
    return this.test('callableFunction', errorMessage, async (value) => {
      if (!value) return true;

      if (normalize) {
        value = normalizeSync(value).toLowerCase().replace(/ /g, '');
      }

      const callableFunction = httpsCallable(functions, functionName);

      const result = await callableFunction(value);
      return result.data as unknown as boolean;
    });
  }
);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    unique(
      firestore: Firestore,
      path: string,
      property: string,
      normalize: boolean
    ): StringSchema<TType, TContext>;
    callableFunction(
      functions: Functions,
      functionName: string,
      normalize: boolean,
      errorMessage: string
    ): StringSchema<TType, TContext>;
  }
}

export default yup;
