import { where } from 'firebase/firestore';
import { orderBy } from 'lodash';

import { Company } from '../models';
import { CollectionQueryOptions } from '../types/';
import { useLocalCollection } from './useLocalCollection';

export function useCompanies(options?: CollectionQueryOptions) {
  return useLocalCollection<Company>(
    'Companies',
    [
      options?.includeDeleted === true
        ? undefined
        : where('isDeleted', '==', false),
    ],
    options?.sorting === undefined
      ? { sortField: 'name', sortDirection: 'asc' }
      : options?.sorting || undefined,
    options?.pagination,
    options?.filters,
    (data) => {
      const newData = data.map((company) => {
        if (company.associatedCompanies) {
          company.associatedCompaniesCount = Object.values(
            company.associatedCompanies
          ).filter((a) => !a.isDeleted).length;
        }
        return company;
      });
      orderBy(newData, 'type', 'desc');
      return newData;
    }
  );
}
