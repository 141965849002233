import * as yup from 'yup';

export const ResetPasswordValidation = yup.object({
  newPassword: yup.string().min(8).required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf(
      [yup.ref('newPassword'), null],
      'common.validations.fieldPasswordShouldMatch'
    ),
});
