import { ErrorLog } from '../models';
import { CollectionQueryOptions } from '../types';
import { useCollection } from './useCollection';

export function useErrorLogs(options?: CollectionQueryOptions) {
  return useCollection<ErrorLog>(
    'ErrorLogs',
    [],
    options?.sorting || { sortField: 'date', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
