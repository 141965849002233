export function queryStringToObject<T>(queryString: string): T {
  if (queryString.length === 0) {
    return {} as T;
  }

  const string =
    queryString.charAt(0) === '?' ? queryString.substring(1) : queryString;

  return JSON.parse(
    '{"' +
      decodeURI(string)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}
