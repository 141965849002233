import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { sortBy } from 'lodash';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { ProgressIndicator } from '..';
import 'react-image-gallery/styles/css/image-gallery.css';
import { BulletinBoardItem } from '../../models';
import {
  AGENT_BULLETIN_BOARD_STORAGE_PREFIX,
  DEFAULT_DELAY,
} from './constants';
import {
  MAX_HEIGHT_THUMBNAIL,
  StyledEmptySlideshowContainerComponent,
  StyledLeftNavComponent,
  StyledOverflowNavContainerComponent,
  StyledRightNavComponent,
  StyledSlideshowContainerComponent,
  StyledSlideshowStackComponent,
} from './BulletinBoardSlideshow.styles';

interface Props {
  delay?: number;
  title?: React.ReactNode;
  items?: BulletinBoardItem[];
  isPreviewMode: boolean;
}

interface ImageGalleryItem extends ReactImageGalleryItem {
  storageId: string;
}

export const BulletinBoardSlideshow: React.FC<Props> = ({
  isPreviewMode,
  delay = DEFAULT_DELAY,
  items = [],
  title,
}: Props) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ImageGalleryItem[]>([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const storage = getStorage();
  const [debounceItems] = useDebounce(items, 1000);

  const getRef = useCallback(
    (storageId: string) =>
      ref(storage, `${AGENT_BULLETIN_BOARD_STORAGE_PREFIX}/${storageId}`),
    [storage]
  );

  const orderedItems = useMemo(
    () =>
      sortBy(
        debounceItems.filter((i) => !!i.order),
        (i) => i.order
      ),
    [debounceItems]
  );

  useEffect(() => {
    (async () => {
      const result: ImageGalleryItem[] = [];
      setLoadingImages(true);
      for (const item of orderedItems) {
        result.push({
          original:
            item.src ??
            images.find((img) => img.storageId === item.storageId)?.original ??
            (await getDownloadURL(getRef(item.storageId))),
          thumbnail:
            item.src ??
            images.find((img) => img.storageId === item.storageId)?.thumbnail ??
            (await getDownloadURL(getRef(item.thumbnailStorageId))),
          thumbnailHeight: MAX_HEIGHT_THUMBNAIL,
          originalHeight: item.height,
          originalWidth: item.width,
          storageId: item.storageId,
        });
      }
      setImages(result);
      setLoadingImages(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage, getRef, orderedItems]);

  const renderLeftNav = (
    onClick: MouseEventHandler<HTMLElement>,
    disabled: boolean
  ) => (
    <StyledLeftNavComponent onClick={onClick} disabled={disabled}>
      <KeyboardArrowLeftIcon fontSize="large" />
    </StyledLeftNavComponent>
  );

  const renderRightNav = (
    onClick: MouseEventHandler<HTMLElement>,
    disabled: boolean
  ) => (
    <StyledRightNavComponent onClick={onClick} disabled={disabled}>
      <KeyboardArrowRightIcon fontSize="large" />
    </StyledRightNavComponent>
  );

  const renderEmptyPreview = () => (
    <StyledEmptySlideshowContainerComponent spacing={3}>
      {loadingImages ? (
        <ProgressIndicator />
      ) : (
        <>
          <Typography variant="h3">
            {t('portalSettings.agentBulletinBoard.previewEmptyTitle')}
          </Typography>
          <Typography>
            {t('portalSettings.agentBulletinBoard.previewEmptyDescription')}
          </Typography>
        </>
      )}
    </StyledEmptySlideshowContainerComponent>
  );

  if (!debounceItems?.length || (!orderedItems.length && !isPreviewMode)) {
    return null;
  }

  return (
    <StyledSlideshowStackComponent mb={10}>
      {title}
      {loadingImages || !orderedItems.length ? (
        renderEmptyPreview()
      ) : (
        <StyledSlideshowContainerComponent>
          <ImageGallery
            autoPlay
            showFullscreenButton={false}
            showPlayButton={false}
            slideInterval={delay * 1000}
            items={images}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderCustomControls={() => (
              <StyledOverflowNavContainerComponent className="overflow-nav" />
            )}
          />
        </StyledSlideshowContainerComponent>
      )}
    </StyledSlideshowStackComponent>
  );
};
