import { Outlet } from 'react-router-dom';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RequireRole, roles } from '../../security/';
import { AdminMenu, Footer, Navigation } from '../../navigation';
import { Layout } from '../../components';
import { AuthenticatedLayout } from '..';
import { defaultLanguage } from '../../types/Languages';

export const AdminLayout = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
    }
  }, [i18n, i18n.language]);

  return (
    <AuthenticatedLayout>
      <RequireRole roles={roles.AdminRoles}>
        <Layout>
          <Navigation variant="side">
            <AdminMenu />
          </Navigation>
          <Outlet />
          <Footer />
        </Layout>
      </RequireRole>
    </AuthenticatedLayout>
  );
};
