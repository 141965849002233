export enum ReelReturnError {
  CancelProcess = 'CANCEL_REEL_RETURN_ERROR',
  CancelEmail = 'CANCEL_EMAIL_ERROR',
  CustomerEmail = 'CUSTOMER_EMAIL_ERROR',
  DepotEmail = 'DEPOT_EMAIL_ERROR',
  ReceiveProcess = 'RECEIVE_REEL_RETURN_ERROR',
}

export const ReelReturnErrorMapping: { [key in ReelReturnError]: string } = {
  [ReelReturnError.CancelProcess]: 'cancelError',
  [ReelReturnError.CancelEmail]: 'errorCancelEmail',
  [ReelReturnError.CustomerEmail]: 'errorCustomerEmail',
  [ReelReturnError.DepotEmail]: 'errorDepotEmail',
  [ReelReturnError.ReceiveProcess]: 'receiveError',
};
