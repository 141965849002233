import { where } from 'firebase/firestore';

import { ReservationStatus } from '../enums';
import { Reservation } from '../models';
import { CollectionQueryOptions } from '../types';
import { useCollection } from './useCollection';

export function useReservationHistory(options?: CollectionQueryOptions) {
  return useCollection<Reservation>(
    'Reservations',
    [
      where('status', 'in', [
        ReservationStatus.Cancelled,
        ReservationStatus.Expired,
        ReservationStatus.Purchased,
        ReservationStatus.UnknownExpired,
      ]),
    ],
    { sortField: 'reservedTime', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
