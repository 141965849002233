import { useMemo } from 'react';
import { ConversionRate, SanitizedConversionRate } from '../models';
import { CollectionQueryOptions, Nullable } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useConversionRate(
  options?: CollectionQueryOptions,
  filterCompanyIds?: Nullable<string[]>
) {
  const data = useLocalCollection<ConversionRate>(
    'ConversionRates',
    [undefined],
    undefined,
    undefined,
    options?.filters
  );

  const conversionRates = useMemo(() => {
    const result = data.list.reduce((prev, cur) => {
      const ids = Object.keys(cur.statistics);

      for (const id of ids) {
        if (filterCompanyIds && !filterCompanyIds.includes(id)) {
          continue;
        }

        const company =
          prev.find((x) => x.id === id) || new SanitizedConversionRate();

        if (!company.id) {
          company.id = id;
          company.companyName = cur.statistics[id].companyName;

          prev.push(company);
        }

        company.cancelled += cur.statistics[id].cancelled;
        company.expired += cur.statistics[id].expired;
        company.purchased += cur.statistics[id].purchased;
        company.reserved += cur.statistics[id].reserved;
      }
      return prev;
    }, [] as SanitizedConversionRate[]);

    result.forEach((value) => {
      const purchased = value.purchased;
      const total = value.purchased + value.expired + value.cancelled;

      value.rate = total !== 0 ? Math.round((purchased / total) * 100) : 0;
    });

    return result;
  }, [data.list, filterCompanyIds]);

  return conversionRates;
}
