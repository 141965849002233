import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { useNavigate, useOutlet, useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import { PublicHeader } from '../layouts/PublicHeader/PublicHeader.component';
import { Container } from '../components';
import { useNotification, usePageTitle, useProgress } from '../hooks';
import { NotificationLevel } from '../enums';
import { getPageTitle } from '../utils/pages';

const Unsubscribe = () => {
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const { t, i18n } = useTranslation();
  const functions = useFunctions();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const id = searchParams.get('id');
  const token = searchParams.get('token');
  const lang = i18n.language;

  const unsubscribe = httpsCallable(functions, 'unsubscribe');

  usePageTitle(getPageTitle('unsubscribe'));

  useEffect(() => {
    showProgress(loading);
  }, [loading, showProgress]);

  const onUnsubscribe = () => {
    setLoading(true);
    addNotification(t('unsubscribe.pending'), NotificationLevel.Information);
    unsubscribe({ id, token })
      .then(() => {
        addNotification(
          t('unsubscribe.confirmation'),
          NotificationLevel.Success
        );
        navigate(`/unsubscribe/confirmation?lang=${lang}`);
      })
      .catch(() =>
        addNotification(t('unsubscribe.error'), NotificationLevel.Error)
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PublicHeader title={t('unsubscribe.title')} icon={<UnsubscribeIcon />} />

      <Container sx={{ mt: 5 }} role="main">
        {outlet ? (
          outlet
        ) : (
          <>
            <Typography variant="h2">{t('unsubscribe.message')}</Typography>

            <Grid mt={5}>
              <Button
                disabled={loading}
                onClick={onUnsubscribe}
                variant="contained"
              >
                {t('unsubscribe.unsubscribe')}
              </Button>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default Unsubscribe;
