import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import useAuth from './useAuthentication';

export const usePageTitle = (prefix?: string) => {
  const { isAgent } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    let title = '';
    if (prefix) {
      title += `${prefix} - `;
    }
    title += t(`common.appTitle.${isAgent ? 'agent' : 'default'}`);
    document.title = title;
  }, [isAgent, prefix, t]);
};
