import { Model } from '../types';
import { Country, ProvinceOrState } from '../enums';

export class DepotContact {
  name!: string;
  email!: string;
}

export class DepotAddress {
  street?: string;
  city?: string;
  provinceOrState!: ProvinceOrState;
  postalCode?: string;
  country!: Country;
}

export class ReelDepot implements Model {
  id!: string;
  name!: string;
  isActive!: boolean;
  address!: DepotAddress;
  contacts!: DepotContact[];
}

export const formatReelDepotLabel = (depot: ReelDepot) =>
  `${depot.name}, ${depot.address.city} ${depot.address.provinceOrState}`;
