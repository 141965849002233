import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { StatusIndicatorProps } from './StatusIndicator.props';

export const StatusIndicator = ({
  title,
  color,
  icon,
}: StatusIndicatorProps) => {
  if (!title) {
    return null;
  }

  return (
    <Grid
      container
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        '& .MuiSvgIcon-root': {
          fontSize: icon ? '1rem' : '0.5rem',
          height: 'auto',
          mr: 1,
          color: color,
        },
      }}
    >
      {icon || <CircleIcon />}
      <Typography>{title}</Typography>
    </Grid>
  );
};
