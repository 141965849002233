import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  FormControlHeader,
  ProgressIndicator,
  UserForm,
} from '../../components';
import { useCompanies, useForm, usePageTitle, useUser } from '../../hooks';
import { AgencyPageView, ModificationMode, UserStatus } from '../../enums/';
import getUserSchema from '../../validation/getUserSchema';
import { Filter } from '../../types';
import { getPageTitle } from '../../utils/pages';

const User: React.FC = () => {
  const { t } = useTranslation();
  const { userId, companyId, agencyId } = useParams();
  const user = useUser(userId || '');
  const navigate = useNavigate();
  usePageTitle(getPageTitle('user.edit', { user: user.item?.name }));

  const isWaitingForApproval = (status?: UserStatus): boolean =>
    status === UserStatus.WaitingForApproval;

  const userIsAwaitingApproval = isWaitingForApproval(user.item?.status);
  const mode = ModificationMode.Edit;

  const form = useForm(user, {
    beforeSave: (item, skipValidation) => {
      if (userIsAwaitingApproval && !skipValidation) {
        item.status = UserStatus.PendingEmailValidation;
      }
    },
    mode,
    onSaveSuccess: (item, { skipValidation, previousState }) => {
      if (isWaitingForApproval(previousState.status) && !skipValidation) {
        navigate('/admin/users/awaitingApproval');
      }
    },
    validations: getUserSchema(mode),
  });

  const companyFilters = useMemo(
    () => [
      companyId || agencyId
        ? (['id', '==', companyId ?? agencyId] as Filter)
        : undefined,
    ],
    [companyId, agencyId]
  );

  const { list: companyList } = useCompanies({
    filters: companyFilters,
    // To remove sorting to eliminate need for index
    sorting: companyId ? null : undefined,
    // To remove default isDeleted filter to eliminate need for index
    includeDeleted: companyId ? true : undefined,
  });

  const backTo = companyId
    ? `/admin/companies/${companyId}`
    : agencyId
    ? `/admin/agencies/${agencyId}?view=${AgencyPageView.AssignedUsers}`
    : userIsAwaitingApproval
    ? '/admin/users/awaitingApproval'
    : '/admin/users';

  return (
    <>
      <FormControlHeader
        backTo={backTo}
        form={form}
        saveButtonLabel={
          userIsAwaitingApproval ? t('users.actions.accept') : undefined
        }
        title={t('users.form.title', { name: user.item?.name })}
        userIsAwaitingApproval={userIsAwaitingApproval}
      />
      {user.loading ? (
        <ProgressIndicator />
      ) : (
        <>
          <UserForm
            companies={companyList}
            form={form}
            isAdmin
            isAgent={false}
            mode={mode}
            userIsAwaitingApproval={userIsAwaitingApproval}
          />
          <Outlet context={form} />
        </>
      )}
    </>
  );
};

export default User;
