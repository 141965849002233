import {
  CheckCircle,
  Error,
  HourglassEmpty,
  NotInterested,
  Paid,
  StopCircle,
} from '@mui/icons-material';

import { ReservationStatus } from '../enums';

export const ReservationStatusIcons: Record<
  ReservationStatus,
  React.ReactNode
> = {
  cancelled: <NotInterested />,
  purchased: <Paid />,
  expired: <HourglassEmpty />,
  reserved: <CheckCircle />,
  temporarilyHeld: <StopCircle />,
  unknown: <Error sx={{ color: 'secondary.main' }} />,
  unknownExpired: <Error sx={{ color: 'secondary.main' }} />,
};
