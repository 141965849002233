import { useRef } from 'react';
import { AppBar, Container, Toolbar } from '@mui/material';
import { useAuth } from 'reactfire';

import { ReservationHeaderCounter, Show } from '../../components';

import {
  AppBarBranding,
  MenuItemToolbar,
  MobileMenu,
  SideMenu,
  UserMenu,
} from '..';
import { NavigationProps } from './Navigation.props';

export const Navigation = ({
  hideApplicationName,
  publicFacing,
  variant = 'top',
  children,
}: NavigationProps) => {
  const { currentUser } = useAuth();
  const appBarRef = useRef<HTMLDivElement | null>(null);

  const emailVerified = !currentUser || !!currentUser?.emailVerified;

  return (
    <AppBar ref={appBarRef} position="fixed">
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          role="menu"
          sx={{ justifyContent: 'space-between', height: '4.5rem' }}
        >
          <AppBarBranding hideApplicationName={hideApplicationName} />

          <Show if={variant === 'top' && emailVerified}>
            <MenuItemToolbar>{children}</MenuItemToolbar>
          </Show>

          <Show if={variant === 'side' && emailVerified}>
            <SideMenu anchorElRef={appBarRef}>{children}</SideMenu>
          </Show>

          <Show if={!publicFacing}>
            <UserMenu />

            <Show if={emailVerified}>
              <ReservationHeaderCounter />
            </Show>
          </Show>

          <MobileMenu anchorElRef={appBarRef}>
            <Show if={emailVerified}>{children}</Show>
          </MobileMenu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
