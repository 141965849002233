import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import { Container, Show } from '../../components';
import { NotificationLevel } from '../../enums';
import { Nullable } from '../../types';
import { PublicHeader } from '../../layouts/PublicHeader/PublicHeader.component';
import { getPageTitle } from '../../utils/pages';
import { queryStringToObject } from '../../utils/strings';
import {
  useNotification,
  usePageTitle,
  useProgress,
  usePublicAuthentication,
} from '../../hooks';

import { SuccessContent } from './components/SuccessContent/SuccessContent.component';
import { FailureContent } from './components/FailureContent/FailureContent.component';
import * as Sx from './VerifyEmail.styles';
import { VerifyEmailUrlParams } from './VerifyEmail.interface';

export const VerifyEmail = () => {
  usePageTitle(getPageTitle('verifyEmail'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showProgress } = useProgress();
  const { search } = useLocation();
  const { oobCode, userId } = queryStringToObject<VerifyEmailUrlParams>(search);
  const verifiedRef = useRef(false);
  const { verifyEmail } = usePublicAuthentication();
  const { addNotification } = useNotification();
  const functions = useFunctions();
  const resendActivation = httpsCallable(functions, 'resendEmailVerification');
  const updatePendingStatus = httpsCallable(functions, 'updatePendingStatus');

  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<Nullable<boolean>>(null);

  useEffect(() => showProgress(loading), [loading, showProgress]);

  useEffect(() => {
    if (!oobCode) {
      showProgress(false);
      return navigate('/login', { replace: true });
    }

    if (!verifiedRef.current) {
      verifiedRef.current = true;

      verifyEmail(oobCode)
        .then(async () => {
          setSuccess(true);
          await updatePendingStatus(userId);
        })
        .catch(() => setSuccess(false))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendVerificationEmail = () => {
    setLoading(true);
    addNotification(
      t('emailValidation.information'),
      NotificationLevel.Information
    );

    resendActivation(userId)
      .then(() =>
        addNotification(t('emailValidation.success'), NotificationLevel.Success)
      )
      .catch((error) => {
        const message =
          error.code === 'functions/failed-precondition'
            ? t('emailValidation.alreadyVerified')
            : t('emailValidation.error');

        addNotification(message, NotificationLevel.Error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PublicHeader
        icon={<MarkEmailReadIcon titleAccess={t('alt.checkedMailIcon')} />}
        title={t('verifyEmail.title')}
      />

      <Container sx={Sx.container} role="main">
        <Show if={!!success}>
          <SuccessContent />
        </Show>

        <Show if={success === false}>
          <FailureContent resendVerificationEmail={resendVerificationEmail} />
        </Show>
      </Container>
    </>
  );
};
