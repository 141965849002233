import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAuth } from 'reactfire';

import * as Sx from '../../VerifyEmail.styles';
import { translateString } from '../../../../i18n';
import { usePortalSettings, usePublicAuthentication } from '../../../../hooks';

export const SuccessContent = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { item: portalSettings } = usePortalSettings();
  const { currentUser: authUser } = useAuth();
  const { logout } = usePublicAuthentication();

  const navigateLogin = () => {
    if (authUser) {
      logout();
    }
    navigate('/login');
  };

  return (
    <>
      <Typography variant="h2" sx={Sx.title}>
        <CheckCircleIcon
          color="secondary"
          sx={Sx.icon}
          titleAccess={t('alt.successIcon')}
        />
        {t('verifyEmail.success.title')}
      </Typography>
      <Typography sx={Sx.text}>{t('verifyEmail.success.body')}</Typography>

      <Button
        onClick={() => navigateLogin()}
        variant="contained"
        sx={Sx.button}
      >
        {t('verifyEmail.success.button')}
      </Button>
      <Typography sx={Sx.footer}>
        <Trans
          i18nKey="verifyEmail.success.footer"
          components={[
            <Link
              href={translateString(portalSettings?.contactURL, i18n)}
              target="_blank"
            />,
          ]}
        />
      </Typography>
    </>
  );
};
