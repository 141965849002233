import { Drawer, Grid, Typography } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../hooks';
import { ContrastButtonMain } from '..';

import { ImpersonateDrawerProps } from './ImpersonateDrawer.props';
import * as Sx from './ImpersonateDrawer.styles';

export const ImpersonateDrawer = ({
  id,
  onDoneClicked,
}: ImpersonateDrawerProps) => {
  const { t } = useTranslation();
  const { item: impersonated } = useUser(id);

  if (!impersonated) return null;

  return (
    <Drawer
      open={true}
      variant="permanent"
      anchor="bottom"
      PaperProps={{
        sx: Sx.drawerPaper,
      }}
    >
      <Grid
        display="flex"
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexShrink={0}
          sx={{
            borderTopLeftRadius: 20,
            height: '100%',
            width: '125px',
          }}
        >
          <HowToRegIcon style={{ fontSize: 60 }} />
        </Grid>

        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          maxWidth="1200px"
        >
          <Grid display="flex" width="75%" justifyContent="space-around">
            <Grid>
              <Typography>{t('inventory.impersonate.reservingFor')}</Typography>
              <Typography variant="h3">{impersonated.name}</Typography>
            </Grid>

            <Grid>
              <Typography>{t('inventory.impersonate.company')}</Typography>
              <Typography variant="h3">{impersonated.companyName}</Typography>
            </Grid>

            <Grid>
              <Typography>{t('inventory.impersonate.market')}</Typography>
              <Typography variant="h3">
                {impersonated.canSeeCSA ? 'CSA' : ''}
                {impersonated.canSeeCSA && impersonated.canSeeUL ? ', ' : ''}
                {impersonated.canSeeUL ? 'UL' : ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid display="flex" width="25%" justifyContent="space-around">
            <Grid>
              <Typography>{t('inventory.impersonate.customer')}</Typography>
              <Grid display="flex">
                <Typography variant="h3">
                  {impersonated.reservationCount}
                </Typography>
                <Typography variant="h3" color="secondary">
                  /{impersonated.maxReservationsPerPeriod}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Typography>{t('inventory.impersonate.nc')}</Typography>
              <Typography variant="h3">
                {impersonated.reservationByNCCount}
              </Typography>
            </Grid>
            <Grid>
              <Typography>{t('inventory.impersonate.total')}</Typography>
              <Typography variant="h3">
                {impersonated?.reservationCount +
                  impersonated.reservationByNCCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ width: '15%' }} display="flex" alignItems="center">
          <ContrastButtonMain onClick={onDoneClicked}>
            {t('inventory.impersonate.done')}
          </ContrastButtonMain>
        </Grid>
      </Grid>
    </Drawer>
  );
};
