import { Timestamp } from 'firebase/firestore';
import { Model } from '../types';

export class ConversionRate implements Model {
  id!: string;
  date!: Timestamp;
  statistics!: Record<string, ConversionStatistic>;
}

export class ConversionStatistic {
  companyName!: string;
  cancelled!: number;
  expired!: number;
  purchased!: number;
  reserved!: number;
}

export class SanitizedConversionRate {
  id!: string;
  companyName!: string;
  userName!: string;
  cancelled!: number;
  expired!: number;
  purchased!: number;
  reserved!: number;
  rate!: number;
  userConversionStatistics!: SanitizedConversionRate[];

  constructor() {
    this.companyName = '';
    this.userName = '';
    this.cancelled = 0;
    this.expired = 0;
    this.purchased = 0;
    this.reserved = 0;
    this.rate = 0;
    this.userConversionStatistics = [];
  }
}
