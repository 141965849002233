import { useTranslation } from 'react-i18next';

import { useMapSubform } from '../../hooks';
import { CompanyClassLength } from '../../models';
import { NumericTextField } from '../../components';
import { StyledTableRow, StyledTableCell } from '../../tables';
import { CompanyClassLengthRowProps } from './CompanyClassLengthRow.props';

export const CompanyClassLengthRow = ({
  form,
  field,
  productType,
}: CompanyClassLengthRowProps) => {
  const { t } = useTranslation();
  const subform = useMapSubform(
    form,
    field,
    productType,
    new CompanyClassLength(1000, 500)
  );

  return (
    <StyledTableRow>
      <StyledTableCell>{t(`productTypes.${productType}`)}</StyledTableCell>
      <StyledTableCell>{t('common.all')}</StyledTableCell>
      <StyledTableCell>
        <NumericTextField
          form={subform}
          field="classB"
          label={t('portalSettings.companyClassLengths.fields.classB')}
          defaultValue={1000}
          minimum={0}
          maximum={9999}
          fullWidth
          unitLabel="m"
        />
      </StyledTableCell>
      <StyledTableCell>
        <NumericTextField
          form={subform}
          field="classC"
          label={t('portalSettings.companyClassLengths.fields.classC')}
          defaultValue={500}
          minimum={0}
          maximum={Math.min(
            subform.item?.classB || subform.item?.classB === 0
              ? subform.item?.classB
              : 9999,
            9999
          )}
          fullWidth
          unitLabel="m"
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};
