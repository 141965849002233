import { TableCell, styled } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    '.MuiTableHead-root &': {
      fontWeight: 'bold',
      paddingTop: '0.825rem',
      paddingBottom: '0.825rem',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
    },
    '.MuiTableBody-root &:first-of-type': {
      borderBottomLeftRadius: '0.5em',
    },
    '.MuiTableBody-root &:last-child': {
      borderBottomRightRadius: '0.5em',
    },
    [theme.breakpoints.up('xs')]: {
      borderBottom: 'none',
      borderTop: 'none',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      flexBasis: '100%',
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
      borderTop: `solid 0.5em ${theme.palette.background.paper}`,
      paddingTop: '0.625rem',
      paddingBottom: '0.625rem',
      flexBasis: 'unset',
    },
  };
});
