import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { DateTime } from 'luxon';
import { ConsultationMode, UserPermission } from '../../enums';
import { NeedsPermission } from '../../security';
import { ReservationFilters as ReservationFiltersType } from '../../types';
import formatReservationNumber from '../../utils/formatReservationNumber';
import { Container, FilterContainer } from '..';
import { getSelectableCompanies } from '../../utils';
import { ReservationFiltersProps } from './ReservationFilters.props';

export const ReservationFilters = ({
  companies,
  filters,
  mode = ConsultationMode.Current,
  setFilters,
  isAdmin = false,
}: ReservationFiltersProps) => {
  const { t } = useTranslation();

  const selectableCompanies = useMemo(
    () => getSelectableCompanies(companies),
    [companies]
  );

  const [reservationNumber, setReservationNumber] = useState<number | null>(
    null
  );
  const [partNumber, setPartNumber] = useState('');
  const [debouncedFilters] = useDebounce(partNumber + reservationNumber, 500);

  useEffect(() => {
    if (reservationNumber) setPartNumber('');
  }, [reservationNumber]);

  useEffect(() => {
    if (partNumber) setReservationNumber(null);
  }, [partNumber]);

  useEffect(() => {
    setFilters((value: ReservationFiltersType) => {
      return {
        ...value,
        reservationNumber,
        partNumber,
      };
    });
    // Only fire when both partNumber and reservationNumber are updated, therefore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters, setFilters]);

  return (
    <>
      <FilterContainer>
        <Grid
          container
          spacing={2}
          sx={{
            my: { xs: 2, md: 4 },
          }}
        >
          <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
            <TextField
              fullWidth
              label={t('reservations.filters.reservationNumber')}
              value={
                reservationNumber !== null
                  ? formatReservationNumber(reservationNumber)
                  : ''
              }
              onChange={(e) => {
                const parsedNumber = Number(
                  e.currentTarget.value.replace(/[^0-9]/g, '')
                );
                setReservationNumber(
                  parsedNumber || parsedNumber === 0 ? parsedNumber : null
                );
              }}
              onBlur={(e) => {
                setReservationNumber(
                  Number(e.currentTarget.value.replace(/[^0-9]/g, '')) || null
                );
              }}
              inputProps={{
                placeholder: formatReservationNumber(0),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" sx={{ color: '#979797' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
            <TextField
              fullWidth
              label={t('reservations.filters.partNumber')}
              value={partNumber || ''}
              onChange={(e) =>
                setPartNumber(e.currentTarget.value.replace(/[^0-9]/g, ''))
              }
              inputProps={{
                maxLength: 6,
                minLength: 6,
                placeholder: '800001',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" sx={{ color: '#979797' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {companies && (
            <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
              <Autocomplete
                fullWidth={true}
                popupIcon={<KeyboardArrowDownIcon />}
                options={selectableCompanies}
                getOptionLabel={(option) => option.name}
                value={
                  selectableCompanies.find((c) => c.id === filters.companyId) ??
                  null
                }
                onChange={(_, value) => {
                  setFilters((prevValue) => {
                    return {
                      ...prevValue,
                      companyId: value?.id || null,
                    };
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('reservations.filters.companyName')}
                  />
                )}
              />
            </Grid>
          )}
          {mode === ConsultationMode.History && (
            <>
              <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
                <DatePicker
                  label={t('reservations.filters.startDate')}
                  value={filters.startDate}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  onChange={(value: DateTime | null) =>
                    setFilters((prevValue) => {
                      return {
                        ...prevValue,
                        startDate: value,
                      };
                    })
                  }
                  format={t('formats.date')}
                  maxDate={filters.endDate || DateTime.now()}
                />
              </Grid>
              <Grid item xs={12} sx={{ flexBasis: { md: '20%' } }}>
                <DatePicker
                  label={t('reservations.filters.endDate')}
                  value={filters.endDate}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  onChange={(value: DateTime | null) =>
                    setFilters((prevValue) => {
                      return {
                        ...prevValue,
                        endDate: value,
                      };
                    })
                  }
                  format={t('formats.date')}
                  minDate={filters.startDate || undefined}
                  maxDate={DateTime.now()}
                />
              </Grid>
            </>
          )}
        </Grid>
      </FilterContainer>
      {!isAdmin && (
        <NeedsPermission
          allOf={[UserPermission.ManageOwnReservations]}
          oneOf={[
            UserPermission.ManageCompanyReservations,
            UserPermission.ManageAgencyCustomerReservations,
          ]}
        >
          <Container
            sx={{ my: 3 }}
            subSx={{
              display: 'flex',
              justifyContent: { xs: 'flex-end', md: 'flex-start' },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showOnlyMyReservations}
                  onChange={() =>
                    setFilters((prevValue) => {
                      return {
                        ...prevValue,
                        showOnlyMyReservations: !filters.showOnlyMyReservations,
                      };
                    })
                  }
                  color="secondary"
                />
              }
              label={t('reservations.showMyReservationsOnly') as string}
              disableTypography={true}
              sx={{
                fontSize: '1rem',
              }}
            />
          </Container>
        </NeedsPermission>
      )}
    </>
  );
};
