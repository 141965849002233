import { SxProps } from '@mui/material';

export const confirmButton: SxProps = {
  backgroundColor: 'secondary.main',
  marginRight: 2,
  '&:hover, &:disabled': {
    backgroundColor: 'secondary.main',
  },
};

export const cancelButton: SxProps = {
  color: 'primary.contrastText',
  borderColor: 'primary.contrastText',
  '&:hover, &:disabled': {
    borderColor: 'primary.contrastText',
    color: 'primary.contrastText',
  },
};

export const message: SxProps = {
  mt: 2,
};
