import { useState } from 'react';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Show } from '..';
import { ExportMenuProps } from './ExportMenu.props';

export const ExportMenu = ({ onPDFClicked, onCSVClicked }: ExportMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClicked = (func?: () => void) => {
    if (func) {
      func();
    }

    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        Export
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Show if={!!onPDFClicked}>
          <MenuItem onClick={() => onItemClicked(onPDFClicked)}>
            <Grid width="45px">PDF</Grid>
          </MenuItem>
        </Show>

        <Show if={!!onCSVClicked}>
          <MenuItem onClick={() => onItemClicked(onCSVClicked)}>
            <Grid width="45px">CSV</Grid>
          </MenuItem>
        </Show>
      </Menu>
    </div>
  );
};
