import {
  Button,
  Checkbox,
  Grid,
  Popover,
  Stack,
  Switch,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore, useFunctions } from 'reactfire';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FilterOption,
  InventoryClass,
  MeasurementUnits,
  NotificationLevel,
  UserPermission,
} from '../../enums';
import {
  useAvailableReels,
  useCompany,
  useNotification,
  usePagination,
  usePermission,
  useProgress,
  useReservationsAreAllowed,
} from '../../hooks';
import { Reel } from '../../models';
import { DataTable, DataTableColumn } from '../../tables';
import { Filter, Response } from '../../types';
import notEmpty from '../../utils/notEmpty';
import { inventoryReportPdf, inventoryReportCsv } from '../../reports';
import {
  ContrastButtonMain,
  ExportMenu,
  ReelQuantity,
  ReelWeight,
  Show,
} from '..';

import ReservationParameters from '../../types/ReservationParameters';
import PartialCut from '../../types/PartialCut';
import * as FiltersSx from '../InventoryFilters/InventoryFilters.styles';
import { InventoryTableProps } from './InventoryTable.props';
import { RowCollapse } from './components/RowCollapse/RowCollapse.component';
import * as TableSx from './InventoryTable.styles';
import { isDisabledFunction, isSelectedFunction } from './utils';

export const InventoryTable = ({
  type,
  filters,
  setFilters,
  user,
  impersonatedCustomer,
  impersonatedUser,
  openedCollapse,
  setOpenedCollapse,
  sorting,
  measureUnits,
  setMeasureUnits,
  multipleReservations,
  selectedReels,
  setMultipleReservations,
  resetMultipleReservations,
  toggleItemSelection,
  isDesktop = false,
  isAdmin,
  isAgent,
}: InventoryTableProps) => {
  const [reserving, setReserving] = useState(false);
  const [partialCutError, setPartialCutError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const impersonating = useRef<boolean>(
    !!impersonatedUser || !!impersonatedCustomer
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const navigate = useNavigate();
  const functions = useFunctions();
  const reserveFunction = httpsCallable<ReservationParameters>(
    functions,
    'reserveReel'
  );
  const { hasOneOfPermissions, hasPermission } = usePermission();
  const reservationsAreAllowed = useReservationsAreAllowed();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const [exportingPdf, setExportingPdf] = useState(false);
  const [exportingCsv, setExportingCsv] = useState(false);

  const firestore = useFirestore();
  const { t, i18n } = useTranslation();
  const { item: company } = useCompany(user.companyRef?.id || '');

  const pagination = usePagination();

  useEffect(() => {
    showProgress(reserving);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserving]);

  useEffect(() => {
    impersonating.current = !!impersonatedUser || !!impersonatedCustomer;
  }, [impersonatedCustomer, impersonatedUser]);

  const queryFilters = useMemo(() => {
    if (!filters) return undefined;

    const filterList = [];

    filterList.push(`${FilterOption.StandardType}=${type}`);
    filterList.push(
      `${FilterOption.InventoryClass}=${
        company ? company.class : InventoryClass.ClassC
      }`
    );

    if (!filters.searchByCable && filters.partNumber) {
      filterList.push(`${FilterOption.CableId}=${filters.partNumber}`);
    } else {
      const {
        productType,
        productSubType,
        material,
        gauge,
        numberOfConductors,
      } = filters;
      let { voltage } = filters;
      if (voltage === 'N/A') {
        voltage = -1;
      }

      filterList.push(
        productType && `${FilterOption.ProductType}=${productType}`
      );
      filterList.push(
        productSubType && `${FilterOption.ProductSubType}=${productSubType}`
      );
      filterList.push(material && `${FilterOption.MaterialType}=${material}`);
      filterList.push(voltage && `${FilterOption.Voltage}=${voltage}`);
      filterList.push(
        productType !== 5 &&
          numberOfConductors &&
          `${FilterOption.NumberOfConductors}=${numberOfConductors}`
      );
      filterList.push(gauge && `${FilterOption.Gauge}=${gauge}`);
    }

    return [
      ['isVisible', '!=', false],
      ['filters', 'array-contains', filterList.filter(notEmpty).join(';')],
    ] as Filter[];
  }, [filters, type, company]);

  const { list: cables, loading } = useAvailableReels({
    filters: queryFilters,
    pagination,
    sorting,
  });

  const reserveReel = (
    reservedReel: Reel,
    note: string,
    packagesAmount: number,
    partialCut: PartialCut | undefined
  ) => {
    setReserving(true);
    addNotification(
      t('inventory.reserveInfo'),
      NotificationLevel.Information,
      impersonating.current
    );

    reserveFunction({
      reelId: reservedReel.id,
      impersonatedUserId: impersonatedUser?.id,
      inventoryType: type,
      madeForCompanyId: impersonatedCustomer?.company.companyId,
      madeForCustomerName: impersonatedCustomer?.personName,
      note,
      packagesAmount,
      partialCut,
    })
      .then((res) => {
        if (!(res.data as Response).succeeded) {
          addNotification(
            t('inventory.reserveNotAvailable'),
            NotificationLevel.Error,
            impersonating.current
          );
        } else {
          addNotification(
            t('inventory.reserveSuccess'),
            NotificationLevel.Success,
            impersonating.current
          );
        }
      })
      .catch((error) => {
        let message = '';
        if (
          error.code === 'functions/invalid-argument' &&
          error.message.startsWith('Not enough cable left')
        ) {
          message = t('inventory.reserveTooBigPartialCutWarning');
          setPartialCutError(true);
        } else {
          message = t('inventory.reserveError');
        }
        addNotification(
          message,
          NotificationLevel.Error,
          impersonating.current
        );
      })
      .finally(() => {
        setReserving(false);
      });
  };

  const reelDescription = (description: string, item: Reel) => {
    if (item.colors) {
      return (
        <>
          <div>
            {description} - {item.cableRef.id}
          </div>
          <div>{item.colors}</div>
        </>
      );
    }

    return (
      <>
        {description} - {item.cableRef.id}
      </>
    );
  };

  useEffect(() => {
    if (filters && !filters.searchByCable && !loading && cables) {
      setFilters({
        ...filters,
        productType: cables.length > 0 ? cables[0].productType : null,
      });
    }
    // This component is only rendered if filters is not null, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.productType, cables, loading]);

  useEffect(() => {
    if (!(exportingPdf || exportingCsv) || !queryFilters || !filters) return;

    const q = query(
      collection(firestore, 'AvailableReels'),
      ...queryFilters.map((queryFilter) =>
        where(queryFilter[0], queryFilter[1], queryFilter[2])
      ),
      orderBy('isVisible', 'asc'),
      orderBy(sorting.sortField, sorting.sortDirection),
      orderBy('quantity', 'desc')
    );

    getDocs(q)
      .then((res) => {
        if (exportingPdf)
          inventoryReportPdf.generate(
            res.docs.map((doc) => doc.data() as Reel),
            filters,
            measureUnits,
            type,
            t,
            i18n
          );

        if (exportingCsv)
          inventoryReportCsv.generate(
            res.docs.map((doc) => doc.data() as Reel),
            filters,
            measureUnits,
            t
          );
      })
      .finally(() => {
        setExportingPdf(false);
        setExportingCsv(false);
      });
  }, [
    exportingCsv,
    exportingPdf,
    filters,
    firestore,
    i18n,
    measureUnits,
    queryFilters,
    sorting,
    t,
    type,
  ]);

  const handleCloseMultipleReservations = () => {
    setAnchorEl(null);
    resetMultipleReservations();
  };

  const shouldNavigateToReserve = useMemo(() => {
    if (
      isAdmin &&
      hasPermission(UserPermission.ImpersonateCustomer) &&
      !impersonatedUser
    ) {
      return true;
    }

    if (
      isAgent &&
      hasPermission(UserPermission.ManageOwnReservations) &&
      !impersonatedCustomer
    ) {
      return true;
    }

    return false;
  }, [hasPermission, impersonatedCustomer, impersonatedUser, isAdmin, isAgent]);

  useEffect(() => {
    if (multipleReservations && shouldNavigateToReserve) {
      navigate('reserve');
    }
  }, [multipleReservations, navigate, shouldNavigateToReserve]);

  const columns: { [key: string]: JSX.Element | undefined } = {
    selected: (
      <DataTableColumn
        key="selected-column"
        property="multipleSelection"
        disableSort
        textAlign="center"
        output={(_, item: Reel) => {
          if (isDisabledFunction(item)) {
            return (
              <Tooltip
                title={
                  <Typography>
                    {t('inventory.multipleReservations.disabled')}
                  </Typography>
                }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'black',
                      textAlign: 'center',
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'black',
                    },
                  },
                }}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            );
          } else {
            return (
              <Checkbox
                id={item.id}
                color="secondary"
                checked={isSelectedFunction(selectedReels, item)}
              />
            );
          }
        }}
      />
    ),
    partsNumber: (
      <DataTableColumn
        key="parts-number-column"
        property="description"
        title={t('inventory.fields.partNumber')}
        mobileWidth="calc(100% - 9rem)"
        mobileOrder={0}
        output={(description, item: Reel) => reelDescription(description, item)}
      />
    ),
    quantity: (
      <DataTableColumn
        key="quantity-column"
        property="quantity"
        title={t('inventory.fields.quantity')}
        hasMobileLabel={false}
        mobileOrder={1}
        output={(_, item: Reel) => (
          <ReelQuantity item={item} unitsType={measureUnits} />
        )}
      />
    ),
    reel: (
      <DataTableColumn
        key="reel-column"
        property="reel"
        title={t('inventory.fields.reel')}
        mobileHidden
        mobileVisibleWhenActive
        mobileWidth="50%"
      />
    ),
    weight: (
      <DataTableColumn
        key="weight-column"
        property="weight"
        title={t('inventory.fields.weight')}
        mobileHidden
        mobileVisibleWhenActive
        mobileWidth="50%"
        output={(_, item: Reel) => (
          <ReelWeight item={item} unitsType={measureUnits} />
        )}
      />
    ),
    reelId: (
      <DataTableColumn
        key="reel-id-column"
        property="id"
        title={t('inventory.fields.reelId')}
        mobileHidden
      />
    ),
    reserveButton:
      (hasPermission(UserPermission.ManageOwnReservations) ||
        hasPermission(UserPermission.ImpersonateCustomer)) &&
      reservationsAreAllowed ? (
        <DataTableColumn
          key="reserve-column"
          property="action"
          title=""
          disableSort
          mobileHeight={0}
          mobileWidth="9rem"
          mobileOrder={0}
          textAlign="left"
          mobileTextAlign="right"
          output={(_, item) => (
            <Button
              sx={{ display: 'inline-block' }}
              variant="contained"
              onClick={(e) => {
                if (shouldNavigateToReserve) {
                  navigate('reserve');
                }

                setOpenedCollapse(item.id);
                e.stopPropagation();
              }}
              disabled={
                (openedCollapse === item.id &&
                  ((!isAgent &&
                    hasPermission(UserPermission.ManageOwnReservations)) ||
                    (hasPermission(UserPermission.ImpersonateCustomer) &&
                      !!impersonatedUser) ||
                    (hasPermission(UserPermission.ManageOwnReservations) &&
                      !!impersonatedCustomer))) ||
                reserving ||
                (user && !impersonatedUser
                  ? user.reservationCount >= user.maxReservationsPerPeriod
                  : false) ||
                (impersonatedUser?.reservationCount || 0) +
                  (impersonatedUser?.reservationByNCCount || 0) >=
                  99
              }
            >
              {t('inventory.actions.reserve')}
            </Button>
          )}
        />
      ) : undefined,
    added: (
      <DataTableColumn
        key="added-column"
        property="added"
        disableSort
        mobileHidden
        width="8%"
        output={(_, item: Reel) => (
          <Typography fontWeight={'bold'}>
            {isSelectedFunction(selectedReels, item)
              ? t('inventory.fields.added')
              : ''}
          </Typography>
        )}
      />
    ),
  };

  const showMultipleReservationsSwitch = useMemo(
    () =>
      isDesktop &&
      !!reservationsAreAllowed &&
      !!hasOneOfPermissions([
        UserPermission.ManageOwnReservations,
        UserPermission.ImpersonateCustomer,
      ]),
    [hasOneOfPermissions, isDesktop, reservationsAreAllowed]
  );

  return (
    <>
      <Show if={cables.length > 0}>
        <Grid container mt={1} justifyContent={'space-between'}>
          <Show if={showMultipleReservationsSwitch}>
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              md={4}
              xs={12}
            >
              <Switch
                id="multiple-reservations-switch"
                checked={multipleReservations}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setMultipleReservations(true);
                  } else if (selectedReels.length) {
                    setAnchorEl(e.currentTarget);
                  } else {
                    handleCloseMultipleReservations();
                  }
                }}
              />
              <Typography
                sx={FiltersSx.switchLabel(i18n, multipleReservations)}
              >
                <label htmlFor="multiProductReservation">
                  {t('inventory.filters.multiProductReservation')}
                </label>
              </Typography>
            </Grid>
            <Popover
              open={anchorEl !== null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack spacing={2} p={2} maxWidth={400}>
                <Typography fontWeight="bold">
                  {t('inventory.multipleReservations.close.title')}
                </Typography>
                <Typography>
                  {t('inventory.multipleReservations.close.body')}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  spacing={1}
                >
                  <Button
                    variant="contained"
                    sx={{ paddingX: 5 }}
                    onClick={() => setAnchorEl(null)}
                  >
                    {t('inventory.actions.cancel')}
                  </Button>
                  <ContrastButtonMain onClick={handleCloseMultipleReservations}>
                    {t('inventory.multipleReservations.close.turnOff')}
                  </ContrastButtonMain>
                </Stack>
              </Stack>
            </Popover>
          </Show>

          <Grid
            container
            item
            md={showMultipleReservationsSwitch ? 8 : 12}
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          >
            <Grid item justifyContent="center" display="flex" pr={1}>
              <ToggleButtonGroup
                size="small"
                exclusive
                value={measureUnits}
                onChange={(_, value) =>
                  setMeasureUnits((prev) => (value ? (prev = value) : prev))
                }
              >
                <ToggleButton value={MeasurementUnits.Metric}>
                  {t('users.fields.units.metric')}
                </ToggleButton>
                <ToggleButton value={MeasurementUnits.Imperial}>
                  {t('users.fields.units.imperial')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid display="flex" justifyContent="flex-end">
              <ExportMenu
                onPDFClicked={() => setExportingPdf(true)}
                onCSVClicked={() => setExportingCsv(true)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Show>

      <DataTable
        data={queryFilters && !loading ? cables : []}
        isDisabledFunction={isDisabledFunction}
        isSelectedFunction={isSelectedFunction}
        selected={selectedReels}
        loading={loading}
        pagination={pagination}
        sorting={sorting}
        tableStyles={multipleReservations ? TableSx.tableStyles : undefined}
        tableBodyStyles={
          multipleReservations ? TableSx.tableBodyStyles : undefined
        }
        rowOnClick={
          multipleReservations ? (item) => toggleItemSelection(item) : undefined
        }
        rowCollapseContent={
          multipleReservations
            ? undefined
            : (item) => (
                <RowCollapse
                  item={item}
                  openedCollapse={openedCollapse}
                  partialCutError={partialCutError}
                  reserve={reserveReel}
                  reserving={reserving}
                  reservingCustomer={impersonatedCustomer}
                  reservingUser={impersonatedUser ?? user}
                  setOpenedCollapse={setOpenedCollapse}
                  setPartialCutError={setPartialCutError}
                  units={measureUnits}
                />
              )
        }
        rowCollapseOpened={
          multipleReservations
            ? undefined
            : (item: Reel) =>
                item.id === openedCollapse &&
                ((hasPermission(UserPermission.ImpersonateCustomer) &&
                  !!impersonatedUser) ||
                  (isAgent &&
                    hasPermission(UserPermission.ManageOwnReservations) &&
                    !!impersonatedCustomer) ||
                  (!!hasPermission(UserPermission.ManageOwnReservations) &&
                    !(isAdmin || isAgent)))
        }
      >
        {multipleReservations
          ? [
              columns.selected,
              columns.partsNumber,
              columns.quantity,
              columns.reel,
              columns.weight,
              columns.reelId,
              columns.added,
            ]
          : [
              columns.partsNumber,
              columns.quantity,
              columns.reel,
              columns.weight,
              columns.reelId,
              columns.reserveButton,
            ]}
      </DataTable>
    </>
  );
};
