import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useTranslation } from 'react-i18next';

import { Container, DecoratedHeader } from '../../../components';
import { Tab } from '../../../navigation';
import { NeedsPermission } from '../../../security';
import { UserPermission } from '../../../enums';

interface Props {
  children: React.ReactNode;
  companyId?: string;
}

const CompanyPartial = ({ children, companyId }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<BusinessCenterIcon />}
          title={t('navigation.companies')}
          to="/admin/companies"
          variant="colored"
        >
          <NeedsPermission oneOf={[UserPermission.ManageCustomerUsers]}>
            <Tab
              title={t('navigation.assignedUsers')}
              to={`/admin/companies/${companyId}`}
            />
          </NeedsPermission>
          <NeedsPermission oneOf={[UserPermission.ManageOrderFilesAccess]}>
            <Tab
              title={t('navigation.orderFilePermissions')}
              to={`/admin/companies/${companyId}/order-file-permissions`}
            />
          </NeedsPermission>
        </DecoratedHeader>
      </Container>
      {children}
    </>
  );
};

export default CompanyPartial;
