import { SxProps } from '@mui/material';

export const formLabel = (variant?: 'small' | 'medium' | 'large'): SxProps => {
  return {
    fontSize:
      variant === 'small'
        ? '0.75rem'
        : variant === 'medium'
        ? '1rem'
        : '1.25rem',
  };
};
