import { ReportFile } from '../models';
import { CollectionQueryOptions } from '../types';
import { useCollection } from './useCollection';

export function useReportFiles(
  companyId: string,
  options?: CollectionQueryOptions
) {
  return useCollection<ReportFile>(
    `Companies/${companyId}/ReportFiles`,
    [],
    options?.sorting || {
      sortField: 'yearMonth',
      sortDirection: 'desc',
    },
    options?.pagination,
    options?.filters
  );
}
