import { useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ErrorLogsFilters as Filters } from '../../types';
import { FilterContainer } from '..';
import { ErrorLogType } from '../../enums/ErrorLogType';
import { ErrorLogsFiltersProps } from './ErrorLogsFilters.props';

const emptyFilters = {
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
  errorLogType: null,
};

export const ErrorLogsFilters = ({ onSearch }: ErrorLogsFiltersProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<Filters>(emptyFilters);

  const clearFilters = () => {
    setFilters(emptyFilters);
    onSearch(null);
  };

  return (
    <FilterContainer
      buttonsDisabled={!filters.startDate || !filters.endDate}
      title={t('reports.errorLogs.title')}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid sx={{ my: { xs: 2, md: 4 } }}>
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.errorLogs.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: { actions: ['clear'] },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || DateTime.now()}
          />
          <DatePicker
            label={t('reports.errorLogs.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: { actions: ['clear'] },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate}
            maxDate={DateTime.now()}
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(type) => t(`reports.errorLogs.types.${type}`)}
            options={Object.values(ErrorLogType)}
            value={filters.errorLogType}
            onChange={(_, errorLogType) =>
              setFilters((prev) => {
                return { ...prev, errorLogType };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t('reports.errorLogs.type')}
              />
            )}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
