import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthError, AuthErrorCodes, ParsedToken } from 'firebase/auth';

import hooks from '../firebase/hooks';
import authFunctions from '../firebase/auth';
import clearFirestoreCache from '../firebase/cache';
import { UserRole } from '../enums/';
import { roles } from '../security';
import {
  CurrentUserContext,
  CurrentUserContextValue,
} from '../providers/CurrentUserProvider';

export const usePublicAuthentication = () => {
  const auth = hooks.useAuth();
  const { user, logout, loading } = useAuthentication();

  const login = useCallback(
    async (
      username: string,
      password: string
    ): Promise<'success' | 'failure' | 'disabled'> => {
      try {
        clearFirestoreCache();
        await authFunctions.signInWithEmailAndPassword(
          auth,
          username,
          password
        );
      } catch (error) {
        const authError = error as AuthError;
        if (authError.code === AuthErrorCodes.USER_DISABLED) {
          return 'disabled';
        }
        return 'failure';
      }
      return 'success';
    },
    [auth]
  );

  const verifyPasswordResetCode = useCallback(
    (oobCode: string) => authFunctions.verifyPasswordResetCode(auth, oobCode),
    [auth]
  );

  const verifyEmail = useCallback(
    (oobCode: string) => authFunctions.applyActionCode(auth, oobCode),
    [auth]
  );

  return { user, login, verifyEmail, verifyPasswordResetCode, logout, loading };
};

export const useAuthentication = () => {
  const auth = hooks.useAuth();

  const { status, data } = hooks.useSigninCheck();

  const [claims, setClaims] = useState<ParsedToken | null>(null);

  const user = useMemo(() => data?.user, [data]);
  const loading = useMemo(() => status === 'loading', [status]);
  const role = useMemo(() => claims?.role as UserRole, [claims]);

  useEffect(() => {
    let isMounted = true;
    user?.getIdTokenResult().then((tokenResult) => {
      if (isMounted && tokenResult.claims) {
        setClaims(tokenResult.claims);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [user]);

  const logout = useCallback(async () => {
    clearFirestoreCache();
    localStorage.clear();
    await authFunctions.signOut(auth);
  }, [auth]);

  const refresh = useCallback(() => {
    user?.getIdTokenResult(true).then((tokenResult) => {
      if (tokenResult.claims) {
        setClaims(tokenResult.claims);
      }
    });
  }, [user]);

  return {
    loading,
    logout,
    refresh,
    role,
    isAdmin: roles.AdminRoles.includes(role),
    isAgent: roles.AgentRoles.includes(role),
    user,
  };
};

export default function AuthConsumer(): CurrentUserContextValue {
  return React.useContext(CurrentUserContext) as CurrentUserContextValue;
}
