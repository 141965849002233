import { Timestamp } from 'firebase/firestore';

import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import {
  useAuthentication,
  useCurrentUser,
  usePagination,
  useSorting,
} from '../../../hooks';
import { DataTable, DataTableColumn } from '../../../tables';
import { Filter } from '../../../types';
import { useUserConversionRates } from '../../../hooks/useUserConversionRates';
import * as Sx from './BreakdownRowCollpase.styles';
import { BreakdownRowCollapseProps } from './BreakdownRowCollapse.props';

export const BreakdownRowCollapse = ({
  companyId,
  conversionRatefilters,
  sanitizedConversionRate,
}: BreakdownRowCollapseProps) => {
  const { t } = useTranslation();
  const sorting = useSorting('userName', { sortDirection: 'asc' });
  const pagination = usePagination();
  const { isAgent } = useAuthentication();
  const { companyRef } = useCurrentUser();

  const logsQueryFilter = useMemo(() => {
    const result = [];

    result.push(['companyId', '==', companyId]);

    if (!conversionRatefilters) return result;

    const { startDate, endDate } = conversionRatefilters;

    if (startDate) {
      result.push([
        'date',
        '>=',
        Timestamp.fromMillis(startDate.startOf('day').toMillis()),
      ]);
    }

    if (endDate) {
      result.push([
        'date',
        '<=',
        Timestamp.fromMillis(endDate.endOf('day').toMillis()),
      ]);
    }

    return result;
  }, [companyId, conversionRatefilters]) as Filter[];

  const { list: userConversionRates, unpaginatedResults } =
    useUserConversionRates(
      companyId,
      {
        filters: logsQueryFilter,
        sorting,
        pagination,
      },
      isAgent ? companyRef.id : undefined
    );

  useEffect(() => {
    sanitizedConversionRate.userConversionStatistics = unpaginatedResults;
  }, [sanitizedConversionRate, unpaginatedResults]);

  return (
    <Grid sx={Sx.container}>
      <DataTable
        data={userConversionRates}
        sorting={sorting}
        pagination={pagination}
      >
        <DataTableColumn
          property="userName"
          title={t('reports.conversionRate.userName')}
          width="20%"
        />
        <DataTableColumn
          property="reserved"
          title={t('reports.conversionRate.reservations')}
          width="15%"
        />
        <DataTableColumn
          property="cancelled"
          title={t('reports.conversionRate.cancelled')}
          width="15%"
        />
        <DataTableColumn
          property="expired"
          title={t('reports.conversionRate.expired')}
          width="15%"
        />
        <DataTableColumn
          property="purchased"
          title={t('reports.conversionRate.purchased')}
          width="15%"
        />
        <DataTableColumn
          property="rate"
          title={t('reports.conversionRate.rate')}
          output={(value) => `${value}%`}
          width="20%"
        />
      </DataTable>
    </Grid>
  );
};
