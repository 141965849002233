import { Box, Stack, SxProps, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { BORDER_COLOR } from '../../../modals/common.styles';

export const selectStyles: SxProps = {
  backgroundColor: '#ffffff',
  borderRadius: '4px',
  '.MuiSelect-icon': { color: BORDER_COLOR },
  '&.MuiInputBase-root': {
    '&.MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderWidth: '3px',
      },
    },
  },
};

export const drawerIconStyles: SxProps = {
  color: 'secondary.main',
};

export const formControlStyles: SxProps = {
  backgroundColor: 'inherit',
};

export const StyledDrawerHeader = styled(Box)(() => {
  return {
    backgroundColor: '#F0F0F0',
    padding: '25px 10px 25px 20px',
  };
});

export const StyledDrawerContent = styled(Stack)(() => {
  return {
    padding: '20px',
    flexGrow: 1,
  };
});

export const StyledDrawerFooter = styled(Stack)(() => {
  return {
    padding: '20px',
    justifyContent: 'flex-end',
  };
});

export const StyledDatePicker = styled(DatePicker)(() => {
  return {
    '& .Mui-error fieldset': {
      borderWidth: '3px',
    },
  };
});
