import { useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UserActivitiesFilters as Filters } from '../../types';
import { FilterContainer } from '..';
import { useCompanies } from '../../hooks';
import { Roles } from '../../security/roles';
import { CompanyType } from '../../enums';
import { UserActivitesFiltersProps } from './UserActivitiesFilters.props';

const emptyFilters = {
  companyId: null,
  email: '',
  role: null,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
};

export const UserActivitiesFilters = ({
  onSearch,
}: UserActivitesFiltersProps) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Filters>(emptyFilters);
  const { list: companies } = useCompanies();

  const clearFilters = () => {
    setFilters(emptyFilters);
    onSearch(null);
  };

  return (
    <FilterContainer
      buttonsDisabled={!filters.startDate || !filters.endDate}
      title={t('reports.userActivity.title')}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.userActivity.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || DateTime.now()}
          />

          <DatePicker
            label={t('reports.userActivity.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate}
            maxDate={DateTime.now()}
          />

          <TextField
            value={filters.email}
            label={t('reports.userActivity.email')}
            sx={{ flex: '1 1 0' }}
            onChange={(e) =>
              setFilters((prev) => {
                return { ...prev, email: e.target.value };
              })
            }
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            options={companies}
            getOptionLabel={(c) =>
              c.type === CompanyType.Agency ? `${c.name} (${c.type})` : c.name
            }
            value={companies.find((c) => c.id === filters.companyId) ?? null}
            onChange={(_, company) =>
              setFilters((prev) => {
                return { ...prev, companyId: company?.id || null };
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.userActivity.company')}
              />
            )}
            sx={{ flex: '1 1 0' }}
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(role) => t(`users.roles.${role}`)}
            options={Object.values(Roles)}
            value={filters.role}
            onChange={(_, role) =>
              setFilters((prev) => {
                return { ...prev, role };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField {...params} label={t('reports.userActivity.role')} />
            )}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
