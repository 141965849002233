import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { useFunctions } from 'reactfire';

import {
  useCompanies,
  useForm,
  useNewUser,
  usePageTitle,
  usePermission,
} from '../../hooks';
import { FormControlHeader, UserForm } from '../../components';
import {
  AgencyPageView,
  CompanyType,
  ModificationMode,
  UserPermission,
  UserStatus,
} from '../../enums/';
import getUserSchema from '../../validation/getUserSchema';
import { Filter } from '../../types';
import { getPageTitle } from '../../utils/pages';

const AddUser: React.FC = () => {
  const functions = useFunctions();
  const { hasOneOfPermissions } = usePermission();
  const { t } = useTranslation();
  const { companyId, agencyId } = useParams();
  const navigate = useNavigate();
  const user = useNewUser();
  const mode = ModificationMode.Add;
  usePageTitle(getPageTitle('user.new'));

  const userValidations = useMemo(
    () => getUserSchema(mode, functions),
    [mode, functions]
  );

  const companyFilters = useMemo(
    () =>
      hasOneOfPermissions([UserPermission.ManageNCUsers])
        ? undefined
        : [['type', '!=', CompanyType.NorthernCables] as Filter],
    [hasOneOfPermissions]
  );

  // Company filter
  const { list: companies } = useCompanies({
    filters: companyFilters,
  });

  const pathPrefix = companyId
    ? `/admin/companies/${companyId}`
    : agencyId
    ? `/admin/agencies/${agencyId}?view=${AgencyPageView.AssignedUsers}`
    : '/admin/users';
  const form = useForm(user, {
    mode: mode,
    validations: userValidations,
    onRevert: () => {
      navigate(pathPrefix);
      return false;
    },
    beforeSave: (item) => {
      // Default status is pending for user create by an admin
      item.status = UserStatus.Pending;
    },
    onSaveSuccess: () => {
      navigate(pathPrefix);
    },
  });

  return (
    <>
      <FormControlHeader
        backTo={pathPrefix}
        form={form}
        title={t('users.form.title', { name: user.item?.name })}
        saveButtonLabel={t('users.actions.sendInvite')}
      />
      <UserForm
        form={form}
        isAdmin
        isAgent={false}
        mode={mode}
        companies={companies}
      />
      <Outlet context={form} />
    </>
  );
};

export default AddUser;
