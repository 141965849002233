import { SxProps } from '@mui/material';

export const assignButton = (): SxProps => {
  return {
    width: {
      xs: '100%',
      md: 'inherit',
    },
  };
};
