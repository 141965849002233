import { Avatar, Grid, Typography } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';

import { DecoratedHeaderProps } from './DecoratedHeader.props';
import * as Sx from './DecoratedHeader.styles';

export const DecoratedHeader = ({
  icon,
  title,
  to,
  variant = 'monochrome',
  children,
  status,
}: DecoratedHeaderProps) => {
  const match = useMatch({ path: to?.toString() || '', end: true });

  return (
    <Grid container sx={Sx.container}>
      {icon && (
        <Grid item>
          <Avatar sx={Sx.avatar(variant)}>{icon}</Avatar>
        </Grid>
      )}
      {title && (
        <Grid item>
          <Typography component="h1" variant="h1" color="primary" sx={Sx.title}>
            {to && !match ? <Link to={to}>{title}</Link> : <>{title}</>}
          </Typography>
        </Grid>
      )}
      {status && (
        <Grid item sx={Sx.status}>
          {status}
        </Grid>
      )}
      <Grid item sx={Sx.contentItem}>
        <Grid container justifyContent="flex-end">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
