import { jsPDF } from 'jspdf';
import { i18n, StringMap, TOptions } from 'i18next';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';
import { Nullable } from '../../types';

export enum PDF_CONFIG {
  // FONT SIZES
  H1 = 16,
  H2 = 14,
  H3 = 12,
  REGULAR = 10,
  LABEL = 8,

  // LINE SIZES
  LINE_SIZE = 20,
}

export enum MARGIN {
  X = 25,
  Y = 20,
}

export enum ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum FONT_WEIGHT {
  NORMAL = 'normal',
  BOLD = 'bold',
}

interface TextOptions {
  fontSize?: number;
  fontWeight?: FONT_WEIGHT;
  align?: ALIGNMENT;
  maxWidth?: number;
}

abstract class report {
  protected readonly doc: jsPDF;
  protected readonly namespace: string;
  protected readonly t: TFunction<'translation', undefined>;
  protected readonly i18n: i18n;
  protected readonly documentWidth: number;
  protected readonly documentHeight: number;
  protected readonly documentCenterX: number;
  protected readonly writableWidth: number;

  protected curWidth: number;
  protected curHeight: number;

  constructor(
    namespace: string,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    this.t = t;
    this.i18n = i18n;
    this.namespace = namespace;

    this.doc = new jsPDF({
      format: 'Letter',
    });

    this.curWidth = MARGIN.X;
    this.curHeight = MARGIN.Y;

    this.documentWidth = this.doc.internal.pageSize.getWidth();
    this.documentHeight = this.doc.internal.pageSize.getHeight();
    this.documentCenterX = (this.documentWidth - 2 * MARGIN.X) / 2;
    this.writableWidth = this.documentWidth - 2 * MARGIN.X;
  }

  protected abstract getFilename(): string;
  protected abstract buildHeader(): void;
  protected abstract buildBody(): void;

  protected generate(): void {
    const path = this.getFilename();

    this.buildHeader();
    this.buildBody();
    this.buildPaging();
    this.output(path);
  }

  protected buildPaging() {
    const totalPages = this.doc.internal.pages.length - 1;

    if (totalPages <= 1) return;

    for (let i = 0; i < totalPages; i++) {
      this.doc.setPage(i + 1);
      this.outputText(
        this.t('reports.paging', { current: i + 1, total: totalPages }),
        this.documentWidth - MARGIN.X,
        this.documentHeight - MARGIN.Y / 2,
        {
          fontWeight: FONT_WEIGHT.NORMAL,
          fontSize: PDF_CONFIG.LABEL,
          align: ALIGNMENT.RIGHT,
        }
      );
    }
  }

  protected output(path: string): void {
    const pdf = this.doc.output('blob');

    // For Firefox, we need to update the type to application/octet-stream otherwise,
    // the file will not download, but rather will replace the current page.
    const blob = new Blob([pdf], {
      type: 'application/octet-stream',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;

    a.download = path;

    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  private styleText(options?: TextOptions) {
    this.doc.setFont(
      this.doc.getFont().fontName,
      options?.fontWeight || FONT_WEIGHT.NORMAL
    );
    this.doc.setFontSize(options?.fontSize || PDF_CONFIG.REGULAR);
    this.doc.setTextColor(
      options?.fontSize === PDF_CONFIG.LABEL ? '#979797' : '#000000'
    );
  }

  protected outputText(
    text: string,
    x: number,
    y: number,
    options?: TextOptions
  ) {
    let formattedText = text;

    this.styleText(options);

    if (options?.maxWidth) {
      formattedText = this.trimText(text, options?.maxWidth);
    }

    this.doc.text(formattedText, x, y, {
      align: options?.align || ALIGNMENT.LEFT,
    });
  }

  protected outputWrappedText(
    text: string,
    x: number,
    y: number,
    options?: TextOptions
  ): number {
    this.styleText(options);

    const splitText = this.doc.splitTextToSize(
      text,
      options?.maxWidth ?? this.writableWidth
    );

    this.doc.text(splitText, x, y, {
      align: options?.align || ALIGNMENT.LEFT,
    });

    return splitText.length;
  }

  protected outputH1(value: string, x: number, y: number) {
    this.outputText(value, x, y, {
      fontSize: PDF_CONFIG.H1,
      fontWeight: FONT_WEIGHT.BOLD,
      align: ALIGNMENT.LEFT,
    });
  }

  protected outputH2(value: string, x: number, y: number) {
    this.outputText(value, x, y, {
      fontSize: PDF_CONFIG.H2,
      fontWeight: FONT_WEIGHT.BOLD,
      align: ALIGNMENT.LEFT,
    });
  }

  protected outputH3(
    value: string,
    x: number,
    y: number,
    maxWidth?: number
  ): number {
    const splitText = this.doc.splitTextToSize(
      value,
      maxWidth ?? this.writableWidth
    );

    this.outputText(splitText, x, y, {
      fontSize: PDF_CONFIG.H3,
      fontWeight: FONT_WEIGHT.BOLD,
      align: ALIGNMENT.LEFT,
    });
    return splitText.length;
  }

  protected checkbox(x: number, y: number, checked: boolean) {
    const side = 3;
    this.doc.setLineWidth(0.1);
    this.doc.rect(x, y, side, side);

    if (checked) {
      this.doc.setLineWidth(0.5);
      this.doc.line(x + 0.4, y + 1.5, x + 1.5, y + side - 0.4);
      this.doc.line(x + 1.5, y + side - 0.4, x + side, y);
    }
  }

  protected addDataColumn(
    header: Nullable<string>,
    value: Nullable<string>,
    x: number,
    maxWidth?: number,
    wrapped?: boolean,
    headerStringArgs?: TOptions<StringMap> | string
  ) {
    const formattedHeader = this.t(
      `${this.namespace}.${header}`,
      headerStringArgs
    );
    const headerOptions = {
      fontSize: PDF_CONFIG.LABEL,
      fontWeight: FONT_WEIGHT.NORMAL,
      align: ALIGNMENT.LEFT,
    };
    const textOptions = {
      fontSize: 9,
      maxWidth,
    };

    if (header) {
      this.outputText(formattedHeader, x, this.curHeight, headerOptions);
    }

    if (value) {
      if (wrapped) {
        return this.outputWrappedText(
          value,
          x,
          this.curHeight + 5,
          textOptions
        );
      }

      this.outputText(value, x, this.curHeight + 5, textOptions);
    }
  }

  protected drawLine(lineWidth: number, color?: string) {
    this.doc.setLineWidth(lineWidth);
    this.doc.setDrawColor(color || '#111111');
    this.doc.line(
      MARGIN.X,
      this.curHeight,
      this.documentWidth - MARGIN.X,
      this.curHeight
    );
  }

  protected outputImage = (path: string, x: number, y: number, size = 0) => {
    const img = new Image();
    img.src = path;

    this.doc.addImage(img, 'png', x, y, size, size);
  };

  protected outputDocumentDate = () => {
    const date = DateTime.now()
      .setLocale(this.i18n.language)
      .toLocaleString(DateTime.DATETIME_SHORT);

    this.outputText(date, this.documentWidth - MARGIN.X, this.curHeight + 5, {
      fontSize: PDF_CONFIG.REGULAR,
      fontWeight: FONT_WEIGHT.BOLD,
      align: ALIGNMENT.RIGHT,
    });
  };

  protected nextRow = (rowHeight: number, index: number, count: number) => {
    if (
      this.curHeight + rowHeight + MARGIN.Y > this.documentHeight &&
      index !== count - 1
    ) {
      this.doc.addPage();
      this.doc.setPage(this.doc.internal.pages.length + 1);
      this.curHeight = MARGIN.Y;
    }
  };

  private trimText(text: string, maxWidth: number): string {
    const currentWidth = this.doc.getTextWidth(text);

    if (currentWidth <= maxWidth) {
      return text;
    }

    for (let i = 0; i < text.length; i++) {
      const formattedText = text.substring(0, text.length - i) + '...';

      const formattedWidth = this.doc.getTextWidth(formattedText);

      if (formattedWidth <= maxWidth) {
        return formattedText;
      }
    }

    return '';
  }
}

export default report;
