import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useOutlet } from 'react-router-dom';
import { usePageTitle } from '../../hooks';
import { UserPermission } from '../../enums/';
import { RequirePermission, roles, statuses } from '../../security';
import { UserFilters as UserFiltersType } from '../../types';
import {
  UserFilters,
  UserTable,
  DecoratedHeader,
  Container,
} from '../../components';
import { getPageTitle } from '../../utils/pages';

const Users: React.FC = () => {
  const { t } = useTranslation();
  usePageTitle(getPageTitle('users'));
  const outlet = useOutlet();

  const [filters, setFilters] = useState<UserFiltersType>({
    companyId: null,
    role: null,
    status: null,
  });

  // Role filter
  const filteredRoles = useMemo(
    () => roles.Roles.filter((r) => roles.AgentRoles.includes(r)),
    []
  );

  return (
    <RequirePermission oneOf={[UserPermission.ManageAgencyUsers]}>
      {outlet || (
        <>
          <Container>
            <DecoratedHeader
              icon={<SupervisedUserCircleIcon />}
              title={t('navigation.users')}
              to="/agent/users"
              variant="colored"
            />
          </Container>
          <UserFilters
            roles={filteredRoles}
            statuses={statuses.Statuses}
            filters={filters}
            setFilters={setFilters}
          />
          <UserTable filters={filters} />
        </>
      )}
    </RequirePermission>
  );
};

export default Users;
