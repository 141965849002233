import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Checkbox, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTranslation } from 'react-i18next';

import { Filter, InventoryFilters } from '../../types';
import {
  useDownloadFiles,
  useProductTypes,
  useSpecifications,
} from '../../hooks';
import { Specification } from '../../models';
import { LanguagesUpper } from '../../types/Languages';

import { InventorySpecificationContainer } from '..';

interface Props {
  filters: InventoryFilters;
}

export const InventorySpecifications = ({ filters }: Props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [selection, setSelection] = useState({} as Record<string, boolean>);
  const { t, i18n } = useTranslation();
  const { downloadFiles } = useDownloadFiles();
  const { item } = useProductTypes();

  const language = i18n.language.toUpperCase() as LanguagesUpper;

  const { list, loading } = useSpecifications({
    sorting: { sortDirection: 'asc', sortField: `normalizedName.${language}` },
    filters: [
      filters.productType && ['productType', '==', filters.productType],
    ] as Array<Filter>,
  });

  const toggleSelection = useCallback(
    (toggled: boolean) => {
      const values = list.reduce((obj, cur) => {
        return { ...obj, [cur.id]: toggled };
      }, {});

      setSelection(values);
    },
    [list]
  );

  const mailSelection = () => {
    const subject = t('inventory.specifications.mail.subject');
    const body = Object.keys(selection)
      .filter((x) => selection[x])
      .reduce((text, value) => {
        const spec = list.find((x) => x.id === value);
        const name = spec?.name[language] || spec?.name.EN || '';
        const url = spec?.url[language] || spec?.url.EN || '';

        return (text += `${name}\r\n${url}\r\n\r\n`);
      }, '');

    window.location.href = `mailto:?subject=${subject}&body=${encodeURIComponent(
      body
    )}`;
  };

  const downloadSelection = () => {
    const files = Object.keys(selection)
      .filter((x) => selection[x])
      .map((item) => {
        const spec = list.find((x) => x.id === item);
        const url = spec?.url[language] || spec?.url.EN || '';
        return {
          name: url.split('/').pop() || item + '_specification.pdf',
          url: url,
        };
      });

    if (item && filters.productType) {
      downloadFiles(
        files,
        item.types[filters.productType].name + '_specification'
      );
    }
  };

  const smsSelection = () => {
    const id = Object.keys(selection).filter((x) => selection[x])[0];
    const spec = list.find((x) => x.id === id);

    const name = encodeURIComponent(
      spec?.name[language] || spec?.name.EN || ''
    );
    const url = encodeURIComponent(spec?.url[language] || spec?.url.EN || '');

    window.location.href = `sms:?&body=${name} : ${url}`;
  };

  useEffect(() => {
    if (!loading) {
      toggleSelection(false);
    }
  }, [list, loading, toggleSelection]);

  useEffect(() => {
    setOpen(false);
  }, [filters]);

  if (loading || list.length === 0) {
    return null;
  }

  return (
    <InventorySpecificationContainer
      onEmailClicked={mailSelection}
      onSMSClicked={smsSelection}
      onDownloadClicked={downloadSelection}
      open={open}
      setOpen={setOpen}
      specificationCount={list.length}
      selectionCount={Object.keys(selection).filter((x) => selection[x]).length}
    >
      <Typography variant="subtitle1" mt={2}>
        {t(
          isDesktop
            ? 'inventory.specifications.textDesktop'
            : 'inventory.specifications.textMobile'
        )}
      </Typography>
      <Grid
        sx={{
          marginY: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'space-between', md: 'left' },
          flexDirection: { xs: 'row-reverse', md: 'row' },
        }}
      >
        <Checkbox
          checked={Object.keys(selection).every((s) => selection[s])}
          size={isDesktop ? 'medium' : 'large'}
          onClick={() =>
            toggleSelection(!Object.keys(selection).every((s) => selection[s]))
          }
          color="secondary"
          icon={<CheckBoxOutlineBlankIcon sx={{ color: 'secondary.light' }} />}
        />
        <Typography>{t('inventory.specifications.selectAll')}</Typography>
      </Grid>

      {list.map((spec: Specification) => (
        <Grid
          key={spec.id}
          sx={{
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'space-between', md: 'left' },
            flexDirection: { xs: 'row-reverse', md: 'row' },
          }}
        >
          <Checkbox
            checked={!!selection[spec.id]}
            onChange={(e) =>
              setSelection({ ...selection, [spec.id]: e.target.checked })
            }
            color="secondary"
            size={isDesktop ? 'medium' : 'large'}
            icon={
              <CheckBoxOutlineBlankIcon sx={{ color: 'secondary.light' }} />
            }
          />
          <Link
            href={spec.url[language] || spec.url.EN}
            target="_blank"
            sx={{ color: 'secondary.light' }}
          >
            {spec.name[language] || spec.name.EN}
          </Link>
        </Grid>
      ))}
    </InventorySpecificationContainer>
  );
};
