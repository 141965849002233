import { SxProps } from '@mui/material';

export const drawerPaper: SxProps = {
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  background: 'linear-gradient(to right, #e68008 125px, #2c2c2c 125px 90%)',
  color: '#ffffff',
  height: '120px',
};

export const iconSection: SxProps = {
  borderTopLeftRadius: 20,
  height: '100%',
  width: '125px',
};
