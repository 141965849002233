import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useTranslation } from 'react-i18next';
import {
  collection,
  doc,
  orderBy,
  where,
  query,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import {
  useUsers,
  useSorting,
  usePagination,
  useCurrentUser,
  usePermission,
  useAuthentication,
  usePermissionRoles,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { CompanyType, UserPermission, UserStatus } from '../../enums';
import { UserStatusColors } from '../../mappings';
import { User } from '../../models';
import { Filter } from '../../types';
import { userMailingList } from '../../reports';
import {
  Container,
  ExportMenu,
  Show,
  StatusIndicator,
  UnderlinedLink,
} from '..';
import { roles } from '../../security';
import { UserTableProps } from './UserTable.props';

export const UserTable = ({ filters }: UserTableProps) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const firestore = useFirestore();
  const { roleHasPermission } = usePermission();
  const sorting = useSorting('name');
  const pagination = usePagination();
  const { hasPermission } = usePermission();
  const [exporting, setExporting] = useState(false);
  const { isAdmin, isAgent } = useAuthentication();
  const allowedRoles = usePermissionRoles();

  const getRoleFilter = useCallback((): Filter | null => {
    if (filters.role) {
      return ['role', 'in', [filters.role]];
    }
    if (isAdmin) {
      return ['role', 'in', allowedRoles];
    }

    return null;
  }, [allowedRoles, filters.role, isAdmin]);

  const userFilters = useMemo(
    () =>
      [
        ...(!isAdmin
          ? [
              [
                'companyRef',
                '==',
                doc(firestore, 'Companies', user.companyRef.id),
              ],
            ]
          : []),
        ...(isAdmin && filters.companyId
          ? [
              [
                'companyRef',
                '==',
                doc(firestore, 'Companies', filters.companyId),
              ],
            ]
          : []),
        getRoleFilter(),
        filters.status ? ['status', '==', filters.status] : null,
        ['isDeleted', '==', false],
      ] as Filter[],
    [
      filters.companyId,
      filters.status,
      firestore,
      getRoleFilter,
      isAdmin,
      user.companyRef.id,
    ]
  );

  const { list, loading } = useUsers({
    filters: userFilters,
    sorting,
    pagination,
  });

  const toggleActiveStatus = async (
    userId: string,
    status: UserStatus.Active | UserStatus.Inactive
  ) => {
    await updateDoc(doc(firestore, 'Users', userId), { status });
  };

  useEffect(() => {
    if (!exporting) return;

    let q = query(
      collection(firestore, 'Users'),
      orderBy('companyName', 'asc'),
      orderBy('name', 'asc')
    );

    if (filters.companyId) {
      q = query(
        q,
        where(
          'companyRef',
          '==',
          doc(firestore, 'Companies', filters.companyId)
        )
      );
    }

    if (filters.status) {
      q = query(q, where('status', '==', filters.status));
    }

    const roleFilter = getRoleFilter();
    if (roleFilter) {
      q = query(q, where('role', 'in', roleFilter[2]));
    }

    getDocs(q)
      .then((res) =>
        userMailingList.generate(
          res.docs.map((doc) => doc.data() as User),
          t
        )
      )
      .finally(() => setExporting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exporting, firestore, t]);

  const userRoleCanReserve = useCallback(
    (user: User) => {
      const role = user.role;
      return (
        role &&
        (!!roleHasPermission(role, UserPermission.ManageOwnReservations) ||
          !!roleHasPermission(role, UserPermission.ImpersonateCustomer))
      );
    },
    [roleHasPermission]
  );

  const nameOutput = (name: string, user: User) => (
    <UnderlinedLink
      to={
        isAdmin
          ? `/admin/users/${user.id}`
          : isAgent
          ? `/agent/users/${user.id}`
          : `/users/${user.id}`
      }
    >
      {name}
    </UnderlinedLink>
  );

  const companyNameOutput = (name: string, user: User) =>
    user.role && roles.AgentRoles.includes(user.role)
      ? `${name} (${CompanyType.Agency})`
      : name;

  const canReserveOutput = (canReserve: boolean, user: User) =>
    t(`common.boolean.${canReserve && userRoleCanReserve(user)}`);

  const adminMenuItems = (item: User) => (
    <MenuItem
      component={Link}
      to={`/admin/users/${item.id}/delete`}
      disabled={item.reservationCount !== 0 || item.id === user.id}
    >
      <ListItemIcon>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('users.actions.delete')}</ListItemText>
    </MenuItem>
  );

  const agentMenuItems = (item: User) => {
    const isActive = item.status === UserStatus.Active;
    return hasPermission(UserPermission.ManageAgencyUsers) &&
      item.status &&
      item.id !== user.id &&
      [UserStatus.Active, UserStatus.Inactive].includes(item.status) ? (
      <MenuItem
        onClick={() =>
          toggleActiveStatus(
            item.id,
            isActive ? UserStatus.Inactive : UserStatus.Active
          )
        }
      >
        <ListItemIcon>
          {isActive ? (
            <PersonRemoveIcon fontSize="small" />
          ) : (
            <PersonAddIcon fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          {t(`users.actions.${isActive ? 'deactivate' : 'activate'}`)}
        </ListItemText>
      </MenuItem>
    ) : undefined;
  };

  return (
    <Container>
      <Show if={isAdmin && list && list.length > 0}>
        <Grid display="flex" justifyContent="flex-end" my={2}>
          <ExportMenu
            onCSVClicked={() => {
              setExporting(true);
            }}
          />
        </Grid>
      </Show>
      <DataTable
        data={!loading ? list : undefined}
        sorting={sorting}
        pagination={pagination}
        menuItems={
          isAdmin ? adminMenuItems : isAgent ? agentMenuItems : undefined
        }
      >
        <DataTableColumn
          property="name"
          title={t('users.fields.name')}
          width={isAdmin ? '20%' : '25%'}
          output={nameOutput}
        />
        <DataTableColumn
          property="role"
          title={t('users.fields.role')}
          output={(role) => (role ? t(`users.roles.${role}`) : 'N/A')}
          width={isAdmin ? '20%' : '25%'}
          mobileWidth="50%"
          mobileHidden
          mobileVisibleWhenActive
          mobileOrder={3}
        />
        {isAdmin && (
          <DataTableColumn
            output={companyNameOutput}
            property="companyName"
            title={t('users.fields.companyName')}
            width="20%"
            mobileWidth="50%"
            mobileHidden
            mobileVisibleWhenActive
            mobileOrder={4}
          />
        )}
        <DataTableColumn
          property="canReserve"
          title={t('users.fields.canReserve.title')}
          output={canReserveOutput}
          width={isAdmin ? '20%' : '25%'}
          mobileWidth="50%"
          mobileOrder={1}
        />
        <DataTableColumn
          property="status"
          title={t('users.fields.status')}
          output={(status) => (
            <StatusIndicator
              color={UserStatusColors[status as UserStatus]}
              title={t(`users.statuses.${status}`)}
            />
          )}
          width={isAdmin ? '20%' : '25%'}
          mobileWidth="50%"
          hasMobileLabel={false}
          mobileOrder={2}
        />
      </DataTable>
    </Container>
  );
};
