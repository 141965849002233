import { i18n } from 'i18next';

import { Language } from '../enums';
import { TranslatedString } from '../types';

export default function translateString(
  string: TranslatedString | null | undefined,
  i18n: i18n
) {
  if (!string) {
    return '';
  }
  const language = i18n.language === 'fr' ? Language.French : Language.English;
  return string[language];
}
