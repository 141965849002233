export enum FilterOption {
  CableId = 'i',
  Gauge = 'g',
  InventoryClass = 'c',
  MaterialType = 'm',
  NumberOfConductors = 'n',
  ProductType = 'p',
  ProductSubType = 'st',
  StandardType = 's',
  Voltage = 'v',
}
