import { usePermission } from '../hooks';
import { UserPermission, ErrorCategory } from '../enums/';
import { HttpError, Loading } from '../pages';

interface Props {
  children: React.ReactNode;
  oneOf: Array<UserPermission>;
}

const RequirePermission = ({ oneOf, children }: Props) => {
  const { hasOneOfPermissions, userHasOneOfPermission } = usePermission();

  const hasPermission = hasOneOfPermissions(oneOf);
  const userPermission = userHasOneOfPermission(oneOf);

  if (hasPermission === undefined || userPermission === undefined) {
    return <Loading />;
  }

  if (!hasPermission && !userPermission) {
    return <HttpError errorCategory={ErrorCategory.AccessDenied} />;
  }

  return <>{children}</>;
};

export default RequirePermission;
