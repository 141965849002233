import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  TextField as MuiTextField,
  IconButton,
  Stack,
  Typography,
  InputAdornment,
  List,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { SideDrawer } from '../SideDrawer/SideDrawer.component';
import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import {
  StyledListTitle,
  StyledListItem,
  StyledListItemText,
} from '../SideDrawer/SideDrawer.styles';
import { Show } from '../Show/Show.component';
import { StyledTextButton } from '../Button/Button.component';
import { NotificationLevel } from '../../enums';
import yup from '../../validation/yup';
import { useNotification, useProgress } from '../../hooks';
import { CustomerRecipientsDrawerProps } from './CustomerRecipients.props';

export const CustomerRecipientsDrawer = ({
  agency,
  customer,
  onClose,
}: CustomerRecipientsDrawerProps) => {
  const [recipients, setRecipients] = useState<string[] | null>(null);
  const [tempRecipient, setTempRecipient] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [addNewRecipient, setAddNewRecipient] = useState(false);

  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();

  useEffect(
    () => setRecipients(customer?.shippingDocEmailRecipient ?? []),
    [customer]
  );

  useEffect(() => {
    showProgress(isSaving);
    return () => {
      showProgress(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const handleSave = async () => {
    if (
      agency.item &&
      customer &&
      agency.item.associatedCompanies?.[customer.id]
    ) {
      setIsSaving(true);
      try {
        await agency.save({
          ...agency.item,
          associatedCompanies: {
            ...agency.item.associatedCompanies,
            [customer.id]: {
              ...agency.item.associatedCompanies?.[customer.id],
              shippingDocEmailRecipient: recipients,
            },
          },
        });

        addNotification(
          t('forms.savedSuccessfully'),
          NotificationLevel.Success
        );
        onClose();
      } catch (e) {
        addNotification(t('errors.unknown.title'), NotificationLevel.Error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleCancel = () => {
    setRecipients(customer?.shippingDocEmailRecipient || null);
    setTempRecipient('');
    setError(null);
    setAddNewRecipient(false);
  };

  const handleAdd = () => {
    if (tempRecipient) {
      try {
        yup.string().email().validateSync(tempRecipient, { strict: true });
      } catch {
        setError(t('common.validations.fieldEmail'));
        return;
      }

      if (!recipients) {
        setRecipients([tempRecipient]);
      } else if (recipients.includes(tempRecipient)) {
        setError(t('common.validations.fieldShouldBeUnique'));
        return;
      } else {
        setRecipients([...recipients, tempRecipient]);
      }

      setError(null);
      setTempRecipient('');
      setAddNewRecipient(false);
    }
  };

  const handleRemove = (recipient: string) => {
    if (!recipients?.length) {
      return;
    }

    setRecipients(recipients.filter((r) => r !== recipient));
  };

  const displayFooterButtons = useMemo(() => {
    if (recipients === null) {
      return false;
    }

    return (
      JSON.stringify(recipients) !==
      JSON.stringify(customer?.shippingDocEmailRecipient)
    );
  }, [customer?.shippingDocEmailRecipient, recipients]);

  const buildBody = () => (
    <>
      {recipients?.length === 0 ? (
        <StyledListTitle>
          <Typography fontWeight="700" variant="h4">
            {t('agencies.emailRecipients.empty')}
          </Typography>
        </StyledListTitle>
      ) : (
        <Stack>
          <StyledListTitle>
            <Typography fontWeight="700" variant="h4">
              {t('agencies.emailRecipients.addresses')}
            </Typography>
          </StyledListTitle>
          <List>
            {recipients?.map((recipient) => (
              <StyledListItem
                key={recipient}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => handleRemove(recipient)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                }
              >
                <StyledListItemText>{recipient}</StyledListItemText>
              </StyledListItem>
            ))}
          </List>
        </Stack>
      )}
      <Show if={addNewRecipient}>
        <Box sx={{ p: 1, mt: 2 }}>
          <MuiTextField
            fullWidth
            autoFocus
            type="email"
            error={!!error}
            helperText={error}
            label={t('companies.fields.shippingEmail')}
            value={tempRecipient}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    disabled={!tempRecipient}
                    onClick={handleAdd}
                    size="small"
                    sx={{ p: 1 }}
                  >
                    {t('companies.actions.add')}
                  </Button>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAdd();
              }
            }}
            onChange={(e) => {
              if (error) setError(null);
              setTempRecipient(e.target.value);
            }}
          />
        </Box>
      </Show>
      <Show if={!addNewRecipient}>
        <Box sx={{ p: 2.5, mt: 2 }}>
          <StyledTextButton onClick={() => setAddNewRecipient(true)}>
            {t('agencies.emailRecipients.add')}
          </StyledTextButton>
        </Box>
      </Show>
    </>
  );

  const buildFooter = () =>
    displayFooterButtons ? (
      <Stack direction="row" spacing={1}>
        <Button disabled={isSaving} onClick={handleCancel}>
          {t('forms.cancel')}
        </Button>
        <ContrastButtonMain disabled={isSaving} onClick={handleSave}>
          {t('forms.saveChanges')}
        </ContrastButtonMain>
      </Stack>
    ) : undefined;

  return (
    <SideDrawer
      headerText={customer?.companyName || t('common.notApplicable')}
      onClose={onClose}
      isOpen={!!customer}
      bodyChildren={buildBody()}
      footerChildren={buildFooter()}
    />
  );
};
