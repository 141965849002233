import { Box, Typography } from '@mui/material';

import { useAuthentication } from '../../hooks';
import { UserAvatar } from '../../components';

import { MenuItem } from '..';
import { UserRole } from '../../enums';

export const CurrentUserMenuItem = () => {
  const { role, authUser: user } = useAuthentication();

  const to = role === UserRole.NciAdmin ? '/admin/settings' : '/settings';

  return (
    <MenuItem to={to}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          width: '100%',
        }}
      >
        <UserAvatar variant="small" />
        <Typography
          sx={{
            ml: 1,
            letterSpacing: '1px',
            whiteSpace: 'break-spaces',
          }}
          color="primary.contrastText"
        >
          {user?.displayName}
        </Typography>
      </Box>
    </MenuItem>
  );
};
