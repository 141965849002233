import { createContext } from 'react';

export interface ProgressContextValue {
  showProgress: (show: boolean) => void;
  visible: boolean;
}

export const ProgressContext = createContext<ProgressContextValue>(
  {} as ProgressContextValue
);
