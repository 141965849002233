import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { useNavigate, useOutlet } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import { PersonAdd } from '@mui/icons-material';

import {
  useForm,
  usePageTitle,
  usePortalSettings,
  useProvincesOrStates,
  useSignUpUser,
} from '../hooks';
import {
  Checkbox,
  PhoneNumberField,
  Select,
  TextField,
  Container,
  Show,
  BottomDrawer,
  RadioGroup,
} from '../components';
import {
  Country,
  Language,
  MeasurementUnits,
  ModificationMode,
  TimeZone,
} from '../enums';
import { getSignUpValidations } from '../validation';
import { getPageTitle } from '../utils/pages';
import { PortalSettings } from '../models';
import { PublicHeader } from '../layouts/PublicHeader/PublicHeader.component';
import { translateString } from '../i18n';

interface Props {
  portalSettings: PortalSettings;
}

interface confirmSignUpProps {
  open: boolean;
  onClose: () => void;
}

const ConfirmSignUp = (props: confirmSignUpProps) => {
  const { t } = useTranslation();
  const { open, onClose } = props;

  const confirmButton = {
    minWidth: '80px',
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText',
    marginRight: 2,
    '&:hover': {
      backgroundColor: 'secondary.main',
    },
  };

  return (
    <BottomDrawer
      icon={<PersonAdd />}
      open={open}
      title={t('signUp.confirmation.modalTitle')}
      rightContent={
        <>
          <Button onClick={onClose} variant="contained" sx={confirmButton}>
            {t('forms.understood')}
          </Button>
        </>
      }
    >
      <Typography variant="h6">
        {t('signUp.confirmation.modalWarning')}
      </Typography>
    </BottomDrawer>
  );
};

const SignUp = () => {
  const { item: portalSettingsData } = usePortalSettings();

  return (
    <Show if={!!portalSettingsData}>
      <SignUpContent portalSettings={portalSettingsData as PortalSettings} />
    </Show>
  );
};

const SignUpContent = ({ portalSettings }: Props) => {
  const { t, i18n } = useTranslation();
  const functions = useFunctions();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const [open, setOpen] = useState(false);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);

  usePageTitle(getPageTitle('signUp'));

  const validations = useMemo(
    () => getSignUpValidations(functions),
    [functions]
  );

  const user = useSignUpUser(portalSettings);

  const form = useForm(user, {
    validations,
    mode: ModificationMode.Add,
    onSaveSuccess: () => {
      setOpen(true);
    },
  });

  const provincesOrStates = useProvincesOrStates(form.item?.country);

  useEffect(() => {
    if (!form.item?.language) return;

    i18n.changeLanguage(form.item?.language.toLowerCase());
  }, [i18n, form.item?.language]);

  const buttonsWrapperSx = {
    flexWrap: 'wrap',
    my: 5,
  };

  const buttonSx = {
    flexBasis: '150px',
    flexGrow: { xs: 1, sm: 0 },
  };

  const buttonRightSx = {
    ...buttonSx,
    ml: 2,
  };

  const h2Sx = {
    fontSize: '1.17em',
  };

  const passwordHelpTextSx = {
    fontSize: 'subtitle1.fontSize',
    fontStyle: 'italic',
  };

  const acceptedTermsAndConditionsTextSx = {
    fontSize: '1rem',
    whiteSpace: 'pre-wrap',
  };

  const handleClose = () => {
    setOpen(false);
    navigate('confirmation');
  };

  return (
    <>
      <PublicHeader
        icon={<LockRoundedIcon titleAccess={t('alt.lockIcon')} />}
        title={t('signUp.title')}
      />

      <Container sx={{ mt: 5 }} role="main">
        {outlet ? (
          <>{outlet}</>
        ) : (
          <>
            <Typography>{t('signUp.fillMessage')}</Typography>
            <Typography>{t('signUp.conditionMessage')}</Typography>
            <Grid maxWidth="500px" role="form">
              <Grid sx={{ mt: 5 }}>
                <Typography variant="h2" sx={h2Sx}>
                  {t('users.form.sections.generalInformation')}
                </Typography>

                <TextField
                  field="name"
                  form={form}
                  label={t('users.fields.name')}
                  required
                />
                <TextField
                  field="title"
                  form={form}
                  label={t('users.fields.title')}
                  required
                />
                <TextField
                  field="email"
                  form={form}
                  label={t('users.fields.email')}
                  required
                />

                <Grid display="flex">
                  <PhoneNumberField
                    field="phoneNumber"
                    form={form}
                    label={t('users.fields.phoneNumber')}
                    required
                  />
                  <Grid ml={1}>
                    <TextField
                      field="extension"
                      form={form}
                      label={t('users.fields.extension')}
                    />
                  </Grid>
                </Grid>

                <Select
                  form={form}
                  field="language"
                  label={t('users.fields.language')}
                  options={Object.values(Language)}
                  getOptionLabel={(value) => t(`languages.${value}`)}
                  required
                  autocomplete="new-password"
                />

                <TextField
                  field="password"
                  form={form}
                  label={t('users.fields.password')}
                  type="password"
                  required
                />

                <Typography component="span" sx={passwordHelpTextSx}>
                  {t('signUp.passwordLength')}
                </Typography>

                <TextField
                  field="confirmPassword"
                  form={form}
                  label={t('users.fields.confirmPassword')}
                  type="password"
                  required
                />
              </Grid>

              <Grid sx={{ mt: 5 }}>
                <Typography variant="h2" sx={h2Sx}>
                  {t('users.form.sections.chooseLocation')}
                </Typography>

                <Select
                  form={form}
                  field="country"
                  label={t('users.fields.country')}
                  options={Object.values(Country)}
                  getOptionLabel={(country) => t(`countries.${country}`)}
                  required
                  autocomplete="new-password"
                />

                <Select
                  disabled={!form.item?.country}
                  form={form}
                  field="provinceOrState"
                  label={t('users.fields.provinceOrState')}
                  options={provincesOrStates}
                  getOptionLabel={(role) => t(`provincesOrStates.${role}`)}
                  required
                  autocomplete="new-password"
                />

                <TextField
                  field="city"
                  form={form}
                  label={t('users.fields.city')}
                  required
                  autocomplete="new-password"
                />

                <Select
                  form={form}
                  field="timeZone"
                  required
                  label={t('users.fields.timeZone')}
                  options={Object.values(TimeZone)}
                  getOptionLabel={(value) => t(`timeZones.${value}`)}
                />

                <RadioGroup
                  row
                  required
                  form={form}
                  field="measurementUnits"
                  defaultValue={
                    form.item?.country === Country.UnitedStates
                      ? MeasurementUnits.Imperial
                      : MeasurementUnits.Metric
                  }
                  options={[
                    {
                      key: MeasurementUnits.Metric,
                      label: t('users.fields.units.metric'),
                    },
                    {
                      key: MeasurementUnits.Imperial,
                      label: t('users.fields.units.imperial'),
                    },
                  ]}
                  label={t('users.fields.units.label')}
                />
              </Grid>

              <Grid sx={{ mt: 5 }}>
                <Typography variant="h2" sx={h2Sx}>
                  {t('users.fields.company')}
                </Typography>

                <TextField
                  field="suggestedCompanyName"
                  form={form}
                  label={t('users.fields.companyName')}
                  required
                />

                <TextField
                  field="comments"
                  form={form}
                  label={t('users.fields.comments')}
                  multiline
                  minRows={5}
                />
              </Grid>

              <Grid mt={2}>
                <Checkbox
                  variant="medium"
                  field="receiveCommunications"
                  form={form}
                  label={t('users.fields.receiveCommunications')}
                />
              </Grid>

              <FormControlLabel
                control={
                  <MuiCheckbox
                    checked={acceptedTermsAndConditions}
                    onChange={(_, checked) =>
                      setAcceptedTermsAndConditions(checked)
                    }
                    color="secondary"
                  />
                }
                label={
                  <Trans
                    i18nKey="signUp.acceptTermsAndConditions"
                    components={[
                      <Link
                        href={translateString(
                          portalSettings?.termsAndConditionsURL,
                          i18n
                        )}
                        target="_blank"
                        rel="noreferrer"
                      />,
                    ]}
                  />
                }
                disableTypography={true}
                sx={acceptedTermsAndConditionsTextSx}
              />

              <Grid display="flex" sx={buttonsWrapperSx}>
                <Button
                  variant="contained"
                  sx={buttonSx}
                  onClick={() => form.save()}
                  disabled={
                    form.saving || !form.valid || !acceptedTermsAndConditions
                  }
                >
                  {t('signUp.actions.signUp')}
                </Button>

                <Button
                  variant="outlined"
                  sx={buttonRightSx}
                  onClick={() => navigate('/login')}
                  disabled={form.saving}
                >
                  {t('signUp.actions.cancel')}
                </Button>
              </Grid>
            </Grid>
            <ConfirmSignUp open={open} onClose={handleClose} />
          </>
        )}
      </Container>
    </>
  );
};

export default SignUp;
