import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BottomDrawer } from '..';

import { QuestionDrawerProps } from './QuestionDrawer.props';
import * as Sx from './QuestionDrawer.styles';

export const QuestionDrawer = ({
  disabled = false,
  icon,
  message,
  open,
  onConfirm,
  onCancel,
  title,
  height,
}: QuestionDrawerProps) => {
  const { t } = useTranslation();
  return (
    <BottomDrawer
      icon={icon}
      open={open}
      title={title}
      height={height}
      rightContent={
        <>
          <Button
            disabled={disabled}
            onClick={() => onConfirm()}
            variant="contained"
            sx={Sx.confirmButton}
          >
            {t('forms.confirm')}
          </Button>
          <Button
            disabled={disabled}
            onClick={onCancel}
            variant="outlined"
            sx={Sx.cancelButton}
          >
            {t('forms.cancel')}
          </Button>
        </>
      }
    >
      <Typography variant="h6" sx={Sx.message}>
        {message}
      </Typography>
    </BottomDrawer>
  );
};
