import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { TextField } from '@mui/material';
import { RequirePermission } from '../../security';
import { UserPermission } from '../../enums/';
import { Container, FilterBar } from '../../components';
import { usePageTitle, useRouterOutlet } from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import { CompaniesTable } from '../../components/CompaniesTable/CompaniesTable.component';
import { CompaniesPartial } from './partials';

const Companies: React.FC = () => {
  const { t } = useTranslation();
  const { showOutletOnly } = useRouterOutlet();
  const navigate = useNavigate();
  usePageTitle(getPageTitle('companies'));
  const params = useParams();

  const showTabs = Object.keys(params).length === 0;

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [debouncedCode] = useDebounce(code, 500);
  const [debouncedName] = useDebounce(name, 500);

  useEffect(() => {
    if (code) {
      setName('');
    }
  }, [code]);

  useEffect(() => {
    if (name) {
      setCode('');
    }
  }, [name]);

  return (
    <RequirePermission oneOf={[UserPermission.ManageCompanies]}>
      <CompaniesPartial showTabs={showTabs}>
        {showOutletOnly || (
          <>
            <FilterBar title={t('companies.filterCompanies')}>
              <TextField
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                label={t('companies.fields.companyName')}
              />
              <TextField
                value={code}
                onChange={(e) => setCode(e.currentTarget.value)}
                label={t('companies.fields.companyCode')}
              />
            </FilterBar>

            <Container>
              <CompaniesTable
                code={debouncedCode}
                name={debouncedName}
                onDeleteClick={(company) =>
                  navigate(`/admin/companies/${company.id}/delete`)
                }
              />
            </Container>
          </>
        )}
        <Outlet />
      </CompaniesPartial>
    </RequirePermission>
  );
};

export default Companies;
