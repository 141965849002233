import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FolderOpen } from '@mui/icons-material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import { BottomDrawer, Show } from '../../../components';
import { useNotification, useProgress } from '../../../hooks';
import {
  NotificationLevel,
  ReelReturnCancelErrorFields,
  ReelReturnCancelReason,
  ReelReturnCancelReasonMapping,
  ReelReturnError,
  ReelReturnErrorMapping,
} from '../../../enums';
import * as Sx from '../common.styles';
import { selectStyles } from '../../admin/modals/AssignDocument/AssignDocument.styles';
import { ValidationError } from '../../../types';
import { CancelReelReturnProps } from './CancelReelReturn.props';
import { REASON_MAX_LENGTH } from './constants';
import { formControlStyles } from './CancelReelReturn.styles';

const CancelReelReturn = ({
  reelReturn,
  visible,
  visibilitySet,
}: CancelReelReturnProps) => {
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const functions = useFunctions();
  const cancelReelReturn = httpsCallable(functions, 'cancelReelReturn');

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [selectedReason, setSelectedReason] = useState<string>('');
  const [errors, setErrors] = useState<
    ValidationError<ReelReturnCancelErrorFields>
  >({});

  const reasons = Object.values(ReelReturnCancelReason);

  const message = useRef<HTMLInputElement>();

  useEffect(() => {
    showProgress(saving);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  const cancelReelReturnHandler = async () => {
    setSaving(true);
    const currentErrors: ValidationError<ReelReturnCancelErrorFields> = {};

    try {
      // Skip error check on draft return
      if (reelReturn?.nciRmaNumber) {
        currentErrors[ReelReturnCancelErrorFields.NoReasonSelected] =
          !selectedReason;
        currentErrors[ReelReturnCancelErrorFields.OtherNoText] =
          selectedReason === ReelReturnCancelReason.Other &&
          !message.current?.value?.trim();

        setErrors((prevValue) => {
          return { ...prevValue, ...currentErrors };
        });

        if (Object.values(currentErrors).find((error) => error)) {
          throw new Error('forms.savedUnsuccessfullyDueToValidation');
        }
      }

      await cancelReelReturn({
        id: reelReturn?.id,
        ...(!!selectedReason && { reason: selectedReason }),
        ...(!!message.current?.value?.trim() && {
          customReason: message.current?.value?.trim(),
        }),
      }).then(
        () => {
          closeModalHandler();
          addNotification(
            t('reelReturns.cancelReelReturn.success'),
            NotificationLevel.Success
          );
        },
        (e) => {
          const error = e as Error;
          if (error.message === ReelReturnError.CancelEmail) {
            closeModalHandler();
          }

          throw new Error(
            `reelReturns.cancelReelReturn.errors.${
              ReelReturnErrorMapping[error.message as ReelReturnError] ??
              'cancelError'
            }`
          );
        }
      );
    } catch (error) {
      addNotification(t((error as Error).message), NotificationLevel.Error);
    } finally {
      setSaving(false);
    }
  };

  const reasonHandler = (event: SelectChangeEvent) => {
    setSelectedReason(
      reasons.find((reason) => reason === event.target.value) || ''
    );
  };

  const closeModalHandler = () => {
    resetFields();
    visibilitySet(false);
  };

  const resetFields = () => {
    setSelectedReason('');
    setErrors({});
  };

  const drawerHeight: string = useMemo(() => {
    if (isMobile && reelReturn?.nciRmaNumber) {
      if (selectedReason === ReelReturnCancelReason.Other) {
        return '500px';
      }

      return '350px';
    }
    return '250px';
  }, [isMobile, reelReturn?.nciRmaNumber, selectedReason]);

  const deleteNoReason = () => (
    <Typography component={Grid} item my={2}>
      {t('reelReturns.cancelReelReturn.message')}
    </Typography>
  );

  const deletewithReason = () => (
    <Grid
      id="form-section"
      container
      display="flex"
      direction={'row'}
      alignItems={'center'}
      spacing={3}
    >
      <Grid item>
        <Typography>
          {t('reelReturns.cancelReelReturn.reason.message')}
        </Typography>
      </Grid>
      <Grid
        id="reason-field"
        item
        md={3}
        xs={12}
        mb={isMobile && selectedReason !== ReelReturnCancelReason.Other ? 3 : 0}
      >
        <FormControl sx={formControlStyles} fullWidth>
          <Select
            labelId="reason-label"
            id="reason-select"
            displayEmpty
            value={selectedReason}
            error={errors[ReelReturnCancelErrorFields.NoReasonSelected]}
            onChange={reasonHandler}
            input={<OutlinedInput />}
            variant="outlined"
            sx={selectStyles}
            renderValue={(selected) =>
              selected ? (
                t(
                  `reelReturns.cancelReelReturn.reason.labels.${
                    ReelReturnCancelReasonMapping[
                      selected as ReelReturnCancelReason
                    ]
                  }`
                )
              ) : (
                <Typography sx={Sx.borderColor}>
                  {t('reelReturns.cancelReelReturn.reason.placeholder')}
                </Typography>
              )
            }
            IconComponent={KeyboardArrowDown}
          >
            <MenuItem disabled value="">
              <em>{t('reelReturns.cancelReelReturn.reason.placeholder')}</em>
            </MenuItem>
            {reasons.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {t(
                  `reelReturns.cancelReelReturn.reason.labels.${
                    ReelReturnCancelReasonMapping[
                      reason as ReelReturnCancelReason
                    ]
                  }`
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Show if={selectedReason === ReelReturnCancelReason.Other}>
        <Grid item md={6} xs={12} mb={2}>
          <TextField
            sx={Sx.input}
            inputRef={message}
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
            error={errors[ReelReturnCancelErrorFields.OtherNoText]}
            placeholder={t('reelReturns.cancelReelReturn.reason.input')}
            defaultValue={''}
            inputProps={{
              maxLength: REASON_MAX_LENGTH,
            }}
          />
        </Grid>
      </Show>
    </Grid>
  );

  return (
    <BottomDrawer
      icon={<FolderOpen sx={{ color: 'secondary.main' }} />}
      title={
        reelReturn?.nciRmaNumber
          ? t('reelReturns.cancelReelReturn.titleWithRma', {
              rma: reelReturn?.nciRmaNumber,
            })
          : t('reelReturns.cancelReelReturn.title')
      }
      open={visible}
      height={drawerHeight}
    >
      <Grid container justifyContent={!isMobile ? 'space-between' : ''}>
        <Grid item display="flex" md={9} sm={12} py={2}>
          {reelReturn?.nciRmaNumber ? deletewithReason() : deleteNoReason()}
        </Grid>
        <Grid item sx={Sx.buttonContainer}>
          <Button
            disabled={saving}
            onClick={() => cancelReelReturnHandler()}
            variant="contained"
            sx={Sx.confirmButton}
          >
            {t('reelReturns.cancelReelReturn.reason.buttons.cancel')}
          </Button>
          <Button
            disabled={saving}
            onClick={() => closeModalHandler()}
            variant="outlined"
            sx={Sx.cancelButton}
          >
            {t('reelReturns.cancelReelReturn.reason.buttons.close')}
          </Button>
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default CancelReelReturn;
