import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication, useCurrentUser, usePermission } from '../hooks';
import { ProgressIndicator } from '../components';
import { UserPermission } from '../enums';

const Home: React.FC = () => {
  const { isAdmin, isAgent, role } = useAuthentication();
  const user = useCurrentUser();
  const { hasPermission, hasOneOfPermissions, loading } = usePermission();
  const navigate = useNavigate();

  useEffect(() => {
    if (role && user && !loading) {
      if (isAgent) {
        navigate('/agent/dashboard');
      } else if (isAdmin) {
        navigate('/admin/dashboard');
      } else {
        redirectCustomer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, navigate, loading, user]);

  const redirectCustomer = () => {
    if (
      hasPermission(UserPermission.ViewInventory) &&
      (user?.canSeeCSA || user?.canSeeUL)
    ) {
      if (user?.canSeeCSA) {
        navigate('/inventory/csa');
      } else {
        navigate('/inventory/ul');
      }
    } else if (
      hasOneOfPermissions([
        UserPermission.ManageCompanyReservations,
        UserPermission.ManageOwnReservations,
      ])
    ) {
      navigate('/reservations');
    } else if (hasPermission(UserPermission.ViewCompanyUsers)) {
      navigate('/users');
    } else {
      navigate('/settings');
    }
  };

  return <ProgressIndicator />;
};

export default Home;
