import { Avatar, Button, Drawer, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';

import { useForm, useUpdatePassword } from '../../../hooks';
import { Container, TextField } from '../../../components';
import { UpdatePasswordValidations } from '../../../validation';
import { ModificationMode } from '../../../enums';

const UpdatePassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const updatePassword = useUpdatePassword();

  const navigateBack = () => {
    navigate(location.pathname.replace('/password', ''));
  };

  const form = useForm(updatePassword, {
    mode: ModificationMode.Add,
    validations: UpdatePasswordValidations,
    defaultNavigationPrompt: false,
    onSaveSuccess: () => {
      navigateBack();
    },
  });

  return (
    <Drawer
      anchor="bottom"
      open={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          height: { xs: '450px', md: '250px' },
          background: '#2C2C2C',
          color: '#ffffff',
          overflow: 'hidden',
        },
      }}
    >
      <Container>
        <Grid display="flex" alignItems="center" mb={2} mt={5}>
          <Avatar
            sx={{
              backgroundColor: 'secondary.contrastText',
              border: 1,
              borderColor: 'secondary.main',
            }}
          >
            <LockIcon sx={{ color: 'secondary.main' }} />
          </Avatar>
          <Typography variant="h2" marginLeft="20px">
            {t('settings.password.title')}
          </Typography>
        </Grid>

        <Grid
          mt={2}
          display="flex"
          flexDirection="row"
          alignItems="end"
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            '& .MuiTextField-root': { ml: 1 },
          }}
        >
          <TextField
            form={form}
            field="oldPassword"
            label={t('settings.password.fields.oldPassword')}
            contrast
            type="password"
          />

          <TextField
            form={form}
            field="newPassword"
            label={t('settings.password.fields.newPassword')}
            contrast
            type="password"
          />

          <TextField
            form={form}
            field="confirmPassword"
            label={t('settings.password.fields.confirmPassword')}
            contrast
            type="password"
          />

          <Grid
            display="flex"
            mb={1.5}
            sx={{
              ml: { xs: 0, md: 2 },
              mt: { xs: 3, md: 0 },
              width: { xs: '100%', md: 'auto' },
              justifyContent: { xs: 'space-between', sm: 'right', md: '' },
              flexDirection: { xs: 'row-reverse', md: 'row' },
            }}
          >
            <Button
              disabled={
                !form.dirty ||
                form.saving ||
                Object.keys(form.errors).length > 0
              }
              onClick={() => form.save()}
              sx={{
                minWidth: { xs: '150px', sm: '250px', md: '80px' },
                backgroundColor: 'secondary.main',
                color: 'primary.contrastText',
                marginLeft: { xs: 0, sm: 2, md: 0 },
                marginRight: { xs: 0, md: 2 },
                '&:hover': {
                  backgroundColor: 'secondary.main',
                },
              }}
            >
              {t('forms.save')}
            </Button>
            <Button
              onClick={() => navigateBack()}
              sx={{
                minWidth: { xs: '150px', sm: '250px', md: '80px' },
                color: 'primary.contrastText',
                border: 1,
                borderColor: 'primary.contrastText',
                '&:hover': {
                  borderColor: 'primary.contrastText',
                },
              }}
            >
              {t('forms.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
};

export default UpdatePassword;
