import { useMemo } from 'react';
import { Button, Drawer, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getReelSizeTotal } from '../ReelReturnTable/utils';
import { CANADIAN_TAX_MAPPING } from '../../mappings/CountryProvincesOrStates';
import { ReelReturnStatus } from '../../enums';
import { useReelDepots } from '../../hooks';
import { Show } from '../Show/Show.component';
import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import * as Sx from './ReelReturnDrawer.styles';
import { ReelReturnDrawerProps } from './ReelReturnDrawer.props';
import { ReelReturnSummary } from './ReelReturnSummary';
import { ReelReturnDrawerAdmin } from './components/ReelReturnDrawerAdmin.component';
import { ReelReturnDrawerCustomer } from './components/ReelReturnDrawerCustomer.component';

export const ReelReturnDrawer = ({
  currencyFormatter,
  form,
  impersonatedUser,
  isAdmin,
  isDesktop,
  isMobile,
  isNewReelReturn,
  loading,
  reelSizes,
  saving,
  sendReturn,
}: ReelReturnDrawerProps) => {
  const { t } = useTranslation();
  const { list: reelDepots } = useReelDepots();

  const reelReturnStats = useMemo(() => {
    const { quantity, subTotal } = form.item?.reels
      ? Object.entries(form.item?.reels).reduce(
          (acc, [reelId, reelQuantity]) => {
            const reelInfo = reelSizes.find(
              (reelSize) => reelId === reelSize.id
            );
            if (reelInfo) {
              return {
                quantity: acc.quantity + reelQuantity,
                subTotal:
                  acc.subTotal + getReelSizeTotal(reelQuantity, reelInfo),
              };
            }

            return acc;
          },
          { quantity: 0, subTotal: 0.0 }
        )
      : { quantity: 0, subTotal: 0.0 };

    let taxPercentage = -1;
    let tax = -1;
    let total = -1;

    if (form.item?.depotId) {
      const taxProvince =
        reelDepots.find((depot) => depot.id === form.item?.depotId)?.address
          .provinceOrState ?? 'default';

      taxPercentage = CANADIAN_TAX_MAPPING[taxProvince];

      tax = subTotal * taxPercentage;
      total = subTotal + tax;
    }

    const formattedTax = tax >= 0 ? currencyFormatter.format(tax) : '--';
    const formattedSubTotal = currencyFormatter.format(subTotal);
    const formattedTotal = total >= 0 ? currencyFormatter.format(total) : '--';
    const formattedTaxPercentage =
      taxPercentage >= 0 ? `${taxPercentage * 100}` : '';

    return {
      quantity,
      subTotal: formattedSubTotal,
      tax: formattedTax,
      taxPercentage: formattedTaxPercentage,
      total: formattedTotal,
    } as ReelReturnSummary;
  }, [
    currencyFormatter,
    form.item?.depotId,
    form.item?.reels,
    reelDepots,
    reelSizes,
  ]);

  const buttonDisabled = useMemo(
    () =>
      loading ||
      form.saving ||
      form.item?.status === ReelReturnStatus.Received ||
      Object.values(form.errors).length > 0 ||
      saving,
    [form.errors, form.item?.status, form.saving, loading, saving]
  );

  return (
    <Drawer
      open={true}
      variant="permanent"
      anchor="bottom"
      PaperProps={{
        sx: isAdmin ? Sx.adminDrawer : Sx.drawerPaper,
      }}
    >
      <Grid container alignItems="center" sx={{ height: '100%' }}>
        <Show if={isAdmin}>
          <ReelReturnDrawerAdmin
            company={impersonatedUser?.companyName || form?.item?.companyName}
            isNewReelReturn={isNewReelReturn}
            linearFeet={form.item?.linearFeet ?? 0}
            reelReturnStats={reelReturnStats}
            totalTrailerNeeded={form.item?.totalTrailerNeeded ?? 0}
            user={impersonatedUser?.name || form?.item?.creatorName}
          />
        </Show>
        <Show if={!isAdmin}>
          <ReelReturnDrawerCustomer
            isDesktop={isDesktop}
            isMobile={isMobile}
            linearFeet={form.item?.linearFeet ?? 0}
            reelReturnStats={reelReturnStats}
            totalTrailerNeeded={form.item?.totalTrailerNeeded ?? 0}
          />
        </Show>

        {/* Button Section */}
        <Grid
          container
          item
          pt={isMobile ? 1 : undefined}
          md={2}
          alignItems="center"
          justifyContent="center"
          sx={Sx.buttonSection}
        >
          <Grid item>
            <Show if={isAdmin}>
              <ContrastButtonMain
                disabled={buttonDisabled}
                onClick={sendReturn}
                sx={Sx.buttonStyles}
              >
                {t('reelReturns.edit.drawer.sendButton')}
              </ContrastButtonMain>
            </Show>
            <Show if={!isAdmin}>
              <Button
                disabled={buttonDisabled}
                variant="contained"
                color="primary"
                onClick={sendReturn}
                sx={Sx.buttonStyles}
              >
                {t('reelReturns.edit.drawer.sendButton')}
              </Button>
            </Show>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};
