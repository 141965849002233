import { Company } from '../models';
import { AssociatedCompany } from '../types';

export interface SelectableCompany {
  id: string;
  name: string;
}

export const getSelectableCompanies = (
  companies?: Company[] | AssociatedCompany[]
) => {
  if (!companies) return [];

  return companies.map((company) => {
    if ('companyId' in company) {
      return {
        id: company.companyId,
        name: company.companyName,
      };
    } else {
      return {
        id: company.id,
        name: company.name,
      };
    }
  }) as SelectableCompany[];
};
