import { useEffect, useMemo } from 'react';
import { useAuth, useFunctions } from 'reactfire';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { httpsCallable } from 'firebase/functions';

import { DecoratedHeader, UnitaryModal } from '../components';
import {
  useCurrentUser,
  useNotification,
  usePortalSettings,
  useProgress,
} from '../hooks';
import { Country, NotificationLevel } from '../enums';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import { defaultLanguageUpper } from '../types/Languages';

const NotVerified = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser: authUser } = useAuth();
  const user = useCurrentUser();
  const functions = useFunctions();
  const { showProgress, visible } = useProgress();
  const { addNotification } = useNotification();
  const sendActivation = httpsCallable(functions, 'resendEmailVerification');
  const { item: settings } = usePortalSettings();

  const phoneNumber = useMemo(() => {
    if (!settings?.contactPhoneNumber || !user.language) return '';

    return formatPhoneNumber(
      settings.contactPhoneNumber[user.language || defaultLanguageUpper]
    );
  }, [settings?.contactPhoneNumber, user.language]);

  const email = useMemo(() => {
    if (!settings?.contactEmail) return '';

    const contactEmail =
      user.country === Country.UnitedStates
        ? settings.contactEmailUS
        : settings.contactEmail;

    return (
      contactEmail[user.language ?? defaultLanguageUpper] ??
      contactEmail[defaultLanguageUpper]
    );
  }, [
    settings?.contactEmail,
    settings?.contactEmailUS,
    user.language,
    user.country,
  ]);

  useEffect(() => {
    if (authUser?.emailVerified) {
      navigate('/');
    }
  }, [authUser?.emailVerified, navigate]);

  const onSendClicked = () => {
    showProgress(true);
    addNotification(
      t('emailValidation.information'),
      NotificationLevel.Information
    );

    sendActivation(authUser?.uid)
      .then(() => {
        addNotification(
          t('emailValidation.success'),
          NotificationLevel.Success
        );
      })
      .catch(() => {
        addNotification(t('emailValidation.error'), NotificationLevel.Error);
      })
      .finally(() => {
        showProgress(false);
      });
  };

  return (
    <UnitaryModal>
      <DecoratedHeader
        icon={<MarkEmailReadIcon />}
        title={t('emailValidation.title')}
      />

      <Grid display="flex" flexDirection="column" justifyContent="left">
        <Typography>{t('emailValidation.message')}</Typography>

        <Grid mt={2} width="100%">
          <Button
            fullWidth
            variant="contained"
            onClick={onSendClicked}
            disabled={visible}
          >
            {t('emailValidation.sendEmail')}
          </Button>
        </Grid>

        <Grid mt={2}>
          <Typography variant="subtitle1">
            {t('emailValidation.subtitle1')}
          </Typography>

          <Typography variant="subtitle1">
            <Trans
              i18nKey="emailValidation.subtitle2"
              components={[
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>,
                <a href={`mailto:${email}`}>{email}</a>,
              ]}
              values={{ phoneNumber, email }}
            />
          </Typography>
        </Grid>
      </Grid>
    </UnitaryModal>
  );
};

export default NotVerified;
