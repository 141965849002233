import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import { usePageTitle, useReelReturnsOfUser, useRouterOutlet } from '../hooks/';
import { getPageTitle } from '../utils/pages';
import {
  Container,
  ContrastButtonMain,
  DecoratedHeader,
  ReelReturnsTable,
} from '../components';
import { Country, UserPermission } from '../enums';
import { ReelReturn } from '../models';
import { CountrySpecific, RequirePermission } from '../security';
import { CancelReelReturn } from './modals';

const ReelReturns: React.FC = () => {
  const { t } = useTranslation();
  const { showOutletOnly } = useRouterOutlet();
  const navigate = useNavigate();
  usePageTitle(getPageTitle('reelReturns'));
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/reel-returns') {
      setShowBottomDrawer(false);
      setReelReturnToCancel(null);
    }
  }, [location.pathname]);

  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const [reelReturnToCancel, setReelReturnToCancel] =
    useState<ReelReturn | null>(null);

  const onCancelReelReturn = (item: ReelReturn) => {
    setReelReturnToCancel(item);
    setShowBottomDrawer(true);
  };

  return (
    <RequirePermission oneOf={[UserPermission.ManageReelReturns]}>
      <CountrySpecific country={Country.Canada}>
        {showOutletOnly || (
          <React.Fragment>
            <Container>
              <DecoratedHeader
                icon={<GroupWorkIcon />}
                title={t('pages.reelReturns.pageTitle')}
                variant="colored"
              >
                <ContrastButtonMain
                  fullWidth
                  sx={{ mt: 3 }}
                  onClick={() => navigate('new')}
                >
                  {t('reelReturns.page.createNewReturnButton')}
                </ContrastButtonMain>
              </DecoratedHeader>
            </Container>
            <ReelReturnsTable
              isAdmin={false}
              reelReturnsHook={useReelReturnsOfUser}
              cancelReelReturn={onCancelReelReturn}
              filters={null}
            />
          </React.Fragment>
        )}
        <CancelReelReturn
          reelReturn={reelReturnToCancel}
          visible={showBottomDrawer}
          visibilitySet={setShowBottomDrawer}
        />
        <Outlet
          context={{
            impersonatedUser: null,
          }}
        />
      </CountrySpecific>
    </RequirePermission>
  );
};

export default ReelReturns;
