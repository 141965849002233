import { SxProps } from '@mui/material';

export const badge: SxProps = {
  '& .MuiBadge-badge': {
    top: '0.8em',
    right: '0.9em',
  },
};

export const menuItem = (currentMatch: boolean): SxProps => {
  return {
    m: 0,
    p: 0,
    py: 1,
    mx: 1,
    px: 1,
    borderBottomWidth: '2px',
    borderBottomColor: currentMatch ? 'black' : 'transparent',
    borderBottomStyle: 'solid',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  };
};

export const menuItemTypography: SxProps = {
  fontSize: '0.75rem',
  fontWeight: '700',
};
