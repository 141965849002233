import { SxProps } from '@mui/material';

export const card: SxProps = {
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 0,
};

export const cardContent: SxProps = {
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  flexGrow: 1,
};
