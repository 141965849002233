import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import '../i18n/i18n';
import { defaultLanguage } from '../types/Languages';
import { useAppLanguage } from '../hooks';

interface Props {
  children: React.ReactNode;
}

const InternationalizationProvider = ({ children }: Props) => {
  const { i18n } = useTranslation();
  useAppLanguage();

  useEffect(
    () =>
      document.documentElement.setAttribute(
        'lang',
        i18n.language ?? defaultLanguage
      ),
    [i18n.language]
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={i18n.language}
    >
      {children}
    </LocalizationProvider>
  );
};

export default InternationalizationProvider;
