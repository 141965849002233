import { TextField as MuiTextField } from '@mui/material';

import { useDebouncedFormFieldValue } from '../../hooks';
import { TextFieldProps } from './TextField.props';

export const TextField = <T,>({
  autocomplete,
  autoFocus = false,
  contrast = false,
  customError,
  disabled,
  displayCustomError = false,
  field,
  form,
  label,
  maxLength,
  maxRows,
  minRows,
  multiline,
  required,
  type,
}: TextFieldProps<T>) => {
  const { value, setDelayedValue, setValue } = useDebouncedFormFieldValue<
    T,
    string | undefined
  >(form, field);

  const id = field as string;

  return (
    <MuiTextField
      id={id}
      autoFocus={autoFocus}
      aria-labelledby={`${id}-label`}
      variant={contrast ? 'filled' : 'outlined'}
      disabled={disabled}
      error={!!form.errors[field] || displayCustomError}
      fullWidth
      helperText={form.errors[field] || (displayCustomError && customError)}
      inputProps={{
        autoComplete: autocomplete,
        maxLength: maxLength,
        ...(contrast && { sx: { backgroundColor: '#eee' } }),
      }}
      inputRef={form.fieldRef(id)}
      label={label}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      onChange={(event) =>
        setDelayedValue(event.target.value, form.fieldRef(id))
      }
      onBlur={(event) => setValue(event.target.value)}
      required={required}
      sx={{
        mt: 1.5,
        mb:
          (!!form.errors[field] || displayCustomError) && contrast ? -0.5 : 1.5,
      }}
      value={value || ''}
      type={type || 'text'}
    />
  );
};
