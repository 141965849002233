import { Container as MuiContainer, Grid, Typography } from '@mui/material';

import { Container } from '..';

import { FilterBarProps } from './FilterBar.props';
import * as Sx from './FilterBar.styles';

export const FilterBar = ({ title, children, buttons }: FilterBarProps) => (
  <Container backgroundColor="#f9f9f9" roundedTopCorners={true}>
    <MuiContainer maxWidth="xl" sx={Sx.container}>
      <Grid display="flex" justifyContent="space-between">
        <Grid>
          <Typography variant="h2" sx={{ mb: { xs: 2, md: 6 } }}>
            {title}
          </Typography>
          <Grid display="flex" justifyContent="flex-start" sx={Sx.item}>
            {children}
          </Grid>
        </Grid>

        <Grid>{buttons}</Grid>
      </Grid>
    </MuiContainer>
  </Container>
);
