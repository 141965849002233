import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';

import { Container, ContrastButtonLight, Show } from '../../components';

import { FormControlHeaderProps } from './FormControlHeader.props';
import * as Sx from './FormControlHeader.styles';

export const FormControlHeader = <T,>({
  title,
  form,
  backTo,
  saveButtonLabel,
  userIsAwaitingApproval = false,
}: FormControlHeaderProps<T>) => {
  const { t } = useTranslation();

  const buttonJustification = userIsAwaitingApproval
    ? 'space-between'
    : 'flex-end';

  return (
    <Container
      background="linear-gradient(270deg, #ee891b 0%, #e27305 100%)"
      roundedTopCorners={true}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Show if={Boolean(title)}>
          <Grid item maxWidth="xl">
            <Typography component="h2" variant="h2" sx={Sx.title} color="white">
              {title}
            </Typography>
          </Grid>
        </Show>
        <Grid item maxWidth="xl" sx={Sx.grid} flexGrow={1}>
          <Grid container justifyContent={buttonJustification}>
            <Show if={userIsAwaitingApproval}>
              <Grid item maxWidth="xl" sx={Sx.grid}>
                <Button
                  disabled={!form.valid || form.saving}
                  component={Link}
                  to={'reject/'}
                  variant="contained"
                  sx={Sx.rejectButton}
                >
                  {t('users.actions.rejectRequest')}
                </Button>
              </Grid>
            </Show>
            <Grid item maxWidth="xl" sx={Sx.grid}>
              <Show if={form.dirty && userIsAwaitingApproval}>
                <Button
                  disabled={!form.valid || form.saving}
                  onClick={() => form.save(true)}
                  variant="contained"
                  sx={Sx.button}
                >
                  {t('forms.saveDraft')}
                </Button>
              </Show>
              <Show if={form.dirty || userIsAwaitingApproval}>
                <Button
                  disabled={!form.valid || form.saving}
                  onClick={() => form.save()}
                  variant="contained"
                  sx={Sx.button}
                >
                  {saveButtonLabel || t('forms.save')}
                </Button>
              </Show>
              <Show if={form.dirty}>
                <ContrastButtonLight
                  onClick={form.revert}
                  variant="outlined"
                  disabled={form.saving}
                >
                  {t('forms.cancel')}
                </ContrastButtonLight>
              </Show>
              <Show if={!form.dirty && Boolean(backTo)}>
                <Button
                  component={Link}
                  to={backTo as To}
                  variant="contained"
                  sx={Sx.button}
                >
                  {t('forms.back')}
                </Button>
              </Show>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
