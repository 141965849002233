import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompaniesTableType, CompanyType } from '../../enums';
import {
  useSearchableFilter,
  useSorting,
  usePagination,
  useCompanies,
} from '../../hooks';
import { Company } from '../../models';
import { DataTable } from '../../tables';
import { Filter } from '../../types';
import { getCompaniesTableColumns } from '../CompaniesTable/CompaniesTableColumns';
import { CompaniesTableMenuItems } from '../CompaniesTable/CompaniesTableMenuItems.component';
import { AgenciesTableProps } from './AgenciesTable.props';

export const AgenciesTable = React.memo(
  ({ salesRepId, name, onDeleteClick }: AgenciesTableProps) => {
    const { t } = useTranslation();
    const { filters: salesRepIdFilters } = useSearchableFilter({
      value: salesRepId,
      property: 'searchSalesRepIdsNormalized',
      operator: 'array-contains',
    });

    const { filters: nameFilters } = useSearchableFilter({
      value: name,
      property: 'nameNormalized',
    });

    const forcedSortField = salesRepId
      ? 'salesRepIdsNormalized'
      : name
      ? 'nameNormalized'
      : undefined;

    const sorting = useSorting('name', { forcedSortField });

    const filters = useMemo(
      () => [
        ['type', '==', CompanyType.Agency] as Filter,
        ...(nameFilters.length === 0 ? salesRepIdFilters : []),
        ...(salesRepIdFilters.length === 0 ? nameFilters : []),
      ],
      [nameFilters, salesRepIdFilters]
    );

    const pagination = usePagination();

    const { list: agenciesList, loading } = useCompanies({
      sorting,
      pagination,
      filters,
    });

    const menuItems = (company: Company) => (
      <CompaniesTableMenuItems
        company={company}
        onDeleteClick={onDeleteClick}
      />
    );

    return (
      <DataTable
        data={!loading ? agenciesList : undefined}
        sorting={sorting}
        pagination={pagination}
        menuItems={menuItems}
      >
        {getCompaniesTableColumns(CompaniesTableType.Agencies, t)}
      </DataTable>
    );
  }
);
