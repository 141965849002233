import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { TextField } from '@mui/material';
import { RequirePermission } from '../../security';
import { UserPermission } from '../../enums/';
import { Container, FilterBar } from '../../components';
import { usePageTitle, useRouterOutlet } from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import { AgenciesTable } from '../../components/AgenciesTable/AgenciesTable.component';
import { CompaniesPartial } from './partials';

const Agencies: React.FC = () => {
  const { t } = useTranslation();
  const { showOutletOnly } = useRouterOutlet();
  const navigate = useNavigate();
  usePageTitle(getPageTitle('agencies'));
  const params = useParams();

  const showTabs = Object.keys(params).length === 0;

  const [name, setName] = useState('');
  const [salesRepId, setSalesRepId] = useState('');
  const [debouncedName] = useDebounce(name, 500);
  const [debouncedSalesRepId] = useDebounce(salesRepId, 500);

  useEffect(() => {
    if (name) {
      setSalesRepId('');
    }
  }, [name]);

  useEffect(() => {
    if (salesRepId) {
      setName('');
    }
  }, [salesRepId]);

  return (
    <RequirePermission oneOf={[UserPermission.ManageAgencies]}>
      <CompaniesPartial showTabs={showTabs}>
        {showOutletOnly || (
          <>
            <FilterBar title={t('agencies.filterAgencies')}>
              <TextField
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                label={t('agencies.fields.agencyName')}
              />
              <TextField
                value={salesRepId}
                onChange={(e) => setSalesRepId(e.currentTarget.value)}
                label={t('agencies.fields.salesRepId')}
              />
            </FilterBar>

            <Container>
              <AgenciesTable
                salesRepId={debouncedSalesRepId}
                name={debouncedName}
                onDeleteClick={(company) =>
                  navigate(`/admin/agencies/${company.id}/delete`)
                }
              />
            </Container>
          </>
        )}
        <Outlet />
      </CompaniesPartial>
    </RequirePermission>
  );
};

export default Agencies;
