import { SxProps } from '@mui/material';

export const button: SxProps = { mt: 3, mb: 2 };

export const container: SxProps = { mt: 1, maxWidth: '25rem' };

export const passwordHelpText: SxProps = {
  fontSize: 'subtitle1.fontSize',
  fontStyle: 'italic',
};
