import { v4 as uuidv4 } from 'uuid';
import { FirebaseFile } from './FirebaseFile';

export const BulletinBoardItemConverter = {
  toFirestore: (item: BulletinBoardItem) => {
    return {
      order: item.order,
      storageId: item.storageId,
      fileName: item.fileName,
      fileExtension: item.fileExtension,
      storageBucket: item.storageBucket,
      thumbnailStorageId: item.thumbnailStorageId,
      width: item.width,
      height: item.height,
    };
  },
};

export class BulletinBoardItem extends FirebaseFile {
  constructor(file: File, image: HTMLImageElement, storageBucket: string) {
    super();
    const fullName = file.name;
    this.order = 0;
    this.width = image.width;
    this.height = image.height;
    this.fileName = fullName.substring(0, fullName.lastIndexOf('.'));
    this.fileExtension = `.${fullName.split('.').pop() ?? ''}`;
    this.storageBucket = storageBucket;
    this.storageId = uuidv4();
    this.thumbnailStorageId = this.storageId + '-thumbnail';

    // local file only
    this.src = image.src;
    this.file = file;
  }
  order: number;
  thumbnailStorageId!: string;
  width!: number;
  height!: number;

  // local file only
  src?: string;
  file?: File;
}
