import { InventoryFilters } from '../../types';

export const emptyFilters: InventoryFilters = {
  material: null,
  numberOfConductors: null,
  partNumber: '',
  productType: null,
  productSubType: null,
  searchByCable: true,
  gauge: null,
  voltage: null,
};

export type PageParameters = {
  type: 'csa' | 'ul';
};
