import { Box } from '@mui/material';
import { ErrorLayoutProps } from './ErrorLayout.props';

export const ErrorLayout = ({ children }: ErrorLayoutProps) => (
  <Box
    sx={{
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'primary.main',
    }}
  >
    {children}
  </Box>
);
