import { SxProps } from '@mui/material';

export const listItemIcon = (isMatch: boolean): SxProps => {
  return {
    pb: 0.5,
    minWidth: '0 !important',
    '& .MuiSvgIcon-root': {
      color: isMatch ? 'secondary.main' : 'primary.contrastText',
      width: '1em',
      height: '1em',
    },
    '.MuiList-root &': {
      pr: 2,
    },
    '.MuiMenu-list &': {
      pl: 1,
    },
  };
};

export const listItemText = (hasIcon: boolean): SxProps => {
  return {
    pt: hasIcon ? 0 : { xs: 0, md: 3.5 },
    pb: 0.5,
  };
};

export const menuItem = (isMatch: boolean, hasTo: boolean): SxProps => {
  return {
    pt: 1.5,
    pb: 0.5,
    px: 3,
    mx: 1,
    flexDirection: { xs: 'row-reverse', md: 'column' },
    color: hasTo && isMatch ? 'secondary.main' : 'primary.contrastText',
    letterSpacing: '1px',
    borderColor: hasTo && isMatch ? 'secondary.main' : 'transparent',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    '.MuiList-root &': {
      py: 1.75,
      pl: 2.5,
      pr: 1.25,
      mx: 0,
      flexDirection: 'row',
      borderBottomWidth: '0',
      minWidth: '10em',
    },
    '.MuiList-root &:after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      right: '0',
      top: '0',
      backgroundColor: 'secondary.main',
      display: isMatch ? 'block' : 'none',
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    },
    '.MuiList-root & .MuiTypography-body1': {
      fontSize: '1rem',
    },
    '.MuiMenu-list &': {
      py: 2,
      pr: 0,
      pl: 2,
      ml: 0,
      flexDirection: 'row-reverse',
      borderBottomWidth: '0',
      minWidth: '10em',
    },
    '.MuiMenu-list &:after': {
      display: 'none',
    },
    '&:hover, &:focus': {
      color: 'secondary.main',
      '.MuiSvgIcon-root': {
        color: 'secondary.main',
      },
    },
  };
};
