import React, { ErrorInfo } from 'react';
import { ErrorCategory } from '../../enums';
import HttpError from '../../pages/error/HttpError';

interface Props {
  children: React.ReactNode;
}

interface State {
  errorCategory: ErrorCategory | null;
}

const ErrorCategoryErrorRegexes: Record<ErrorCategory, RegExp[]> = {
  notFound: [/not-found/],
  accessDenied: [/permission-denied/],
  expiredLink: [/expired-link/],
  unknown: [],
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorCategory: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorText = error.toString();
    console.error('Uncaught error:', error, errorInfo);
    let errorCategory = ErrorCategory.Unknown;
    for (const [category, regexes] of Object.entries(
      ErrorCategoryErrorRegexes
    )) {
      for (const regex of regexes) {
        if (errorText.match(regex)) {
          errorCategory = category as ErrorCategory;
        }
      }
    }

    this.setState({ errorCategory: errorCategory });
  }

  render() {
    if (this.state.errorCategory) {
      return <HttpError errorCategory={this.state.errorCategory} />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}
