import { ReelReturn } from '../models';
import { CollectionQueryOptions } from '../types';
import { useCollection } from './useCollection';
import { useCollectionGroup } from './useCollectionGroup';
import { useCurrentUser } from './useCurrentUser';

export function useReelReturnsOfUser(options?: CollectionQueryOptions) {
  const user = useCurrentUser();
  return useCollection<ReelReturn>(
    `Companies/${user.companyRef.id}/ReelReturns`,
    [],
    options?.sorting || { sortField: 'returnByDate', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}

export function useReelReturnsOfAdmin(options?: CollectionQueryOptions) {
  return useCollectionGroup<ReelReturn>(
    'ReelReturns',
    [],
    options?.sorting || { sortField: 'returnByDate', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
