import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  CompanyUserList,
  ProgressIndicator,
  Show,
  CompanyDetails,
  CompanyUserPermissionsList,
} from '../../components';
import { CompanyPageView, CompanyType, UserPermission } from '../../enums';

import {
  useCompany,
  usePageTitle,
  usePermission,
  useRouterOutlet,
} from '../../hooks';
import { RequirePermission } from '../../security';
import { getPageTitle } from '../../utils/pages';
import { CompanyPartial } from './partials';

interface Props {
  view?: CompanyPageView;
}

const Company: React.FC<Props> = ({ view = CompanyPageView.AssignedUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { showOutletOnly } = useRouterOutlet();
  const { companyId } = useParams();
  const company = useCompany(companyId || '');
  usePageTitle(
    view === CompanyPageView.OrderFilePermissions
      ? getPageTitle('company.permissions', { name: company.item?.name })
      : getPageTitle('company.edit', { name: company.item?.name })
  );

  useEffect(() => {
    if (company.item && company.item?.type === CompanyType.Agency) {
      navigate(`/admin/agencies/${companyId}`);
    }
    if (!company.loading && !company.item) {
      navigate('/admin/companies');
    }
  }, [companyId, company, navigate]);

  const showUserList = Boolean(
    hasPermission(UserPermission.ManageCustomerUsers) &&
      view === CompanyPageView.AssignedUsers
  );

  const showPermissionSettings = Boolean(
    hasPermission(UserPermission.ManageCustomerUsers) &&
      hasPermission(UserPermission.ManageOrderFilesAccess) &&
      view === CompanyPageView.OrderFilePermissions
  );

  return (
    <>
      {showOutletOnly || (
        <CompanyPartial companyId={companyId}>
          <React.Fragment>
            {company.loading ? (
              <ProgressIndicator />
            ) : (
              <>
                <Show if={showUserList}>
                  <CompanyDetails
                    company={company}
                    companyType={CompanyType.Customer}
                  />
                </Show>
                <Show if={showUserList}>
                  <CompanyUserList companyId={companyId} />
                </Show>

                <RequirePermission
                  oneOf={[UserPermission.ManageOrderFilesAccess]}
                >
                  <Show if={showPermissionSettings}>
                    <CompanyUserPermissionsList
                      backTo={'/admin/companies'}
                      company={company}
                      headerTitle={t(
                        'companies.orderFilePermissions.pageTitle'
                      )}
                      formHeaderTitle={t('companies.form.title', {
                        name: company.item?.name,
                      })}
                    />
                  </Show>
                </RequirePermission>
              </>
            )}
          </React.Fragment>
        </CompanyPartial>
      )}

      <Outlet />
    </>
  );
};

export default Company;
