import { MeasurementUnits } from '../enums';
import { Reel } from '../models';
import { convertQuantity } from './units';

export default function formatPackagedReelQuantity(
  unitsType: MeasurementUnits,
  reel: Reel
): string {
  if (reel.packageLength) {
    return `${reel.quantity / (reel.packageLength as number)}x${convertQuantity(
      unitsType,
      reel.packageLength as number
    )}`;
  } else {
    return `${convertQuantity(unitsType, reel.quantity as number)}`;
  }
}
