import { useTranslation } from 'react-i18next';
import { SupervisedUserCircle } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { QuestionDrawer } from '../../../components';
import { useNotification, useProgress } from '../../../hooks';
import { NotificationLevel } from '../../../enums';
import { ModalProps } from '../../../types/Modal';

interface RejectUserRequestProps extends ModalProps {
  inProfile?: boolean;
}

const RejectUserRequest: React.FC<RejectUserRequestProps> = (props) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress, visible: progressVisible } = useProgress();
  const functions = useFunctions();
  const rejectFunction = httpsCallable(functions, 'rejectUserRequest');
  const navigate = useNavigate();
  const { userId } = useParams();

  const rejectUserRequest = async () => {
    showProgress(true);

    addNotification(
      t('users.rejectUserRequest.progress'),
      NotificationLevel.Information
    );

    try {
      await rejectFunction(userId);
      addNotification(
        t('users.rejectUserRequest.success'),
        NotificationLevel.Success
      );

      showProgress(false);
      navigateBack(true);
    } catch {
      addNotification(
        t('users.rejectUserRequest.error'),
        NotificationLevel.Error
      );
      showProgress(false);
      navigateBack();
    }
  };

  const navigateBack = (success = false) => {
    if (props.inProfile && !success) {
      return navigate(`/admin/users/${userId}`);
    }

    navigate('/admin/users/awaitingApproval');
  };

  return (
    <QuestionDrawer
      disabled={progressVisible}
      open={true}
      icon={<SupervisedUserCircle sx={{ color: 'secondary.main' }} />}
      message={t('users.rejectUserRequest.message')}
      onConfirm={() => rejectUserRequest()}
      onCancel={() => navigateBack()}
      title={t('users.rejectUserRequest.title')}
    />
  );
};

export default RejectUserRequest;
